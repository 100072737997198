import {put, takeEvery} from 'redux-saga/effects';
import history from '../libs/history';
import ability from "../ability";
import {RULES_SET} from '../creators/rules';
import {TYPES as CURRENT_USER_TYPES} from '../creators/currentUser';


function* resetRelated(action) {
    yield put({type: "CURRENT_USER_RESET"});
    if(action.error.response && action.error.response.statusCode && (action.error.response.statusCode === 404 || action.error.response.statusCode === 401)){
        localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME);
        history.push('/connexion');
    }
}

export function* fetchSuccess(action) {
    try {
        // console.log(gen);

        if (action.payload && action.payload.rules) {
            let rules = action.payload.rules;
            delete action.payload.rules;
            yield put({type: RULES_SET, rules});
            // yield put({type: "CURRENT_USER_TEST", data : {test : "lalap"}});
            if (rules) yield ability.update(rules);

        }

    } catch (error) {
        console.log(error, "error");
        resetRelated();
    }
}

export function* watchCurrentUser() {
    yield takeEvery(CURRENT_USER_TYPES.GET_ONE_SUCCESS, fetchSuccess);
    yield takeEvery(CURRENT_USER_TYPES.GET_ONE_FAIL, resetRelated);
}

export default watchCurrentUser;
