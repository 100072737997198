import React, {Fragment} from 'react';
import {Col, Row} from "react-bootstrap";
import DraftContentReader from "./editor/DraftContentReader";
import {config} from "../../config";
import moment from "moment-timezone";


const Warning = (props) => {
    let {
        contentRaw = {},
        moderatorNoteRaw = {},
        moderator = {},
        tag = {},
        restriction = {
            type : 0,
            isPermanent : false,
            startedAt : null,
            endedAt: null
        },
        createdAt = null
    } = props;

    let createdDate = createdAt ? moment(createdAt) : null;
    let endDate = restriction.endedAt ?  moment(restriction.endedAt) : null;

    return (
        <Fragment>
            <Row>
                <Col sm={6} >
                    <p className={"m-0"}><b>Raison : </b> {tag.title || "Inconnu"}</p>
                </Col>
                <Col sm={6} >
                    <p className={"m-0"}><b>Restriction : </b> {config.warningRestriction[restriction.type]}</p>
                </Col>
            </Row>
            {restriction.type === 1 ? (
                <Row>
                    <Col sm={6} >

                    </Col>
                    <Col sm={6} >
                        <p className={"m-0"}><b>Durée : </b>
                            {restriction.isPermanent ? "Permanente" :
                                restriction.startedAt ?
                                    `du ${moment(restriction.startedAt).format("DD/MM/YYYY")} au ${moment(restriction.endedAt).format("DD/MM/YYYY")}`
                                    :
                                    (
                                        `${endDate.diff(createdDate, 'hours') + 1} h`
                                    )
                            }
                        </p>
                    </Col>
                </Row>
            ) : null}

            <Row>
                <Col sm={12} className={"mt-4"} >
                    <p className={"m-0"}><b>Note pour le membre : </b></p>
                    <DraftContentReader contentRaw={contentRaw} />
                </Col>
            </Row>
            <Row>
                <Col sm={12} className={"mt-4"} >
                    <p className={"m-0"}><b>Note de "{moderator.username || "Inconnu"}" : </b></p>
                    <DraftContentReader contentRaw={moderatorNoteRaw} />
                </Col>
            </Row>
        </Fragment>
    )
};
export default Warning;
