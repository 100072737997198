import React from 'react';
import {Button, Form} from "react-bootstrap";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {updateUser} from "../../creators/user";

import {SpinLoader} from "../components/Loader";
import FormFields from "../components/FormFields";


class FormSettingCommentAndVisitor extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            allowed: this.props.user.setting.comment.allowed,
            toValidate: this.props.user.setting.comment.toValidate,
            displayLastVisitors: this.props.user.setting.displayLastVisitors,
            chatWithOnlyWithFriends : this.props.user.setting.chatWithOnlyWithFriends
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.props.userLoading) {
            let currentUser = {
                setting: {
                    comment : {
                        allowed: this.state.allowed,
                        toValidate: this.state.toValidate,
                    },
                    displayLastVisitors: this.state.displayLastVisitors,
                    chatWithOnlyWithFriends : this.state.chatWithOnlyWithFriends
                },
            };

            this.props.updateUser(this.props.user._id, currentUser);
        }
    }

    render() {
        return (
            <Form onSubmit={(e) => this.handleSubmit(e)}>
                <h4 className={"w-100"}>Commentaires & visiteurs</h4>
                <FormFields
                    type={"checkbox"}
                    checkBox={{
                        id : `checkbox-allowed`,
                        name : "allowed",
                        checked : this.state.allowed,
                        label : "Permettre aux membres de publier des statuts sur mon profil",
                        onChange : () =>{this.setState({allowed : !this.state.allowed})}
                    }}
                />
                <FormFields
                    type={"checkbox"}
                    checkBox={{
                        id : `checkbox-toValidate`,
                        name : "toValidate",
                        checked : this.state.toValidate,
                        label : "Permettre aux membres de mettre des commentaires sur mes statuts",
                        onChange : () =>{this.setState({toValidate : !this.state.toValidate})}
                    }}
                />
                <FormFields
                    type={"checkbox"}
                    checkBox={{
                        id : `checkbox-displayLastVisitors`,
                        name : "displayLastVisitors",
                        checked : this.state.displayLastVisitors,
                        label : "Afficher les derniers visiteurs sur mon profil",
                        onChange : () =>{this.setState({displayLastVisitors : !this.state.displayLastVisitors})}
                    }}
                />
                <FormFields
                    type={"checkbox"}
                    checkBox={{
                        id : `checkbox-chatWithOnlyWithFriends`,
                        name : "chatWithOnlyWithFriends",
                        checked : this.state.chatWithOnlyWithFriends,
                        label : "Etre contacté sur le chat uniquement par mes amis",
                        onChange : () =>{this.setState({chatWithOnlyWithFriends : !this.state.chatWithOnlyWithFriends})}
                    }}
                />
                <Button variant="primary" type="submit" className={"btn-loading"}>
                    Valider {this.props.userLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                </Button>
            </Form>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        userLoading: state.user.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateUser
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormSettingCommentAndVisitor);
