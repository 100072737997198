import React from "react";
import {Link} from "react-router-dom";
import {Button, ButtonGroup, Col, Dropdown, DropdownButton, Image, OverlayTrigger, Row, Tooltip} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalculator, faEdit} from "@fortawesome/free-solid-svg-icons";
import "./CardModerationMedia.scss";
import createLink from "../../../../../libs/createLink";
import _ from "lodash";

const Score = ({score, posterGender}) =>{
    if(score){
        let genderList = ['Non défini', 'Homme', 'Femme', 'Couple hétéro', 'Couple lesbien', 'Couple gay', 'Travesti'];
        return <div className={"moderation-score"}>
            {score.map(item =><li key={item.label}>{item.label} - {item.value}</li>)}
            <li>Genre du profil - {genderList[posterGender] || "Non défini"}</li>
        </div>
    }
    else{
        return <div className={"moderation-score"}>Aucune data</div>;
    }
};

const CardModerationMedia = ({
        iaKnnId = null,
        _id = null,
        slug = "",
        kind = "gallery",
        title = "",
        categories = [],
        previewUrl = "",
        authorName= "",
        owner = {
            _id : null,
            username : "",
            profile : {
                avatar : "",
                gender : 0
            }
        },
        posterGender = 0,
        modelVersion = 0,
        temperatureList = [],
        genderList = [],
        confidences = {},
        updateTemperature = ()=>{},
        updateGender = ()=>{},
        toggleModalBigThumb = ()=>{}
     }) => {


    let currentTemperature = _.find(categories, function(item) { return item.parents[0].slug === "temperature" });
    let currentGender = _.find(categories, function(item) { return item.parents[0].slug === "genre" });


    return (
        <Col sm={4} className={"moderation-card"}>
            <Row>
                <Col sm={8} className={"font-size-14 text-center pr-0"}>
                <span className={`${_.get(currentGender, "slug") || "undefined"}-background username`}>
                    {owner._id ? (<a href={createLink("userProfile" , owner)} target={"_blank"}>{owner.username}</a>) : authorName}
                </span>
                </Col>
                <Col sm={4} className={"pl-0"}>
                    <DropdownButton
                        as={ButtonGroup}
                        key={"gender"}
                        id={`dropdown-gender`}
                        variant={_.get(currentGender,'_id') ? "success" : "danger"}
                        className={_.get(currentGender,'_id') ? "green-light" : "red-light"}
                        size="sm"
                        title={"Genre"}
                    >
                        {genderList.map(item => (
                            <Dropdown.Item
                                key={item._id}
                                active={_.get(currentGender,'_id') && item._id.toString() === currentGender._id.toString()}
                                onClick={()=>updateGender(_id, item._id)}
                            >
                                {item.title}
                            </Dropdown.Item>)
                        )}
                    </DropdownButton>
                </Col>
            </Row>

            <Col sm={12} className={`moderation-thumb`}>
                {kind === "video" ? (
                    previewUrl.map((img, index) => <Image onClick={()=>toggleModalBigThumb(`${img}?width=800`)} src={img ? `${img}?width=250` : ""} className={`cursor-pointer ${_.get(currentTemperature, "slug") || ""}`} key={index} /> )
                ) : (
                    <Image onClick={()=>toggleModalBigThumb(`${previewUrl}?width=800`)} src={previewUrl ? `${previewUrl}?width=250` : ""} className={`cursor-pointer ${_.get(currentTemperature, "slug") || ""}`} />
                )}
            </Col>
            <Col sm={12} className={"font-size-14 moderation-actions"}>
                <Link to={createLink("ediFacette", {media : {_id}})} target={"_blank"} ><FontAwesomeIcon icon={faEdit}  /></Link>
                <OverlayTrigger
                    placement="right"
                    delay={{ show: 250, hide: 400 }}
                    overlay={<Tooltip id="button-tooltip" >
                        <Score score={confidences} posterGender={posterGender} />
                    </Tooltip>}
                >
                    <span><FontAwesomeIcon icon={faCalculator} className={"icon ml-2"}  /></span>
                </OverlayTrigger>
            </Col>
            <Col sm={12} className={"font-size-14"}>
                ModelVersion : {modelVersion}
            </Col>
            <Col sm={12} className={"font-size-14"}>
                <a href={createLink(kind === "video" ? "mediaviewerVideo" : "mediaviewerPhoto", {...owner, media : {_id,slug}})} target={"_blank"}>{title}</a>
            </Col>
            <div className={"moderation-group-btn"}>
                <div className={"moderation-temperature"}>
                    {temperatureList.map(item => (
                        <Button
                            key={item._id}
                            active={_.get(currentTemperature,'_id') && item._id.toString() === currentTemperature._id.toString()}
                            onClick={()=>updateTemperature(_id, item._id)}
                        >
                            {item.title}
                        </Button>)
                    )}
                </div>

            </div>
        </Col>
    )
}

export default CardModerationMedia;