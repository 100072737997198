import {Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";


const ColoneTn = props => {
    let {currentSort = "", label = "", sort = "", className=""} = props;
    return (
        <th>
            <Row className={`d-flex justify-content-between ${className}`}>
                <Col xs={7} md={7}>{label}</Col>
                {typeof props.handleSort === "function" && (
                    <Col md={"auto"} >
                        <Row className={"d-flex justify-content-between pr-1"}>
                            <FontAwesomeIcon
                                icon="arrow-up"
                                size="sm"
                                color={currentSort ===sort ? "#2276f4" : ""}
                                onClick={() =>props.handleSort(sort)}
                                className={currentSort !==sort ? "cursor-pointer icone-xsmall" : "icone-xsmall"}
                            />

                            <FontAwesomeIcon
                                icon="arrow-down"
                                size="sm"
                                color={currentSort === `-${sort}` ? "#2276f4" : ""}
                                onClick={() =>props.handleSort(`-${sort}`)}
                                className={currentSort !== `-${sort}` ? "cursor-pointer icone-xsmall" : "icone-xsmall"}
                            />
                        </Row>
                    </Col>
                )}
            </Row>
        </th>
    )
}

export default ColoneTn;