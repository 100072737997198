import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";
import queryString from 'querystring';


let redux = reduxCreator.type('USER_ROOMS').plugin(axiosPlugin, {template: "array"});



let fetchRoomsAction = redux.fetchActionGet();

export const TYPES = redux.types;

export const fetchUserRooms = (ownerId = "", query = {}, more = false) => fetchRoomsAction(`/rooms/admin/${ownerId}?${queryString.stringify(query)}`, null, {more});
export const resetUserRooms = () => redux.actions.reset();

export const userRooms = redux.reducer();