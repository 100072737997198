import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";



let redux = reduxCreator.type('IA_CLASSIFIER_MODERATION_MEDIAS').plugin(axiosPlugin, {template: "array"});


redux.reducerForType('UPDATE', (state,payload) => {
    let newState = {...state};

    if(payload.data && payload.data.mediaId){
        newState.data = newState.data.map(item => {
            if(item.mediaId.toString() === payload.data.mediaId.toString()){
                item.media.categories = payload.data.categories;
            }
            return item;
        });
    }
    return newState;
});

export const TYPES = redux.types;

let fetchAction = redux.fetchActionGet();


export const fetchIaClassifierModerationMedias = (query = {}) => fetchAction(`/ia/classifier/moderation-medias-no-verified?${queryString.stringify(query)}`);
export const resetIaClassifierModerationMedias = () => redux.actions.reset();

export const iaClassifierModerationMedias = redux.reducer();