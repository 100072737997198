import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('CATEGORIZED_MEDIAS').plugin(axiosPlugin, {template: "array"});

let fetchMediaAction = redux.fetchActionGet();


export const TYPES = redux.types;

export const fetchCategorizedMedias = (query = {}) => fetchMediaAction(`/medias/categorized?${queryString.stringify(query)}`);

export const resetCategorizedMedias = () => redux.actions.reset();

export const categorizedMedias = redux.reducer();