import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('CATEGORIES_TEMPERATURE').plugin(axiosPlugin, {template: "array"});
let fetchAction = redux.fetchActionGet();


export const TYPES = redux.types;

export const fetchCategoriesTemperature = (query = {}) => fetchAction(`/categories/temperature`);
export const resetCategoriesTemperature = () => redux.actions.reset();

export const categoriesTemperature = redux.reducer();