import React from 'react';
import { Button, Col, Row, Container } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import _ from "lodash";

import FormFields from "../components/FormFields";
import { SpinLoader } from "../components/Loader";
import { fetchProducts } from '../../creators/products';
import { createUserItemSubscriptionGift } from '../../creators/user.itemSubscription';


class FormProfileGirft extends React.Component {
    constructor(props) {
        super(props);
        this.state = { productId : "", isLoading : false };

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.props.fetchProducts({ active: true });
    }

    onSubmit(){
        if(this.state.productId){
            this.setState({isLoading : true})
            this.props.createUserItemSubscriptionGift(this.props.userId, {productId : this.state.productId}).then(() =>{
                window.location.reload();
            });
        }
    }
    
    render() {
        return (
            <Container>
                    <Row className="mb-3">
                        <Col xs={12}>
                            <FormFields
                                initialized={this.props.productsLoading}
                                group={{controlId : "product", className : "col-12"}}
                                type={"select"}
                                label={{
                                    label : "Produits"
                                }}
                                controle={{
                                    name : "product",
                                    value : this.state.productId,
                                    options : this.props.products,
                                    //disabled : this.props.products.length ? false : true,
                                    optionField : {value : "_id", label : "name"},
                                    onChange : (event) =>{this.setState({productId : event.target.value})},
                                    className : "w-100"
                                }}
                            />
                        </Col>
            
                        <Col xs={12} md={4} className="mt-3 mt-md-4 text-center text-md-left">
                            <Button variant="primary" disabled={!this.state.productId} className="btn-loading" onClick={() => this.onSubmit()}>
                                Valider {this.state.isLoading && (
                                <div className="pl-1 d-inline">
                                    <SpinLoader className="xsmall" />
                                </div>
                            )}
                            </Button>
                        </Col>
                    </Row>
                
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    const groupedProducts = _.groupBy(state.products.data, "groupId");

    // Obtenez un produit unique par groupId (le premier produit de chaque groupe)
    const uniqueProducts = Object.keys(groupedProducts).map(groupId => {
        return groupedProducts[groupId][0]; // Prenez le premier produit de chaque groupe
    });

    // Ajoutez une option par défaut
    uniqueProducts.unshift({ _id: "", name: "Selectionner un produit", active: true });

    return {
        products : uniqueProducts,
        productsInitialized: state.products.initialized,
        productsLoading: !state.products.loading,
}};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchProducts,
    createUserItemSubscriptionGift
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormProfileGirft);