import {put,takeEvery} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';

import {TYPES as USER_WARNING_TYPE} from '../creators/user.warning';

export function* createSuccess(action) {
    try {
        yield put({type: "USER_WARNINGS_ADD", data : action.payload});
    } catch (error) {

    }
}

export function* deleteSuccess(action) {
    try {
        yield put({type: "USER_WARNINGS_DELETE", data : action.payload});

    } catch (error) {

    }
}

export function* updateSuccess(action) {
    try {
        yield put({type: "USER_WARNINGS_UPDATE", data : action.payload});
    } catch (error) {

    }
}

export function* watchWarning() {
    yield takeEvery(USER_WARNING_TYPE.CREATE_SUCCESS, createSuccess);
    yield takeEvery(USER_WARNING_TYPE.DELETE_SUCCESS, deleteSuccess);
    yield takeEvery(USER_WARNING_TYPE.UPDATE_SUCCESS, updateSuccess);
}

export default watchWarning;

