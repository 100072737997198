import React from "react";
import {config} from "../../config";
import FormFields from "./FormFields";
import {Col, Row} from "react-bootstrap";


const FilterGender = props => {
    let listGender = [
        {label : "Tous", value : ""}
    ];
    config.genderList.forEach((item,index) =>{
        listGender.push({label : item, value : parseInt(index)});
    });

    let {value = "", onChange = ()=>{}} = props;
    return (
        <Row>
            <Col sm={1} className={"pt-2"}>
                Gender
            </Col>
            <Col>
                <FormFields
                    group={{controlId : "filter-gender"}}
                    type={"select"}
                    controle={{
                        name : "filter-gender",
                        value,
                        options : listGender,
                        onChange : (event) =>{ onChange(event.target.value)}
                    }}
                />
            </Col>
        </Row>
    )
}

export default FilterGender;