import React, {Component} from 'react';
import Uploader from "../libs/UploaderLib";

export default ComposedComponent => {
    class FileUpload extends Component {
        static defaultProps = {
            uploadPath: null,
            onProgress: () => {
            },
            onSuccess: () => {
            },
            onError: () => {
            },
            onPreviewAvailable: (base64) => {
            }
        };

        constructor(props) {
            super(props);
            this.componentInstance = {};
            this.uploader = new Uploader(props);
            this.state = {
                preview: '',
                success: false,
                loadingPreview: false,
                file: {},
                uploadError: null,
                uploading: false,
                status: Uploader.defaultStatus
            };
        }

        async _startUpload(data = {}) {
            try {
                this.setState({uploading: true, success: false, uploadError: null, status: Uploader.defaultStatus});
                let res = await this.uploader.upload(data, status => this.setState({status}));
                this.setState({uploading: false, success: true}, () => {
                    this.props.onSuccess(res);
                    this.componentInstance.onUploadSuccess && this.componentInstance.onUploadSuccess(res);
                });
            }
            catch (uploadError) {
                this.setState({uploadError, uploading: false}, () => {
                    this.props.onError(uploadError);
                    this.componentInstance.onUploadError && this.componentInstance.onUploadError(uploadError);
                });
            }
        }

        _cancelUpload() {
            this.uploader.cancel();
        }

        reset(){
            this._cancelUpload();
            this.setState({
                preview: '',
                success: false,
                loadingPreview: false,
                file: {},
                uploadError: null,
                uploading: false,
                status: Uploader.defaultStatus
            });
        }

        async _handleChangeFile(event, fileEvent) {
            if(event){
                event.preventDefault();
                fileEvent = event.target.files && event.target.files[0];
            }
            try {
                this.setState({loadingPreview: true, success: false});
                let {file, preview} = await this.uploader.load(fileEvent);
                this.setState({file, preview, uploadError: null, loadingPreview: false}, ()=>{
                    this.props.onPreviewAvailable(preview);
                    this.componentInstance.onUploadPreviewAvailable && this.componentInstance.onUploadPreviewAvailable(preview);
                });
            }
            catch (uploadError) {
                this.setState({uploadError, loadingPreview: false}, ()=>{
                    this.props.onError(uploadError);
                    this.componentInstance.onUploadError && this.componentInstance.onUploadError(uploadError);
                });
            }
        }

        render() {
            return <ComposedComponent
                {...this.props}
                ref={ref => {
                    if (ref) this.componentInstance = ref
                }}
                success={this.state.success}
                loadFile={this._handleChangeFile.bind(this)}
                reset={this.reset.bind(this)}
                loadedFile={this.state.file}
                preview={this.state.preview}
                loadingPreview={this.state.loadingPreview}
                uploadFile={this._startUpload.bind(this)}
                cancelUpload={this._cancelUpload.bind(this)}
                uploadProgress={this.state.status}
                uploadError={this.state.uploadError}
                uploading={this.state.uploading}
            />
        }
    }

    return FileUpload;
};