import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';

import {apiUrl} from "../../../../libs/api";
import createLink from "../../../../libs/createLink";

import {stringify} from "query-string/index";

export default class DraftSharedMedia extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            album : {},
            albumMedia: {},
            media: {},
            article: {},
            noAlbumCover: false,
            error: false,
        }
    }

    componentDidMount() {
        if(this.props.kind === "album"){
            apiUrl.get(`albums/${this.props._id}`).then(response => {
                this.setState({album: response});
                apiUrl.get(`albums/${this.props._id}/medias?${stringify({
                    byAlbumId: this.props._id,
                    sort: "-createdAt",
                    limit: 1,
                    throwError: false
                })}`).then(response => {
                    if(response === null){
                        this.setState({error: true})
                    } else {
                        this.setState({albumMedia : response.data[0]});
                    }
                }).catch(e => {
                    this.setState({noAlbumCover: true});
                })
            }).catch( e => {
                this.setState({error: true});
            })
        } else if (this.props.kind === "media" || this.props.kind === "gallery") {
            apiUrl.get(`medias/${this.props._id}`).then(response => {
                if(response === null){
                    this.setState({error: true})
                } else {
                    this.setState({media: response});
                }
            }).catch( e => {
                this.setState({error: true});
            })
        } else if (this.props.kind === "articles" || this.props.kind === "blog") {
            apiUrl.get(`articles/${this.props._id}`).then(response => {
                if(response === null){
                    this.setState({error: true})
                } else {
                    this.setState({article: response});
                }
            }).catch( e => {
                this.setState({error: true});
            })
        }
    }

    render(){
        return this.state.error ? (
            <div className={"draft-shared-media error"}>
                Vous n'avez pas accès à ce contenu
            </div>
        ) : (
            <Fragment>
                {this.props.kind === "album" && this.state.album._id && (
                    <Link to={createLink("userAlbum", {...this.state.album.owner, album : {_id : this.state.album._id, slug : this.state.album.slug}})}
                          className={"draft-shared-media album"} data-id={this.state.album._id} >
                        <div className={"content"} style={{backgroundImage: `url(${this.state.albumMedia.previewUrl})`}} />
                        <div className={"caption"}>{this.state.album.title}</div>
                    </Link>
                )}
                {(this.props.kind === "media" || this.props.kind === "gallery") && this.state.media._id && (
                    <Link to={createLink("mediaviewerPhoto", this.state)}
                          data-id={this.state.media._id}
                          className={"draft-shared-media media"}>
                        <div className={"content"}><img alt={""} src={this.state.media.previewUrl} /></div>
                        <div className={"caption"}>{this.state.media.title}</div>
                    </Link>
                )}
                {(this.props.kind === "articles" || this.props.kind === "blog") && this.state.article._id && (
                    <Link to={createLink("article", this.state)} className={"draft-shared-media article"} data-id={this.state.article._id}>
                        <div className={"content"} style={{backgroundImage: `url(${this.state.article.coverUrl})`}} />
                        <div className={"caption"}>{this.state.article.title}</div>
                    </Link>
                )}
            </Fragment>
        )
    }
}