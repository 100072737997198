import React, {useEffect, useRef, useState} from 'react';
import {apiUrl} from "../../../../libs/api";

import {Button, Col, Form, FormLabel, InputGroup, Modal} from "react-bootstrap";
import AnnouncementMessagesEditor from "./AnnouncementMessagesEditor/AnnouncementMessagesEditor";
import FormFields from "../../../components/FormFields";
import {config} from "../../../../config";
import _ from "lodash";
import moment from "moment-timezone";
import convertToNext from "../../../../libs/lexical/convertissors/simple";
import createLink from "../../../../libs/createLink";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink} from "@fortawesome/free-solid-svg-icons";


const optionsStatus = [
    {value : false, label : "Désactiver"},
    {value : true, label : "Activer"}
];

const optionsLinkTarget = [
    {value : "_blank", label : "Blank"},
    {value : '_self', label : "Self"}
];

const InitLink = {
    toggle : false,
    url : "",
    target : "_blank",
    rel : "nofollow noopener"
}

export const AnnouncementMessagesForm = ({
     history = () =>{},
     match = {}
     }) => {
    const [message, setMessage] = useState({
        noteState : {},
        text : "",
        links : [],
        status : true,
        starAt : null,
        endAt : null,
        right : []
    });

    const [link, setLink] = useState(InitLink);


    const inputEl = useRef(null);

    useEffect((message) => {
        const fetchData = async () => {
            try {
                if(match.params.moderationMessageId){
                    const result = await apiUrl.get(`/announcement-messages/${match.params.moderationMessageId}`);
                    let newMessage = {
                        ...message,
                        ...result
                    };
                    setMessage(newMessage);

                   inputEl.current.load(result.text, result.links);
                }


            }
            catch (e){

            }
        }
        fetchData();
    }, [match]);

    async function handleSubmit(e){
        e.preventDefault();
        let {text, links} = convertToNext(message.noteState);
        message.text = text;
        message.links = links;
        if(message.starAt && message.endAt && message.right.length){
            let data = {
                text,
                links,
                status : message.status,
                starAt : moment.utc(message.starAt).startOf('day'),
                endAt : moment.utc(message.endAt).endOf("day"),
                right : message.right
            }

            if(message._id){
                await apiUrl.patch(`/announcement-messages/${message._id}`, data).then(response =>{
                    history.push(createLink('announcementMessages'));
                }).catch(e =>{
                    window.alert("Impossible de modifier" +e.message);
                })
            }
            else{
                await apiUrl.post(`/announcement-messages`, data).then(response =>{
                    history.push(createLink('announcementMessages'));
                }).catch(e =>{
                    window.alert("Impossible d'ajouter" +e.message);
                })
            }

        }
        else{
            window.alert("Merci de remplir tous les champs");
        }

    }

    function updateRole(role){
        let newMessage = {...message};
        if(newMessage.right.includes(role)){
            newMessage.right = newMessage.right.filter(item => item !== role);
        }
        else{
            newMessage.right.push(role);
        }

        setMessage(newMessage);
    }

    function _addLink(){
        let {url, target, rel} = link;

        if(url){
            inputEl.current.addLink({
                url,
                target,
                rel
            });
        }
        setLink(InitLink);
    }


    return (
        <div id="moderation-messages">
            <Modal centered backdrop={false} show={link.toggle} onHide={()=>setLink(InitLink)}>
                <Modal.Body>
                    <Col xs={12}>
                        <FormFields
                            group={{controlId : "url"}}
                            type={"input"}
                            label={{
                                label : "Url"
                            }}
                            controle={{
                                type : "url",
                                name : "url",
                                value : link.url,
                                required : true,
                                placeHolder : "http://",
                                onChange : (event) =>{setLink({...link, url : event.target.value})}
                            }}
                        />
                    </Col>
                    <Col xs={12}>
                        <FormFields
                            group={{controlId : "target"}}
                            type={"select"}
                            label={{
                                label : "Target"
                            }}
                            controle={{
                                name : "target",
                                value : link.target,
                                options : optionsLinkTarget,
                                onChange : (event) =>{ setLink({...link, [event.target.name] : event.target.value} )}
                            }}
                        />
                    </Col>
                    <Col xs={12}>
                        <FormFields
                            group={{controlId : "rel"}}
                            type={"input"}
                            label={{
                                label : "Rel"
                            }}
                            text={{
                                text :"ex : \"nofollow noopener noreferrer\"",
                                className : "text-info"
                            }}
                            controle={{
                                type : "rel",
                                name : "rel",
                                value : link.rel,
                                required : false,
                                placeHolder : "nofollow noopener",
                                onChange : (event) =>{setLink({...link, rel : event.target.value})}
                            }}
                        />
                    </Col>
                    <Modal.Footer>
                        <Button variant="primary" className={"btn-loading"} onClick={()=>_addLink()}>
                            Valider
                        </Button>
                        <Button variant="danger"  className={"btn-loading"} onClick={()=>setLink(InitLink)}>
                            Annuler
                        </Button>
                    </Modal.Footer>

                </Modal.Body>
            </Modal>
            <Form onSubmit={(e) => handleSubmit(e)}>
                <Form.Row>
                    <Col xs={12}>
                        <FormLabel>Afficher le message uniquement pour les roles suivants</FormLabel>
                    </Col>
                    <Col xs={12}>
                        <InputGroup className="mb-3">
                            <FormFields
                                type={"checkbox"}
                                group={{as : Col}}
                                checkBox={{
                                    id : `checkbox-guest`,
                                    name : "right",
                                    checked : message.right.includes("guest"),
                                    label : "guest",
                                    onChange : () =>{updateRole("guest")}
                                }}
                            />
                            {Object.keys(config.role).filter(role => role !== "banned").map(role =>(
                                <FormFields
                                    key={role}
                                    type={"checkbox"}
                                    group={{as : Col}}
                                    checkBox={{
                                        id : `checkbox-${role}`,
                                        name : "right",
                                        checked : message.right.includes(role),
                                        label : role,
                                        onChange : () =>{updateRole(role)}
                                    }}
                                />
                            ))}
                        </InputGroup>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs={12} className={"p-2"}>
                        <FontAwesomeIcon
                            icon={faLink}
                            className={"ml-1 cursor-pointer"}
                            onClick={()=>setLink({...link, toggle : !link.toggle})}
                        />
                    </Col>
                    <Col xs={12}>
                        <AnnouncementMessagesEditor ref={inputEl} onChange={(editorState) => setMessage({...message, noteState : editorState})}  />
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col xs={12}>
                        <FormFields
                            group={{controlId : "status"}}
                            type={"select"}
                            label={{
                                label : "Statut"
                            }}
                            controle={{
                                name : "status",
                                value : message.status,
                                options : optionsStatus,
                                onChange : (event) =>{ setMessage({...message, [event.target.name] : event.target.value} )}
                            }}
                        />
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col xs={3}>
                        <FormFields
                            group={{as : Col, controlId : "starAt"}}
                            type={"date"}
                            datePicker={{showLeadingZeros : true}}
                            label={{
                                label : "Debut"
                            }}
                            controle={{
                                name : "starAt",
                                id : "starAt",
                                value : _.get(message,"starAt") || moment(),
                                onChange : (date) =>{
                                    setMessage({...message, starAt: date})
                                },
                                //disabled : true,
                                className : `ml-2 ${_.get(message,"starAt") === null ? "warning" : ""}`
                            }}
                        />
                    </Col>
                    <Col xs={3}>
                        <FormFields
                            group={{as : Col, controlId : "endAt"}}
                            type={"date"}
                            datePicker={{showLeadingZeros : true}}
                            label={{
                                label : "Fin"
                            }}
                            controle={{
                                name : "endAt",
                                id : "endAt",
                                value : _.get(message,"endAt") || moment(),
                                onChange : (date) =>{
                                    setMessage({...message, endAt: date})
                                },
                                //disabled : true,
                                className : `ml-2 ${_.get(message,"endAt") === null ? "warning" : ""}`
                            }}
                        />
                    </Col>
                </Form.Row>


                <Button variant="primary" type="submit" className={"btn-loading"}>
                    Valider
                </Button>
            </Form>
        </div>
    )


};
export default AnnouncementMessagesForm;