import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ReactPlayer from "react-player";
import {PageLoader, SpinLoader, LoadingBar} from "../../components/Loader";
import FilterGender from "../../components/FilterGender";
import ContentUser from "./components/ContentUser";
import {Button, Modal, Row} from "react-bootstrap";
import createLink from "../../../libs/createLink";
import { fetchModerationCertifications, resetModerationCertifications } from '../../../creators/moderation.certifications';
import {acceptedModerationCertification,refusedModerationCertification} from "../../../creators/moderation.certification";


class ModerationsCertified extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            gender : 0,
            tachesRemoveIds : [],
            currentCertification : null
        };

        this.fetchModerationContents();
        this.openModal = this.openModal.bind(this);
        this.handleGender = this.handleGender.bind(this);
        this.submitRefused = this.submitRefused.bind(this);
        this.submitAccepted = this.submitAccepted.bind(this);
    }

    UNSAFE_componentWillMount(){
        this.props.resetModerationCertifications();
    }

    fetchModerationContents(query = {}){
        this.props.fetchModerationCertifications(query);
    }

    submitRefused(id){
        this.props.refusedModerationCertification(id);
        let tachesRemoveIds = [...this.state.tachesRemoveIds,id];
        this.setState({tachesRemoveIds});
    }

    submitAccepted(){
        let tachesRemoveIds = this.state.tachesRemoveIds;
        let ids = this.props.moderationCertifications.filter(item => !tachesRemoveIds.includes(item._id)).map(item => item._id);
        this.props.acceptedModerationCertification({ids}).then(() =>{
            let gender = this.state.gender;
            this.fetchModerationContents(gender === 0 ? {} : {gender});
        })
    }

    handleGender(gender){
        gender = parseInt(gender);
        this.fetchModerationContents(gender === 0 ? {} : {gender});
        this.setState({gender});
    }


    openModal(currentCertification){
        this.setState({currentCertification});
    }

    render() {
        let tachesRemoveIds = this.state.tachesRemoveIds;
        return !this.props.moderationCertificationsInitialized ? (<PageLoader />) :(
            <Fragment>
                <FilterGender value={this.state.gender} onChange={(gender)=>{this.handleGender(gender)}}/>
                <Row>
                    {this.props.moderationCertificationsLoading ? (<LoadingBar />) : (
                        this.props.moderationCertifications.filter(item => !tachesRemoveIds.includes(item._id)).map((item) =>{
                            const previewUrl = Array.isArray(item.previewUrl) ? item.previewUrl[0] : item.previewUrl;
                            return <ContentUser tachesRemoveIds={this.state.tachesRemoveIds} key={item._id} typeCertification={item.kind} gender={item.owner && item.owner.profile.gender} src={previewUrl ? `${previewUrl}?width=200&height=200` : ""} openModal={()=>this.openModal(item)} username={<a href={createLink("userProfile", item.owner)} target={"new"}>{item.owner.username}</a>} deleteList={this.submitRefused} _id={item._id} />
                        })
                    )}
                </Row> 
                <Button variant="primary" className={"btn-loading"} onClick={()=>this.submitAccepted()}>
                    Valider {this.props.moderationCertificationLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                </Button>  
                {this.state.currentCertification ? (
                    <Modal className={"modal-big-thumb"} centered backdrop={false} show={this.state.currentCertification ? true : false} onHide={()=>this.setState({currentCertification : null})}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.state.currentCertification.kind === "certification-video" && ("Vidéo ")} 
                                {this.state.currentCertification.kind === "certification-photo" && ("Photo ")} 
                                {this.state.currentCertification.kind === "certification-passport" && ("Passport ")} 
                                Certifié
                                </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.currentCertification.kind === "certification-video" ? 
                                (
                                <div>
                                    <p>{this.state.currentCertification.question}</p>
                                    <ReactPlayer
                                        url={this.state.currentCertification.videoUrl}
                                        playing={true}
                                        controls={true}
                                        width="100%"
                                        height="100%"
                                        className={"player"}
                                    />
                                </div>
                                )
                                 : (
                                    <img alt={"img"} src={this.state.currentCertification.previewUrl} />
                                )
                            }
                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                    </Modal>
                ) : null}             
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        moderationCertifications : state.moderationCertifications.data,
        moderationCertificationsTotal : state.moderationCertifications.total,
        moderationCertificationsLoading : state.moderationCertifications.loading,
        moderationCertificationsInitialized : state.moderationCertifications.initialized,

        moderationCertificationLoading : state.moderationCertification.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchModerationCertifications,
    resetModerationCertifications,
    acceptedModerationCertification,
    refusedModerationCertification
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModerationsCertified);
