import {put, race, take, takeEvery} from 'redux-saga/effects';
import {LOGIN_USER_FAIL, LOGIN_USER_SUCCESS, LOGOUT_USER} from '../creators/login';
import {RULES_RESET, RULES_SET} from '../creators/rules';
import {fetchCurrentUser, TYPES as CURRENT_USER_TYPE} from '../creators/currentUser';
import ability from "../ability";


export function* fetchSuccess(action) {
    try {
        yield put({type: RULES_SET, rules: action.payload.rules});
        yield put(fetchCurrentUser());
        yield race({
            user: take(CURRENT_USER_TYPE.SUCCESS_ONE),
            error: take(CURRENT_USER_TYPE.FAILURE)
        });
        if (action.payload.rules) yield ability.update(action.payload.rules);
        // history.push('/');
    } catch (error) {
        console.log(error, "error");
        // yield put({type: LOGIN_USER_ME_FAIL, payload: action.payload.rules});
    }
}

export function* fetchFail(action) {
    try {
        yield put({type: RULES_RESET});
        yield put({type: CURRENT_USER_TYPE.RESET});
        yield put(localStorage.removeItem(process.env.REACT_APP_TOKEN_NAME));
    } catch (error) {
        // yield put({type: LOGIN_USER_ME_FAIL, payload: action.payload.rules});
    }
}

export function* watchLogin() {
    yield takeEvery(LOGIN_USER_SUCCESS, fetchSuccess);
    yield takeEvery(LOGIN_USER_FAIL, fetchFail);
    yield takeEvery(LOGOUT_USER, fetchFail);
}

export default watchLogin;