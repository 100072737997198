import React from 'react';
import moment from "moment-timezone";

const DateSince = props => {
    const { date = "", format = "DD/MM/YYYY à HH:mm", prefix = false, className = "", currentFormat = true, maxDay = 2,  maxMinutes = 60, maxHours = 24} = props;
    let now = moment(new Date()); //todays date
    let dateMoment = moment(date); // initialization moment
    //check isValid date
    if(dateMoment.isValid()){
        let showDate = dateMoment.format(format); // variable initialization with date and output format
        if(currentFormat){
            let duration = moment.duration(now.diff(dateMoment.format("MM-DD-YYYY HH:mm:ss")));
            let years = Number(duration.years().toFixed());
            let months = Number(duration.months().toFixed());
            let days = Number(duration.days().toFixed());
            let hours = Number(duration.hours().toFixed());
            let minutes = Number(duration.minutes().toFixed());
            let seconds = Number(duration.seconds().toFixed());

            if(seconds === 0  && minutes === 0  && hours === 0  && days === 0 && years === 0 && months === 0){
                showDate = prefix ? `maintenant` : `maintenant`;
            }
            else if(seconds > 0  && minutes === 0  && hours === 0  && days === 0 && years === 0 && months === 0){
                showDate = prefix ? `il y a ${seconds} secondes` : `${seconds} secondes`;
            }
            else if(minutes > 0 && minutes < maxMinutes && hours === 0  && days === 0 && years === 0 && months === 0){
                showDate = prefix ? `il y a ${minutes} minute${minutes >1 ? "s" : ""}` : `${minutes} minute${minutes >1 ? "s" : ""}`;
            }
            else if(hours > 0 && hours < maxHours && days === 0 && years === 0 && months === 0){
                showDate = prefix ? `il y a ${hours} heure${hours >1 ? "s" : ""}` : `${hours} heure${hours >1 ? "s" : ""}`;
            }
            else if(days === 0 && years === 0 && months === 0){
                showDate = `aujourd'hui`;
            }
            else if(days === 1 && years === 0 && months === 0){
                showDate = `hier à ${dateMoment.format("HH:mm")}`;
            }
            else if(days >= 2 && years === 0 && months === 0 && days < maxDay){
                showDate = prefix ? `il y a ${days} jour${days >1 ? "s" : ""}` : `${days} jour${days >1 ? "s" : ""}`;
            }
            else{
                showDate = prefix ? `le ${showDate}` : showDate;
            }
        }
        else{
            showDate = prefix ? `le ${showDate}` : showDate;
        }
        return(
            <span className={`date-since ${className}`}>{showDate}</span>
        )
    }
    else{
        return(
            <span className={`date-since error ${className}`}>Date invalide</span>
        )
    }

};


export default DateSince;
