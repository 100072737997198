
import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('TAG').plugin(axiosPlugin);

let fetchTagAction = redux.fetchActionGet();
let updateTagAction = redux.fetchActionUpdate();
let createTagAction = redux.fetchActionCreate();
let deleteTagAction = redux.fetchActionDelete();

export const fetchTag = (tagId) => fetchTagAction(`/tags/${tagId}`);
export const updateTag = (tagId,data) => updateTagAction(`/tags/${tagId}`, data);
export const createTag = (data) => createTagAction(`/tags`, data);
export const resetTag = () => redux.actions.reset();
export const deleteTag = (tagId) => deleteTagAction(`/tags/${tagId}`);

export const tag = redux.reducer();