import React from 'react';
import {Button, Form} from "react-bootstrap";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {updateUser} from "../../creators/user";

import {SpinLoader} from "../components/Loader";
import FormFields from "../components/FormFields";
// import {fetchUserQuestions} from "../../creators/user.questions";
// import {deleteUserQuestion} from "../../creators/user.question";
// import {fetchUserCustomQuestions} from "../../creators/user.customQuestions";
import Col from "react-bootstrap/Col";
// import Card from "react-bootstrap/Card";
// import RequestQuestionsBlockSuggestions from "../components/RquestQuestionBlockSuggestion";


class FormSettingFriendAndRequest extends React.Component {
    constructor(props) {
        super(props);
        // this.groupList = [
        //     {group: 'general', label: ` "d'ordre général"`},
        //     {group: 'facondetre', label: ` "la façon d'être"`},
        //     {group: 'vousetmoi', label: ` dans le thème "vous et moi"`},
        //     {group: 'couple', label: ` "pour les couples"`},
        //     {group: 'femme', label: ` "pour les femmes"`},
        //     {group: 'homme', label: ` "pour les hommes"`},
        // ];
        this.state = {
            // isValidQuestion: {
            //     general: [],
            //     facondetre: [],
            //     vousetmoi: [],
            //     couple: [],
            //     femme: [],
            //     homme: []
            // },
            displayFriends: this.props.user.setting.displayFriends,
            friendRequestQuestions: this.props.user.setting.friendRequestQuestions,
        };
        // this.props.fetchUserQuestions(this.props.user._id,{limit: false});

        // this.props.fetchUserCustomQuestions(this.props.user._id);
        this.handleSubmit = this.handleSubmit.bind(this);
        // this.handleCheck = this.handleCheck.bind(this);
        // this.handleDeleteQuestion = this.handleDeleteQuestion.bind(this);
    }

    componentDidUpdate(prevProps) {
        // if (prevProps.customQuestion !== this.props.customQuestion) {
        //     // console.log(this.props.myQuestions);
        //     this.setState({isValidQuestion: this.props.customQuestion});
        // }
    }

    // handleCheck(value, key) {
    //     let questions = this.state.isValidQuestion;
    //     let currentQuestion = questions[key];
    //     let isPresent = currentQuestion && currentQuestion.filter(item => value._id === item._id);
    //
    //     if (!isPresent.length) {
    //         currentQuestion.push(value);
    //     } else {
    //         currentQuestion = currentQuestion && currentQuestion.filter(item => value._id !== item._id);
    //     }
    //     this.setState({isValidQuestion: {...questions, [key]: currentQuestion}});
    // }
    //
    // handleDeleteQuestion(id){
    //     this.props.deleteUserQuestion(id);
    // }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.props.userLoading) {
            // let question = {
            //     general: this.state.isValidQuestion.general.map(item => item._id),
            //     facondetre: this.state.isValidQuestion.facondetre.map(item => item._id),
            //     vousetmoi: this.state.isValidQuestion.vousetmoi.map(item => item._id),
            //     couple: this.state.isValidQuestion.couple.map(item => item._id),
            //     femme: this.state.isValidQuestion.femme.map(item => item._id),
            //     homme: this.state.isValidQuestion.homme.map(item => item._id)
            // };
            let currentUser = {
                // question,
                setting : {
                    displayFriends: this.state.displayFriends,
                    friendRequestQuestions: this.state.friendRequestQuestions,
                }
            };

            this.props.updateUser(this.props.user._id, currentUser);
        }
    }

    render() {
        return (
            <Form onSubmit={(e) => this.handleSubmit(e)} className={"mb-5"}>
                <h4 className={"w-100"}>Amis et Demandes</h4>
                <Form.Row>
                    <FormFields
                        type={"checkbox"}
                        group={{as : Col}}
                        checkBox={{
                            id : `checkbox-displayFriends`,
                            name : "displayFriends",
                            checked : this.state.displayFriends,
                            label : "Afficher mes amis dans mon profil",
                            onChange : (event) =>{this.setState({displayFriends : !this.state.displayFriends})}
                        }}
                    />
                    <FormFields
                        type={"checkbox"}
                        group={{as : Col}}
                        checkBox={{
                            id : `checkbox-friendRequestQuestions`,
                            name : "friendRequestQuestions",
                            checked : this.state.friendRequestQuestions,
                            label : "Activer les questions pour faire connaissance",
                            onChange : (event) =>{this.setState({friendRequestQuestions : !this.state.friendRequestQuestions})}
                        }}
                    />
                </Form.Row>


                <Button variant="primary" type="submit" className={"btn-loading mt-3"}>
                    Valider {this.props.userLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                </Button>
            </Form>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        userLoading: state.user.loading,

        // questions: state.userQuestions.data,
        // questionsLoading: state.userQuestions.loading,
        // questionsTotal: state.userQuestions.total,
        //
        // customQuestion: state.userCustomQuestions.data,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateUser,
    // fetchUserQuestions,
    // deleteUserQuestion,
    // fetchUserCustomQuestions

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormSettingFriendAndRequest);
