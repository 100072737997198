import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Aqp from '../../../../libs/mk-react-aqp';
import {LoadingBar, PageLoader} from "../../../components/Loader";
import {Button, Col, Row, Table} from "react-bootstrap";
import {
    fetchAnnouncementMessages,
    resetAnnouncementMessages,
} from "../../../../creators/announcementMessages";
import {Link} from "react-router-dom";
import createLink from "../../../../libs/createLink";
import Pagination from "../../../components/Pagination";
import ColoneTn from "../../../components/ColoneTh";
import moment from "moment-timezone";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


let mapping = {
    "sort" : "trie",
};

class AnnouncementMessages extends React.Component {
    constructor(props) {
        super(props);

        this.filter = {
            limit: 30,
            page: 1,
            sort: "-createdAt",
        };
        this.aqp = new Aqp(mapping, {
            blacklist: ['limit', 'page'],
            currentLink: createLink("announcementMessages"),
            filterDefault: this.filter
        });

        this.state = {
            filter: {...this.filter, ...this.aqp.query, page: this.props.match.params.page || 1},
        };

        this.handleChangePage = this.handleChangePage.bind(this);
        this.fetchContent(this.state.filter);
    }

    componentWillUnmount() {
        this.props.resetAnnouncementMessages();
    }

    fetchContent(filter){
        this.props.fetchAnnouncementMessages(this.aqp.set(filter));
        this.setState({filter});
    }

    handleChangePage(page, link) {
        let filter = {...this.state.filter, page};

        if (page === 1) {
            this.props.history.push(link);
        } else {
            this.props.history.push(link + '/' + page);
        }
        this.setState({filter});
        this.fetchContent(filter);
        window.scrollTo(0, 0);
    }


    render() {

        let totalPage = this.props.announcementMessagesTotal ? Math.ceil(this.props.announcementMessagesTotal / this.state.filter.limit) : 0;
        return !this.props.announcementMessagesInitialized ? (<PageLoader />) :(
            <div id={"moderation-messages"}>
                <Row className={"mb-2"}>
                    <Col sm={12}>
                        <Link to={`${createLink("announcementMessages")}/nouveau`}>
                            <Button variant="primary" type="submit" className={"btn-loading"}>Ajouter</Button>
                        </Link>
                    </Col>
                </Row>
                <Row>
                    {this.props.announcementMessagesLoading ? (<LoadingBar />) : null}
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <ColoneTn label={"Roles"}  />
                            <ColoneTn label={"Debut"}  />
                            <ColoneTn label={"Fin"}  />
                            <ColoneTn label={"Statut"}  />
                            <ColoneTn label={"Text"}  />
                            <ColoneTn label={""}  />
                        </tr>
                        </thead>
                        <tbody>
                        {this.props.announcementMessages.map(item => (
                            <tr key={item._id}>
                                <td width={"120px"}>{item.right.join(', ')}</td>
                                <td width={"120px"}>{moment(item.starAt).format("DD-MM-YYYY")}</td>
                                <td width={"120px"}>{moment(item.endAt).format("DD-MM-YYYY")}</td>
                                <td width={"50px"}>{item.status ? "Activé" : "Désactivé"}</td>
                                <td >{item.text}</td>
                                <td width={"20px"}><Link to={`${createLink("announcementMessages")}/${item._id}`} ><FontAwesomeIcon icon={faEdit} className={"icon-hover"}  /></Link></td>
                            </tr>

                        ) )}
                        </tbody>
                    </Table>
                    <Pagination currentPage={this.state.filter.page}
                                pagesCount={totalPage}
                                {...this.aqp.queryUrl(this.state.filter, mapping)}
                                handleChangePage={this.handleChangePage}/>
                </Row>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        announcementMessages : state.announcementMessages.data,
        announcementMessagesTotal : state.announcementMessages.total,
        announcementMessagesLoading : state.announcementMessages.loading,
        announcementMessagesInitialized : state.announcementMessages.initialized,

    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAnnouncementMessages,
    resetAnnouncementMessages,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AnnouncementMessages);
