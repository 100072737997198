import React from 'react';
import ReactJWPlayer from "react-jw-player";

const DraftUploadedMedia = (props) => {
    let {src = "", kind = "", width = "740"} = props;
    return (
        <div className={"draft-uploaded-media"}>
            {kind === "image" ? (
                <img  alt={""} src={`${src}?width=${width}`} />
            ) : kind === "video" && (
                <ReactJWPlayer
                    playerScript='https://content.jwplatform.com/libraries/o4p642J0.js'
                    file={`${process.env.REACT_APP_BASE_STATIC}${src}`}
                    isAutoPlay={true}
                    customProps={{
                        width: "100%",
                    }}
                    aspectRatio={"16:9"}
                    className={"player"}
                />
            )}
        </div>
    )
};

export default DraftUploadedMedia;