import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('USER_CUSTOM_QUESTIONS').plugin(axiosPlugin, {template: "array"});

let fetchUserCustomQuestionsAction = redux.fetchActionGet();


export const fetchUserCustomQuestions = (userId,query = {}) => fetchUserCustomQuestionsAction(`/users/${userId}/my-questions?${queryString.stringify(query)}`);
export const resetUserCustomQuestions = () => redux.actions.reset();

export const userCustomQuestions = redux.reducer();