import axios from 'axios';

const timeout = 10000;
const baseURL = process.env.REACT_APP_BASE_API;
// const baseURL = 'http://176.31.106.73:31989/api';
// const baseURL = 'http://151.80.27.45:2351/api';
// const baseURL = 'https://preprod.maxken.fr/voissa-api/api';
// const baseURL = 'http://localhost:3030/api';
// const baseURL = 'http://192.168.1.101:3030/api';
// const baseURL = `https://api.voissa.fr`;

const apiUrl = axios.create({
    baseURL,
    timeout
});

export {
    apiUrl
};
