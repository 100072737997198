import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('MODERATION_CERTIFICATION').plugin(axiosPlugin);


let moderationAction = redux.fetchActionUpdate();

export const TYPES = redux.types;

export const refusedModerationCertification = (tacheId) => moderationAction(`/moderation-contents/certifications/refused/${tacheId}`);

export const acceptedModerationCertification = (data = {}) => moderationAction(`/moderation-contents/certifications/accepted`, data);

export const resetModerationCertification = () => redux.actions.reset();

export const moderationCertification = redux.reducer();