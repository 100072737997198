import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {fetchCategoriesTemperature, resetCategoriesTemperature} from "../../../creators/categories.temperature";
import {Button, Col, Modal, Row} from "react-bootstrap";
import "./ModerationsTemprature.scss";
import FormFields from "../../components/FormFields";
import {
    fetchIaClassifierModerationMedias,
    resetIaClassifierModerationMedias
} from "../../../creators/ia.classifierModerationMedias";
import {LoadingBar, SpinLoader} from "../../components/Loader";
import CardModerationMedia from "./components/CardModerationMedia/CardModerationMedia";
import {fetchCategoriesGender, resetCategoriesGender} from "../../../creators/categories.gender";
import {
    resetIaClassifierTemperature,
    updateIaClassifierTemperature
} from "../../../creators/ia.classifierModerationMedias.updateTemperature";
import {
    resetIaClassifierGender,
    updateIaClassifierGender
} from "../../../creators/ia.classifierModerationMedias.updateGender";
import {
    resetIaClassifierVerified,
    updateIaClassifierVerified
} from "../../../creators/ia.classifierModerationMedias.updateVerified";


class iaModerationsTemperature extends React.Component {
    constructor(props) {
        super(props);

        this.legendGender = [
            {slug : 'undefined', title : "Non définis" },
            {slug : 'homme', title : "Homme" },
            {slug : 'femme', title : "Femme" },
            {slug : 'couple-hetero', title : "Couple hétéro" },
            {slug : 'couple-gay', title : "Couple gay" },
            {slug : 'couple-lesbien', title : "Couple lesbien" },
            {slug : 'trio', title : "Trio" },
            {slug : 'groupe', title : "Groupe" },
            {slug : 'transsexuel', title : "Transsexuel" },
            {slug : 'travesti', title : "Travesti" }
        ];

        this.legendTemperature = [
            {slug : 'soft', title : "Soft" },
            {slug : 'hot', title : "Hot" },
            {slug : 'hard', title : "Hard" },
            {slug : 'gros-plan', title : "Gros plan" }
        ];

        this.filter = {
            currentCategory : "all",
            state : 1,
            sort : "-createdAt",
            kind : "KNN_CLASSIFIER_MEDIA_TEMP"
        }
        this.state = {
            filter : this.filter,
            modalImg : ""
        }

        this.fetchMedias = this.fetchMedias.bind(this);
        this.handleFilter = this.handleFilter.bind(this);
        this.updateTemperature = this.updateTemperature.bind(this);
        this.updateGender = this.updateGender.bind(this);

        this.props.fetchCategoriesTemperature();
        this.props.fetchCategoriesGender();
        this.fetchMedias(this.state.filter);

    }

    componentWillUnmount() {
        this.props.resetIaClassifierModerationMedias();
    }

    fetchMedias(filter){
        this.props.fetchIaClassifierModerationMedias(filter)
    }

    handleFilter(key,value){
        let filter = {...this.state.filter, [key] :value };
        this.setState({filter})
        this.fetchMedias(filter);
    }

    submitAccepted(){
        let idsVerified = this.props.iaClassifierModerationMedias.map(item => item._id);
        this.props.updateIaClassifierVerified({iaKnnIds : idsVerified}).then(response =>{
            this.fetchMedias({...this.state.filter});
        }).catch(e=>{
            window.alert(`Mise a jour impossible : ${e.message}`);
        })

    }

    updateTemperature(mediaId = null, temperatureId = null){
        this.props.updateIaClassifierTemperature(mediaId, {categoryId : temperatureId});
    }

    updateGender(mediaId = null, genderId = null){
        this.props.updateIaClassifierGender(mediaId, {categoryId : genderId});
    }


    render() {
        return (
            <div id={"ia-modaration"}>
                <Row>
                    <Col sm={3}>
                        {!this.props.categoriesTemperatureInitialized ? (<LoadingBar />) : (
                            <FormFields
                                group={{controlId : "currentCategory"}}
                                type={"select"}
                                controle={{
                                    name : "currentCategory",
                                    value : this.state.filter.currentCategory,
                                    optionField : {value : "_id", label : "title"},
                                    options : [{_id : "all", title : "Toutes les temperatures"}, ...this.props.categoriesTemperature],
                                    onChange : (event) =>{this.handleFilter([event.target.name],event.target.value)}
                                }}
                            />
                        )}

                    </Col>

                    <Col sm={2}>
                        <FormFields
                            initialized={true}
                            group={{controlId : "state"}}
                            type={"select"}
                            controle={{
                                name : "state",
                                value : this.state.filter.state,
                                options : [{value : 1, label : "Traités"}, {value : 3, label : "Erreur"}],
                                onChange : (event) =>{this.handleFilter([event.target.name],event.target.value)}
                            }}
                        />
                    </Col>
                </Row>
                <div className={"legends"}>
                    <h3>Légendes</h3>
                    <Col sm={12}>
                        <h6>Genre present sur la photo : Background sur l'utilisateur</h6>
                        <ul className={"legend-gender-list"}>
                            {this.legendGender.map(legend => <li key={legend.slug} className={`${legend.slug}-background`}>{legend.title}</li>)}
                        </ul>
                    </Col>
                    <Col sm={12}>
                        <h6>Temperature present sur la photo : border sur l'image</h6>
                        <ul className={"legend-temperature-list"}>
                            {this.legendTemperature.map(legend => <li key={legend.slug} className={`${legend.slug}`}>{legend.title}</li>)}
                        </ul>
                    </Col>
                </div>
                <Row >
                    {this.props.iaClassifierModerationMediasLoading ? (<LoadingBar />) : (
                        this.props.iaClassifierModerationMedias.map(item =>{
                            if(item.media){
                                return (
                                    <CardModerationMedia
                                        key={item._id}
                                        {...item.media}
                                        confidences={item.confidences}
                                        modelVersion={item.modelVersion}
                                        temperatureList={this.props.categoriesTemperature}
                                        genderList={this.props.categoriesGender}
                                        iaKnnId={item._id}
                                        updateTemperature={this.updateTemperature}
                                        updateGender={this.updateGender}
                                        posterGender={item.posterGender || 0}
                                        toggleModalBigThumb={(uri => this.setState({modalImg : uri}))}
                                    />
                                )
                            }
                            return <div key={item._id}>Media effacer</div>
                        })
                    )}
                </Row>
                <Button variant="primary" className={"btn-loading mt-3"} onClick={()=>this.submitAccepted()}>
                    Valider {this.props.iaClassifierModerationMediasLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                </Button>
                {this.state.modalImg !=="" ? (
                    <Modal className={"modal-big-thumb"} centered backdrop={false} show={this.state.modalImg !== "" ? true : false} onHide={()=>this.setState({modalImg : ""})}>
                        <Modal.Header closeButton>
                            <Modal.Title>Image</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <img alt={"img"} src={this.state.modalImg} />
                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                    </Modal>
                ) : null}
            </div>
        )
    }
}


const mapStateToProps = (state) => {

    return {
        currentUserInitialized: state.currentUser.initialized,

        categoriesTemperature : state.categoriesTemperature.data,
        categoriesTemperatureLoading : state.categoriesTemperature.loading,
        categoriesTemperatureInitialized : state.categoriesTemperature.initialized,

        categoriesGender : state.categoriesGender.data,
        categoriesGenderLoading : state.categoriesGender.loading,
        categoriesGenderInitialized : state.categoriesGender.initialized,


        iaClassifierModerationMedias : state.iaClassifierModerationMedias.data,
        iaClassifierModerationMediasLoading : state.iaClassifierModerationMedias.loading,
        iaClassifierModerationMediasInitialized : state.iaClassifierModerationMedias.initialized,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCategoriesTemperature,
    resetCategoriesTemperature,

    fetchCategoriesGender,
    resetCategoriesGender,

    fetchIaClassifierModerationMedias,
    resetIaClassifierModerationMedias,


    updateIaClassifierTemperature,
    resetIaClassifierTemperature,

    updateIaClassifierGender,
    resetIaClassifierGender,

    updateIaClassifierVerified,
    resetIaClassifierVerified
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(iaModerationsTemperature);
