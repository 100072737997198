import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import routes from './routes';
import {Provider} from 'react-redux';
import Store from './store.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './sass/master.scss';
import history from './libs/history';
import Aqp from './libs/mk-react-aqp';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faEnvelope, faKey, faEdit, faArrowUp, faArrowDown, faTrash, faBan, faTimes, faBolt, faLink } from '@fortawesome/free-solid-svg-icons';

library.add(faEnvelope, faKey, faEdit, faArrowUp, faArrowDown, faTrash, faBan, faTimes, faBolt, faLink);

Aqp.init(history);


ReactDOM.render(
    <Provider store={Store}>
        {routes}
    </Provider>,
    document.getElementById('root')
);