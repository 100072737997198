import React from "react";
import {config} from "../../../../config";
import {Col} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SpinLoader} from "../../../components/Loader";


const ContentUserLink = props => {
    let {username = "Pseudo", linkExternal ="", gender = 0,_id = 0, deleteList = ()=>{console.log("Fonction non envoyer");}, tachesRemoveIds = [] } = props;
    gender = config.genderList[gender];
    let loading = tachesRemoveIds.includes(_id) ? true : false;

    return (
        <Col sm={6} className={"p-0"}>
            <Col sm={12} className={"font-size-14"}>
                {username}
            </Col>
            <Col sm={12} className={"font-size-14"}>
                Genre : {gender}
            </Col>
            <Col sm={12}>
                {loading ? (<div className={"pt-2 flex justify-content-center"}><SpinLoader className={"xsmall"} /></div>) : (
                    <FontAwesomeIcon
                        icon="trash"
                        className={"cursor-pointer"}
                        onClick={()=>deleteList(_id)}
                    />
                )} <a href={linkExternal} target={"_blank"}>{linkExternal}</a>
            </Col>
        </Col>
    )
}

export default ContentUserLink;