import {takeEvery} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {TYPES as FACETTES_TYPES_GENERATEUR} from '../creators/facettes.types.generateur';


export function createSuccess(action) {
    try {
        window.location.reload();
    } catch (error) {
    }
}

export function* watchFacettesTypesGenerateur() {
    yield takeEvery(FACETTES_TYPES_GENERATEUR.CREATE_SUCCESS, createSuccess);
}

export default watchFacettesTypesGenerateur;

