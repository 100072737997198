
async function requestSuccess({getState, dispatch, getSourceAction}, req) {
    const token = await localStorage.getItem(process.env.REACT_APP_TOKEN_NAME);
    if (token != null) {
        req.headers.Authorization = `Bearer ${token}`;
    }
    return req;
}

async function responseSuccess({getState, dispatch, getSourceAction}, req) {
    if (req.data && req.data.token) {
        await localStorage.setItem(process.env.REACT_APP_TOKEN_NAME, req.data.token);
    }
    return req;
}

export default {
    request: {
        success: requestSuccess
    },
    response: {
        success: responseSuccess
    }
}
