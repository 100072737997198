import queryString from 'querystring';
import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('CATEGORIES_ROOT_CHIDRENS').plugin(axiosPlugin, {template: "array"});
let fetchAction = redux.fetchActionGet();


export const TYPES = redux.types;

export const fetchCategoriesRoot = (query = {}) => fetchAction(`/categories/childrens-root?${queryString.stringify(query)}`);
export const resetCategoriesRoot = () => redux.actions.reset();

export const categoriesRoot = redux.reducer();
