import React from 'react';
import {Form} from "react-bootstrap";

const TagList = (props) => {
    let {
        row = {
            className : ""
        },
        btn = {
            className : "btn btn-primary m-2",
            type : "button",
            as : "input"
        },
        remove = {
            isRemove : false,
            remove : ()=>{console.log("function non envoyer")}
        },
        options = [],
        optionField = {
            value : "value",
            label : "label"
        }
    } = props;
    return (
        <Form.Row {...row}>
            {options.map((item, index) =>(
                <button {...btn} key={index}>
                    {item[optionField.label]} {remove.isRemove && (<span className="badge badge-danger ml-2 cursor-pointer" onClick={()=> remove.remove(item[optionField.value])}>X</span>)}
                </button>
            ))}
        </Form.Row>
    )
};
export default TagList;
