import React, {Fragment} from 'react';
import {Router, Switch, Route} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import history from '../../../libs/history';
import SubNavBar from "../../components/SubNavBar";

import CategorizedPhotos from "./CategorizedPhotos";
import CategorizedManualPhotos from "./CategorizedManualPhotos";
import CategorizedManualPhoto from "./CategorizedManualPhoto";
import CategorizedByCategory from "./CategorizedByCategory";
import {Container} from "react-bootstrap";


class Index extends React.Component {
    constructor(props) {
        super(props);
        this.pathPage = '/categorizations';
        this.subNavBar = [
            {link : `${this.pathPage}`, label : "Photos catégorisées"},
            {link : `${this.pathPage}/photo-by-category`, label : "Photos par catégorie"},
            {link : `${this.pathPage}/manual-photos`, label : "Catégoriser manuellement de photos"},
            //{link : `${this.pathPage}/manual-photo`, label : "Catégoriser manuellement d'une photo"},
        ];

    }

    /*shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(nextProps.location !== this.props.location){
            // this.props.resetModerationContents();
            return true;
        }
        return false;
    }*/

    render() {
        return (
            <Fragment>
                <Container>
                    <SubNavBar {...this.props} subNav={this.subNavBar}/>
                </Container>
                <div id={"categorizations"}>
                    <Router history={history}>
                        <Switch>
                            <Route path={`${this.pathPage}/:page([0-9]+)?`} exact component={CategorizedPhotos}/>
                            <Route path={`${this.pathPage}/manual-photos`} exact component={CategorizedManualPhotos}/>
                            <Route path={`${this.pathPage}/photo-by-category`} exact component={CategorizedByCategory}/>
                            <Route path={`${this.pathPage}/:mediaId`} exact component={CategorizedManualPhoto}/>
                        </Switch>
                    </Router>
                </div>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        currentUserInitialized: state.currentUser.initialized
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Index);
