import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import StoriesList from "../../components/StoriesList";


class ModerationsStories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {

        return (
            <StoriesList {...this.props}  />
        )
    }
}


const mapStateToProps = (state) => {
    return {
        currentUserInitialized: state.currentUser.initialized
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModerationsStories);
