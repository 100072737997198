import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Aqp from '../../../libs/mk-react-aqp';
import {PageLoader, LoadingBar} from "../../components/Loader";
import {fetchMedias, resetMedias} from "../../../creators/medias";
import {Col, Row} from "react-bootstrap";
import FormFields from "../../components/FormFields";
import {config} from "../../../config";
import ContentMedia from "./components/ContentMedia";
import Pagination from "../../components/Pagination";
import {deleteMedia, updateMedia} from "../../../creators/media";


let mapping = {
    "sort" : "trie",
    "owner.profile.gender" : "genre"
};

class ContentsMedias extends React.Component {
    constructor(props) {
        super(props);
        this.filter = {
            limit: 18,
            page: 1,
            sort: "createdAt",
            kind : "gallery"
        };
        this.aqp = new Aqp(mapping, {
            blacklist: ['limit', 'page'],
            currentLink: "/contents",
            filterDefault: this.filter
        });

        this.gender = config.genderList.map((item,index) =>{
            return {value : index, label : item}
        });
        this.gender.unshift({value : "all", label : "Tous les genres"});

        this.contentReview = [
            {title : "Tous les temperatures", _id : "all", variant: "light"},
            {title : "Non definis", _id : 0, variant: "light" },
            {title : "Soft", _id : 1, variant: "success" },
            {title : "Gros Plan", _id : 3, variant: "danger"},
            {title : "Hard", _id : 2, variant: "warning"}
        ];

        this.state = {
            filter: {...this.filter, ...this.aqp.query, page: this.props.match.params.page || 1},
        };

        this.handleFilter = this.handleFilter.bind(this);
        this.updateReview = this.updateReview.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.deleteMedia = this.deleteMedia.bind(this);
        this.fetchMedias(this.state.filter);
    }

    componentWillUnmount() {
        this.props.resetMedias();
    }

    fetchMedias(filter){
        this.props.fetchMedias(this.aqp.set(filter));
        this.setState({filter});
    }

    updateReview(mediaId, contentReview = 0){
        this.props.updateMedia(mediaId, {contentReview});
    }

    deleteMedia(mediaId){
        this.props.deleteMedia(mediaId);
    }

    handleFilter(name,value){
        let filter = {...this.state.filter, [name] : value};
        this.fetchMedias(filter);
    }

    handleChangePage(page, link) {
        let filter = {...this.state.filter, page};

        if (page === 1) {
            this.props.history.push(link);
        } else {
            this.props.history.push(link + '/' + page);
        }
        this.setState({filter});
        this.fetchMedias(filter);
        window.scrollTo(0, 0);
    }


    render() {
        let totalPage = this.props.mediasTotal ? Math.ceil(this.props.mediasTotal / this.state.filter.limit) : 0;
        return !this.props.mediasInitialized ? (<PageLoader />) :(
            <Fragment>
                <Row className={"mb-4"}>
                    <Col sm={2}>
                        <FormFields
                            group={{controlId : "kind"}}
                            type={"select"}
                            controle={{
                                name : "typeAction",
                                value : this.state.filter.kind,
                                options : [{value : "gallery", label : "Photos"}, {value : "video", label : "Videos"}],
                                onChange : (event) =>{this.handleFilter('kind',event.target.value)}
                            }}
                        />
                    </Col>
                    <Col sm={2}>
                        <FormFields
                            initialized={true}
                            group={{controlId : "contentReview"}}
                            type={"select"}
                            controle={{
                                name : "contentReview",
                                value : this.state.filter.contentReview,
                                options : this.contentReview,
                                optionField : {value : "_id", label : "title"},
                                onChange : (event) =>{this.handleFilter('contentReview',event.target.value)}
                            }}
                        />
                    </Col>
                    <Col sm={2}>
                        <FormFields
                            initialized={true}
                            group={{controlId : "gender"}}
                            type={"select"}
                            controle={{
                                name : "gender",
                                value : this.state.filter.gender,
                                options : this.gender,
                                onChange : (event) =>{this.handleFilter('gender',event.target.value)}
                            }}
                        />
                    </Col>
                    {this.props.mediasLoading && (<Col sm={12}><LoadingBar /></Col>)}
                </Row>
                <Row >
                    {this.props.medias.map(item => (
                            <ContentMedia
                                key={`${item._id}_${item.contentReview}`}
                                reviewOptions={this.contentReview}
                                currentContent={item}
                                updateReview={this.updateReview}
                                deleteMedia={this.deleteMedia}
                                currentUrl={this.aqp.queryUrl(this.state.filter).link}
                            />
                        )
                    )}
                </Row>
                <Pagination currentPage={this.state.filter.page}
                            pagesCount={totalPage}
                            {...this.aqp.queryUrl(this.state.filter, mapping)}
                            handleChangePage={this.handleChangePage}/>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        medias : state.medias.data,
        mediasTotal : state.medias.total,
        mediasLoading : state.medias.loading,
        mediasInitialized : state.medias.initialized,

    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchMedias,
    resetMedias,
    deleteMedia,
    updateMedia
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContentsMedias);
