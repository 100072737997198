import React from 'react';
import {Col, Row} from "react-bootstrap";

import Avatar from "../../../components/Avatar";
import createLink from "../../../../libs/createLink";

const CardUser = (props) => {
        let {owner = {}, className ="", classNameAvatar="avatar-max-80"} = props;
        let { username = "", profile = {}} = owner;
        let {avatar = "", gender = ""} = profile;
        return (
            <Row className={`mt-2 ${className}`}>
                <Col sm={12} className={"d-flex"}>
                   <div className={classNameAvatar}><Avatar avatar={avatar} gender={gender} /></div>
                    {owner._id ? <span className={"pl-2"}><a href={createLink("userProfile", owner)} target={"_blank"}> {username}</a></span> : null}
                </Col>
            </Row>
        )

}

export default CardUser;