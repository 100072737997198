import _ from 'lodash';

class ReduxCreator {
    constructor(baseName, opts = {}) {
        let {reset = true} = opts;
        this.baseName = _.snakeCase(baseName).toUpperCase();
        this.handlers = {};
        this.actions = {};
        this.initialState = {};
        this.types = {};
        if(reset) this.plugin(resetPlugin);
    };

    reducer(initialState) {
        if (initialState) this.initialState = initialState;
        return this.createReducer(this.initialState, this.handlers);
    }

    createReducer(initialState, handlers) {
        return function reducer(state = initialState, action) {
            if (handlers.hasOwnProperty(action.type)) {
                return handlers[action.type](state, action)
            } else {
                return state
            }
        }
    }

    plugin(plugin, opts = {}) {
        plugin(this, opts);
        return this;
    }

    reducerForType(type, reducer) {
        let clearType = this.registerType(type);
        this.handlers[clearType.full] = reducer;
    }

    registerType(type){
        let snakeType = _.snakeCase(type).toUpperCase();
        let clearType = {
            type: snakeType,
            full: `${this.baseName}_${snakeType}`
        };
        this.types[clearType.type] = clearType.full;
        return clearType;
    }

    registerAction(method, action) {
        this.actions[method] = action;
        return action;
    }

    action(
        type,
        handler = () => {
        },
        options = {}) {
        let actionType = this.registerType(type);
        return function (...args) {
            return {
                type: actionType.full,
                ...handler(...args)
            }
        };
    }
}

function type(baseName) {
    return new ReduxCreator(baseName);
}

export function resetPlugin(reduxCreator) {
    let action = reduxCreator.action('RESET');
    reduxCreator.registerAction('reset', action);
    reduxCreator.reducerForType('RESET', () => ({...reduxCreator.initialState}));
}

export default {
    type
};