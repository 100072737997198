import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Aqp from '../../libs/mk-react-aqp';

import {PageLoader, LoadingBar} from "../components/Loader";
import {Button, Col, Row} from "react-bootstrap";
import Pagination from "../components/Pagination";
import {fetchTags, resetTags} from "../../creators/tags";
import FormFields from "../components/FormFields";

let mapping = {
    "sort" : "trie"
};

class Tags extends React.Component {
    constructor(props) {
        super(props);
        this.filter = {
            limit: 60,
            page: 1,
            sort: "title",
            search : ""
        };
        this.aqp = new Aqp(mapping, {
            blacklist: ['limit', 'page'],
            currentLink: "/tags",
            filterDefault: this.filter
        });

        this.state = {
            filter: {...this.filter, ...this.aqp.query, page: this.props.match.params.page || 1},
        };
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleValue = this.handleValue.bind(this);
        this.fetchTags(this.state.filter);

    }

    fetchTags(filter){
        this.props.fetchTags(this.aqp.set(filter));
    }

    handleValue(search){
        let filter = {...this.state.filter, page : 1, search};
        this.setState({filter});
    }

    componentWillUnmount() {
        this.props.resetTags();
    }

    handleChangePage(page, link) {
        let filter = {...this.state.filter, page};
        if (page === 1) {
            this.props.history.push(link);
        } else {
            this.props.history.push(link + '/' + page);
        }
        this.setState({filter});
        this.fetchTags(filter);
        window.scrollTo(0, 0);
    }


    render() {
        let totalPage = this.props.tagsTotal ? Math.ceil(this.props.tagsTotal / this.state.filter.limit) : 0;
        return !this.props.tagsInitialized ? (<PageLoader />) :(
            <Fragment>
                <Row >
                    <Col sm={8}>
                        <FormFields
                            group={{controlId : "search"}}
                            type={"input"}
                            controle={{
                                type : "text",
                                name : "search",
                                value : this.state.filter.search,
                                onChange : (event) =>{this.handleValue(event.target.value)}
                            }}
                        />
                    </Col>
                    <Col><Button variant="primary" className={"btn-loading mb-5"}  onClick={()=>this.fetchTags(this.state.filter)}>
                        Rechercher
                    </Button></Col>
                    <Col>
                        <Button variant="primary" className={"btn-loading mb-5 float-right"}  onClick={()=>this.props.history.push("/tags/create")}>Ajouter un tag </Button>
                    </Col>
                </Row>
                <Row >
                    {this.props.tagsLoading ? (<LoadingBar />) : (
                        this.props.tags.map(item =>{
                            return <a key={item._id} href={`/tags/${item._id}`} className="list-group-item list-group-item-action list-group-item-light w-25">{item.title}</a>
                        })
                    )}
                </Row>
                <Pagination currentPage={this.state.filter.page}
                            pagesCount={totalPage}
                            {...this.aqp.queryUrl(this.state.filter, mapping)}
                            handleChangePage={this.handleChangePage}/>
            </Fragment>

        )
    }
}


const mapStateToProps = (state) => {
    return {
        tags : state.tags.data,
        tagsTotal : state.tags.total,
        tagsLoading : state.tags.loading,
        tagsInitialized : state.tags.initialized
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchTags,
    resetTags
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Tags);
