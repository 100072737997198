import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {PageLoader, SpinLoader} from "../../components/Loader";
import {Button, Col, Form, Row} from "react-bootstrap";
import FormFields from "../../components/FormFields";
import {deleteMedia, fetchMedia, resetMedia, updateMedia} from "../../../creators/media";
import {fetchUserAlbums, resetUserAlbums} from "../../../creators/user.albums";
import createLink from "../../../libs/createLink";
import TagList from "../../components/TagList";




class ContentsMedia extends React.Component {
    constructor(props) {
        super(props);

        this.mediaId = this.props.match.params.mediaId;

        this.optionsStatus = [
            {value : 0, label : "Désactiver"},
            {value : 1, label : "Activer"}
        ];

        this.contentReview = [
            // {title : "Non definis", _id : 0 },
            {title : "Soft", _id : 1 },
            {title : "Hard", _id : 3},
            {title : "Hot", _id : 2}
        ];

        this.state = {
            title : "",
            contentReview : 0,
            status : 0,
            tags : [],
            contentRaw : {},
            album : {}
        };
        this.removeTags = this.removeTags.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.props.fetchMedia(this.mediaId);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.media !== this.props.media){
            this.props.fetchUserAlbums(this.props.media.owner._id);
            this.setState({
                title : this.props.media.title,
                contentReview : this.props.media.contentReview,
                status : this.props.media.status,
                tags : this.props.media.tags,
                content : this.props.media.content,
                album : this.props.media.album._id
            })
        }
    }

    componentWillUnmount() {
        this.props.resetMedia();
        this.props.resetUserAlbums();
    }

    removeTags(tagId) {
        let tags = [...this.state.tags];
        tags = tags.filter(item => item._id !== tagId);
        this.setState({tags});
    }

    handleSubmit(e){
        e.preventDefault();
        if (!this.props.mediaLoading) {
            this.props.updateMedia(this.props.media._id, this.state);
            // this.props.updateStory(this.props.story._id, this.state);
            this.props.history.push("/contents");
        }
    }


    render() {

        return !this.props.mediaInitialized ? (<PageLoader />) :(
            <Form onSubmit={(e) => this.handleSubmit(e)}>
                <Row className={"mb-3"}>
                    <Col sm={1}>Pseudo</Col>
                    <Col><a href={createLink("userProfile", this.props.media.owner)} target={"new"}>{this.props.media.owner.username}</a></Col>
                </Row>
                <FormFields
                    initialized={this.props.albumsInitialized}
                    group={{controlId : "album"}}
                    type={"select"}
                    label={{
                        label : "Album"
                    }}
                    controle={{
                        name : "album",
                        value : this.state.album,
                        options :this.props.albums,
                        disabled : this.props.albums.length ? false : true,
                        optionField : {value : "_id", label : "title"},
                        onChange : (event) =>{this.setState({[event.target.name] : event.target.value})}
                    }}
                />
                <FormFields
                    group={{controlId : "title"}}
                    type={"input"}
                    label={{
                        label : "Titre"
                    }}
                    controle={{
                        type : "text",
                        name : "title",
                        value : this.state.title,
                        onChange : (event) =>{this.setState({[event.target.name] : event.target.value})
                        }
                    }}
                />

                <FormFields
                    initialized={this.props.mediaInitialized}
                    group={{controlId : "contentReview"}}
                    type={"select"}
                    label={{
                        label : "Temperature"
                    }}
                    controle={{
                        name : "contentReview",
                        value : this.state.contentReview,
                        options :this.contentReview,
                        optionField : {value : "_id", label : "title"},
                        onChange : (event) =>{this.setState({[event.target.name] : event.target.value})}
                    }}
                />
Description en attente du draft
                {/*<FormFields*/}
                {/*    group={{controlId : "content"}}*/}
                {/*    type={"input"}*/}
                {/*    label={{*/}
                {/*        label : "Description"*/}
                {/*    }}*/}
                {/*    controle={{*/}
                {/*        type : "input",*/}
                {/*        as : "textarea",*/}
                {/*        name : "content",*/}
                {/*        value : this.state.description,*/}
                {/*        rows :"3",*/}
                {/*        onChange : (event) =>{ this.setState([event.target.name] , event.target.value)}*/}
                {/*    }}*/}
                {/*/>*/}

                <Row>
                    <Col sm={12}>
                        Tags
                    </Col>
                </Row>

                <TagList
                    row = {{
                        className : "mb-3"
                    }}
                    btn = {{
                        className : "btn btn-primary m-2",
                        type : "button",
                        as : "input"
                    }}
                    remove = {{
                        isRemove : true,
                        remove : this.removeTags
                    }}
                    options = {this.state.tags}
                    optionField = {{
                        value : "_id",
                        label : "title"
                    }}
                />

                <FormFields
                    group={{controlId : "status"}}
                    type={"select"}
                    label={{
                        label : "Statut"
                    }}
                    controle={{
                        name : "status",
                        value : this.state.status,
                        options : this.optionsStatus,
                        onChange : (event) =>{ this.setState([event.target.name] , event.target.value)}
                    }}
                />


                <Button variant="primary" type="submit" className={"btn-loading"}>
                    Valider {this.props.mediaLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                </Button>
            </Form>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        media : state.media.data,
        mediaLoading : state.media.loading,
        mediaInitialized : state.media.initialized,

        albums : state.userAlbums.data,
        albumsLoading : state.userAlbums.loading,
        albumsInitialized : state.userAlbums.initialized,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchMedia,
    resetMedia,
    deleteMedia,
    updateMedia,
    fetchUserAlbums,
    resetUserAlbums
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContentsMedia);
