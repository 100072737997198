import {$createParagraphNode, $createTextNode, $getRoot} from "lexical";
import {$createMentionNode} from "../plugins/mention/MentionNode";
import slicer from "../sclicer";
import {$createLinkNode} from "@lexical/link";

function removeAtSymbolOnFirstPlace(str = "") {
    if (str.charAt(0) === "@") return str.slice(1);
    else return str;
}

export default function parseSimple(text = "", meta = {}) {
    const root = $getRoot();
    const paragraph = $createParagraphNode();
    let metas = [];
    Object.keys(meta).forEach(key=>{
        let currentMetas = meta[key].map(({offset, length, ...data})=>({
            type : key,
            offset,
            length,
            data
        }));
        metas.push(...currentMetas);
    });
    let {sliced} = slicer(text, metas);
    let nodes = sliced.map(slice=>{
        if (slice.isMeta && slice.type === "mention"){
            return $createMentionNode(removeAtSymbolOnFirstPlace(slice.text), slice.data.user._id || slice.data.user);
        }
        else if (slice.isMeta && slice.type === "link"){
            return $createLinkNode(slice.data.url).append($createTextNode(slice.text));
        }
        else return $createTextNode(slice.text);
    });
    paragraph.append(...nodes);
    root.clear();
    paragraph.select();
    root.append(paragraph);
}