import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('KEYWORDS_WARNINGS_SETTING').plugin(axiosPlugin);

let fetchAction = redux.fetchActionGetOne();

let updateAction = redux.fetchActionUpdate();


export const TYPES = redux.types;

export const fetchKeywordsWarningsSetting = () => fetchAction(`/keywords-warnings/setting`);
export const updateKeywordsWarningsSetting = (data = {}) => updateAction(`/keywords-warnings/setting`, data);
export const resetKeywordsWarningsSetting = () => redux.actions.reset();

export const keywordsWarningsSetting = redux.reducer();