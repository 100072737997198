import React from 'react';
import {Button, Col, Row} from "react-bootstrap";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {updateUser} from "../../creators/user";

import FormFields from "../components/FormFields";
import _ from "lodash";
import MkEditor from "../components/editor/Editor";
import {SpinLoader} from "../components/Loader";

class FormSettingSignature extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            contentRaw : _.get(this.props.user, "setting.signature.contentRaw") || {},
            display : _.get(this.props.user, "setting.signature.display")
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit() {
        if (!this.props.userLoading) {
            let currentUser = {
                setting: {
                    signature : {
                        contentRaw : this.state.contentRaw,
                        display : this.state.display
                    }
                },
            };

            this.props.updateUser(this.props.user._id, currentUser);
        }
    }

    render() {
        return (
            <Row>
                <Col xs={12}>
                    <h4 className={"w-100"}>Signature</h4>
                    <MkEditor kind={"userContent"} onChange={(contentRaw) => this.setState({contentRaw})}
                              defaultValue={_.get(this.props.user,"setting.signature.contentRaw")}/>
                </Col>
                <Col xs={12}>
                    <FormFields
                        type={"checkbox"}
                        checkBox={{
                            id : `checkbox-display`,
                            name : "display",
                            checked : this.state.display,
                            label : "Attacher la signature par défaut",
                            onChange : () =>{this.setState({display : !this.state.display})}
                        }}
                    />
                </Col>
                <Col xs={12}>
                    <Button variant="primary" onClick={()=>this.handleSubmit()} className={"btn-loading"}>
                        Valider {this.props.userLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                    </Button>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        userLoading: state.user.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateUser
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormSettingSignature);
