import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


// function socketPlugin(reduxCreator) {
//     let subscribe = reduxCreator.action('SUBSCRIBE', (subscribeTo)=>{
//         socketClient.subscribe(subscribeTo);
//         return {};
//     });
//     reduxCreator.registerAction('subscribe', subscribe);
//     reduxCreator.reducerForType('SUBSCRIBE', (state) => ({...state, subscribed:true}));
// }

let redux = reduxCreator.type('CURRENT_USER').plugin(axiosPlugin);
//

export const TYPES = redux.types;

let fetchCurrentUserAction = redux.fetchActionGetOne();

let updateCurrentUserAction = redux.fetchActionUpdate();

// redux.reducerForType('RESET', (state,payload) => {
//     let newState = {...state};
//     console.log("ok", newState);
//     return newState;
// });


export const fetchCurrentUser = () => fetchCurrentUserAction(`/users/me`);
export const updateCurrentUser = (data) => updateCurrentUserAction(`/users/me`, data);
export const resetCurrentUser = () => redux.actions.reset();

export const currentUser = redux.reducer();