import React, { Component } from 'react';
import Icon from "../../Icon";
import { EditorState, RichUtils, SelectionState, Modifier } from "draft-js";
import {Button, Modal} from "react-bootstrap";
import FormFields from "../../FormFields";


export default class AddLink extends Component {
    constructor(props){
        super(props);
        this.state = {
            toggleUrl: false,
            urlText: "",
            url: "",
            error: false,
            showUrlText: true,
        };
    };


    componentDidUpdate(prevProps, prevState) {
        if(prevState.toggleUrl !== this.state.toggleUrl){
            const editorState = this.props.editorState;
            const selection = editorState.getSelection();
            const anchorKey = selection.getAnchorKey();
            const currentContent = editorState.getCurrentContent();
            const currentBlock = currentContent.getBlockForKey(anchorKey);
            //Then based on the docs for SelectionState -
            const start = selection.getStartOffset();
            const end = selection.getEndOffset();
            const selectedText = currentBlock.getText().slice(start, end);

            if(selectedText.length >= 1){
                this.setState({showUrlText: false});
            } else {
                this.setState({showUrlText: true});
            }
        }
    }

    handleToggle(){
        this.setState({
            urlText: "",
            url: "",
            error: false,
            showUrlText: true,
            toggleUrl: !this.state.toggleUrl
        });
    }

    onAddLink(){
        const editorState = this.props.editorState;
        const contentState = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        const textWithSpace = this.state.urlText.concat(' ');

        // create new content with text
        const newContent = Modifier.insertText(
            contentState,
            selection,
            textWithSpace,
        );
        // create new link entity
        const newContentWithEntity = newContent.createEntity(
            "LINK",
            'MUTABLE',
            {url: this.state.url}
        );
        const entityKey = newContentWithEntity.getLastCreatedEntityKey();
        // create new selection with the inserted text
        const anchorOffset = selection.getAnchorOffset();
        const newSelection = new SelectionState({
            anchorKey: selection.getAnchorKey(),
            anchorOffset,
            focusKey: selection.getAnchorKey(),
            focusOffset: anchorOffset + this.state.urlText.length,
        });
        // and aply link entity to the inserted text
        const newContentWithLink = Modifier.applyEntity(
            newContentWithEntity,
            newSelection,
            entityKey,
        );
        // create new state with link text
        const withLinkText = EditorState.push(
            editorState,
            newContentWithLink,
            'insert-characters',
        );
        // now lets add cursor right after the inserted link
        const newEditorState = EditorState.forceSelection(
            withLinkText,
            newContent.getSelectionAfter(),
        );
        // update the editor with all changes
        this.props.onChange(newEditorState);
        this.setState({url:  "", urlText: "", toggleUrl: false, showUrlText: true});
    };

    urlOnSelectedLink(){
        const editorState = this.props.editorState;
        const selection = editorState.getSelection();
        const link = this.state.url;
        if (!link) {
            this.props.onChange(RichUtils.toggleLink(editorState, selection, null), this.setState({url: "", open: false}));
            return "handled";
        }
        const content = editorState.getCurrentContent();
        const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
            url: link
        });
        const newEditorState = EditorState.push(
            editorState,
            contentWithEntity,
            "create-entity"
        );
        const entityKey = contentWithEntity.getLastCreatedEntityKey();
        this.props.onChange(RichUtils.toggleLink(newEditorState, selection, entityKey), this.setState({}));
        return "handled";
    };

    handleInput(name, value){
        this.setState({ [name]: value });
    }

    handleKeyPress(event) {
        if (event.key === "Enter") {
            this.handleSubmit();
        }
    }

    handleSubmit(){
        if(this.state.showUrlText && this.state.urlText.length >= 1 && this.state.url.length >= 1){
            this.onAddLink();
            this.setState({url:  "", urlText: "", toggleUrl: false, showUrlText: true, error: false});
        } else if (!this.state.showUrlText && this.state.url.length >= 1) {
            this.urlOnSelectedLink();
            this.setState({url:  "", urlText: "", toggleUrl: false, showUrlText: true, error: false});
        } else {
            this.setState({error: true});
        }
    }

    render() {

        const buttonClassName = this.state.open ?
            "addItemPressedButton" :
            "addItemButton";
        return (
            <div className={"addItem"}>
                <span
                    className={buttonClassName}
                    onMouseUp={()=>this.handleToggle()}
                    type="button"
                >
                    <Icon type={"link"} />
                </span>
                <Modal centered backdrop={false}   show={this.state.toggleUrl} onHide={()=>this.setState({toggleUrl: false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Ajouter un lien</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.showUrlText && (
                            <FormFields
                                group={{controlId : "ancre"}}
                                type={"input"}
                                label={{
                                    label : "Texte affiché pour le lien"
                                }}
                                controle={{
                                    type : "text",
                                    name : "urlText",
                                    placeholder : "exemple : cliquez ici pour voir mon lien",
                                    value : this.state.urlText,
                                    onKeyPress : (event) =>{this.handleKeyPress(event)},
                                    onChange : (event) =>{this.setState({[event.target.name] : event.target.value})}
                                }}
                            />
                        )}
                        <FormFields
                            group={{controlId : "url"}}
                            type={"input"}
                            label={{
                                label : "Url"
                            }}
                            controle={{
                                type : "text",
                                name : "url",
                                placeholder : "https://",
                                value : this.state.url,
                                onChange : (event) =>{this.setState({[event.target.name] : event.target.value})}
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        {this.state.error && (
                            <p className={"text-danger w-100"}>Vous n'avez pas rempli tous les champs</p>
                        )}
                        <Button variant="danger"  className={"btn-loading"} onClick={()=>this.setState({toggleUrl: false})}>
                            Annuler
                        </Button>

                        <Button variant="primary" type="submit" className={"btn-loading"} onClick={()=>this.handleSubmit()} >
                            Ajouter
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}