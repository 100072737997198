import React, {Fragment} from 'react';
import {Router, Switch, Route} from 'react-router-dom';

import history from '../../libs/history';
import TagsList from './Tags.tagsList';
import SubNavBar from "../components/SubNavBar";
import TagList from "../pages/Tags.tagList";
import TagsPage from "../pages/Tags.tags";
import FormTag from "../formulaires/tag"
import TagEdit from "../pages/Tags.tag"

class Tags extends React.Component {
    constructor(props) {
        super(props);
        this.pathPage = '/tags';
        this.subNavBar = [
            {link : `${this.pathPage}/list-tag`, label : "Liste Tags"},
            {link : `${this.pathPage}`, label : "Tags"},
        ]
    }


    render() {
        return (
            <Fragment>
                <SubNavBar {...this.props} subNav={this.subNavBar}/>
                <Router history={history}>
                    <Switch>
                        <Route exact path={`${this.pathPage}/create`} component={FormTag}/>
                        <Route exact path={`${this.pathPage}/:page([0-9]+)?`} component={TagsPage}/>
                        <Route exact path={`${this.pathPage}/list-tag/:tagListId/:page([0-9]+)?`} component={TagList}/>
                        <Route exact path={`${this.pathPage}/list-tag/:page([0-9]+)?`} component={TagsList}/>
                        <Route exact path={`${this.pathPage}/:tagId`} component={TagEdit}/>
                    </Switch>
                </Router>
            </Fragment>
        )
    }
}


export default Tags;
