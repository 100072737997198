import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('ALBUM_MEDIAS').plugin(axiosPlugin, {template: "array"});

let fetchAlbumMediasAction = redux.fetchActionGet();

redux.reducerForType('ADD', (state,payload) => {
    let newState = {...state};
    payload.data.map(item => newState.data.unshift(item));
    newState.total++;
    return newState;
});

redux.reducerForType('DELETE', (state,payload) => {
    let newState = {...state};
    if(payload.data && payload.data._id){
        newState.data = newState.data.filter(item => item._id.toString() !== payload.data._id.toString());
        newState.total--;
        if(newState.total < 0){
            newState.total = 0;
        }
    }
    return newState;
});

redux.reducerForType('UPDATE', (state,payload) => {
    let newState = {...state};
    if(payload.data && payload.data._id){
        newState.data = newState.data.map(item => {
            if(item._id.toString() === payload.data._id.toString()){
                item = payload.data;
            }
            return item;
        });
    }
    return newState;
});

export const fetchAlbumMedias = (albumId, query = {}) => fetchAlbumMediasAction(`/albums/${albumId}/medias?${queryString.stringify(query)}`);
export const resetAlbumMedias = () => redux.actions.reset();

export const albumMedias = redux.reducer();



