import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";



let redux = reduxCreator.type('FACETTES_TYPES').plugin(axiosPlugin, {template: "array"});

redux.reducerForType('ADD', (state, payload) => {
    let newState = {...state};
    if(payload.data && payload.data._id) {
        newState.data = [...newState.data, payload.data];
    }
    return newState;
});

redux.reducerForType('DELETE', (state,payload) => {
    let newState = {...state, lastUpdate : Date.now()};
    if(payload.data && payload.data._id){
        newState.data = newState.data.filter(item => item._id.toString() !== payload.data._id.toString());
        newState.total--;
        if(newState.total < 0){
            newState.total = 0;
        }
    }
    return newState;
});

redux.reducerForType('UPDATE', (state,payload) => {
    let newState = {...state, lastUpdate : Date.now()};
    if(payload.data && payload.data._id){
        newState.data = newState.data.map(item => {
            if(item._id.toString() === payload.data._id.toString()){
                item = payload.data;
            }
            return item;
        });
    }
    return newState;
});

export const TYPES = redux.types;

let fetchAction = redux.fetchActionGet();

export const fetchTypes = (query = {}) => fetchAction(`/facettes-types?${queryString.stringify(query)}`);
export const resetTypes = () => redux.actions.reset();

export const facettesTypes = redux.reducer();