import {put,takeEvery} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {TYPES as CATEGORY_TYPE} from '../creators/category';
import {TYPES as CATEGORIES_TYPE} from '../creators/categories';


export function* updateSuccess(action) {
    try {
        yield put({type: CATEGORIES_TYPE.UPDATE, data : action.payload});
    } catch (error) {
    }
}

export function* deleteSuccess(action) {
    try {
        yield put({type: CATEGORIES_TYPE.REMOVE, data : action.payload});
    } catch (error) {

    }
}

export function* createSuccess(action) {
    try {
        yield put({type: CATEGORIES_TYPE.ADD, data : action.payload});
    } catch (error) {
    }
}

export function* watchCategory() {
    yield takeEvery(CATEGORY_TYPE.UPDATE_SUCCESS, updateSuccess);
    yield takeEvery(CATEGORY_TYPE.DELETE_SUCCESS, deleteSuccess);
    yield takeEvery(CATEGORY_TYPE.CREATE_SUCCESS, createSuccess);
}

export default watchCategory;

