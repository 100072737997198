import React, {Fragment} from 'react';
import {Button, Modal} from "react-bootstrap";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {fetchUser, updateUser} from "../../creators/user";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FormFields from "../components/FormFields";
import {SpinLoader} from "../components/Loader";
import moment from "moment";


class FormProfileBan extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user : this.props.user,
            toggleModal : false,
            reason : "",
            isRemoveContent : false
        };

    }


    handleSubmit() {
        //e.preventDefault();
        if (!this.props.userLoading) {
            let user = this.state.user;
            delete user.__v;
            user = {
                ...user,
                role : "banned",
                profile : {
                  ...user.profile,
                  avatarId : "",
                  coverId : ""
                },
                banned : {
                    reason : this.state.reason,
                    roleBefore : this.props.user.role,
                    date : moment()
                }
            }
            this.props.updateUser(this.props.user._id, user);
            if(this.state.isRemoveContent){
                this.props.deleteContent();
            }
            this.setState({toggleModal : false, reason : "", isRemoveContent : false});
        }
    }

    render() {
        return (
            <Fragment>
                <Button variant="warning" onClick={()=>this.setState({toggleModal : true, reason : ""})}>
                    <FontAwesomeIcon
                        icon="ban"
                    /> Bannir
                </Button>
                <Modal centered backdrop={false} show={this.state.toggleModal} onHide={()=>this.setState({toggleModal : false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Bannissement</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FormFields
                            group={{controlId : "reason"}}
                            type={"input"}
                            label={{
                                label : "Pour quelle raison voulez vous bannir ce membre ?"
                            }}
                            controle={{
                                as : "textarea",
                                type : "text",
                                name : "reason",
                                value : this.state.reason,
                                required : false,
                                onChange : (event) =>{this.setState({[event.target.name] : event.target.value})}
                            }}
                        />

                        <FormFields
                            type={"checkbox"}
                            checkBox={{
                                id : `checkbox-isRemoveContent`,
                                className : "text-danger",
                                name : "isRemoveContent",
                                checked : this.state.isRemoveContent,
                                label : "Supprimer tout le contenu du membre",
                                onChange : () =>{this.setState({isRemoveContent : !this.state.isRemoveContent})}
                            }}
                        />

                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger"  className={"btn-loading"} onClick={()=>this.setState({toggleModal: false})}>
                            Annuler
                        </Button>

                        <Button variant="primary" type="submit" className={"btn-loading"} onClick={()=>this.handleSubmit()} >
                            Valider {this.props.userLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        userLoading: state.user.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateUser,
    fetchUser
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormProfileBan);
