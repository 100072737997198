import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Modal} from "react-bootstrap";
import {fetchFacette, resetFacette, updateFacette} from "../../../../creators/facette";
import FormFacette from "../../../formulaires/facette";
import _ from "lodash";

class EditFacette extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleModalEdit : this.props.toggle || false
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.props.fetchFacette(this.props.currentId);
    }

    componentWillUnmount(){
        this.handleToggle();
    }

    handleToggle(){
        this.setState({toggleModalEdit : false});
        this.props.handleToggle();
    }

    handleSubmit(data) {
        if (!this.props.facetteLoading) {
            this.props.updateFacette(this.props.facette._id,data).then(response =>{
                let statusCode = _.get(response,"error.response.statusCode");
                if(statusCode === 200 || statusCode === undefined){
                    this.handleToggle();
                }
            }).catch(e =>{

            })
        }
    }

    render() {
        return (
            <Fragment>
                {this.props.facetteInitialized ? (
                    <Modal size={"xl"} centered backdrop={false} show={this.state.toggleModalEdit} onHide={()=>this.handleToggle()} >
                        <FormFacette
                            handleSubmit={this.handleSubmit}
                            onHide={()=>this.handleToggle()}
                            _id={this.props.facette._id}
                            uri={this.props.facette.uri}
                            uriInitial={this.props.facette.uriInitial}
                            typeId={this.props.facette.typeId}
                            count={this.props.facette.count}
                            categoriesValues={this.props.facette.categoriesValues}
                            deleted={this.props.facette.deleted}
                            redirectUri={this.props.facette.redirectUri}
                            seo={this.props.facette.seo}
                            valid={this.props.facette.valid}
                            indexable={this.props.facette.indexable}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        facette : state.facette.data,
        facetteInitialized : state.facette.initialized,
        facetteLoading : state.facette.loading,
        facetteStatusCode : state.facette.error && state.facette.error.statusCode,
        facetteSubmitting : state.facette.submitting,
        facetteTest : state.facette
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateFacette,
    resetFacette,
    fetchFacette,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditFacette);
