import React from 'react';
import Nav from "react-bootstrap/Nav";
import {Link} from "react-router-dom";

const NavBar = props => {
    const { location } = props;
    let pathname = location.pathname;
    if(pathname){
        let location2 = pathname.split("/");
        pathname = `/${location2[1]}`;
    }
    return (
            <Nav variant="pills" activeKey={pathname} className='mb-4'>
                <Nav.Item>
                    <Nav.Link as={Link} to="/" eventKey={"/"}>Dashboard</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/members" eventKey={"/members"}>Membres</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/moderations" eventKey="/moderations">Moderations</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/contents/status" eventKey="/contents">Contenus</Nav.Link>
                </Nav.Item>
                {/*<Nav.Item>*/}
                {/*    <Nav.Link as={Link} to="/tickets" eventKey="/tickets">Tickets</Nav.Link>*/}
                {/*</Nav.Item>*/}
                {/*<Nav.Item>*/}
                {/*    <Nav.Link as={Link} to="/supect-contents" eventKey="/supect-contents">Contenu Supect</Nav.Link>*/}
                {/*</Nav.Item>*/}
                <Nav.Item>
                    <Nav.Link as={Link} to="/reportings" eventKey="/reportings">Rapports</Nav.Link>
                </Nav.Item>
                {/*<Nav.Item>*/}
                {/*    <Nav.Link as={Link} to="/newsletter" eventKey="/newsletter">Newsletter</Nav.Link>*/}
                {/*</Nav.Item>*/}
                <Nav.Item>
                    <Nav.Link as={Link} to="/configurations" eventKey="/configurations">Config</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/stats" eventKey="/stats">Stats</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/moteur-a-facettes" eventKey="/moteur-a-facettes">Moteur à facettes</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/categorizations" eventKey="/categorizations">Categorisations</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/keywords-warnings" eventKey="/keywords-warnings">Keywords Warnings</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link as={Link} to="/ia-classifier" eventKey="/ia-classifier">IA</Nav.Link>
                </Nav.Item>
            </Nav>

    )
};

export default NavBar;