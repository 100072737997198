import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Aqp from '../../../libs/mk-react-aqp';
import {LoadingBar, PageLoader} from "../../components/Loader";

import Pagination from "../../components/Pagination";
import _ from "lodash";
import DateSince from "../../components/Date";
import createLink from "../../../libs/createLink";
import {Link} from "react-router-dom";
import moment from "moment-timezone";
import {fetchBannedMembers, resetBannedMembers} from "../../../creators/bannedMembers";
import { fetchDomainNotWhiteList, resetDomainNotWhiteList} from "../../../creators/antispam.domainNotWhiteList";

let mapping = {
    "sort" : "trie",
};

class ModerationsBannedMembers extends React.Component {
    constructor(props) {
        super(props);
        let createdAtMax = moment().subtract(1, 'month').format('YYYY-MM-DD');

        this.filter = {
            limit: 100,
            page: 1,
            sort: "-createdAt",
            "createdAt>" : createdAtMax
        };
        this.aqp = new Aqp(mapping, {
            blacklist: ['limit', 'page'],
            currentLink: "/moderations/membres-bannis",
            filterDefault: this.filter
        });

        this.state = {
            filter: {...this.filter, ...this.aqp.query, page: this.props.match.params.page || 1},
        };

        this.handleFilter = this.handleFilter.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.fetchData(this.state.filter);
        this.props.fetchDomainNotWhiteList();
    }

    componentWillUnmount() {
        this.props.resetBannedMembers();
        this.props.resetDomainNotWhiteList();
    }

    fetchData(filter){
        this.props.fetchBannedMembers(this.aqp.set(filter));
        this.setState({filter});
    }


    handleFilter(name,value){
        let filter = {...this.state.filter, [name] : value};
        this.fetchMedias(filter);
    }

    handleChangePage(page, link) {
        let filter = {...this.state.filter, page};

        if (page === 1) {
            this.props.history.push(link);
        } else {
            this.props.history.push(link + '/' + page);
        }
        this.setState({filter});
        this.fetchData(filter);
        window.scrollTo(0, 0);
    }


    render() {
        let totalPage = this.props.bannedMembersTotal ? Math.ceil(this.props.bannedMembersTotal / this.state.filter.limit) : 0;
        return !this.props.bannedMembersInitialized ? (<PageLoader />) :(
            <div id={"banned-members"}>
                <h3>Ban des 2 dernires mois</h3>
                {this.props.domainNotWhiteListInitialized ? (
                    <>
                        <span>Liste des domaines</span>
                        <div className={"last-domain"}>
                            {this.props.domainNotWhiteList.map(item =>(
                                <li>{item.label} ( {item.count} )</li>
                            ))}
                        </div>
                    </>

                ) : null}

                {this.props.bannedMembersLoading ? (<LoadingBar />) : null}
                <div className={"banned-members-list"}>
                    {this.props.bannedMembers.map(item => (
                            <div key={item._id} className={"banned-members-list-item"}>
                                <div className={"banned-members-list-item-informations"}>
                                    <div className={"banned-members-list-item-informations-username"}><Link to={createLink('userProfileAdmin', item.owner)} alt={"Profile admin"} >{_.get(item,"owner.username")}</Link></div>
                                    <div className={"banned-members-list-item-informations-date"}>{item.actions.join(', ')}</div>
                                    <div className={"banned-members-list-item-informations-date"}><DateSince date={item.createdAt} currentFormat={false}/></div>
                                </div>
                                <div className={"status-list-item-content mb-1"}><strong>Collection</strong> : {item.collectionName}</div>
                                <div className={"status-list-item-content mb-1"}><strong>texte original</strong> : {item.contentOriginal}</div>
                                <div className={"status-list-item-content"}><strong>Domaines</strong> : {item.positiveDomain.join(', ')}</div>

                            </div>
                        )
                    )}
                </div>
                <Pagination currentPage={this.state.filter.page}
                            pagesCount={totalPage}
                            {...this.aqp.queryUrl(this.state.filter, mapping)}
                            handleChangePage={this.handleChangePage}/>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        bannedMembers : state.bannedMembers.data,
        bannedMembersTotal : state.bannedMembers.total,
        bannedMembersLoading : state.bannedMembers.loading,
        bannedMembersInitialized : state.bannedMembers.initialized,

        domainNotWhiteList : state.domainNotWhiteList.data,
        domainNotWhiteListInitialized : state.domainNotWhiteList.initialized,

    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchBannedMembers,
    resetBannedMembers,
    fetchDomainNotWhiteList,
    resetDomainNotWhiteList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModerationsBannedMembers);
