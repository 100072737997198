import React from "react";
import {Link} from "react-router-dom";

import createLink from "../../../libs/createLink";

import {
    convertFromRaw,
    convertToRaw,
    EditorState,
    RichUtils,
    ContentState,
    AtomicBlockUtils
} from "draft-js";
import Editor from 'draft-js-plugins-editor';
import isSoftNewlineEvent from "draft-js/lib/isSoftNewlineEvent";

import createEmojiPlugin from 'draft-js-emoji-plugin';
import createMentionPlugin from 'draft-js-mention-plugin';
import createImagePlugin from 'draft-js-image-plugin';
import addLinkPlugin from "./plugins/AddLinkPlugin";
import createLinkifyPlugin from 'draft-js-linkify-plugin';

import 'draft-js-linkify-plugin/lib/plugin.css';
import "draft-js/dist/Draft.css";
import 'draft-js-emoji-plugin/lib/plugin.css';
import 'draft-js-mention-plugin/lib/plugin.css';
import 'draft-js-image-plugin/lib/plugin.css';

import {getBlockStyle} from "./blockStyles/BlockStyleToolbar";
import InlineStyleToolbar from "./blockStyles/InlineStylesToolbar";
import {mediaBlockRenderer} from "./entities/mediaBlockRenderer";
import ImageAdd from './entities/ImageAdd';
import AddLink from "./entities/AddLink";
import Avatar from "../Avatar";
import ColorControls from "./blockStyles/ColorControls";
// import AddSurvey from "./entities/AddSurvey";
// import {PageLoader} from "../Loader";

const defaultColor = 'rgba(51,51,51, 1.0)';
const primaryColor = 'rgba(95, 170, 253, 1.0)';
const secondaryColor = 'rgba(128, 128, 128, 1.0)';
const successColor = 'rgba(40, 167, 69, 1.0)';
const dangerColor = 'rgba(251, 77, 77, 1.0)';
const warningColor = 'rgba(255, 177, 66, 1.0)';
const infoColor = 'rgba(94, 159, 244, 1.0)';

const CustomColorStyle = {
    "color-default": {
        backgroundColor: defaultColor,
    },
    "color-primary": {
        backgroundColor: primaryColor,
    },
    "color-secondary": {
        backgroundColor: secondaryColor,
    },
    "color-success": {
        backgroundColor: successColor,
    },
    "color-danger": {
        backgroundColor: dangerColor,
    },
    "color-warning": {
        backgroundColor: warningColor,
    },
    "color-info": {
        backgroundColor: infoColor,
    }
};

const colorStyleMap = {
    "color-primary": {
        color: primaryColor,
        fill: primaryColor,
    },
    "color-secondary": {
        color: secondaryColor,
        fill: secondaryColor,
    },
    "color-success": {
        color: successColor,
        fill: successColor,
    },
    "color-danger": {
        color: dangerColor,
        fill: dangerColor,
    },
    "color-warning": {
        color: warningColor,
        fill: warningColor,
    },
    "color-info": {
        color: infoColor,
        fill: infoColor,
    }
};

const imagePlugin = createImagePlugin();
const linkifyPlugin = createLinkifyPlugin({
    target: "_blank",
    rel : "noopener noreferrer nofollow"
});

const customSuggestionsFilter = (searchValue, suggestions) => {
    const size = (list) => (list.constructor.name === 'List'
        ? list.size
        : list.length);

    const get = (obj, attr) => (obj.get
        ? obj.get(attr)
        : obj[attr]);

    const value = searchValue.toLowerCase();
    const filteredSuggestions = suggestions.filter((suggestion) => (
        !value || get(suggestion, 'username').toLowerCase().indexOf(value) > -1
    ));
    const length = size(filteredSuggestions) < 2 ? size(filteredSuggestions) : 1;
    return filteredSuggestions.slice(0, length);
};

const Entry = (props) => {
    const {
        mention,
        theme,
        searchValue,
        isFocused,
        ...parentProps
    } = props;
    return (
        <div {...parentProps}>
            <div className={"mentionSuggestionsEntryContainer"}>
                <div className={"mentionSuggestionsEntryContainerLeft"}>
                    <Avatar gender={mention.profile.gender} avatar={mention.profile.avatar} />
                </div>

                <div className={"mentionSuggestionsEntryContainerRight"}>
                    <div className={"mentionSuggestionsEntryText"}>
                        {mention.username}
                    </div>
                </div>
            </div>
        </div>
    );
};

class MkEditor extends React.Component {
    constructor(props) {
        super(props);
        const newEditorState = this.props.defaultValue && this.props.defaultValue.blocks && this.props.defaultValue.blocks[0] ? EditorState.createWithContent(convertFromRaw(this.props.defaultValue)) : EditorState.createEmpty();
        this.mentionPlugin = createMentionPlugin();
        this.mentions = [
            {
                name: 'Matthew Russell',
                title: 'Senior Software Engineer',
                avatar: 'https://pbs.twimg.com/profile_images/517863945/mattsailing_400x400.jpg',
            },
            {
                name: 'Julian Krispel-Samsel',
                title: 'United Kingdom',
                avatar: 'https://avatars2.githubusercontent.com/u/1188186?v=3&s=400',
            },
            {
                name: 'Jyoti Puri',
                title: 'New Delhi, India',
                avatar: 'https://avatars0.githubusercontent.com/u/2182307?v=3&s=400',
            },
            {
                name: 'Max Stoiber',
                title: 'Travels around the world, brews coffee, skis mountains and makes stuff on the web.',
                avatar: 'https://pbs.twimg.com/profile_images/763033229993574400/6frGyDyA_400x400.jpg',
            },
            {
                name: 'Nik Graf',
                title: 'Passionate about Software Architecture, UX, Skiing & Triathlons',
                avatar: 'https://avatars0.githubusercontent.com/u/223045?v=3&s=400',
            },
            {
                name: 'Pascal Brandt',
                title: 'HeathIT hacker and researcher',
                avatar: 'https://pbs.twimg.com/profile_images/688487813025640448/E6O6I011_400x400.png',
            },
            {
                name: 'Łukasz Bąk',
                title: 'Randomly Generated User',
                avatar: 'https://randomuser.me/api/portraits/men/36.jpg',
            },
        ];
        this.state = {
            editorState: newEditorState,
            suggestions: [],
            mentions: [],
            initial : false
        };
        this.mentionPlugin = createMentionPlugin({
            mentionComponent: (mentionProps) => (
                <Link to={createLink('userProfile', mentionProps.mention)} target={"_blank"} rel="noopener noreferrer" className={"mentionned-name"}>
                    @{mentionProps.mention.username}
                </Link>
            ) ,
            theme : {
                mentionSuggestions : "mentionSuggestions",
                mentionSuggestionsEntry: "mentionSuggestionsEntry",
                mentionSuggestionsEntryFocused: "mentionSuggestionsEntryFocused",
                mentionSuggestionsEntryText: "mentionSuggestionsEntryText",
                mentionSuggestionsEntryAvatar: "mentionSuggestionsEntryAvatar"
            }
        });
        this.emojiPlugin = createEmojiPlugin({
            useNativeArt: true,
            theme : {
                emoji: "emoji",
                emojiSuggestions: "emojiSuggestions",
                emojiSuggestionsEntry: "emojiSuggestionsEntry",
                emojiSuggestionsEntryFocused: "emojiSuggestionsEntryFocused",
                emojiSuggestionsEntryText: "emojiSuggestionsEntryText",
                emojiSuggestionsEntryIcon: "emojiSuggestionsEntryIcon",
                emojiSelect: "emojiSelect",
                emojiSelectButton: "emojiSelectButton",
                emojiSelectButtonPressed: "emojiSelectButtonPressed",
                emojiSelectPopover:"emojiSelectPopover",
                emojiSelectPopoverClosed:"emojiSelectPopoverClosed",
                emojiSelectPopoverTitle:"emojiSelectPopoverTitle",
                emojiSelectPopoverGroups:"emojiSelectPopoverGroups",
                emojiSelectPopoverGroup:"emojiSelectPopoverGroup",
                emojiSelectPopoverGroupTitle:"emojiSelectPopoverGroupTitle",
                emojiSelectPopoverGroupList:"emojiSelectPopoverGroupList",
                emojiSelectPopoverGroupItem:"emojiSelectPopoverGroupItem",
                emojiSelectPopoverToneSelect:"emojiSelectPopoverToneSelect",
                emojiSelectPopoverToneSelectList:"emojiSelectPopoverToneSelectList",
                emojiSelectPopoverToneSelectItem:"emojiSelectPopoverToneSelectItem",
                emojiSelectPopoverEntry:"emojiSelectPopoverEntry",
                emojiSelectPopoverEntryFocused:"emojiSelectPopoverEntryFocused",
                emojiSelectPopoverEntryIcon:"emojiSelectPopoverEntryIcon",
                emojiSelectPopoverNav:"emojiSelectPopoverNav",
                emojiSelectPopoverNavItem:"emojiSelectPopoverNavItem",
                emojiSelectPopoverNavEntry:"emojiSelectPopoverNavEntry",
                emojiSelectPopoverNavEntryActive:"emojiSelectPopoverNavEntryActive",
                emojiSelectPopoverScrollbar:"emojiSelectPopoverScrollbar",
                emojiSelectPopoverScrollbarThumb:"emojiSelectPopoverScrollbarThumb"
            }
        });

        this.focus = () => this.refs.editor.focus();
        this.replyToUser = this.replyToUser.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(prevProps.userFriends !== this.props.userFriends){
            this.setState({mentions : this.props.userFriends});
        }
        if(prevProps.quoteIds !== this.props.quoteIds){
            this.props.quoteIds && this.props.quoteIds.forEach((quote, index) => {
                setTimeout(()=> {
                        this.replyToUser(quote);
                    }, 100 * index);
            });
        }

        if(prevProps.defaultValue !== this.props.defaultValue && !this.state.initial ){
            let newEditorState = this.props.defaultValue && this.props.defaultValue.blocks && this.props.defaultValue.blocks[0] ? EditorState.createWithContent(convertFromRaw(this.props.defaultValue)) : EditorState.createEmpty();
            this.setState({editorState : newEditorState, initial : true});
        }
    }

    onChange(editorState) {
        this.setState({editorState}, ()=>this.props.onChange(this.getRaw()));
    };

    getRaw() {
        const content = this.state.editorState.getCurrentContent();
        let contentRaw = convertToRaw(content);
        return contentRaw;
    }

    focus = () => this.refs.editor.focus();

    handleKeyCommand(command) {
        const { editorState } = this.state;

        const newState = RichUtils.handleKeyCommand(
            editorState,
            command
        );
        if (newState) {
            this.onChange(newState);
            return "handled";
        }
        return "not-handled";
    };

    handleReturn(event) {
        if (isSoftNewlineEvent(event)) {
            this.onChange(RichUtils.insertSoftNewline(this.state.editorState));
            return 'handled';
        }
        return 'not-handled';
    }

    toggleBlockType(blockType) {
        this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
    };

    toggleInlineStyle(inlineStyle) {
        this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, inlineStyle));
    };

    onSearchChange = ({ value }) => {
        this.setState({
            suggestions: customSuggestionsFilter(value, this.state.mentions),
        });
    };

    replyToUser(commentId){
        const editorState = this.state.editorState;
        const contentState = editorState.getCurrentContent();
        let data = {
            _id: commentId,
            kind : this.props.kind || null
        };
        if(this.props.kind === "topicComments"){
            data = {
                _id: commentId,
                kind : this.props.kind || null,
                forumId : this.props.forumId,
                topicId : this.props.topicId
            };
        }
        const contentStateWithEntity = contentState.createEntity("QUOTE", 'IMMUTABLE',data);

        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(
            editorState,
            {currentContent: contentStateWithEntity},
        );

        this.onChange(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '));
    }

    handleHasText(){
        return this.state.editorState.getCurrentContent().hasText();
    }

    handleClear(){
        const editorState = EditorState.push(this.state.editorState, ContentState.createFromText(''));
        this.setState({ editorState });
    }

    render() {
        const { MentionSuggestions } = this.mentionPlugin;
        const { EmojiSelect } = this.emojiPlugin;
        const plugins = [
            imagePlugin,
            this.emojiPlugin,
            addLinkPlugin,
            linkifyPlugin,
            this.mentionPlugin
        ];
        return (
            <div className={`mk-editor ${this.props.className ? this.props.className : ""}`}>
                {/*{typeof(this.props.handleCreateDraft) === "function" && (*/}
                {/*    <div className={"fake-answer-topic"} onClick={()=>this.props.handleCreateDraft()}>*/}
                {/*        {this.props.commentSubmitting && !this.props.commentInitialized && (*/}
                {/*            <PageLoader />*/}
                {/*        )}*/}
                {/*        {this.props.waitingDraft && !this.props.commentSubmitting && (*/}
                {/*            <span className={"btn primary"}>Répondre au sujet</span>*/}
                {/*        )}*/}
                {/*    </div>*/}
                {/*)}*/}
                <div className="mk-editor-toolbar">
                    {/*
                    <BlockStyleToolbar
                        editorState={this.state.editorState}
                        onToggle={this.toggleBlockType.bind(this)}
                    />
                    */}
                    <InlineStyleToolbar
                        editorState={this.state.editorState}
                        onToggle={this.toggleInlineStyle.bind(this)}
                    />
                    <div className={"mk-editor-btn-group"} >
                        <ColorControls
                              editorState={this.state.editorState}
                              colorStyleMap={colorStyleMap}
                              customColorStyle={CustomColorStyle}
                              onChange={this.onChange.bind(this)}
                        />
                        <AddLink  editorState={this.state.editorState}
                                  onChange={this.onChange.bind(this)}/>
                        {/*{this.props.disabledSurvey === false ? "" : (<AddSurvey  editorState={this.state.editorState}*/}
                        {/*            onChange={this.onChange.bind(this)}/>)}*/}
                        <EmojiSelect />
                        <ImageAdd
                            kind={this.props.kind}
                            editorState={this.state.editorState}
                            onChange={this.onChange.bind(this)}
                            modifier={imagePlugin.addImage}
                        />
                    </div>
                </div>

                <Editor
                    customStyleMap={colorStyleMap}
                    blockRendererFn={mediaBlockRenderer}
                    ref="editor"
                    blockStyleFn={getBlockStyle}
                    editorState={this.state.editorState}
                    plugins={plugins}
                    handleKeyCommand={this.handleKeyCommand.bind(this)}
                    onChange={this.onChange.bind(this)}
                    handleReturn={this.handleReturn.bind(this)}
                    extendedProps={this.props.extendedProps}
                    toolbar={{
                        options: ['emoji', 'image', 'remove', 'history'],
                        inline: { inDropdown: true },
                        list: { inDropdown: true },
                        textAlign: { inDropdown: true },
                        link: { inDropdown: true },
                        history: { inDropdown: true },
                    }}
                />

                <MentionSuggestions
                    onSearchChange={this.onSearchChange}
                    suggestions={this.state.suggestions}
                    entryComponent={Entry}
                />
            </div>
        );
    }
}

export default MkEditor;