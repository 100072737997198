import queryString from 'querystring';
import reduxCreator from '../libs/mk-womanizer/redux-creator';
// import axiosObjectPlugin from "../libs/mk-womanizer/axios-action-creator-object-plugin";
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

// let redux = reduxCreator.type('ROOM_MESSAGES').plugin(axiosObjectPlugin);
let redux = reduxCreator.type('USER_ROOM_MESSAGES').plugin(axiosPlugin, {template: "array"});

let fetchRoomAction = redux.fetchActionGet();


export const TYPES = redux.types;
export const fetchUserRoomMessages = (roomId, query = {}, more = false) => fetchRoomAction(`/rooms/admin/${roomId}/messages?${queryString.stringify(query)}`, null, {more});
export const resetUserRoomMessages = () => redux.actions.reset();
export const userRoomMessages = redux.reducer();