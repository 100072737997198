import {put,takeEvery} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {TYPES as FACETTE} from '../creators/facette';
import {TYPES as FACETTES} from '../creators/facettes';


export function* updateSuccess(action) {
    try {
        yield put({type: FACETTES.UPDATE, data : action.payload});
    } catch (error) {
    }
}


export function* watchFacette() {
    yield takeEvery(FACETTE.UPDATE_SUCCESS, updateSuccess);
}

export default watchFacette;

