import React, {forwardRef, useEffect, useImperativeHandle, useRef} from "react";
import styles from './AnnouncementMessagesEditor.module.scss';
import {LinkNode, TOGGLE_LINK_COMMAND} from '@lexical/link';

import {LexicalComposer} from '@lexical/react/LexicalComposer';
import {ClearEditorPlugin} from '@lexical/react/LexicalClearEditorPlugin';
import {PlainTextPlugin} from '@lexical/react/LexicalPlainTextPlugin';
import {ContentEditable} from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {HistoryPlugin} from "@lexical/react/LexicalHistoryPlugin";
import {OnChangePlugin} from "@lexical/react/LexicalOnChangePlugin";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {
    $createParagraphNode,
    $createTextNode,
    $getRoot,
    CLEAR_EDITOR_COMMAND,
    CONTROLLED_TEXT_INSERTION_COMMAND
} from "lexical";
import {LinkPlugin} from "@lexical/react/LexicalLinkPlugin";
import parseSimple from "../../../../../libs/lexical/convertissors/parseSimple";


function onError(error) {
    console.error(error);
}

function RefPlugin({ editorRef }) {
    const [editor] = useLexicalComposerContext()
    editorRef.current = editor
    return null;
}

function InsertText({text = ""}) {
    const [editor] = useLexicalComposerContext();

    useEffect(()=>{
        if (typeof text === "string" && text.length > 0){

            editor.update(() => {
                const root = $getRoot();
                const paragraph = $createParagraphNode();
                paragraph.append(
                    $createTextNode(text)
                );
                root.clear();
                paragraph.select();
                root.append(paragraph);
            });
        }
    }, [editor, text]);

    return null
}
/*
function LoadContent({text = "", links = []}) {
    const [editor] = useLexicalComposerContext();

    useEffect(()=>{
        editor.update(() => {
            parseSimple(text, {
                link : links
            });
        });
    }, [editor, text, links]);

    return null
}*/

const AnnouncementMessagesEditor = ({onChange=(editorState)=>{}, text, links=[]}, ref)=>{
    const initialConfig = {
        namespace: 'MyEditor',
        onError,
        nodes: [
            LinkNode
            // , AutoLinkNode
        ]
    };

    const editorRef = useRef();
    useImperativeHandle(ref, () => ({
        insert: (text) => {
            const editor = editorRef.current;
            editor.focus();
            editor.dispatchCommand(CLEAR_EDITOR_COMMAND);
            editor.dispatchCommand(CONTROLLED_TEXT_INSERTION_COMMAND, text);
        },
        addLink: ({url , target, rel}) => {
            const editor = editorRef.current;
            editor.focus();
            editor.dispatchCommand(TOGGLE_LINK_COMMAND, {url, target, rel});
        },
        load : (text = "", links = []) => {
            const editor = editorRef.current;
            editor.update(() => {
                parseSimple(text, {
                    link : links
                });
            });

        }
    }));

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <div className={styles["announcement-messages-editor"]} >
                <div className={styles["editor-wrapper"]}>
                    <div className={styles["editor-container"]}>
                        <InsertText text={text}/>

                        <PlainTextPlugin
                            contentEditable={<ContentEditable className={styles["editor-input"]}/>}
                            placeholder={<div className={styles["editor-placeholder"]}>Message</div>}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                        <LinkPlugin />

                        <RefPlugin editorRef={editorRef}/>
                        <OnChangePlugin onChange={onChange} ignoreSelectionChange={true}/>
                        <HistoryPlugin />
                        <ClearEditorPlugin/>
                    </div>
                </div>
            </div>
        </LexicalComposer>
    );
}

export default forwardRef(AnnouncementMessagesEditor)