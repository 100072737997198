import React from 'react';
import Icon from "../../Icon";
import ColorsButton from "./ColorsButton";
import {EditorState, Modifier, RichUtils} from "draft-js";

const COLORS = [
    {label: "color-default", style: 'color-default'},
    {label: 'color-primary', style: 'color-primary'},
    {label: 'color-secondary', style: 'color-secondary'},
    {label: 'color-success', style: 'color-success'},
    {label: 'color-danger', style: 'color-danger'},
    {label: 'color-warning', style: 'color-warning'},
    {label: 'color-info', style: 'color-info'},
];

class ColorControls extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            open: false,
            toggledColor: "",
        };
        this.openColors = this.openColors.bind(this);
        this.toggleColor = this.toggleColor.bind(this);
        this.handleToggledColor = this.handleToggledColor.bind(this);
        this.setWrapperRef = this.setWrapperRef.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setWrapperRef(node) {
        this.wrapperRef = node;
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({open: false});
        }
    }

    openColors(){
        this.setState({open: !this.state.open});
    }


    toggleColor(toggledColor) {
        const {editorState} = this.props;
        const selection = editorState.getSelection();

        // Let's just allow one color at a time. Turn off all active colors.
        const nextContentState = Object.keys(this.props.colorStyleMap)
            .reduce((contentState, color) => {
                return Modifier.removeInlineStyle(contentState, selection, color)
            }, editorState.getCurrentContent());

        let nextEditorState = EditorState.push(
            editorState,
            nextContentState,
            'change-inline-style'
        );

        const currentStyle = editorState.getCurrentInlineStyle();

        // Unset style override for current color.
        if (selection.isCollapsed()) {
            nextEditorState = currentStyle.reduce((state, color) => {
                return RichUtils.toggleInlineStyle(state, color);
            }, nextEditorState);
        }

        // If the color is being toggled on, apply it.
        if (!currentStyle.has(toggledColor)) {
            nextEditorState = RichUtils.toggleInlineStyle(
                nextEditorState,
                toggledColor
            );
        }
        this.props.onChange(nextEditorState);
        this.setState({open: false});
    }

    handleToggledColor(color){
        this.setState({toggledColor: color});
    }

    render(){
        const popoverClassName = this.state.open ?
            "addItemPopover colorPopover" :
            "addItemClosedPopover";
        const buttonClassName = this.state.open ?
            "addItemPressedButton" :
            "addItemButton";
        let currentStyle = this.props.editorState.getCurrentInlineStyle();
        return(
            <div className={`addItem`} ref={this.setWrapperRef}>
                <span
                    className={`${buttonClassName} `}
                    onMouseUp={this.openColors}
                    type="button"
                >
                    <Icon type={"palette"} style={{fill: this.props.colorStyleMap[this.state.toggledColor] && this.props.colorStyleMap[this.state.toggledColor].fill}} />
                </span>
                <div className={popoverClassName}>
                    {COLORS.map(type =>
                        <ColorsButton
                            key={type.label}
                            active={currentStyle.has(type.style)}
                            onToggle={this.toggleColor}
                            style={type.style}
                            handleToggledColor={this.handleToggledColor}
                            colorStyleMap={this.props.colorStyleMap}
                            customColorStyle={this.props.customColorStyle}
                            toggledColor={this.state.toggledColor}
                        />
                    )}
                </div>
            </div>
        )
    }
};

export default ColorControls;