import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {PageLoader} from "../../components/Loader";
import {fetchStory, resetStory} from "../../../creators/story";
import FormStory from "../../formulaires/story";



class ModerationsStory extends React.Component {
    constructor(props) {
        super(props);
        this.storyId = this.props.match.params.storyId || false;
        this.props.resetStory();
        if(this.storyId){
            this.props.fetchStory(this.storyId);
        }
    }

    componentWillUnmount() {
        this.props.resetStory();
    }


    render() {
        return (!this.props.storyrInitialized || this.props.storyrLoading)  ? (<PageLoader />) :(
            <Fragment>
                <FormStory {...this.props} />
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        storyrInitialized: state.story.initialized,
        storyrLoading: state.story.loading,
        story: state.story.data
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchStory,
    resetStory
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModerationsStory);
