import React from 'react';
import {Button, Form} from "react-bootstrap";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {SpinLoader} from "../components/Loader";
import FormFields from "../components/FormFields";

import {updateKeywordsWarningsSetting} from "../../creators/keywordsWarningSetting";


class FormSetting extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            setting : this.props.keywordsWarningsSetting.map(rule => rule.rule).join("\n")
        };

        this.handleSubmit = this.handleSubmit.bind(this);

    }


    handleSubmit(e) {
        e.preventDefault();
        if (!this.props.keywordsWarningsSettingLoading) {
            this.props.updateKeywordsWarningsSetting({setting : this.state.setting}).then(response =>{
                this.setState({setting : response.payload.map(rule => rule.rule).join("\n")})
                //window.location.reload();
            });
        }
    }

    render() {
        return (
            <Form onSubmit={(e) => this.handleSubmit(e)}>
                <FormFields
                    group={{controlId : "setting"}}
                    type={"input"}
                    label={{
                        label : "Config"
                    }}
                    controle={{
                        type : "input",
                        as : "textarea",
                        name : "setting",
                        rows : 20,
                        value : this.state.setting && this.state.setting,
                        onChange : (event) =>{ this.setState({setting : event.target.value})}
                    }}
                    text={{
                        text : `Une regle par ligne, pour les regex elles doivent commences par un / et doit en contenir un autre`,
                        className : "text-secondary"
                    }}
                />
                {this.props.keywordsWarningsError.message ? (<div className={"text-danger"}>Mise a jour impossible</div>) : null}
                <Button variant="primary" type="submit" className={"btn-loading"}>
                    Valider {this.props.keywordsWarningsSettingLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                </Button>
            </Form>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        keywordsWarningsSetting : state.keywordsWarningsSetting.data,
        keywordsWarningsSettingLoading : state.keywordsWarningsSetting.loading,
        keywordsWarningsError : state.keywordsWarningsSetting.error
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateKeywordsWarningsSetting
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormSetting);
