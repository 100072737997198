import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button, Col, Modal, Row} from "react-bootstrap";
import FormFields from "../../components/FormFields";
import {LoadingBar, SpinLoader} from "../../components/Loader";
import {fetchCategoriesRoot, resetCategoriesRoot} from "../../../creators/categories.rootChildrens";
import _ from "lodash";
import Icon from "../../components/Icon";

import ViewCount from "./components/ViewCount";
import CardUser from "./components/CardUser";
import {faThumbsUp, faThumbsDown, faMinusSquare, faSearch, faEdit} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    acceptedCategorizedMedia,
    clearCategorizedMedia,
    declinedCategorizedMedia, resetCategorizedMedia
} from "../../../creators/categorized.media";

import {
    fetchCategorizedMediasSearch,
    resetCategorizedMediasSearch
} from "../../../creators/categorized.medias.search";
import Dotdotdot from "react-dotdotdot";
import {Link} from "react-router-dom";


class CategorizedByCategory extends React.Component {
    constructor(props) {
        super(props);
        this.pathPage = `/categorizations`;
        this.filter = {
            sort : "-count.like",
            kind : "gallery",
            search : "",
            limit : 100,
            source : "user",
            right : "null",
            optionSearch : 0
        }
        this.state = {
            filter : {...this.filter},
            categoryParentId : "",
            categoryId : "",
            currentTag : "",
            filterCategoriesList : [],
            mediasAccepted : [],
            mediasDeclined : [],
            modalImg : ""
        }


        this.handleFilter = this.handleFilter.bind(this);
        this.addFilterCategoriesList = this.addFilterCategoriesList.bind(this);
        this.removeFilterCategoriesList = this.removeFilterCategoriesList.bind(this);
        this.handleFilterCategoriesList = this.handleFilterCategoriesList.bind(this);
        this.likeAccepted = this.likeAccepted.bind(this);
        this.likeDeclined = this.likeDeclined.bind(this);
        this.likeClear = this.likeClear.bind(this);
        this.fetchCategoryName = this.fetchCategoryName.bind(this);
        this.setChildrens = this.setChildrens.bind(this);
        this.acceptedUntreatedAll = this.acceptedUntreatedAll.bind(this);
        this.props.resetCategoriesRoot();
        this.props.fetchCategoriesRoot();

    }

    componentWillUnmount() {
        //this.props.resetCategorizedMedia();
        this.props.resetCategoriesRoot();
        this.props.resetCategorizedMediasSearch();
        this.props.resetCategorizedMedia();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.categoriesRoot.length < this.props.categoriesRoot.length) {
            let parentId = _.get(this.props.categoriesRoot, `[0]._id`) || "";
            let categoryId = _.get(this.props.categoriesRoot, `[0].childrens.[0]._id`) || ""
            this.setState({categoryParentId : parentId, categoryId}, ()=> this.fetchMedia());
        }

        return null;
    }

    fetchMedia(){
        //this.props.fetchCategorizedMediaRandom(filter);
        let query = {...this.state.filter, currentCategory : this.state.categoryId};
        if(this.state.filterCategoriesList.length >0){
            let categoriesIncluded = this.state.filterCategoriesList.filter(item => item.include === "1").map(item => item.categoryId)
            if(categoriesIncluded.length){
                query["categoryId"] = categoriesIncluded;
            }
            let categoriesExcluded = this.state.filterCategoriesList.filter(item => item.include === "0").map(item => item.categoryId);
            if(categoriesExcluded){
                query["categoryId!"] = categoriesExcluded;
            }
        }

        this.setState({currentTag : this.state.categoryId, mediasAccepted : [], mediasDeclined : []});
        this.fetchCategoryName();
        this.props.fetchCategorizedMediasSearch(query);
    }

    fetchCategoryName(){
        let parentId = this.state.categoryParentId || "";
        let categoryId = this.state.categoryId || "";
        let categoryName = this.state.categoryName || "";
        if(this.props.categoriesRootInitialized){
            let findParentIndIndex = _.findIndex(this.props.categoriesRoot, {"_id" : parentId.toString()} );
            if(findParentIndIndex < 0){
                findParentIndIndex = 0;
            }
            let findCategoryIndIndex = _.findIndex(this.props.categoriesRoot[findParentIndIndex].childrens, {"_id" : categoryId.toString()} );
            if(findCategoryIndIndex < 0){
                findCategoryIndIndex = 0;
            }
            let newCategoryName =  _.get(this.props.categoriesRoot, `[${findParentIndIndex}].childrens[${findCategoryIndIndex}].title`) || "";

            if(categoryName !== newCategoryName ){
                this.setState({categoryName : newCategoryName});
            }

        }
    }

    handleFilter(name,value){
        let filter = {...this.state.filter, [name] : value};
        this.setState({filter});
    }

    fetchCurrentChildrens(parentId){
        let findIndex = _.findIndex(this.props.categoriesRoot, {"_id" : parentId.toString()} );
        if(findIndex < 0){
            findIndex = 0;
        }
        let chidrens = _.get(this.props.categoriesRoot, `[${findIndex}].childrens`) || [];
        return chidrens;
    }

    setChildrens(){
        let parentId = this.state.categoryParentId;
        let findIndex = _.findIndex(this.props.categoriesRoot, {"_id" : parentId.toString()} );
        if(findIndex < 0){
            findIndex = 0;
        }
        let chidrens = _.get(this.props.categoriesRoot, `[${findIndex}].childrens[0]`) || {};
        this.setState({categoryId : chidrens._id});
    }

    addFilterCategoriesList(){
        let filterCategoriesList = this.state.filterCategoriesList;
        let parentId = _.get(this.props.categoriesRoot, `[0]._id`) || "";
        let categoryId = _.get(this.props.categoriesRoot, `[0].childrens.[0]._id`) || "";
        filterCategoriesList.push({
            parentId,
            categoryId,
            include : "1"
        })

        this.setState({filterCategoriesList});
    }

    removeFilterCategoriesList(index){
        let filterCategoriesList = this.state.filterCategoriesList;
        filterCategoriesList.splice(index, 1);
        this.setState({filterCategoriesList});
    }

    handleFilterCategoriesList(index, key, value){
        let filterCategoriesList = this.state.filterCategoriesList;
        filterCategoriesList[index][key] = value;
        this.setState({filterCategoriesList});
    }


    likeAccepted(mediaId){
        let mediasAccepted = this.state.mediasAccepted;
        mediasAccepted.push(mediaId.toString());
        //console.log("Accepted", {categoryId : this.state.currentTag});
        this.props.acceptedCategorizedMedia(mediaId, {categoryId : this.state.currentTag}).then(({error}) => {
            if (!error) {
                this.setState({mediasAccepted});
            }
            else{
                console.log(error);
            }
        }).catch(e =>{
            console.log(e);
        })

    }

    likeDeclined(mediaId){
        let mediasDeclined = this.state.mediasDeclined;
        mediasDeclined.push(mediaId.toString());
        this.props.declinedCategorizedMedia(mediaId, {categoryId : this.state.currentTag}).then(({error}) => {
            if (!error) {
                this.setState({mediasDeclined});
            }
            else{
                console.log(error);
            }
        }).catch(e =>{
            console.log(e);
        })
    }

    likeClear(mediaId){
        let mediasAccepted = this.state.mediasAccepted;
        let mediasDeclined = this.state.mediasDeclined;
        mediasAccepted = mediasAccepted.filter(item => item !== mediaId.toString());
        mediasDeclined = mediasDeclined.filter(item => item !== mediaId.toString());
        this.props.clearCategorizedMedia(mediaId, {categoryId : this.state.currentTag}).then(({error}) => {
            if (!error) {
                this.setState({mediasAccepted, mediasDeclined});
            }
            else{
                console.log(error);
            }
        }).catch(e =>{
            console.log(e);
        })
    }

    declinedAll(){
        let medias = [...this.state.mediasAccepted, ...this.state.mediasDeclined];
        let mediasIds = this.props.categorizedMedias.filter(item => !medias.includes(item._id.toString())).map(item => item._id.toString());
        mediasIds.forEach(mediaId => {
            this.likeDeclined(mediaId);
        });
    }

    acceptedUntreatedAll(){
        let medias = [...this.state.mediasAccepted, ...this.state.mediasDeclined];
        let mediasIds = this.props.categorizedMedias.filter(item => !medias.includes(item._id.toString()) ).map(item => item._id.toString());
        mediasIds.forEach(mediaId => {
            this.likeAccepted(mediaId);
        });
    }


    render() {

        return (
            <Fragment>
                <h1>Choix de photos par categorie</h1>
                <h5>Tag à appliquer</h5>
                <Row>
                    <Col sm={2}>
                        <FormFields
                            group={{controlId: "categoryParentIndex"}}
                            type={"select"}
                            controle={{
                                name: "categoryParentId",
                                initialized: this.props.categoriesRootInitialized,
                                value: this.state.categoryParentId,
                                options: this.props.categoriesRoot,
                                optionField: {value: "_id", label: "title"},
                                onChange: (event) => {
                                    this.setState({[event.target.name]: event.target.value}, () =>{this.setChildrens()})
                                }
                            }}
                        />
                    </Col>
                    <Col sm={2}>
                        <FormFields
                            group={{controlId: "categoryId"}}
                            type={"select"}
                            controle={{
                                name: "categoryId",
                                initialized: this.props.categoriesRootInitialized,
                                value: this.state.categoryId,
                                options: this.fetchCurrentChildrens(this.state.categoryParentId),
                                optionField: {value: "_id", label: "title"},
                                onChange: (event) => {
                                    this.setState({[event.target.name]: event.target.value})
                                }
                            }}
                        />
                    </Col>
                </Row>
                <h5>Recherche</h5>
                <Row>
                    <Col sm={2}>
                        <FormFields
                            group={{controlId: "search"}}
                            type={"input"}
                            prepend={{id: "search", content: <Icon type="search" iconSize={15}/>}}
                            controle={{
                                type: "text",
                                name: "search",
                                placeholder: "Mot clé",
                                value: this.state.filter.search,
                                onChange: (event) => {
                                    this.handleFilter([event.target.name], event.target.value)
                                }
                            }}
                        />
                    </Col>
                    <Col sm={2}>
                        <FormFields
                            group={{controlId: "optionSearch"}}
                            type={"select"}
                            controle={{
                                name: "optionSearch",
                                value: this.state.filter.optionSearch,
                                options: [{value: 0, label: "Titre + Description"}, {
                                    value: 1,
                                    label: "Titre de l'album"
                                }, {value: 2, label: "Commentaires"}],
                                onChange: (event) => {
                                    this.handleFilter('optionSearch', event.target.value)
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={2}>
                        <FormFields
                            group={{controlId: "sort"}}
                            type={"select"}
                            controle={{
                                name: "sort",
                                value: this.state.filter.sort,
                                options: [{value: "-count.like", label: "plus de likes"}, {
                                    value: "-count.view",
                                    label: "plus de vues"
                                }, {value: "-count.comment", label: "plus de commentaires"}],
                                onChange: (event) => {
                                    this.handleFilter('sort', event.target.value)
                                }
                            }}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col sm={2}>
                        <FormFields
                            group={{controlId: "source"}}
                            type={"select"}
                            controle={{
                                name: "source",
                                value: this.state.filter.source,
                                options: [{value: "null", label: "Tous"}, {value: "user",label: "User"}, {value: "ia", label: "IA"}],
                                onChange: (event) => {
                                    this.handleFilter('source', event.target.value)
                                }
                            }}
                        />
                    </Col>
                    <Col sm={2}>
                        <FormFields
                            group={{controlId: "right"}}
                            type={"select"}
                            controle={{
                                name: "right",
                                value: this.state.filter.right,
                                options: [{value: "null", label: "Tous"}, {value: "guest",label: "Publique"}, {value: "public", label: "Membres"}, {value: "friend", label: "Amis"}, {value: "private", label: "Privés"}],
                                onChange: (event) => {
                                    this.handleFilter('right', event.target.value)
                                }
                            }}
                        />
                    </Col>
                </Row>
                {
                    this.state.filterCategoriesList.map((item, index) =>(
                        <Row key={`category-${index}`}>
                            <Col sm={2} >
                                <FormFields
                                    group={{controlId: `parentId-${index}`}}
                                    type={"select"}
                                    controle={{
                                        name: "parentId",
                                        initialized: this.props.categoriesRootInitialized,
                                        value: this.state.filterCategoriesList[index].parentId,
                                        options: this.props.categoriesRoot,
                                        optionField: {value: "_id", label: "title"},
                                        onChange: (event) => {
                                            this.handleFilterCategoriesList(index,[event.target.name], event.target.value)
                                            let findParentIndIndex = _.findIndex(this.props.categoriesRoot, {"_id" : event.target.value.toString()} );
                                            let categoryId = _.get(this.props.categoriesRoot, `[${findParentIndIndex}].childrens.[0]._id`) || "";
                                            this.handleFilterCategoriesList(index,'categoryId', categoryId);
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm={2}>
                                <FormFields
                                    group={{controlId: `categoryId-${index}`}}
                                    type={"select"}
                                    controle={{
                                        name: "categoryId",
                                        initialized: this.props.categoriesRootInitialized,
                                        value: this.state.filterCategoriesList[index].categoryId,
                                        options: this.fetchCurrentChildrens(this.state.filterCategoriesList[index].parentId),
                                        optionField: {value: "_id", label: "title"},
                                        onChange: (event) => {
                                            this.handleFilterCategoriesList(index,[event.target.name], event.target.value)
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm={2}>
                                <FormFields
                                    group={{controlId: `categoryId-type-${index}`}}
                                    type={"select"}
                                    controle={{
                                        name: "include",
                                        value: this.state.filterCategoriesList[index].include,
                                        options: [{value : "1", label : "Include"}, {value : "0", label : "Exclude"}],
                                        onChange: (event) => {
                                            this.handleFilterCategoriesList(index,[event.target.name], event.target.value)
                                        }
                                    }}
                                />
                            </Col>
                            <Col sm={2}>
                                <Button variant="outline-danger" onClick={() => this.removeFilterCategoriesList(index)}>
                                    <Icon type={"trash"} style={{fill: "red"}}  iconSize={"15"} />
                                </Button>
                            </Col>
                        </Row>
                    ))
                }

                <Row>
                    <Col sm={2} >
                        <Button variant="outline-dark" onClick={() => this.addFilterCategoriesList()}>
                            <Icon type={"plus"} iconSize={"20"} /> Préciser une catégorie
                        </Button>
                    </Col>
                </Row>
                <Row className={"mb-4"}>
                    <Col sm={4} className={"d-flex justify-content-center"}>
                        <Button variant="primary" onClick={() => this.fetchMedia()}>
                            {this.props.categorizedMediasLoading && (
                                <div className={"pl-1"}><SpinLoader className={"xsmall"}/></div>)}
                            Rechercher
                        </Button>
                    </Col>
                </Row>
                    <Row>
                        <div className={"w-100 mb-3"}>
                            {this.props.categorizedMediasLoading ? <LoadingBar/> : null}
                        </div>
                        <h4 className={"w-100"}>Ces images sont-elles bien des {this.state.categoryName} ?</h4>
                        {this.props.categorizedMedias.map(item =>(
                            <Col xs={12} sm={5} md={4} xl={2} lg={3} className={"content-thumb-like"} key={item._id} >
                                <Dotdotdot tagName="span" className={"d-flex justify-content-center pb-1"} clamp={2} useNativeClamp={false}>
                                    {item.title}
                                </Dotdotdot>
                                <div className={"d-flex justify-content-center thumb-like"} style={{ backgroundImage : `url("${item.previewUrl}?width=222")`, backgroundRepeat  : 'no-repeat', backgroundSize : 'contain'}}>
                                    {this.state.mediasAccepted.includes(item._id.toString()) ? (
                                        <div className={"like-accepted"} onClick={()=> this.likeClear(item._id)}><FontAwesomeIcon icon={faMinusSquare} className={"icone"} /></div>
                                    ) : this.state.mediasDeclined.includes(item._id.toString()) ? (
                                        <div className={"like-declined"} onClick={()=> this.likeClear(item._id)}><FontAwesomeIcon icon={faMinusSquare} className={"icone"} /></div>
                                    ) : (
                                        <Fragment>
                                            <div className={"like"} onClick={()=> this.likeAccepted(item._id)}>
                                                <FontAwesomeIcon icon={faThumbsUp} className={"icone"}  />
                                            </div>
                                            <div className={"nolike"} onClick={() => this.likeDeclined(item._id)}>
                                                <FontAwesomeIcon icon={faThumbsDown} className={"icone"} />
                                            </div>
                                        </Fragment>
                                    )}
                                </div>
                                <div className={"d-flex justify-content-center"}>
                                    <FontAwesomeIcon icon={faSearch} className={"icone cursor-pointer mr-4 mt-1"} onClick={()=> this.setState({modalImg : item.previewUrl})}  />

                                    <Link to={`/categorizations/${item._id}`} target={"_blank"}><FontAwesomeIcon icon={faEdit} className={"icone"}  /></Link>
                                </div>
                                <ViewCount
                                    count={item.count}
                                />
                                <CardUser owner={item.owner} classNameAvatar="avatar-max-40"/>
                            </Col>
                        ))}
                        {this.state.modalImg !=="" ? (
                            <Modal className={"modal-big-thumb"} centered backdrop={false} show={this.state.modalImg !== "" ? true : false} onHide={()=>this.setState({modalImg : ""})}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Photo</Modal.Title>
                                </Modal.Header>
                                <Modal.Body className={"d-flex justify-content-center"}>
                                    <img src={this.state.modalImg} alt={this.state.modalImg} />
                                </Modal.Body>
                                <Modal.Footer>
                                </Modal.Footer>
                            </Modal>
                        ) : null}
                    </Row>
                    <Row>
                    <Col sm={12} className={"d-flex justify-content-center mt-4"}>
                    <Button variant="primary" onClick={() => this.fetchMedia()}>
                {this.props.categorizedMediaLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                    Renouveler
                    </Button>
                    <Button className={"ml-5"} variant="primary" onClick={() => this.declinedAll()}>
                        {this.props.categorizedMediaLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                        Refuser les non traités
                    </Button>

                    <Button className={"ml-5"} variant="primary" onClick={() => this.acceptedUntreatedAll()}>
                        {this.props.categorizedMediaLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                        Accepter les non traités
                    </Button>
                    </Col>
                    </Row>

            </Fragment>)
    }
}


const mapStateToProps = (state) => {
    return {
        categoriesRoot : state.categoriesRoot.data,
        categoriesRootInitialized : state.categoriesRoot.initialized,
        categoriesRootLoading : state.categoriesRoot.loading,

        categorizedMedias : state.categorizedMediasSearch.data,
        totalMediasCategorized : state.categorizedMediasSearch.total || 0,
        categorizedMediasInitialized : state.categorizedMediasSearch.initialized,
        categorizedMediasLoading : state.categorizedMediasSearch.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCategoriesRoot,
    resetCategoriesRoot,
    fetchCategorizedMediasSearch,
    resetCategorizedMediasSearch,
    acceptedCategorizedMedia,
    declinedCategorizedMedia,
    clearCategorizedMedia,
    resetCategorizedMedia
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CategorizedByCategory);
