import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";



let redux = reduxCreator.type('FACETTES').plugin(axiosPlugin, {template: "array"});


redux.reducerForType('UPDATE', (state,payload) => {
    let newState = {...state, lastUpdate : Date.now()};
    if(payload.data && payload.data._id){
        newState.data = newState.data.map(item => {
            if(item._id.toString() === payload.data._id.toString()){
                item = payload.data;
            }
            return item;
        });
    }
    return newState;
});

export const TYPES = redux.types;

let fetchAction = redux.fetchActionGet();

export const fetchFacettes = (query = {}) => fetchAction(`/facettes?${queryString.stringify(query)}`);
export const resetFacettes = () => redux.actions.reset();

export const facettes = redux.reducer();