import React from 'react';
import {config} from "../../config";
import {Form} from "react-bootstrap";
import FormFields from "../components/FormFields";
import Col from "react-bootstrap/Col";
import TagList from "../components/TagList";


const FormDatingInformation = ({information = [], informationsLength = 0, handleProfileInformations = () => {} }) => {
    let sexualPosition = config.sexualPosition.map((item,index) => { return {_id : index, name : item}});
    let fetishObject = config.fetishObject.map((item,index) => { return {_id : index, name : item}});
    let partnerBodyPart = config.partnerBodyPart.map((item,index) => { return {_id : index, name : item}});
    let bodyPart = config.bodyPart.map((item,index) => { return {_id : index, name : item}});
    let placeToHaveFetishSex = config.placeToHaveFetishSex.map((item,index) => { return {_id : index, name : item}});
    let size = config.size.map((item,index) => { return {_id : index, name : item}});
    let weight = config.weight.map((item,index) => { return {_id : index, name : item}});
    let eyesColor = config.eyesColor.map((item,index) => { return {_id : index, name : item}});

    let hairColor = config.hairColor.map((item,index) => { return {_id : index, name : item}});
    let lookStyle = config.lookStyle.map((item,index) => { return {_id : index, name : item}});
    let hairiness = config.hairiness.map((item,index) => { return {_id : index, name : item}});
    let personality = config.personality.map((item,index) => { return {_id : index, name : item}});
    let smoker = config.smoker.map((item,index) => { return {_id : index, name : item}});

    let selectPersonality = information.personality.map(item => { return {_id : item, name : personality[item].name}});

    let classWidth = informationsLength === 2 ? "w-50" : "w-100";

    function removePersonality(data){
        handleProfileInformations(information._id, "personality", data)
    }

    return (
        <Col className={classWidth}>
            {informationsLength !== 2 && (
                <div className={"w-100"}>
                        {information.sex === 0 ? (
                            <h6 className={"text-info"}>Lui</h6>
                        ) : information.sex === 1 && (
                            <h6 className={"text-danger"}>Elle</h6>
                        )}
                </div>
            )}
            <Form.Row className={"w-100"}>
                <div className={"col-form-label w-25"}>Une position sexuelle</div>
                <FormFields
                    group={{controlId : "sexualPosition"}}
                    type={"select"}
                    controle={{
                        name : "sexualPosition",
                        value : information.sexualPosition,
                        options : sexualPosition,
                        optionField : {value : "_id", label : "name"},
                        onChange : (event) =>{ handleProfileInformations(information._id, "sexualPosition", event.target.value)}
                    }}
                />
            </Form.Row>

            <Form.Row className={"w-100"}>
                <div className={"col-form-label w-25"}>Un objet fétichiste</div>
                <FormFields
                    group={{controlId : "fetishObject"}}
                    type={"select"}
                    controle={{
                        name : "fetishObject",
                        value : information.fetishObject,
                        options : fetishObject,
                        optionField : {value : "_id", label : "name"},
                        onChange : (event) =>{ handleProfileInformations(information._id, "fetishObject", event.target.value)}
                    }}
                />
            </Form.Row>

            <Form.Row className={"w-100"}>
                <div className={"col-form-label w-25"}>Une partie de son corps</div>
                <FormFields
                    group={{controlId : "partnerBodyPart"}}
                    type={"select"}
                    controle={{
                        name : "partnerBodyPart",
                        value : information.partnerBodyPart,
                        options : partnerBodyPart,
                        optionField : {value : "_id", label : "name"},
                        onChange : (event) =>{ handleProfileInformations(information._id, "partnerBodyPart", event.target.value)}
                    }}
                />
            </Form.Row>

            <Form.Row className={"w-100"}>
                <div className={"col-form-label w-25"}>Une partie de votre corps</div>
                <FormFields
                    group={{controlId : "bodyPart"}}
                    type={"select"}
                    controle={{
                        name : "bodyPart",
                        value : information.bodyPart,
                        options : bodyPart,
                        optionField : {value : "_id", label : "name"},
                        onChange : (event) =>{ handleProfileInformations(information._id, "bodyPart", event.target.value)}
                    }}
                />
            </Form.Row>

            <Form.Row className={"w-100"}>
                <div className={"col-form-label w-25"}>Un lieu où faire l'amour</div>
                <FormFields
                    group={{controlId : "placeToHaveFetishSex"}}
                    type={"select"}
                    controle={{
                        name : "placeToHaveFetishSex",
                        value : information.placeToHaveFetishSex,
                        options : placeToHaveFetishSex,
                        optionField : {value : "_id", label : "name"},
                        onChange : (event) =>{ handleProfileInformations(information._id, "placeToHaveFetishSex", event.target.value)}
                    }}
                />
            </Form.Row>

            <Form.Row className={"w-100"}>
                <div className={"col-form-label w-25"}>Taille</div>
                <FormFields
                    group={{controlId : "size"}}
                    type={"select"}
                    controle={{
                        name : "size",
                        value : information.size,
                        options : size,
                        optionField : {value : "_id", label : "name"},
                        onChange : (event) =>{ handleProfileInformations(information._id, "size", event.target.value)}
                    }}
                />
            </Form.Row>

            <Form.Row className={"w-100"}>
                <div className={"col-form-label w-25"}>Poids</div>
                <FormFields
                    group={{controlId : "weight"}}
                    type={"select"}
                    controle={{
                        name : "weight",
                        value : information.weight,
                        options : weight,
                        optionField : {value : "_id", label : "name"},
                        onChange : (event) =>{ handleProfileInformations(information._id, "weight", event.target.value)}
                    }}
                />
            </Form.Row>

            <Form.Row className={"w-100"}>
                <div className={"col-form-label w-25"}>Yeux</div>
                <FormFields
                    group={{controlId : "eyesColor"}}
                    type={"select"}
                    controle={{
                        name : "eyesColor",
                        value : information.eyesColor,
                        options : eyesColor,
                        optionField : {value : "_id", label : "name"},
                        onChange : (event) =>{ handleProfileInformations(information._id, "eyesColor", event.target.value)}
                    }}
                />
            </Form.Row>

            <Form.Row className={"w-100"}>
                <div className={"col-form-label w-25"}>Cheveux</div>
                <FormFields
                    group={{controlId : "hairColor"}}
                    type={"select"}
                    controle={{
                        name : "hairColor",
                        value : information.hairColor,
                        options : hairColor,
                        optionField : {value : "_id", label : "name"},
                        onChange : (event) =>{ handleProfileInformations(information._id, "hairColor", event.target.value)}
                    }}
                />
            </Form.Row>

            <Form.Row className={"w-100"}>
                <div className={"col-form-label w-25"}>Look</div>
                <FormFields
                    group={{controlId : "lookStyle"}}
                    type={"select"}
                    controle={{
                        name : "lookStyle",
                        value : information.lookStyle,
                        options : lookStyle,
                        optionField : {value : "_id", label : "name"},
                        onChange : (event) =>{ handleProfileInformations(information._id, "lookStyle", event.target.value)}
                    }}
                />
            </Form.Row>

            <Form.Row className={"w-100"}>
                <div className={"col-form-label w-25"}>Pilosité</div>
                <FormFields
                    group={{controlId : "hairiness"}}
                    type={"select"}
                    controle={{
                        name : "hairiness",
                        value : information.hairiness,
                        options : hairiness,
                        optionField : {value : "_id", label : "name"},
                        onChange : (event) =>{ handleProfileInformations(information._id, "hairiness", event.target.value)}
                    }}
                />
            </Form.Row>

            <Form.Row className={"w-100"}>
                <div className={"col-form-label w-25"}>Personnalité</div>
                <FormFields
                    group={{controlId : "personality"}}
                    type={"select"}
                    controle={{
                        name : "personality",
                        value : "",
                        options : personality,
                        optionField : {value : "_id", label : "name"},
                        onChange : (event) =>{ handleProfileInformations(information._id, "personality", event.target.value)}
                    }}
                />
                {information.personality && information.personality.length > 0 && (
                    <div className={"w-100"}>
                        <TagList
                            row = {{
                                className : "mb-3"
                            }}
                            btn = {{
                                className : "btn btn-primary m-2",
                                type : "button",
                                as : "input"
                            }}
                            remove = {{
                                isRemove : true,
                                remove : removePersonality
                            }}
                            options = {selectPersonality}
                            optionField = {{
                                value : "_id",
                                label : "name"
                            }}
                        />
                    </div>
                )}
            </Form.Row>

            <Form.Row className={"w-100"}>
                <div className={"col-form-label w-25"}>Fumeur</div>
                <FormFields
                    group={{controlId : "smoker"}}
                    type={"select"}
                    controle={{
                        name : "smoker",
                        value : information.smoker,
                        options : smoker,
                        optionField : {value : "_id", label : "name"},
                        onChange : (event) =>{ handleProfileInformations(information._id, "smoker", event.target.value)}
                    }}
                />
            </Form.Row>

        </Col>
    )
};

export default FormDatingInformation;