
import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('STATS_SITE_DAYLY').plugin(axiosPlugin, {template: "array"});

let fetchStatsSiteDaylyAction = redux.fetchActionGet();

export const fetchStatsSiteDayly = (query = {}) => fetchStatsSiteDaylyAction(`/stats/current-month`);
export const resetStatsSiteDayly = () => redux.actions.reset();

export const statsSiteDayly = redux.reducer();