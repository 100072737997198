import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('MODERATION_CONTENT').plugin(axiosPlugin);


let acceptedModerationContentAction = redux.fetchActionDelete();
let refusedModerationContentAction = redux.fetchActionUpdate();

export const TYPES = redux.types;

export const refusedModerationContent = (type = 'avatar', tacheId, data = {}) => refusedModerationContentAction(`/moderation-contents/${type}/${tacheId}`, data);

export const acceptedModerationContent = (type = 'avatar', data = {}) => acceptedModerationContentAction(`/moderation-contents/${type}`, data);

export const resetModerationContent = () => redux.actions.reset();

export const moderationContent = redux.reducer();