import React from 'react';

export default class ColorsButton extends React.Component {
    onToggle = e => {
        e.preventDefault();
        this.props.onToggle(this.props.style);

    };

    componentDidUpdate(prevProps) {
        if(prevProps.active !== this.props.active && this.props.active){
            this.props.handleToggledColor(this.props.style);
        } else if(prevProps.active !== this.props.active && !this.props.active){
            this.props.handleToggledColor("");
        } else if(prevProps.toggledColor !== this.props.toggledColor && this.props.active){
            this.props.handleToggledColor(this.props.style);
        }
    }

    render(){
        return(
            <div className={`menu-item-color ${this.props.active ? 'active' : ''}`}
                 style={{backgroundColor: this.props.customColorStyle[this.props.style] && this.props.customColorStyle[this.props.style].backgroundColor}}
                 onMouseDown={this.onToggle}
            >
                <div style={{backgroundColor: this.props.customColorStyle[this.props.style] && this.props.customColorStyle[this.props.style].backgroundColor}}
                     className={`menu-item-color__active ${this.props.active ? 'active' : ''}`} />
            </div>
        )
    }
}