import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('REPORTING').plugin(axiosPlugin);

let fetchReportingAction = redux.fetchActionGet();
let updateReportingAction = redux.fetchActionUpdate();


export const fetchReporting = (reportingId) => fetchReportingAction(`/reportings/${reportingId}`);
export const updateReporting = (reportingId, data = {}) => updateReportingAction(`/reportings/${reportingId}`, data);
export const resetReporting = () => redux.actions.reset();

export const reporting = redux.reducer();