import querystring from 'querystring';
import _ from 'lodash';

let history;

export default class Aqp {
    constructor(mapping, options = {}) {
        if (!history) throw new Error('You must provide "history"');
        this.mapping = mapping;
        this.options = {
            blacklist: [],
            whitelist: [],
            currentLink: "",
            filterDefault: {},
            isRemoveValueFalseQueryUrl : true,
            ...options
        };
        this.defaultAqp = {
            page: Number,
            limit: Number,
            skip: Number,
            sort: String,
            select: String
        };

    }

    static init(h) {
        history = h;
    }

    get query() {
        let query = typeof history.location.search === 'string' ? history.location.search.replace('?', '') : '';
        query = query.trim();
        let aqpQuery = this.fromUrl(query, this.mapping);
        aqpQuery = this._toApi(aqpQuery, this.mapping);

        return this._parseAqpQuery(aqpQuery);
    }

    queryUrl(query = {}, mapping = {}){
        let qs = querystring.stringify(this._clearQueryUrl(query));
        let newMapping = {};
        Object.keys(mapping).forEach(function (key) {
            newMapping[mapping[key]] = key;
        });
        Object.keys(newMapping).forEach(function (key) {
            let regex = new RegExp(newMapping[key], 'g');
            qs = qs.replace(regex, key);
        });
        return {link : this.options.currentLink, search : qs ? `?${qs}` : ""};
    }

    setCurrentLink(link) {
        this.options = {
            ...this.options,
            currentLink: link
        }
    }

    toUrl(query = {}, mapping = {}) {
        let qs = querystring.stringify(query);
        Object.keys(mapping).forEach(function (key) {
            let regex = new RegExp(key, 'g');
            qs = qs.replace(regex, mapping[key]);
        });
        return qs;
    }

    fromUrl(qs = '', mapping = {}) {
        let newMapping = {};
        Object.keys(mapping).forEach(function (key) {
            newMapping[mapping[key]] = key;
        });

        Object.keys(newMapping).forEach(function (key) {
            let regex = new RegExp(newMapping[key], 'g');
            qs = qs.replace(regex, key);
        });

        return querystring.parse(qs);
    }

    _toApi(aqpQuery = '', mapping = {}) {
        let newMapping = {};
        Object.keys(mapping).forEach(function (key) {
            newMapping[mapping[key]] = key;
        });
        Object.keys(aqpQuery).forEach(function (key, value) {
            if (newMapping[key]) {
                aqpQuery[newMapping[key]] = aqpQuery[key];
                delete aqpQuery[key];
            }
        });
        return aqpQuery;
    }

    _parseAqpQuery(query) {
        Object.keys(query).forEach(key => {
            if (Math.floor(query[key])) {
                query[key] = parseInt(query[key])
            } else if (Array.isArray(query[key])) {
                query[key] = query[key].map(item => {
                    if (item === "0") {
                        item = parseInt(item);
                    } else if (item === "true" || item === "false") {
                        item = !!item;
                    } else if (Math.floor(item)) {
                        item = parseInt(item);
                    }
                    return item;
                })
            } else if (query[key] === "true" || query[key] === "false") {
                query[key] = query[key] === "true";
            }
        });
        return query;
    }

    _clearQueryUrl(queryUrl) {
        if (this.options.blacklist.length) {
            queryUrl = _.omit(queryUrl, this.options.blacklist);
        }
        if (this.options.whitelist.length) {
            queryUrl = _.pick(queryUrl, this.options.whitelist);
        }
        //permet de retirer les filtres par default ou les valeurs vide
        Object.keys(queryUrl).forEach((key) => {
            if ((this.options.filterDefault[key] && queryUrl[key].toString() === this.options.filterDefault[key].toString()) || queryUrl[key] === this.options.filterDefault[key]) {
                delete queryUrl[key];
            } else if (typeof queryUrl[key] === 'string' && queryUrl[key].length === 0) {
                delete queryUrl[key];
            } else if (typeof queryUrl[key] === 'boolean' && queryUrl[key] === false && this.options.isRemoveValueFalseQueryUrl) {
                delete queryUrl[key];
            } else if (Array.isArray(queryUrl[key]) && queryUrl[key].length === 0) {
                delete queryUrl[key];
            }

        });
        return queryUrl;
    }

    _pathname(aqpQuery) {
        let pathname = this.options.currentLink ? `${this.options.currentLink}` : history.location.pathname;
        if (aqpQuery.page > 1 && this.options.currentLink) {
            pathname = pathname + '/' + aqpQuery.page;
        }
        return pathname;
    }

    set(aqpQuery) {
        let search = '?' + this.toUrl(this._clearQueryUrl(aqpQuery), this.mapping);
        history.replace({
            pathname: this._pathname(aqpQuery),
            search
        });

        aqpQuery = this._toApi(aqpQuery, this.mapping);

        return aqpQuery;
    }
}
