import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {fetchCategories, resetCategories} from "../../../creators/categories";
import CategoriesList from "./components/CategoriesList";
import CreateCategories from "./components/CreateCategory";
import CategoriesToCsv from "./components/CategoriesToCsv";
import {LoadingBar, PageLoader} from "../../components/Loader";

class Categories extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
        this.props.fetchCategories({sort : "-count"});
    }
/*
    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo);
    }*/

    render() {
        return !this.props.categoriesInitialized ? (<PageLoader />) :(
            <div id={"facette-categories"} className={"mb-5"}>
                <h4 className={"w-100"}>Categories</h4>
                <CategoriesToCsv />
                <div className={"w-100 pb-1"}>{this.props.categoriesLoading ? (<LoadingBar />) : null}</div>
                <CategoriesList limit={this.props.limitChildrens} />
                <CreateCategories parentId={""} />
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        categoriesLoading : state.categories.loading,
        categoriesInitialized : state.categories.initialized,
        limitChildrens : state.categories.limitChildrens || 0,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCategories,
    resetCategories
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
