import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('TAGS_MEDIA').plugin(axiosPlugin, {template: "array"});

let fetchTagsMediaAction = redux.fetchActionGet();

export const fetchTagsMedia = (kind = "photo",query = {}) => fetchTagsMediaAction(`/tags-list/${kind}/childrens?${queryString.stringify(query)}`);

export const tagsMedia = redux.reducer();