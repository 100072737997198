import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {
    fetchCategorizedMediaRandom,
    resetCategorizedMedia,
    updateCategorizedMedia,
    refuserCategorizedMedia
} from "../../../creators/categorized.media";
import {Button, Col, Image, Modal, Row} from "react-bootstrap";
import FormFields from "../../components/FormFields";
import ViewCount from "./components/ViewCount";
import CardUser from "./components/CardUser";
import Categories from "./components/Categories";
import {SpinLoader} from "../../components/Loader";
import _ from "lodash";


class CategorizedManualPhotos extends React.Component {
    constructor(props) {
        super(props);
        this.pathPage = `/categorizations/manual-photos`;
        this.filter = {
            sort : "-count.like",
            kind : "gallery",
            right : "all"
        }
        this.state = {
            filter : {...this.filter},
            categories : [],
            toggleAlert : false
        }

        this.handleFilter = this.handleFilter.bind(this);
        this.updateCategories = this.updateCategories.bind(this);
        this.handleDelete = this.handleDelete.bind(this);

        this.fetchMedia(this.state.filter);
    }

    componentWillUnmount() {
        this.props.resetCategorizedMedia();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.categorizedMediaInitialized !== this.props.categorizedMediaInitialized) {
            this.setState({categories : this.props.categorizedMedia.categories || [] });
        }
        return null;
    }

    fetchMedia(filter){
        this.props.fetchCategorizedMediaRandom(filter);
        this.setState({filter, categories : []});
    }

    handleFilter(name,value){
        let filter = {...this.state.filter, [name] : value};
        this.fetchMedia(filter);
    }

    updateCategories(categories){
        this.setState({categories});
    }

    onSubmit(){
        let categories = this.state.categories;
        if(categories.length >0 && this.props.categorizedMedia._id){
            this.props.updateCategorizedMedia(this.props.categorizedMedia._id, {categories}).then(response =>{
                let  statusCode = _.get(response, "error.response.statusCode");
                let  message = _.get(response, "error.response.message");
                if(statusCode){
                    alert(message)
                }
                else{
                    let filter = {...this.state.filter};
                    this.fetchMedia(filter);
                    window.scrollTo(0, 0);
                }
            }).catch(e=>{alert(e);})
        }
        else{
            alert("Aucune catégories selectionnée");
        }
    }

    handleDelete(){
        //update Media
        this.props.refuserCategorizedMedia(this.props.categorizedMedia._id).then(response =>{
            let  statusCode = _.get(response, "error.response.statusCode");
            let  message = _.get(response, "error.response.message");
            if(statusCode){
                alert(message)
            }
            else{
                let filter = {...this.state.filter};
                this.fetchMedia(filter);
            }
        }).catch(e=>{alert(e);})
        //Reload Fetch
        //let filter = {...this.state.filter};
        //this.fetchMedia(filter);
        this.setState({toggleAlert : false});

    }

    render() {

        return (
            <Fragment>
                <h1>Categorisation manuelle de photos</h1>
                <Row className={"justify-content-between"}>
                    <Col sm={4}>
                        <Row className={"justify-content-between"}>
                            <Col sm={6}>
                                <FormFields
                                    group={{controlId : "sort"}}
                                    type={"select"}
                                    controle={{
                                        name : "sort",
                                        value : this.state.filter.sort,
                                        options : [{value : "-count.like", label : "plus de likes"}, {value : "-count.view", label : "plus de vues"}, {value : "-count.comment", label : "plus de commentaires"}],
                                        onChange : (event) =>{this.handleFilter('sort',event.target.value)}
                                    }}
                                />
                            </Col>
                                <Col sm={6}>
                                    <FormFields
                                        group={{controlId : "right"}}
                                        type={"select"}
                                        controle={{
                                            name : "right",
                                            value : this.state.filter.right,
                                            options: [{value: "all", label: "Tous"}, {value: "guest",label: "Publique"}, {value: "public", label: "Membres"}, {value: "friend", label: "Amis"}, {value: "private", label: "Privés"}],
                                            onChange : (event) =>{this.handleFilter('right',event.target.value)}
                                        }}
                                    />
                                </Col>
                        </Row>
                    </Col>
                    <Col sm={2}>
                        {this.props.categorizedMedia.totalCategorized} photos catégorisés manuellement
                    </Col>
                </Row>
                <Row className={"m-0"}>
                    <Col className={"m-w-550 "}>
                        <Image src={this.props.categorizedMedia.previewUrl ? `${this.props.categorizedMedia.previewUrl}?width=500` : ""} fluid className={"big-thumb"}  />
                        <ViewCount
                            count={this.props.categorizedMedia.count}
                        />
                        <CardUser owner={this.props.categorizedMedia.owner}/>
                    </Col>
                    <Col className={"o-s-y-800"}>
                        <Categories updateCategories={this.updateCategories} valuesCheck={this.state.categories} />
                    </Col>
                </Row>
                <Row >
                    <Col sm={12} className={"d-flex justify-content-center "}>
                        <Button variant="primary" onClick={() => this.onSubmit()}>
                            {this.props.categorizedMediaLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                            Enregistrer et passer à la suivante
                        </Button>
                        <Button variant="danger" className={"ml-4"} onClick={()=>this.setState({toggleAlert : true})}>
                            Cette photo ne peut pas être catégorisée
                        </Button>
                    </Col>
                </Row>
                <Modal centered backdrop={false} show={this.state.toggleAlert} onHide={()=>this.setState({toggleAlert : false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Etes-vous sûr ?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            A n'utiliser que si l'image est de trés mauvaise qualité, ou n'est pas une photo d'une personne, par exemple.
                        </p>
                       <p className={"text-danger"}>
                           Si vous confirmza, cette image ne pourra plus être catégorisée (opération non réversible)
                       </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.setState({toggleAlert : false})}>
                            Annuler
                        </Button>
                        <Button variant="danger" onClick={()=>this.handleDelete()}>
                            Confirmer
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>)
    }
}


const mapStateToProps = (state) => {
    return {
        categorizedMedia : state.categorizedMedia.data,
        categorizedMediaInitialized : state.categorizedMedia.initialized,
        categorizedMediaLoading : state.categorizedMedia.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCategorizedMediaRandom,
    resetCategorizedMedia,
    updateCategorizedMedia,
    refuserCategorizedMedia
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CategorizedManualPhotos);
