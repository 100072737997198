import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button, Row, Table} from "react-bootstrap";

import {fetchUserRoom, resetUserRoom} from "../../../creators/user.room";
import {LoadingBar, SpinLoader} from "../../components/Loader";
import {fetchUserRoomMessages, resetUserRoomMessages} from "../../../creators/user.room.messages";
import ColoneTn from "../../components/ColoneTh";
import moment from "moment-timezone";
import DraftContentReader from "../../components/editor/DraftContentReader";


const MessageType = ({
        kind = "text",
        content = ""
    }) =>{

    if(kind === "text"){
        return <DraftContentReader contentRaw={content} />
    }
    if(kind === "media"){
        return <a href={content} target={"_blank"}><img src={`${content}?width=200`} alt={"img"} /></a>
    }
    return null;
}

class MemberRoom extends React.Component {
    constructor(props) {
        super(props);
        this.currentUserId = this.props.match.params.id || false;
        this.currentRoomId = this.props.match.params.roomId || false;
        this.filter = {
            sort : "-createdAt",
            limit : 40,
            page : 1,
            kind : ["text", "media"]
        }
        this.state = {
            filter : this.filter
        }

        this.nextMessage = this.nextMessage.bind(this);

        this.props.fetchUserRoom(this.currentRoomId);
        this.props.fetchUserRoomMessages(this.currentRoomId, this.state.filter);
    }
    componentWillUnmount() {
        this.props.resetUserRoom();
    }

    nextMessage(){
        let page = this.state.filter.page + 1;
        let filter = {...this.state.filter, page};
        this.setState({filter});
        this.props.fetchUserRoomMessages(this.currentRoomId, filter, true);
    }


    render() {

        return (
            <Fragment>
                {this.props.roomInitialized ? (
                    <Row className={"mt-5 p-2"}>
                        <h4 className={"w-100"}>Rooms {this.props.room._id || ""}</h4>
                        <ul className={"rooms-list-users"}>
                            <span className={"pr-2"}>Membres</span>
                            {this.props.room.users.filter(user => user._id.toString() !== this.currentUserId.toString()).map(user =>(<li key={user._id}>{user.username}</li>))}
                        </ul>
                    </Row>
                ) : (
                    <Row className={"mt-5 p-2"}>
                        <h4 className={"w-100"}><LoadingBar /></h4>
                    </Row>
                )}
                <Row>
                    {this.props.roomMessagesLoading ? (<LoadingBar/>) : null }
                    <Table striped bordered hover>
                        <thead>
                        <tr>
                            <ColoneTn label={"Membre"}  />
                            <ColoneTn label={"Date"}  />
                            <ColoneTn label={""}  />
                        </tr>
                        </thead>
                        <tbody>
                        {this.props.roomMessages.map(message =>(
                                <tr key={message._id}>
                                    <td width={"300"}>{message.owner.username}</td>
                                    <td width={"150"}>{moment(message.createdAt).format("DD-MM-YYYY HH:mm")}</td>
                                    <td className={message.isDeleted ? "room-message-deleted" : ""}><MessageType
                                        kind={message.kind}
                                        content={message.kind === "media" ? message.media : message.contentRaw}
                                    /></td>
                                </tr>
                        ))}
                        </tbody>
                    </Table>

                </Row>
                {this.props.roomMessagesMore ? (
                    <Row>
                        <Button variant="primary" className={"btn-loading mt-3"} onClick={()=>this.nextMessage()}>
                            Plus de message {this.props.roomMessagesLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                        </Button>
                    </Row>
                ) : null}

            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        room: state.userRoom.data,
        roomInitialized: state.userRoom.initialized,
        roomsLoading: state.userRoom.loading,

        roomMessages: state.userRoomMessages.data,
        roomMessagesMore: state.userRoomMessages.more,
        roomMessagesInitialized: state.userRoomMessages.initialized,
        roomMessagesLoading: state.userRoomMessages.loading
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUserRoom,
    resetUserRoom,
    fetchUserRoomMessages,
    resetUserRoomMessages

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MemberRoom);
