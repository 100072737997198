import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Row, Table} from "react-bootstrap";
import ColoneTn from "../../components/ColoneTh";
import {LoadingBar} from "../../components/Loader";
import {fetchUserSessions, resetUserSessions} from "../../../creators/user.sessions";
import moment from "moment-timezone";


class MemberSessions extends React.Component {
    constructor(props) {
        super(props);
        this.currentUserId = this.props.match.params.id || false;
        this.state = {

        }
        this.props.fetchUserSessions(this.currentUserId);
    }
    componentWillUnmount() {
        this.props.resetUserSessions();
    }

    render() {
        return (
            <Fragment>
                <Row className={"mt-5 p-2"}>
                    <h4 className={"w-100"}>Sessions</h4>
                    {this.props.userSessionsLoading ? (<LoadingBar/>) : (
                        <Table striped bordered hover>
                            <thead>
                            <tr>
                                <ColoneTn label={"Date"}  />
                                <ColoneTn label={"IP"}  />
                                <ColoneTn label={"Ville"}  />
                            </tr>
                            </thead>
                            <tbody>
                            {this.props.userSessions.map(session =>(
                                <tr key={session.createdAt}>
                                    <td>{moment(session.createdAt).format("DD-MM-YYYY HH:mm")}</td>
                                    <td>{session.ip}</td>
                                    <td>{session.city}</td>
                                </tr>
                            ))}
                            </tbody>
                        </Table>
                    )}
                </Row>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        userSessions: state.userSessions.data,
        userSessionsInitialized: state.userSessions.initialized,
        userSessionsLoading: state.userSessions.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUserSessions,
    resetUserSessions
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MemberSessions);
