import queryString from 'querystring';
import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('CONFIGURATIONS').plugin(axiosPlugin, {template: "array"});
let fetchAction = redux.fetchActionGet();

redux.reducerForType('UPDATE', (state,payload) => {
    let newState = {...state};
    let data = payload.data;
    if(data && data._id){
        newState.data = newState.data.map(item => {
            if(item._id.toString() === payload.data._id.toString()){
                item = payload.data;
            }
            return item;
        });
    }

    return newState;
});

export const TYPES = redux.types;

export const fetchConfigurations = (query = {}) => fetchAction(`/configurations?${queryString.stringify(query)}`);
export const resetConfigurations = () => redux.actions.reset();

export const configurations = redux.reducer();
