import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import { CSVLink } from "react-csv";
import {fetchCategories} from "../../../../creators/categories";

class CategoriesToCsv extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }

        this.headers = [
            { label: "Nom", key: "title" },
            { label: "Slug", key: "slug" },
            { label: "Level", key: "level" },
            { label: "Parent", key: "parent" },
            { label: "count", key: "count" },
            { label: "Tag", key: "isChildrens" },
        ];

        if(!this.props.categoriesInitialized){
            this.props.fetchCategories({sort : "-count"});
        }

    }

    fetchCategories(categories,level = 0, data=[], parent = {}){
        categories.forEach(category=>{
            data.push({title : category.title, slug : category.slug, level, parent : parent.slug ? parent.slug : "", count : category.count, isChildrens : (!category.childrens || (category.childrens && category.childrens.length)) === 0 ? 1 : 0});
            if(category.childrens){
                this.fetchCategories(category.childrens, level+1, data, category);
            }
        });
        return data;
    }

    render() {
        return (
            this.props.categoriesInitialized ? (
                <CSVLink
                    headers={this.headers}
                    data={this.fetchCategories(this.props.categories)}
                    separator={";"}
                    filename={"categories.csv"}
                    className="mb-2 btn btn-outline-success btn-sm"
                    target="_blank"
                >
                    Export CSV
                </CSVLink>
            ) : null
        )
    }
}


const mapStateToProps = (state) => {
    return {
        categories : state.categories.data,
        categoriesLoading : state.categories.loading,
        categoriesInitialized : state.categories.initialized
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCategories
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesToCsv);
