import queryString from 'querystring';
import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('CATEGORIES').plugin(axiosPlugin, {template: "array"});
let fetchAction = redux.fetchActionGet();


/*redux.reducerForType('DELETE', (state,payload) => {
    let newState = {...state, lastUpdate : Date.now()};
    if(payload.data && payload.data._id){
        newState.data = newState.data.filter(item => item._id.toString() !== payload.data._id.toString());
        newState.total--;
        if(newState.total < 0){
            newState.total = 0;
        }
    }
    return newState;
});

redux.reducerForType('UPDATE', (state,payload) => {
    let newState = {...state, lastUpdate : Date.now()};
    if(payload.data && payload.data._id){
        newState.data = newState.data.map(item => {
            if(item._id.toString() === payload.data._id.toString()){
                item = payload.data;
            }
            return item;
        });
    }
    return newState;
});*/

const fetchChildrensAdd = (childrens ,data) => {
    childrens = childrens.map(category => {
        if (category._id.toString() === data.parentId.toString()) {
            if(category.childrens){
                category.childrens.push(data);
            }
            else{
                category.childrens = [data];
            }

            category.total = category.total +1;
        } else {
            category.childrens = fetchChildrensAdd(category.childrens, data);
        }
        return category;
    });
    return childrens;
}

const fetchChildrensUpdate = (childrens ,data) => {
    childrens = childrens.map(category => {
        if (category._id.toString() === data._id.toString()) {
            category = {
                ...data,
                childrens : category.childrens
            };
        } else {
            category.childrens = fetchChildrensUpdate(category.childrens, data);
        }
        return category;
    });
    return childrens;
}

const fetchChildrensRemove = (childrens ,data) => {
    childrens = childrens.map(category => {
        if (category._id.toString() === data.parentId.toString()) {
            category.childrens = category.childrens.filter(item => item._id.toString() !== data._id.toString());
            category.total = category.total -1;
        } else {
            category.childrens = fetchChildrensRemove(category.childrens, data);
        }
        return category;
    });
    return childrens;
}

redux.reducerForType('ADD', (state,payload) => {
    let newState = {...state, lastUpdate : Date.now()};
    let data = payload.data;

    if(data && data._id){
        if(data.parentId){
            newState.data = fetchChildrensAdd(newState.data, data);
        }
        else{
            newState.data.push(data);
        }
    }
    newState.total = newState.total+1;
    return newState;
});

redux.reducerForType('UPDATE', (state,payload) => {
    let newState = {...state, lastUpdate : Date.now()};
    let data = payload.data;
    if(data && data._id){
        newState.data = fetchChildrensUpdate(newState.data, data);
    }

    return newState;
});

redux.reducerForType('REMOVE', (state,payload) => {
    let newState = {...state, lastUpdate : Date.now()};
    let data = payload.data;

    if(data && data._id){
        if(data.parentId){
            newState.data = fetchChildrensRemove(newState.data, data);
        }
        else{
            newState.data = newState.data.filter(item => item._id.toString() !== data._id.toString());
        }
    }
    newState.total = newState.total-1;
    return newState;
});

export const TYPES = redux.types;

export const fetchCategories = (query = {}) => fetchAction(`/categories?${queryString.stringify(query)}`);
export const resetCategories = () => redux.actions.reset();

export const categories = redux.reducer();
