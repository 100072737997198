import React, {Fragment} from 'react';
import {Router, Switch, Route} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import history from '../../../libs/history';
import PageStatsTransaction from "./Transaction";
import SubNavBar from "../../components/SubNavBar";


class Stats extends React.Component {
    constructor(props) {
        super(props);
        this.pathPage = '/stats';
        this.subNavBar = [
            {link : `${this.pathPage}`, label : "Stats Transaction"},
            // {link : `${this.pathPage}/couvertures`, label : "Couvertures"},
            // {link : `${this.pathPage}/contributions`, label : "Contributions"},
            // {link : `${this.pathPage}/certifications`, label : "Certifications"},
            // {link : `${this.pathPage}/histoires`, label : "Histoires"}
        ]
    }


    render() {
        return (
            <Fragment>
                <SubNavBar {...this.props} subNav={this.subNavBar}/>
                <Router history={history}>
                    <Switch>
                        {/*<Route exact path={`${this.pathPage}/couvertures`} component={ContentsCover}/>*/}
                        {/*<Route exact path={`${this.pathPage}/contributions`} component={ContentsMedia}/>*/}
                        {/*<Route exact path={`${this.pathPage}/certifications`} component={ContentsPhotoCertified}/>*/}
                        {/*<Route exact path={`${this.pathPage}/histoires/:page([0-9]+)?`} component={ContentsStories}/>*/}
                        {/*<Route exact path={`${this.pathPage}/histoires/:storyId`} component={ContentsStory}/>*/}
                        <Route exact path={`${this.pathPage}`} component={PageStatsTransaction}/>
                    </Switch>
                </Router>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        currentUserInitialized: state.currentUser.initialized
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Stats);
