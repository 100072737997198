import React from 'react';
import {Image} from "react-bootstrap";

export default class ImageRotation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentSource: 0,
            intervalID: ''
        };
        this.height = this.props.height || "200";
        this.width = this.props.width || "200";
        this.startRotation = this.startRotation.bind(this);
        this.stopRotation = this.stopRotation.bind(this);
    }

    startRotation() {
        let internalId = setInterval(() => {
            this.setState({currentSource: (this.state.currentSource < this.props.srcs.length - 1) ? this.state.currentSource + 1 : 0});
        }, this.props.interval);
        this.setState({internalId, currentSource: 0});
    }

    stopRotation() {
        clearInterval(this.state.internalId);
        this.setState({currentSource: 0});
    }

    render() {
        return (
            <Image
                src={`${this.props.srcs[this.state.currentSource]}?width=${this.width}&height=${this.height}`}
                onLoad={this.props.onLoad}
                alt={this.props.alt}
                thumbnail
                onMouseEnter={() => this.startRotation()}
                onMouseLeave={() => this.stopRotation()}
            />
        )
    }
}

ImageRotation.defaultProps = {
    srcs: [''],
    onLoad: () => {
    },
    interval: 1000
};
