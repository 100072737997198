import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('TAG_LIST_CHILDRENS').plugin(axiosPlugin, {template: "array"});

let fetchTagsListChildrensAction = redux.fetchActionGet();
let createTagsListChildrensAction = redux.fetchActionCreate();
let removeTagsListChildrensAction = redux.fetchActionDelete();

redux.reducerForType('DELETE', (state,payload) => {
    let newState = {...state};
    if(payload.data && payload.data._id){
        newState.data = newState.data.filter(item => item._id.toString() !== payload.data._id.toString());
        newState.total--;
        if(newState.total < 0){
            newState.total = 0;
        }
    }
    return newState;
});

// redux.reducerForType('CREATE', (state,payload) => {
//     let newState = {...state};
//
//     if(payload.data && payload.data._id){
//         newState.data.push(payload.data);
//         newState.total++;
//     }
//     console.log("ok",newState);
//     return newState;
// });

export const TYPES = redux.types;

export const fetchTagListChildrens = (listId,query) => fetchTagsListChildrensAction(`/tags-list/${listId}/childrens?${queryString.stringify(query)}`);

export const createTagListChildrens = (listId, data) => createTagsListChildrensAction(`/tags-list/${listId}/childrens`, data);
export const removeTagListChildrens = (listId, data) => removeTagsListChildrensAction(`/tags-list/${listId}/childrens`, data);

export const resetTagListChildrens = () => redux.actions.reset();


export const tagsListChildrens = redux.reducer();