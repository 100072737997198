import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('FACETTES_TYPES_GENERATEUR').plugin(axiosPlugin);

let createAction = redux.fetchActionCreate();

export const TYPES = redux.types;

export const createTypesGenerateur = (data = {}) => createAction(`/facettes-types/generer-facette`, data);


export const facettesTypesGenerateur = redux.reducer();