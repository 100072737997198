import React from 'react';


const PageLoader = (props) => {
    return (
        <div className={`loader spin-loader big ${props.className || ""}`} />
    )
};

const SpinLoader = (props) => {
    return (
        <div className={`loader spin-loader ${props.className || ""}`} />
    )
};

const LoadingBar = (props) => {
    return(
        <div className={"infinite-loading-container"}>
            <div className={`infinite-loading-bar ${props.className || ""}`} />
        </div>
    )
}

export {PageLoader, SpinLoader, LoadingBar};
