import React from 'react';
import Editor from 'draft-js-plugins-editor';
import {
    convertFromRaw,
    EditorState,
    CompositeDecorator
} from "draft-js";

import createImagePlugin from 'draft-js-image-plugin';
import addLinkPlugin from "./plugins/AddLinkPlugin";
import createLinkifyPlugin from 'draft-js-linkify-plugin';
import addCustomMentionPlugin from "./plugins/AddCustomMention";
import alternativeToMentionPlugin from "./plugins/alternativeToMention";

import 'draft-js-linkify-plugin/lib/plugin.css';
import "draft-js/dist/Draft.css";
import 'draft-js-emoji-plugin/lib/plugin.css';
import 'draft-js-mention-plugin/lib/plugin.css';
import 'draft-js-image-plugin/lib/plugin.css';

import MultiDecorator from "draft-js-plugins-editor/lib/Editor/MultiDecorator";

import {getBlockStyle} from "./blockStyles/BlockStyleToolbar";
import {mediaBlockRenderer} from "./entities/mediaBlockRenderer";

const primaryColor = 'rgba(95, 170, 253, 1.0)';
const secondaryColor = 'rgba(128, 128, 128, 1.0)';
const successColor = 'rgba(40, 167, 69, 1.0)';
const dangerColor = 'rgba(251, 77, 77, 1.0)';
const warningColor = 'rgba(255, 177, 66, 1.0)';
const infoColor = 'rgba(94, 159, 244, 1.0)';

const colorStyleMap = {
    "color-primary": {
        color: primaryColor,
        fill: primaryColor,
    },
    "color-secondary": {
        color: secondaryColor,
        fill: secondaryColor,
    },
    "color-success": {
        color: successColor,
        fill: successColor,
    },
    "color-danger": {
        color: dangerColor,
        fill: dangerColor,
    },
    "color-warning": {
        color: warningColor,
        fill: warningColor,
    },
    "color-info": {
        color: infoColor,
        fill: infoColor,
    }
};

const imagePlugin = createImagePlugin();
const linkifyPlugin = createLinkifyPlugin({
    target: "_blank"
});
// const linkifyPlugin = createLinkifyPlugin({
//     component: (props) => {
//         console.log(props);
//         if(props.blockKey !== "630dr"){
//             return(
//                 // eslint-disable-next-line no-alert, jsx-a11y/anchor-has-content
//                 <a {...props} target={"_blank"} />
//             )
//         }
//         return props.href.replace("http://", "");
//     }
// });



const plugins = [
    imagePlugin,
    addLinkPlugin,
    linkifyPlugin,
    addCustomMentionPlugin,
    alternativeToMentionPlugin,
];


function getPluginDecoratorArray() {
    let decorators = [];
    let plugin;
    // check each plugin that will be used in the editor for decorators
    // (retrieve listOfPlugins however makes sense in your code)
    for (plugin of plugins) {
        if (plugin.decorators !== null && plugin.decorators !== undefined) {
            // if the plugin has any decorators, add them to a list of all decorators from all plugins
            decorators = decorators.concat(plugin.decorators);

        }
    }
    return decorators;
}

function myFunctionForGrabbingAllPluginDecorators() {
    // I can't quite remember why I had to wrap things in this exact way, but found that I ran into
    // errors if I did not both have a MultiDecorator and a CompositeDecorator wrapping
    // This MultiDecorator can now be used as shown in my previous post.
    return new MultiDecorator(
        [new CompositeDecorator(getPluginDecoratorArray())]
    );
}


class DraftContentReader extends React.Component {
    constructor(props){
        super(props);

        this.state = {
          editorState: EditorState.createEmpty(),
        };

        this.onChange = this.onChange.bind(this);
        this.updateContent = this.updateContent.bind(this);
    }

    componentDidMount() {
        if(this.props.contentRaw && this.props.contentRaw.blocks && this.props.contentRaw.blocks[0]){
            if(this.props.contentRaw.entityMap){
                const contentState =  convertFromRaw(this.props.contentRaw);
                let decorator = myFunctionForGrabbingAllPluginDecorators();
                let editorState = EditorState.createWithContent(contentState, decorator);
                this.setState({editorState});
            } else {
                let contentRaw = {...this.props.contentRaw, entityMap: {}};
                const contentState =  convertFromRaw(contentRaw);
                let decorator = myFunctionForGrabbingAllPluginDecorators();
                let editorState = EditorState.createWithContent(contentState, decorator);
                this.setState({editorState});
            }
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.contentRaw !== this.props.contentRaw && this.props.shouldUpdateRoomLastMessage){
            this.updateContent(this.props.contentRaw);
        }
    }

    onChange(editorState) {
        this.setState({editorState});
    };

    updateContent(newContentRaw){
        if(newContentRaw && newContentRaw.blocks && newContentRaw.blocks[0]){
            const contentState =  convertFromRaw(newContentRaw);
            let decorator = myFunctionForGrabbingAllPluginDecorators();
            let editorState = EditorState.createWithContent(contentState, decorator);
            this.setState({editorState});
        }
    }

    render(){
        const plugins = [
            imagePlugin,
            addLinkPlugin,
            linkifyPlugin,
            addCustomMentionPlugin,
            alternativeToMentionPlugin
        ];
        return(
            <div className={`readonly-editor ${this.props.editorStyle ? this.props.editorStyle : ""}`}>
                {this.props.extendedProps && this.props.extendedProps.isComment ? (
                    <Editor extendedProps={this.props.extendedProps}
                            ref="editor"
                            editorState={this.state.editorState}
                            onChange={this.onChange.bind(this)}
                            readOnly={true}/>
                ) : (
                    <Editor customStyleMap={colorStyleMap}
                            extendedProps={this.props.extendedProps}
                            blockRendererFn={mediaBlockRenderer}
                            ref="editor"
                            blockStyleFn={getBlockStyle}
                            editorState={this.state.editorState}
                            plugins={plugins}
                            onChange={this.onChange.bind(this)}
                            readOnly={true}/>
                )}
            </div>
        )
    }
}

export default DraftContentReader;