
import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('USER_SESSIONS').plugin(axiosPlugin, {template: "array"});

let fetchUserSessionsAction = redux.fetchActionGet();


export const fetchUserSessions = (userId) => fetchUserSessionsAction(`/users/${userId}/sessions`);
export const resetUserSessions = () => redux.actions.reset();

export const userSessions = redux.reducer();