import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('USER_AUTOLOGIN').plugin(axiosPlugin);

let fetchAutoLoginAction = redux.fetchActionGetOne();


export const TYPES = redux.types;

export const fetchAutoLogin = (userId) => fetchAutoLoginAction(`/users/${userId}/auto-login`);

export const resetAutoLogin = () => redux.actions.reset();

export const autologin = redux.reducer();