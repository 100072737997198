import queryString from 'querystring';
import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('ANNOUCEMENT_MESSAGES').plugin(axiosPlugin, {template: "array"});
let fetchAction = redux.fetchActionGet();

export const TYPES = redux.types;

export const fetchAnnouncementMessages = (query = {}) => fetchAction(`/announcement-messages?${queryString.stringify(query)}`);
export const resetAnnouncementMessages = () => redux.actions.reset();

export const announcementMessages = redux.reducer();
