import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {fetchKeywordsWarningsSetting} from "../../../creators/keywordsWarningSetting";
import {PageLoader} from "../../components/Loader";
import FormSetting from "../../formulaires/keywordsWarnings.setting";


class Setting extends React.Component {
    constructor(props) {
        super(props);
        this.props.fetchKeywordsWarningsSetting();
    }


    render() {
        return !this.props.keywordsWarningsSettingInitialized ? (<PageLoader/>) : (
            <FormSetting />
        )
    }
}


const mapStateToProps = (state) => {
    return {
        keywordsWarningsSetting : state.keywordsWarningsSetting.data,
        keywordsWarningsSettingLoading : state.keywordsWarningsSetting.loading,
        keywordsWarningsSettingInitialized: state.keywordsWarningsSetting.initialized,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchKeywordsWarningsSetting
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Setting);
