import React from 'react';
import {Image} from "react-bootstrap";
import Col from "react-bootstrap/Col";

import ViewCount from "./ViewCount";
import CardUser from "./CardUser";
import {Link} from "react-router-dom";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Dotdotdot from "react-dotdotdot";

const CardPhoto = (props) => {
    const {linkTo = "", previewUrl = "", title ="", count = {view : 0, like : 0, comment : 0}, owner = {avatar : "", gender : "", username : ""} } = props;
    return (
        <Col xs={12} sm={5} md={4} xl={2} lg={3} className={"mt-4"}>
            <Dotdotdot tagName="span" className={"d-flex justify-content-center pl-3 pr-3 pb-1"} clamp={2} useNativeClamp={false}>
                {title}
            </Dotdotdot>
            <div className={"d-flex justify-content-center"}>
                {linkTo ? (
                    <Link to={linkTo} target={"_blank"}>
                        <Image src={previewUrl ? `${previewUrl}?width=222&height=222` : ""}  />
                    </Link>
                ) : <Image src={previewUrl ? `${previewUrl}?width=222&height=222` : ""} />}
            </div>
            <a href={previewUrl} target={"_blank"} className={"d-flex justify-content-center"}><FontAwesomeIcon icon={faSearch} className={"icone"}  /></a>
            <ViewCount
                count={count}
            />
            <CardUser owner={owner} classNameAvatar="avatar-max-40"/>
        </Col>
    )

}

export default CardPhoto;