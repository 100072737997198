import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('TAGS_LIST').plugin(axiosPlugin);


let fetchTagsListAction = redux.fetchActionGetOne();
let updateTagsListAction = redux.fetchActionUpdate();

export const TYPES = redux.types;

export const fetchTagsList = (listId,query) => fetchTagsListAction(`/tags-list/${listId}`);
export const updateTagsList = (listId,data) => updateTagsListAction(`/tags-list/${listId}`, data);
export const resetTagsList = () => redux.actions.reset();

export const tagsList = redux.reducer();