import {put,takeEvery} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {TYPES as REPORTING_COMMENT_TYPE} from '../creators/reporting.comment';


export function* deleteSuccess(action) {
    try {
        yield put({type: "REPORTING_COMMENTS_DELETE", data : action.payload});
    } catch (error) {

    }
}

export function* updateSuccess(action) {
    try {
        yield put({type: "REPORTING_COMMENTS_UPDATE", data : action.payload});
    } catch (error) {
    }
}

export function* createSuccess(action) {
    try {
        yield put({type: "REPORTING_COMMENTS_ADD", data : action.payload});
    } catch (error) {
    }
}

export function* watchReportingComment() {
    // yield takeEvery(REPORTING_COMMENT_TYPE.DELETE_SUCCESS, deleteSuccess);
    // yield takeEvery(REPORTING_COMMENT_TYPE.UPDATE_SUCCESS, updateSuccess);
    yield takeEvery(REPORTING_COMMENT_TYPE.CREATE_SUCCESS, createSuccess);
}

export default watchReportingComment;

