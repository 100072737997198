import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('CONFIGURATION').plugin(axiosPlugin);

let fetchAction = redux.fetchActionGetOne();

let updateAction = redux.fetchActionUpdate();


export const TYPES = redux.types;

export const fetchConfiguration = (id) => fetchAction(`/configurations/${id}`);
export const updateConfiguration = (id, data = {}) => updateAction(`/configurations/${id}`, data);
export const resetConfiguration = () => redux.actions.reset();

export const configuration = redux.reducer();