import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button, Col, Row} from "react-bootstrap";
import FormFields from "../../components/FormFields";
import {LoadingBar, SpinLoader} from "../../components/Loader";
import {fetchCategorizedMedias, resetCategorizedMedias} from "../../../creators/categorized.medias";
import _ from "lodash";
import {fetchCategoriesRoot, resetCategoriesRoot} from "../../../creators/categories.rootChildrens";
import CardPhoto from "./components/CardPhoto";
import Aqp from "../../../libs/mk-react-aqp";
import Pagination from "../../components/Pagination";
import CreateZipMedia from "./components/CreateZipMedia";


class CategorizedPhotos extends React.Component {
    constructor(props) {
        super(props);
        this.pathPage = `/categorizations`;
        this.filter = {
            sort : "-count.like",
            kind : "gallery",
            categoryId : "",
            limit : 100,
            source : "null"
        }

        this.aqp = new Aqp({}, {
            blacklist: ['limit', 'page'],
            currentLink: this.pathPage,
            filterDefault: this.filter
        });

        this.state = {
            filter: {...this.filter, ...this.aqp.query, page: this.props.match.params.page || 1},
            categoryParentId : "",
            lastUpdate : new Date()
        }



        this.handleFilter = this.handleFilter.bind(this);
        this.handleCategoryParent = this.handleCategoryParent.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.fetchMedia = this.fetchMedia.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.props.resetCategoriesRoot();
        this.props.fetchCategoriesRoot()

    }

    componentWillUnmount() {
        this.props.resetCategoriesRoot();
        this.props.resetCategorizedMedias();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.categoriesRoot.length < this.props.categoriesRoot.length) {
            let parentId = _.get(this.props.categoriesRoot, `[0]._id`) || "";
            let categoryId = _.get(this.props.categoriesRoot, `[0].childrens.[0]._id`) || "";
            this.setState({categoryParentId : parentId, filter : {...this.state.filter, categoryId}}, ()=> this.fetchMedia());
        }
        return null;
    }

    fetchMedia(filter){
        this.props.fetchCategorizedMedias(filter);
        this.setState({lastUpdate : new Date()});
    }

    handleSubmit(){
        let filter = {...this.state.filter, page : 1};
        this.props.history.push(this.pathPage);
        this.setState({filter}, ()=>this.fetchMedia(filter))
    }

    handleFilter(name,value){
        let filter = {...this.state.filter, [name] : value};
        this.setState({filter});
    }

    handleCategoryParent(parentId){
        let findIndex = _.findIndex(this.props.categoriesRoot, {"_id" : parentId.toString()} );
        if(findIndex < 0){
            findIndex = 0;
        }
        let categoryId = _.get(this.props.categoriesRoot, `[${findIndex}].childrens.[0]._id`) || "";
        this.setState({categoryParentId : parentId, filter : {...this.state.filter, categoryId}});
    }

    fetchCurrentChildrens(parentId){
        let findIndex = _.findIndex(this.props.categoriesRoot, {"_id" : parentId.toString()} );
        if(findIndex < 0){
            findIndex = 0;
        }
        let chidrens = _.get(this.props.categoriesRoot, `[${findIndex}].childrens`) || [];
        return chidrens;
    }

    handleChangePage(page, link) {
        let filter = {...this.state.filter, page};
        if (page === 1) {
            this.props.history.push(link);
        } else {
            this.props.history.push(link + '/' + page);
        }
        this.setState({filter});
        this.fetchMedia(filter);
        window.scrollTo(0, 0);
    }


    render() {
        let totalPage = this.props.categorizedMediasTotal ? Math.ceil(this.props.categorizedMediasTotal / this.state.filter.limit) : 0;
        return (
            <Fragment>
                <h1>photos catégorisées</h1>
                <Row>
                    <Col sm={2}>
                        <FormFields
                            group={{controlId : "categoryParentIndex"}}
                            type={"select"}
                            controle={{
                                name : "categoryParentId",
                                initialized : this.props.categoriesRootInitialized,
                                value : this.state.categoryParentId,
                                options : this.props.categoriesRoot,
                                optionField : {value : "_id", label : "title"},
                                onChange : (event) =>{this.handleCategoryParent(event.target.value)}
                            }}
                        />
                    </Col>
                    <Col sm={2}>
                        <FormFields
                            group={{controlId : "categoryId"}}
                            type={"select"}
                            controle={{
                                name : "categoryId",
                                initialized : this.props.categoriesRootInitialized,
                                value : this.state.filter.categoryId,
                                options : this.fetchCurrentChildrens(this.state.categoryParentId),
                                optionField : {value : "_id", label : "title"},
                                onChange : (event) =>{this.handleFilter([event.target.name], event.target.value)}
                            }}
                        />
                    </Col>

                    <Col sm={2}>
                        <FormFields
                            group={{controlId : "sort"}}
                            type={"select"}
                            controle={{
                                name : "sort",
                                value : this.state.filter.sort,
                                options : [{value : "-count.like", label : "plus de likes"}, {value : "-count.view", label : "plus de vues"}, {value : "-count.comment", label : "plus de commentaires"}],
                                onChange : (event) =>{this.handleFilter('sort',event.target.value)}
                            }}
                        />
                    </Col>
                    <Col sm={2}>
                        <FormFields
                            group={{controlId : "source"}}
                            type={"select"}
                            controle={{
                                name : "source",
                                value : this.state.filter.source,
                                options : [{value : "null", label : "Tous"}, {value : "ia", label : "IA"}, {value : "user", label : "User"}],
                                onChange : (event) =>{this.handleFilter('source',event.target.value)}
                            }}
                        />
                    </Col>
                    <Col sm={2}>
                        <Button variant="primary" onClick={() => this.handleSubmit()}>
                            {this.props.categorizedMediasLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                            Rechercher
                        </Button>
                    </Col>
                </Row>

                <CreateZipMedia filter={this.state.filter} lastUpdate={this.state.lastUpdate} />
                <div className={"w-100 mt-3 pb-md-3"}>
                    {this.props.categorizedMediasLoading && (<LoadingBar />)}
                </div>
                <Pagination currentPage={this.state.filter.page}
                            pagesCount={totalPage}
                            {...this.aqp.queryUrl(this.state.filter, {})}
                            handleChangePage={this.handleChangePage}/>
                <Row >
                    {this.props.categorizedMedias.map(media =>(
                        <CardPhoto key={media._id} {...media} linkTo ={`/categorizations/${media._id}`}  />
                    ))}
                </Row>
                <Pagination currentPage={this.state.filter.page}
                            pagesCount={totalPage}
                            {...this.aqp.queryUrl(this.state.filter, {})}
                            handleChangePage={this.handleChangePage}/>
            </Fragment>)
    }
}


const mapStateToProps = (state) => {

    return {

        categorizedMedias : state.categorizedMedias.data,
        categorizedMediasTotal : state.categorizedMedias.total,
        totalMediasCategorized : state.categorizedMedias.length || 0,
        categorizedMediasInitialized : state.categorizedMedias.initialized,
        categorizedMediasLoading : state.categorizedMedias.loading,

        categoriesRoot : state.categoriesRoot.data,
        categoriesRootInitialized : state.categoriesRoot.initialized,
        categoriesRootLoading : state.categoriesRoot.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCategorizedMedias,
    resetCategorizedMedias,
    fetchCategoriesRoot,
    resetCategoriesRoot
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CategorizedPhotos);
