import React, {Component} from 'react';
import {Alert} from "react-bootstrap";
import {apiUrl} from "../../../libs/api";
import _ from "lodash";


class AlertMonitoring extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAlert : false,
            members : [],
            message : ""
        }
        this.getHealthCheck = _.throttle(this._getHealthCheck.bind(this), 300000);

        this.interval = setInterval(()=>{
            this.getHealthCheck()
        }, 1000)
    }


    componentWillUnmount() {
        clearInterval(this.interval);
    }

    _getHealthCheck(){
        apiUrl.get(`/healthcheck`).then(response => {
            this.setState({
                showAlert : response.status !== 1,
                members : _.get(response,"mongoRS.members") || [],
                message : ""
            })
        }).catch(e => {
            this.setState({
                showAlert : true,
                members : [],
                message : e.message
            })
        });
    }

    render(){
        return !this.state.showAlert ? null : (
            <Alert variant="danger" onClose={() => this.setState({showAlert : false})} dismissible>
                <Alert.Heading>error mongo !</Alert.Heading>
                <div>
                    {this.state.members.map(item =>(
                        <ul key={item.name}>
                            <li>Server : {item.name}</li>
                            <li>Latency : {item.latency}</li>
                            <li>Status : {item.status}</li>
                        </ul>
                    ))}
                    {this.state.message !== "" ? <p>{this.state.message}</p> : null}
                </div>

            </Alert>
        )
    }
}

export default AlertMonitoring;
