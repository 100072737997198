import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('USER_CERTIFICATION').plugin(axiosPlugin);


let updateAction = redux.fetchActionUpdate();

let deleteAction = redux.fetchActionDelete();

export const TYPES = redux.types;

export const acceptedUserCertification = (userId, certificationId, data = {}) => updateAction(`/users/${userId}/certifications/${certificationId}/accepted`, data);
export const refusedUserCertification = (userId, certificationId, data = {}) => updateAction(`/users/${userId}/certifications/${certificationId}/refused`, data);
export const deleteUserCertification = (userId, certificationId) => deleteAction(`/users/${userId}/certifications/${certificationId}`);
export const resetUserCertification = () => redux.actions.reset();

export const userCertification = redux.reducer();