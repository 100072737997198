import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('USER_SET_PASSWORD').plugin(axiosPlugin);


let updateUserPasswordAction = redux.fetchActionUpdate();

export const TYPES = redux.types;

export const updateUserPassword = (userId, data = {}) => updateUserPasswordAction(`/users/${userId}/set-password`, data);
export const resetUserSetPassword = () => redux.actions.reset();

export const userSetPassword = redux.reducer();