import { TextNode } from "lexical";
function convertMentionElement(domNode) {
    const textContent = domNode.textContent;
    if (textContent !== null) {
        const node = $createMentionNode(textContent);
        return {
            node
        };
    }
    return null;
}
const mentionStyle = "color: #2276f4; text-decoration:underline";
export class MentionNode extends TextNode {
    static getType() {
        return "mention";
    }
    static clone(node) {
        return new MentionNode({
            name : node.__mention,
            userId : node.__mentionUserId
        }, node.__text, node.__key);
    }
    static importJSON(serializedNode) {
        const node = $createMentionNode(serializedNode.mentionName);
        node.setTextContent(serializedNode.text);
        node.setFormat(serializedNode.format);
        node.setDetail(serializedNode.detail);
        node.setMode(serializedNode.mode);
        node.setStyle(serializedNode.style);
        return node;
    }
    constructor(mention, text, key) {
        super(text ? '@'+mention.name : null, key);
        this.__mention = mention.name;
        this.__mentionUserId = mention.userId;
    }
    exportJSON() {
        return {
            ...super.exportJSON(),
            mentionName: this.__mention,
            mentionUserId: this.__mentionUserId,
            type: "mention",
            version: 1
        };
    }
    createDOM(config) {
        const dom = super.createDOM(config);
        dom.style.cssText = mentionStyle;
        dom.className = "mention";
        return dom;
    }
    exportDOM() {
        const element = document.createElement("span");
        element.setAttribute("data-lexical-mention", "true");
        element.textContent = this.__text;
        return { element };
    }
    static importDOM() {
        return {
            span: (domNode) => {
                if (!domNode.hasAttribute("data-lexical-mention")) {
                    return null;
                }
                return {
                    conversion: convertMentionElement,
                    priority: 1
                };
            }
        };
    }
    isTextEntity() {
        return true;
    }
}
export function $createMentionNode(mentionName, mentionUserId) {
    const mentionNode = new MentionNode({
        name : mentionName,
        userId : mentionUserId
    });
    mentionNode.setMode("token").toggleDirectionless();
    return mentionNode;
}
export function $isMentionNode(node) {
    return node instanceof MentionNode;
}
