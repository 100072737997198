import {normalizeError} from "./error";
// import history from '../../libs/history';
import {toast} from "react-toastify";

let statusCodeMapping = {
    // 409 : 'Conflit, une entrée similaire existe déjà',
    // 400 : 'Certains champs sont invalides',
    // 400 : 'Une erreur est survenue, essayez de rafraîchir la page',
    401 : 'Identifiants incorrects',
    // 403 : 'Vous n\'êtes pas autorisé à executer cette action',
    415 : 'Type de fichier non supporté',
    413 : 'Fichier trop lourd',
    500 : 'Une erreur serveur est survenue, contactez un administrateur ou un membre de l\'équipe technique',
    501 : 'Cette fonctionnalité n\'est pas disponible'
};

export default {
    response: {
        success: ({getState, dispatch, getSourceAction}, response) => {
            return response.data;
        },
        error: ({getState, dispatch, getSourceAction}, error) => {
            let errorResponse = error.response || {};

            let responseData = errorResponse.data || {};
            let normalized = normalizeError({
                errors: responseData.errors,
                message: responseData.message || errorResponse.statusText,
                name: null,
                statusCode: responseData.statusCode || errorResponse.status,
                error: responseData.error || errorResponse.statusText,
            });
            if(statusCodeMapping[normalized.statusCode]){
                toast(statusCodeMapping[normalized.statusCode],{
                    autoClose: 10000,
                    className: 'toast-background toast-error',
                    bodyClassName: "toast-font toast-error",
                    progressClassName: 'toast-loader toast-error'
                });
            }

            if(normalized.statusCode === 404){
                // history.push('/page-introuvable');
            }
            return Promise.reject({...error, response: normalized});
        }
    }
};