import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";

import FormDating from "../../formulaires/member.dating";

class MemberDating extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }


    render() {

        return (
            <Fragment>
                <Row className={"mt-4"}>
                    <Col xs={12}>
                        <FormDating />
                    </Col>
                </Row>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user.data
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MemberDating);
