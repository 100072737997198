import React from 'react';
import {Button, Form} from "react-bootstrap";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {SpinLoader} from "../components/Loader";
import FormFields from "../components/FormFields";
import {resetTagsList, updateTagsList} from "../../creators/tags.list";



class FormTagsList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.props.tagsList
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValue = this.handleValue.bind(this);

    }

    handleValue(name, value){
        this.setState({[name]: value});
    }



    handleSubmit(e) {
        e.preventDefault();
        if (!this.props.tagsListLoading) {
            this.props.updateTagsList(this.props.tagsList._id, {title : this.state.title});
        }
    }

    render() {

        return (
            <Form onSubmit={(e) => this.handleSubmit(e)}>
                <FormFields
                    group={{controlId : "title"}}
                    type={"input"}
                    label={{
                        label : "Nom de la liste de tags"
                    }}
                    controle={{
                        type : "text",
                        name : "title",
                        value : this.state.title,
                        onChange : (event) =>{this.handleValue([event.target.name], event.target.value)}
                    }}
                />

                <Button variant="primary" type="submit" className={"btn-loading mb-5"}>
                    Valider {this.props.tagsListLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                </Button>
            </Form>
        )
    }
}


const mapStateToProps = (state) => {

    return {
        tagsList : state.tagsList.data,
        tagsListLoading : state.tagsList.loading,
        tagsListInitialized : state.tagsList.initialized
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateTagsList,
    resetTagsList
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormTagsList);
