import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import MembersList from "../components/MembersList";
import {Form, Col, Button} from "react-bootstrap";
import querystring from 'querystring';
import FormFields from "../components/FormFields";
import {config} from "../../config";

class Members extends React.Component {
    constructor(props) {
        super(props);
        let search = querystring.parse(this.props.location.search.replace("?",""));
        this.listRole = Object.keys(config.role).map(key => ({ "value" : key, label: config.role[key] }));
        this.listGender = config.genderList.map((gender, index) =>{
            return {
                label : gender, value : parseInt(index)
            }
        })
        this.state = {
            login : search.user || "",
            role : search.role || "",
            gender : search.gender || "",
            isConfirmedPanicMode : search.isConfirmedPanicMode || "",
        };
        this.memberListRef = React.createRef();
    }

    handleSubmitSignin(e) {
        e.preventDefault();
        this.memberListRef.handleSearch(this.state.login, this.state.role, this.state.gender, this.state.isConfirmedPanicMode);
    }

    render() {

        return (
            <div id={"members"}>
                <Form className='mb-4' onSubmit={(e) => this.handleSubmitSignin(e)}>
                    <Form.Row>
                        <Col>
                            <Form.Control placeholder="Rechercher un utilisateur par email / login" value={this.state.login} onChange={(event) => this.setState({login : event.target.value})} />
                        </Col>
                        <Col>
                            <FormFields
                                initialized={true}
                                group={{controlId : "role"}}
                                type={"select"}
                                label={{
                                    label : ""
                                }}
                                controle={{
                                    name : "role",
                                    value : this.state.role,
                                    options : [{value : "", "label" : "Tous les roles"},...this.listRole],
                                    onChange : (event) =>{this.setState({[event.target.name] : event.target.value})}
                                }}
                            />
                        </Col>
                        <Col>
                            <FormFields
                                initialized={true}
                                group={{controlId : "gender"}}
                                type={"select"}
                                label={{
                                    label : ""
                                }}
                                controle={{
                                    name : "gender",
                                    value : this.state.gender,
                                    options : [{value : "", "label" : "Tous les genres"},...this.listGender],
                                    onChange : (event) =>{this.setState({[event.target.name] : event.target.value})}
                                }}
                            />
                        </Col>
                        <Col>
                            <FormFields
                                initialized={true}
                                group={{controlId : "isConfirmedPanicMode"}}
                                type={"select"}
                                label={{
                                    label : ""
                                }}
                                controle={{
                                    name : "isConfirmedPanicMode",
                                    value : this.state.isConfirmedPanicMode,
                                    options : [{value : "", "label" : "Tout les comptes"},{value : true, "label" : "Confirmé mode panic"}, {value : false, "label" : "Non confirmé mode panic"}],
                                    onChange : (event) =>{this.setState({[event.target.name] : event.target.value})}
                                }}
                            />
                        </Col>
                        <Col>
                            <Button variant="primary" type="submit">
                                Rechercher
                            </Button>
                        </Col>
                    </Form.Row>
                </Form>
                <MembersList {...this.props} search={this.state.login} onRef={ref => (this.memberListRef = ref)} />
                <div className={"legends"}>
                    <h3>Légendes</h3>
                    <Col sm={12}>
                        <h6>Background sur l'utilisateur</h6>
                        <ul className={"legend-members"}>
                            <li className={"risk"}>Membre a risque</li>
                            <li className={"panic-mode"}>Membre à confirmer (mode panic ) </li>
                        </ul>
                    </Col>
                </div>
            </div>

        )
    }
}


const mapStateToProps = (state) => {
    return {
        currentUserInitialized: state.currentUser.initialized
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Members);
