import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('MODERATION_CONTENTS').plugin(axiosPlugin, {template: "array"});

let fetchModerationContentsAction = redux.fetchActionGet();

redux.reducerForType('DELETE', (state,payload) => {
    let newState = {...state};
    if(payload.data && payload.data._id){
        newState.data = newState.data.filter(item => item._id.toString() !== payload.data._id.toString());
        newState.total--;
        if(newState.total < 0){
            newState.total = 0;
        }
    }
    return newState;
});

redux.reducerForType('UPDATE_MEDIA', (state,payload) => {
    let newState = {...state};
    if(payload.data && payload.data._id){
        newState.data = newState.data.map(item =>{
            if(item.parentId && item.parentId._id && item.parentId._id.toString() === payload.data._id.toString()){
                item.parentId = payload.data;
            }
            return item;
        });
    }
    return newState;
});

export const TYPES = redux.types;

export const fetchModerationContents = (type = 'avatar',query) => fetchModerationContentsAction(`/moderation-contents/${type}?${queryString.stringify(query)}`);

export const resetModerationContents = () => redux.actions.reset();


export const moderationContents = redux.reducer();