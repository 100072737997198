import React from 'react';
import {Button, Form} from "react-bootstrap";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {updateUserPassword} from "../../creators/user.setPassword";

import {SpinLoader} from "../components/Loader";
import FormFields from "../components/FormFields";


class FormProfilePassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {password : ""};

        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleSubmit(e) {
        e.preventDefault();
        if (!this.props.userPasswordLoading && this.state.password !== "") {
            let data = {password : this.state.password};
            this.props.updateUserPassword(this.props.user._id, data);
            this.setState({password : ""});
        }
    }

    render() {
        return (
            <Form onSubmit={(e) => this.handleSubmit(e)}>
                <FormFields
                    group={{controlId : "password"}}
                    type={"input"}
                    label={{
                        label : "Mot de passe"
                    }}
                    controle={{
                        type : "password",
                        name : "password",
                        autoComplete : "new-password",
                        value : this.state.password,
                        onChange : (event) =>{this.setState({[event.target.name] : event.target.value})}
                    }}
                    text={{
                        text : "Remplir uniquement pour modifier le mot de passe",
                        className : "text-danger"
                    }}
                />
                {this.props.userPasswordError && this.props.userPasswordError.message ? (
                    <div className={"text-danger form-text"}>{this.props.userPasswordError.message}</div>
                ) : null}
                <Button variant="primary" type="submit" className={"btn-loading"}>
                    Valider {this.props.userPasswordLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                </Button>
            </Form>
        )
    }
}


const mapStateToProps = (state) => {

    return {
        user: state.user.data,
        userPassword: state.userSetPassword.data,
        userPasswordError: state.userSetPassword.error,
        userPasswordLoading: state.userSetPassword.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateUserPassword
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormProfilePassword);
