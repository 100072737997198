import React, {Fragment} from 'react';
import {Router, Switch, Route} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import history from '../../../libs/history';
import ContentsAvatar from './Avatar';
import ContentsCover from './Cover';
//import ContentsTemperatures from './Temperatures';
//import ContentsPhotoCertified from './PhotoCertified';
import ContentsCertified from './Certifications';
import ContentsStories from './Stories';
import ContentsStory from './Story';
import ContentsTags from './NouveauxTags';
import ContentsBannedMembers from './BannedMembers';
import ContentsLinkExternal from './LinkExternal';
import ContentsMemberDescription from './MemberDescription';
import SubNavBar from "../../components/SubNavBar";
import {resetModerationContents} from "../../../creators/moderationContents";
import ModerationsWarnings from "./Warnings";
//import TagsMedias from "./TagsMedias";

class Moderations extends React.Component {
    constructor(props) {
        super(props);
        this.pathPage = '/moderations';
        this.subNavBar = [
            {link : `${this.pathPage}`, label : "Avatar"},
            {link : `${this.pathPage}/couvertures`, label : "Couvertures"},
            {link : `${this.pathPage}/certifications`, label : "Certifications"},
            {link : `${this.pathPage}/histoires`, label : "Histoires"},
            {link : `${this.pathPage}/nouveaux-tags`, label : "Nouveaux Tags"},
            {link : `${this.pathPage}/avertissements`, label : "Messages de modération"},
            {link : `${this.pathPage}/membres-bannis`, label : "Membres bannis"},
            {link : `${this.pathPage}/link-external`, label : "Lien externe"},
            {link : `${this.pathPage}/membre-description`, label : "Description des Membres"},
        ]
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(nextProps.location !== this.props.location){
            this.props.resetModerationContents();
            return true;
        }
        return false;
    }

    componentWillUnmount(){
        this.props.resetModerationContents();
    }

    render() {
        return (
            <Fragment>
                <SubNavBar {...this.props} subNav={this.subNavBar}/>
                <Router history={history}>
                    <Switch>
                        <Route exact path={`${this.pathPage}/couvertures`} component={ContentsCover}/>
                        <Route exact path={`${this.pathPage}/avertissements`} component={ModerationsWarnings}/>
                        <Route exact path={`${this.pathPage}/certifications`} component={ContentsCertified}/>
                        <Route exact path={`${this.pathPage}/link-external`} component={ContentsLinkExternal}/>
                        <Route exact path={`${this.pathPage}/membre-description`} component={ContentsMemberDescription}/>
                        <Route exact path={`${this.pathPage}/nouveaux-tags`} component={ContentsTags}/>
                        <Route exact path={`${this.pathPage}/histoires/:page([0-9]+)?`} component={ContentsStories}/>
                        <Route exact path={`${this.pathPage}/histoires/:storyId`} component={ContentsStory}/>
                        <Route exact path={`${this.pathPage}/membres-bannis/:page([0-9]+)?`} component={ContentsBannedMembers}/>
                        <Route exact path={`${this.pathPage}`} component={ContentsAvatar}/>

                    </Switch>
                </Router>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        currentUserInitialized: state.currentUser.initialized
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    resetModerationContents
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Moderations);
