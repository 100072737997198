import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('STORY').plugin(axiosPlugin);

let fetchStoryAction = redux.fetchActionGetOne();

let createStoryAction = redux.fetchActionCreate();

let updateStoryAction = redux.fetchActionUpdate();

let deleteStoryAction = redux.fetchActionDelete();

export const TYPES = redux.types;

export const fetchStory = (id) => fetchStoryAction(`/stories/${id}`);
export const createStory = (data) => createStoryAction(`/stories`, data);
export const updateStory = (id, data) => updateStoryAction(`/stories/${id}`, data);
export const deleteStory = (id) => deleteStoryAction(`/stories/${id}`);
export const resetStory = () => redux.actions.reset();

export const story = redux.reducer();