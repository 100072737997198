import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Aqp from '../../libs/mk-react-aqp';

import {PageLoader, LoadingBar} from "../components/Loader";
import { Row} from "react-bootstrap";
// import createLink from "../../libs/createLink";
import {fetchTagsListes, resetTagsListes} from "../../creators/tags.listes";
import Pagination from "../components/Pagination";

let mapping = {
    "sort" : "trie"
};

class tagsList extends React.Component {
    constructor(props) {
        super(props);
        this.filter = {
            limit: 20,
            page: 1,
            sort: "createdAt",
        };
        this.aqp = new Aqp(mapping, {
            blacklist: ['limit', 'page'],
            currentLink: "/tags/list-tag",
            filterDefault: this.filter
        });

        this.state = {
            filter: {...this.filter, ...this.aqp.query, page: this.props.match.params.page || 1},
        };
        this.handleChangePage = this.handleChangePage.bind(this);
        this.fetchTagsListes(this.state.filter);

    }

    fetchTagsListes(filter){
        this.props.fetchTagsListes(this.aqp.set(filter));
    }

    componentWillUnmount() {
        this.props.resetTagsListes();
    }

    handleChangePage(page, link) {
        let filter = {...this.state.filter, page};
        if (page === 1) {
            this.props.history.push(link);
        } else {
            this.props.history.push(link + '/' + page);
        }
        this.setState({filter});
        this.fetchTagsListes(filter);
        window.scrollTo(0, 0);
    }


    render() {
        let totalPage = this.props.tagsListesTotal ? Math.ceil(this.props.tagsListesTotal / this.state.filter.limit) : 0;
        return !this.props.tagsListesInitialized ? (<PageLoader />) :(
            <Fragment>
                <Row >
                    {this.props.tagsListesLoading ? (<LoadingBar />) : (
                        this.props.tagsListes.map(item =>{
                            return <a key={item._id} href={`/tags/list-tag/${item._id}`} className="list-group-item list-group-item-action list-group-item-light">{item.title}</a>
                        })
                    )}
                </Row>
                <Pagination currentPage={this.state.filter.page}
                            pagesCount={totalPage}
                            {...this.aqp.queryUrl(this.state.filter, mapping)}
                            handleChangePage={this.handleChangePage}/>
            </Fragment>

        )
    }
}


const mapStateToProps = (state) => {
    return {
        tagsListes : state.tagsListes.data,
        tagsListesTotal : state.tagsListes.total,
        tagsListesLoading : state.tagsListes.loading,
        tagsListesInitialized : state.tagsListes.initialized
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchTagsListes,
    resetTagsListes
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(tagsList);
