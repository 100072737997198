import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('KEYWORDS_WARNING').plugin(axiosPlugin);

let fetchAction = redux.fetchActionGetOne();

let updateAction = redux.fetchActionUpdate();


export const TYPES = redux.types;

export const fetchKeywordsWarning = (id) => fetchAction(`/keywords-warnings/${id}`);
export const confirmedKeywordsWarning = (id, data) => updateAction(`/keywords-warnings/${id}/confirmed-spam`, data);
export const cancelKeywordsWarning = (id) => updateAction(`/keywords-warnings/${id}/cancel-spam`);
export const resetKeywordsWarning = () => redux.actions.reset();

export const keywordsWarning = redux.reducer();