import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('STATS_SITE_MONTH').plugin(axiosPlugin, {template: "array"});

let fetchStatsSiteAction = redux.fetchActionGet();

export const fetchStatsSiteMonth = (query = {}) => fetchStatsSiteAction(`/stats/current-year`);
export const resetStatsSiteMonth = () => redux.actions.reset();

export const statsSiteMonth = redux.reducer();