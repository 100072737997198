import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('USER_QUESTION').plugin(axiosPlugin);

let createUserQuestionAction = redux.fetchActionCreate();

let deleteUserQuestionAction = redux.fetchActionDelete();

export const TYPES = redux.types;

export const createUserQuestion = (userId,data = {}) => createUserQuestionAction(`/users/${userId}/questions`, data);
export const deleteUserQuestion = (userId,questionId = {}) => deleteUserQuestionAction(`/users/${userId}/questions/${questionId}`);
export const resetUserQuestion = () => redux.actions.reset();

export const userQuestion = redux.reducer();