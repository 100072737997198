import errorSkeleton from "../libs/errorSkeleton";

export const RULES = 'RULES';
export const RULES_SUCCESS = 'RULES_SUCCESS';
export const RULES_FAIL = 'RULES_FAIL';
export const RULES_RESET = 'RULES_RESET';
export const RULES_SET = 'RULES_SET';

const INITIAL_STATE = {
    loading: false,
    data: [],
    error: errorSkeleton,
    initialized: false
};

export default function reducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case RULES:
            return {...state, loading: true};
        case RULES_SUCCESS:
            return {...state, loading: false, error: errorSkeleton, data: action.payload, initialized: true};
        case RULES_SET:
            return {...state, data: action.rules, initialized: true};
        case RULES_FAIL:
            return {
                loading: false,
                error: action.error.response,
            };
        case RULES_RESET:
            return INITIAL_STATE;
        default:
            return state;
    }
};