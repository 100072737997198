import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Form, Col, Button} from "react-bootstrap";
import querystring from 'querystring';
import FacettesList from "./components/FacettesList";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import FormFields from "../../components/FormFields";
import {fetchTypes} from "../../../creators/facettes.types";
import {resetFacettes} from "../../../creators/facettes";

class Facettes extends React.Component {
    constructor(props) {
        super(props);
        let {searchUri = "", isActive = "false", isNotValid = "false", noIndexable = "false", isDeleted = "false", typeId = ""} = querystring.parse(this.props.location.search.replace("?",""));
        this.state = {
            searchUri,
            isActive : isActive === "true" ? true : false,
            isNotValid : isNotValid === "true" ? true : false,
            noIndexable : noIndexable === "true" ? true : false,
            isDeleted : isDeleted === "true" ? true : false,
            typeId
        };
        this.facetteListRef = React.createRef();
        this.props.fetchTypes();
    }

    componentWillUnmount(){
        this.props.resetFacettes();
    }

    resetSearchUri(){
        this.setState({searchUri : ""});
        this.facetteListRef.handleSearch("");
    }

    handleSubmitSignin(e) {
        e.preventDefault();
        this.facetteListRef.handleSearch(this.state.searchUri, {
            isActive : this.state.isActive,
            isNotValid : this.state.isNotValid,
            noIndexable : this.state.noIndexable,
            isDeleted : this.state.isDeleted,
            typeId : this.state.typeId
        });
    }


    render() {
        return (
            <Fragment>
                <Form className='mb-4' onSubmit={(e) => this.handleSubmitSignin(e)}>
                    <Form.Row>
                        <Col md={11}>
                            <Form.Control placeholder="Rechercher URI" value={this.state.searchUri} onChange={(event) => this.setState({searchUri : event.target.value})} />
                        </Col>
                        <Col md={1} className={"d-flex align-items-center "}>
                            {this.state.searchUri ? <FontAwesomeIcon icon={faTimes} className={"icon-hover text-danger cursor-pointer"} onClick={()=>this.resetSearchUri()}/> : null}
                        </Col>
                    </Form.Row>
                    <Form.Row className={"mt-4"}>
                        <Col>
                            <FormFields
                                type={"checkbox"}
                                checkBox={{
                                    id : `checkbox-isActive`,
                                    name : "isActive",
                                    checked : this.state.isActive,
                                    label : "Actives ?",
                                    onChange : (e) =>{this.setState({isActive : !this.state.isActive})}
                                }}
                            />
                        </Col>
                        <Col>
                            <FormFields
                                type={"checkbox"}
                                checkBox={{
                                    id : `checkbox-isNotValid`,
                                    name : "isNotValid",
                                    checked : this.state.isNotValid,
                                    label : "Invalides ?",
                                    onChange : (e) =>{this.setState({isNotValid : !this.state.isNotValid})}
                                }}
                            />
                        </Col>
                        <Col>
                            <FormFields
                                type={"checkbox"}
                                checkBox={{
                                    id : `checkbox-deleted`,
                                    name : "deleted",
                                    checked : this.state.isDeleted,
                                    label : "Supprimés ?",
                                    onChange : (e) =>{this.setState({isDeleted : !this.state.isDeleted})}
                                }}
                            />
                        </Col>
                        <Col>
                            <FormFields
                                type={"checkbox"}
                                checkBox={{
                                    id : `checkbox-noIndexable`,
                                    name : "noIndexable",
                                    checked : this.state.noIndexable,
                                    label : "Non Indesable ?",
                                    onChange : (e) =>{this.setState({noIndexable : !this.state.noIndexable})}
                                }}
                            />
                        </Col>
                    </Form.Row>
                    <Form.Row className={"mt-4"}>
                        <Col>
                            <FormFields
                                initialized={!this.props.typesLoading}
                                type={"select"}
                                label={{
                                    label : "type Uri"
                                }}
                                controle={{
                                    name : "typeId",
                                    value : this.state.typeId,
                                    options : [{_id : "", uri : "Tous"}, ...this.props.types],
                                    disabled : this.props.types.length ? false : true,
                                    optionField : {value : "_id", label : "uri"},
                                    onChange : (e) =>{this.setState({typeId : e.target.value})}
                                }}
                            />
                        </Col>
                    </Form.Row>
                    <Form.Row className={"mt-4"}>
                        <Col>
                            <Button variant="primary" type="submit">
                                Rechercher
                            </Button>
                        </Col>
                    </Form.Row>
                </Form>
                <FacettesList {...this.props} searchUri={this.state.searchUri}  onRef={ref => (this.facetteListRef = ref)} />
            </Fragment>

        )
    }
}


const mapStateToProps = (state) => {
    return {
        types : state.facettesTypes.data,
        typesInitialized : state.facettesTypes.initialized,
        typesLoading : state.facettesTypes.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchTypes,
    resetFacettes
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Facettes);
