import {errorSkeleton} from "../../error";

const itemInitialState = {
    loading: false,
    submitting: false,
    hasError: false,
    data: [],
    more: false,
    total: 0,
    error: errorSkeleton,
    initialized: false
};

const reducer = (state, {key, ...action}) => {
    let current = state[key] ? {...state[key]} : itemInitialState;
    return {
        ...state,
        [key]: {
            ...current,
            loading: true,
            submitting: ['POST', 'PATCH', 'DELETE'].includes(action.payload.request.method)
        }
    }
};

const reducerSuccess = (state, action) => {
    let key = action.meta.previousAction.key;
    return {
        ...state,
        [key]: {
            ...state[key],
            ...action.payload,
            data: action.meta.previousAction.more ? [...state[key].data, ...action.payload.data] : action.payload.data,
            error: errorSkeleton,
            initialized: true,
            loading: false,
            submitting: false,
            hasError: false
        }
    }
};

const reducerFail = (state, action) => {
    let key = action.meta.previousAction.key;
    return {
        ...state,
        [key]: {
            ...state[key],
            error: action.error.response,
            loading: false,
            submitting: false,
            hasError: true
        }
    }
};

export const INITIAL_STATE = {
    default: itemInitialState
};

export default function () {
    return {
        INITIAL_STATE,
        reducer,
        reducerSuccess,
        reducerFail
    };
}
