import React from 'react';
import _ from 'lodash';
import withUpload from "../../hoc/withUpload";
import {Button, InputGroup} from "react-bootstrap";
import FormFields from "../FormFields";

class Uploader extends React.Component {
    constructor(props){
        super(props);
        this.id = _.random(0, 99999);
        this.state = {
            errorMessage: '',
            preview: this.props.preview,
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.preview !== this.props.preview){
            this.setState({preview: this.props.preview});
        }
    }

    onUploadError({response}){
        this.props.reset();
        let message = '';
        switch (response.statusCode) {
            case 413:
                message = `Fichier trop lourd, maximum accepté : ${response.errors.maxFileSizeStr}`;
                break;
            case 415:
                message = `Fichier non accepté, sont acceptés : ${response.errors.allowedTypes.join(', ')}`;
                break;
            default :
                message = 'Envoi du fichier impossible, erreur système inconnue';
                break;
        }
        this.setState({errorMessage: message, preview: ""})
    }

    onUploadSuccess(data){
        this.props.getUploadData && this.props.getUploadData(data, this.state.preview);
        this.props.reset();
        this.props.handleClose();
    }

    render(){
        return <div id={"uploads"} className={"modal-container"}>
            <div className={"modal-topline"}>
                {`Télécharger une photo`}
            </div>
            <div className={"modal-body"}>
                {this.props.preview &&
                    <div className={"img-preview-container mb-2"}>
                        <img alt={""} className={`img-preview`} src={this.props.preview} />
                    </div>
                }
                <FormFields
                    key={this.props.loadedFile.name}
                    group={{controlId : "url"}}
                    type={"input"}
                    controle={{
                        id : this.id,
                        type : "file",
                        name : "media",
                        style : {display:'none'},
                        accept : "image/jpeg, image/png, image/gif",
                        disabled : this.props.uploading,
                        onChange : this.props.loadFile.bind(this)
                    }}
                />

                <label htmlFor={this.id} className={"upload-input-btn"}>
                    <div className="btn white w-100 text-white cursor-pointer"
                        style={{background: `linear-gradient(to right,#51a9fd ${this.props.uploadProgress.percentCompleted}%,#1F7DEC ${this.props.uploadProgress.percentCompleted}%)`}}
                    >
                        {this.props.uploading ? (
                            `Envoi... ${Math.round(this.props.uploadProgress.eta / 1000)}s (${this.props.uploadProgress.kos}ko/s)`
                        ) : (
                            _.truncate(this.props.loadedFile.name, {length:this.props.nameLength || 25}) || 'Choisissez un fichier'
                        )}
                    </div>
                </label>
            </div>
            <div className={"modal-baseline"}>
                <InputGroup className={"flex justify-content-between"}>
                {this.state.errorMessage && <span className={"warning upload-error-message w-100"}>{this.state.errorMessage}</span>}
                {!this.props.uploading &&
                <Button variant="danger" type="submit" className={"btn-loading"} onClick={()=>this.props.handleClose()} >
                    Fermer
                </Button>
                }
                {this.props.loadedFile.name && (
                    this.props.uploading ?
                        <Button variant="primary" type="submit" className={"btn-loading"} onClick={()=>this.props.reset()} >
                            Ajouter
                        </Button>
                        :
                        <Button variant="primary" type="submit" className={"btn-loading"} onClick={()=>this.props.uploadFile()} >
                            Envoyer
                        </Button>
                )}
                </InputGroup>
            </div>
        </div>
    }
}

export default withUpload(Uploader);