import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('USERS').plugin(axiosPlugin, {template: "array"});

let fetchUsersAction = redux.fetchActionGet();

redux.reducerForType('UPDATE', (state,payload) => {
    let newState = {...state, lastUpdate : Date.now()};
    if(payload.data && payload.data._id){
        newState.data = newState.data.map(item => {
            if(item._id.toString() === payload.data._id.toString()){
                item = payload.data;
            }
            return item;
        });
    }
    return newState;
});

export const TYPES = redux.types;

export const fetchUsers = (query) => fetchUsersAction(`/users?${queryString.stringify(query)}`);

export const resetUsers = () => redux.actions.reset();

export const users = redux.reducer();