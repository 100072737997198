import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button, Modal, Row, Table} from "react-bootstrap";
import {LoadingBar} from "../../components/Loader";
import ColoneTn from "../../components/ColoneTh";
import {fetchUserWarnings, resetUserWarnings} from "../../../creators/user.warnings";
import {Can} from "../../../ability-context";
import DateSince from "../../components/Date";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from "lodash";
import FormWarning from "../../formulaires/warning";
import {deleteUserWarningAccepted} from "../../../creators/user.warning";
import Warning from "../../components/Warning";


class Warnings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleModalCreated : false,
            toggleModalInfo : false,
            toggleAlert : false,
            currentId : null
        };

        this.props.fetchUserWarnings(this.props.user._id, {sort : "createdAt", limit : 100});
        this.handleDelete = this.handleDelete.bind(this);
    }

    componentWillUnmount() {
        this.props.resetUserWarnings();
    }

    handleDelete(){
        let currentId = this.state.currentId;
        this.props.deleteUserWarningAccepted(this.props.user._id, currentId);
        this.setState({toggleAlert : false, currentId : null});
    }

    currentWarning(){
        let currentId = this.state.currentId;
        if(currentId){
            let currentWarning = this.props.warnings.filter(warning => warning._id.toString() === currentId.toString());
            if(currentWarning.length){
                return currentWarning[0];
            }
        }
        return {}
    }



    render() {

        return (
            <Fragment>
                <Row className={"mt-5 p-2"}>
                    <h4 className={"w-100"}>Messages de modération</h4>
                    <div className={"w-100 mb-4"}>
                        <Can action={"create"} subject="Warning" >
                            <Button variant="primary" onClick={()=>this.setState({toggleModalCreated : true})} className={"btn-loading"}>
                                Avertir le membre
                            </Button>
                        </Can>
                    </div>
                    {!this.props.warningsInitialized ? (<LoadingBar/>) : (
                        this.props.warningsTotal > 0
                            ? (
                                <Fragment>
                                    {this.props.warningsLoading && (<LoadingBar />)}
                                    <Table striped bordered hover>
                                        <thead>
                                        <tr>
                                            <ColoneTn label={"Date"} />
                                            <ColoneTn label={"Raison"} />
                                            <ColoneTn label={"Par"} />
                                            <ColoneTn label={"Détails"} />
                                            <ColoneTn label={"Accpeter"}  />
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {this.props.warnings.map(warning =>(
                                            <tr key={warning._id}>
                                                <td><DateSince date={warning.createdAt} currentFormat={false}/></td>
                                                <td>{_.get(warning,"tag.title") || "Non défini"}</td>
                                                <td>{_.get(warning,"moderator.username") || "Non défini"}</td>
                                                <td>
                                                    <Button onClick={()=>this.setState({toggleModalInfo : true, currentId : warning._id})}>Plus de détails</Button>
                                                </td>
                                                <td>{warning.isAccepted ? "Oui" : "Non"}</td>
                                                <td>
                                                    <FontAwesomeIcon
                                                        icon="trash"
                                                        className={"cursor-pointer"}
                                                        onClick={()=>this.setState({toggleAlert : true, currentId : warning._id})}
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                        </tbody>
                                    </Table>

                                    {/*<Pagination currentPage={this.state.filter.page}
                                                pagesCount={totalPage}
                                                {...this.aqp.queryUrl(this.state.filter, mapping)}
                                                handleChangePage={this.handleChangePage}/>*/}

                                </Fragment>
                            )
                            : (
                                <div >Aucun message de modération</div>
                            )
                    )
                    }
                </Row>

                <Modal id={"warning"} centered backdrop={false} show={this.state.toggleModalCreated} onHide={()=>this.setState({toggleModalCreated : false})}>
                    <FormWarning  onHide={()=>this.setState({toggleModalCreated : false})} />
                </Modal>

                <Modal id={"warning-info"} size={"xl"} centered backdrop={false} show={this.state.toggleModalInfo} onHide={()=>this.setState({toggleModalInfo : false, currentId : null})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Message de modération</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {this.state.toggleModalInfo ? <Warning test={"jkj"} {...this.currentWarning()} /> : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger" onClick={()=>this.setState({toggleModalInfo : false, currentId : null})}>
                            Fermer
                        </Button>
                    </Modal.Footer>
                </Modal>

                <Modal centered backdrop={false} show={this.state.toggleAlert} onHide={()=>this.setState({toggleAlert : false, currentId : null})}>
                    <Modal.Header closeButton>
                        <Modal.Title>SUPPRESSION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className={"text-danger font-weight-bold"}>Etes vous sur ?</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.setState({toggleAlert : false, currentId : null})}>
                            Non
                        </Button>
                        <Button variant="danger" onClick={()=>this.handleDelete()}>
                            Oui
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        warnings : state.userWarnings.data,
        warningsInitialized: state.userWarnings.initialized,
        warningsLoading: state.userWarnings.loading,
        warningsTotal: state.userWarnings.total,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUserWarnings,
    resetUserWarnings,
    deleteUserWarningAccepted
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Warnings);
