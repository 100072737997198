import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('TAGS').plugin(axiosPlugin, {template: "array"});

let fetchTagsAction = redux.fetchActionGet();

export const fetchTags = (query = {}) => fetchTagsAction(`/tags?${queryString.stringify(query)}`);
export const resetTags = () => redux.actions.reset();

export const tags = redux.reducer();