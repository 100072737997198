import React, {Fragment} from 'react';
import {Router, Switch, Route} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import history from '../../../libs/history';
import SubNavBar from "../../components/SubNavBar";
import Facettes from "./Facettes";
import Categories from "./Categories";
import Types from "./Types";


class Index extends React.Component {
    constructor(props) {
        super(props);
        this.pathPage = '/moteur-a-facettes';
        this.subNavBar = [
            {link : `${this.pathPage}`, label : "Types"},
            {link : `${this.pathPage}/facettes`, label : "Facettes"},
            {link : `${this.pathPage}/categories`, label : "Categories"},
        ]
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(nextProps.location !== this.props.location){
            // this.props.resetModerationContents();
            return true;
        }
        return false;
    }


    render() {
        return (
            <Fragment>
                <SubNavBar {...this.props} subNav={this.subNavBar}/>
                <Router history={history}>
                    <Switch>
                        <Route path={`${this.pathPage}/categories`} exact component={Categories}/>
                        <Route path={`${this.pathPage}/facettes/:page([0-9]+)?`} exact component={Facettes}/>
                        <Route path={`${this.pathPage}`} exact component={Types}/>
                    </Switch>
                </Router>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        currentUserInitialized: state.currentUser.initialized
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Index);
