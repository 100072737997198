import React, {Fragment} from 'react';
import FormFields from "../components/FormFields";
import {Col, Row} from "react-bootstrap";


const TagInfo = (props) => {
    let {
        kind = {kind : "", label : ""},
        description = "",
        title = "",
        count = 0,
        weight = 0,
        indexSeo = 0
    } = props;
    return (
        <Fragment>
            <h5>{kind.label}</h5>
            <Col sm={12} >
                <FormFields
                    group={{controlId : `${kind.kind}_title`}}
                    type={"input"}
                    label={{
                        label : "Titre"
                    }}
                    controle={{
                        type : "text",
                        name : "title",
                        value : title,
                        onChange : (event) =>{props.handleValue(kind.kind,[event.target.name],event.target.value)}
                    }}
                />
            </Col>
            <Col sm={12} >
                <FormFields
                    group={{controlId : `${kind.kind}_description`}}
                    type={"input"}
                    label={{
                        label : "Description"
                    }}
                    controle={{
                        type : "text",
                        as : "textarea",
                        name : "description",
                        value : description,
                        onChange : (event) =>{props.handleValue(kind.kind,[event.target.name],event.target.value)}
                    }}
                />
            </Col>
            <Row className={"p-3"}>
                <Col sm={4} >
                    <FormFields
                        group={{controlId : `${kind.kind}_indexSeo`}}
                        type={"select"}
                        label={{
                            label : "Index Seo ?"
                        }}
                        controle={{
                            name : "indexSeo",
                            value : indexSeo,
                            options : [{value : true, label : "Oui"}, {value : false, label : "Non"}],
                            onChange : (event) =>{props.handleValue(kind.kind,[event.target.name],event.target.value)}
                        }}
                    />
                </Col>
                <Col sm={4} >
                    <FormFields
                        group={{controlId : `${kind.kind}_count`}}
                        type={"input"}
                        label={{
                            label : "Count"
                        }}
                        controle={{
                            type : "number",
                            name : "count",
                            value : count,
                            onChange : (event) =>{props.handleValue(kind.kind,[event.target.name],event.target.value)}
                        }}
                    />
                </Col>
                <Col sm={4} >
                    <FormFields
                        group={{controlId : `${kind.kind}_weight`}}
                        type={"input"}
                        label={{
                            label : "weight"
                        }}
                        controle={{
                            type : "number",
                            name : "weight",
                            value : weight,
                            onChange : (event) =>{props.handleValue(kind.kind,[event.target.name],event.target.value)}
                        }}
                    />
                </Col>
            </Row>
        </Fragment>

    )
};
export default TagInfo;