import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Table} from "react-bootstrap";
import {LoadingBar, PageLoader} from "./Loader";
import DateSince from "./Date";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {fetchUsers} from "../../creators/users";
import {config} from "../../config";
import Aqp from '../../libs/mk-react-aqp';

import Pagination from "./Pagination";
import ColoneTn from "./ColoneTh";
import createLink from "../../libs/createLink";
import Icon from "./Icon";
import {updateUserModePanic} from "../../creators/user";


let mapping = {
    "sort" : "trie",
    "search" : "user"
};

class MembersList extends React.Component {
    constructor(props) {
        super(props);
        this.filter = {
            limit: 20,
            page: 1,
            sort: "-createdAt",
            search : ""
        };

        this.aqp = new Aqp(mapping, {
            blacklist: ['limit', 'page'],
            currentLink: "/members",
            filterDefault: this.filter
        });

        this.state = {
            filter: {...this.filter, ...this.aqp.query, page: this.props.match.params.page || 1}
        };

        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSort = this.handleSort.bind(this);
        if(this.props.onRef){
            this.props.onRef(this);
        }

        this.fetchUsers(this.state.filter);
    }

    componentWillUnmount() {
        if(this.props.onRef){
            this.props.onRef(undefined)
        }
    }

    fetchUsers(filter){
        this.props.fetchUsers(this.aqp.set(filter));
    }

    handleSearch(search, role, gender, isConfirmedPanicMode){
        let filter = {...this.state.filter, page : 1, search, role, gender, isConfirmedPanicMode};
        if(filter.role === ""){
            delete filter.role;
        }
        if(filter.gender === ""){
            delete filter.gender;
        }
        if(filter.search === ""){
            delete filter.search;
        }
        if(filter.isConfirmedPanicMode === ""){
            delete filter.isConfirmedPanicMode;
        }
        this.props.history.push("/members");
        this.setState({filter});
        this.fetchUsers(filter);
        window.scrollTo(0, 0);
    }

    handleSort(value){
        let filter = {...this.state.filter, page : 1, sort : value};
        this.props.history.push("/members");
        this.setState({filter});
        this.fetchUsers(filter);
        window.scrollTo(0, 0);
    }

    handleChangePage(page, link) {
        let filter = {...this.state.filter, page};
        if (page === 1) {
            this.props.history.push(link);
        } else {
            this.props.history.push(link + '/' + page);
        }
        this.setState({filter});
        this.fetchUsers(filter);
        window.scrollTo(0, 0);
    }


    render() {
        let totalPage = this.props.usersTotal ? Math.ceil(this.props.usersTotal / this.state.filter.limit) : 0;
        return !this.props.usersInitialized ? (<PageLoader />) : (
            <Fragment>
                {this.props.usersLoading && (<LoadingBar />)}
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <ColoneTn label={"Pseudo"} currentSort={this.state.filter.sort} sort={"username"} handleSort={this.handleSort}  />

                        <ColoneTn label={"Email"} currentSort={this.state.filter.sort} sort={"email.address"} handleSort={this.handleSort}  />
                        <ColoneTn label={"IP"}  />
                        <ColoneTn label={"Genre"} currentSort={this.state.filter.sort} sort={"profile.gender"} handleSort={this.handleSort}  />
                        <ColoneTn label={"Groupe"} currentSort={this.state.filter.sort} sort={"role"} handleSort={this.handleSort}  />
                        <ColoneTn label={"Inscription"} currentSort={this.state.filter.sort} sort={"createdAt"} handleSort={this.handleSort}  />
                        <ColoneTn label={"Activité"} currentSort={this.state.filter.sort} sort={"lastActivity"} handleSort={this.handleSort}  />
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.users.map(user =>(
                        <tr key={user._id} className={`${user.isRisk ? "risk" : ""} ${user.isConfirmedPanicMode ? "" : "panic-mode"}`}>
                            <td><a href={createLink("userProfile", user)} target={"new"}>{user.username}</a></td>
                            <td>{user.email && user.email.address}</td>
                            <td>{user.ip} {user.isocodeIp ? `- ${user.isocodeIp}` : ""}</td>
                            <td>{config.genderList[user.profile.gender]}</td>
                            <td>{config.role[user.role]}</td>
                            <td><DateSince date={user.createdAt} currentFormat={false}/></td>
                            <td><DateSince date={user.lastActivity} currentFormat={false}/></td>
                            <td className={`${user.isConfirmedPanicMode ? "" : "action-panic"}`}>
                                {!user.isConfirmedPanicMode ? (
                                    <span className="btn btn-outline-success btn-sm mr-1 cursor-pointer" title={"Retirer les restrictions du Panic Mode"} onClick={()=>this.props.updateUserModePanic(user._id)}>
                                        <Icon type={"unlock"} iconSize={"16px"} />
                                    </span>
                                    )
                                : null
                                }
                                <Link to={`/members/${user._id}`} className="btn btn-outline-primary btn-sm">
                                    <FontAwesomeIcon
                                        icon="edit"
                                    />
                                </Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <Pagination currentPage={this.state.filter.page}
                            pagesCount={totalPage}
                            {...this.aqp.queryUrl(this.state.filter, mapping)}
                            handleChangePage={this.handleChangePage}/>

            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        usersInitialized: state.users.initialized,
        usersLoading: state.users.loading,
        users: state.users.data,
        usersTotal: state.users.total
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUsers,
    updateUserModePanic
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MembersList);
