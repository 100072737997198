import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {Modal} from "react-bootstrap";
import {deleteType, fetchType, resetType, updateType} from "../../../../creators/facettes.type";
import FormFacettesType from "../../../formulaires/facettes.type";

class EditType extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleModalEdit : this.props.toggle || false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.props.fetchType(this.props.currentId);
    }

    componentWillUnmount(){
        this.handleToggle();
    }

    handleToggle(){
        this.setState({toggleModalEdit : false});
        this.props.handleToggle();
    }

    handleDelete(){
        this.props.deleteType(this.props.currentId);
        this.handleToggle();
    }

    handleSubmit(data) {
        let newData = {
            tpl : data.tpl
        }
        if (!this.props.facettesTypeLoading) {
            this.props.updateType(this.props.facettesType._id,newData);
            this.handleToggle();
        }
    }

    render() {

        return (
            <Fragment>
                {this.props.facettesTypeInitialized ? (
                    <Modal centered backdrop={false} show={this.state.toggleModalEdit} onHide={()=>this.handleToggle()}>
                        <FormFacettesType
                            handleSubmit={this.handleSubmit}
                            onHide={()=>this.handleToggle()}
                            _id={this.props.facettesType._id}
                            uri={this.props.facettesType.uri}
                            tpl={this.props.facettesType.tpl}
                            handleDelete={()=>this.handleDelete()}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        facettesType : state.facettesType.data,
        facettesTypeInitialized : state.facettesType.initialized,
        facettesTypeLoading : state.facettesType.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateType,
    resetType,
    fetchType,
    deleteType
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditType);
