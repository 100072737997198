import React from "react";
import Icon from "../../Icon";

class HeaderStyleDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggle: false
        }
    }

    onToggle(value) {
        this.setState({toggle: !this.state.toggle});
        this.props.onToggle(value);
    };

    toggleDropdown() {
        this.setState({toggle: !this.state.toggle});
    }

    render() {
        let currentHeader = this.props.headerOptions.find(header => header.style === this.props.active);
        return (
            <div className="mk-editor-btn-group">
                <span className={`mk-editor-btn dropdown-toggle ${this.state.toggle && 'active'}`}
                        onClick={this.toggleDropdown.bind(this)}>
                    {currentHeader ? currentHeader.labelTxt : 'Autre'}
                    <Icon type={"arrow-down"} />
                    <span className={"full"}>
                    <div className="dropdown-menu" style={{display: this.state.toggle ? 'block' : 'none'}}>
                        {this.props.headerOptions.map(heading => {
                            return <span
                                key={heading.style}
                                style={{cursor: 'pointer'}}
                                className={`dropdown-item pt-2 ${(this.props.active === heading.style) && 'active'}`}
                                onClick={() => this.onToggle(heading.style)}
                                dangerouslySetInnerHTML={{__html: heading.label}}
                            />;
                        })}
                    </div>
                </span>
                </span>
            </div>
        );
    }
}

export default HeaderStyleDropdown;