import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('USER_ALBUMS').plugin(axiosPlugin, {template: "array"});

let fetchUserAlbumsAction = redux.fetchActionGet();
//
// redux.reducerForType('ADD_MEDIA', (state,payload) => {
//     let newState = {...state};
//     if(Array.isArray(payload.data)){
//         payload.data.forEach(data =>{
//             newState.data = newState.data.map(item => {
//                 if(item._id.toString() === data.album.toString()){
//                     if(data.kind === "gallery"){
//                         item.count.photo = item.count.photo+1;
//                     }
//                     else if(data.kind === "video"){
//                         item.count.video = item.count.video+1;
//                     }
//                 }
//                 return item;
//             });
//         })
//     }
//     else if(payload.data && payload.data._id){
//         newState.data = newState.data.map(item => {
//             if(item._id.toString() === payload.data.album.toString()){
//                 if(payload.data.kind === "gallery"){
//                     item.count.photo = item.count.photo+1;
//                 }
//                 else if(payload.data.kind === "video"){
//                     item.count.video = item.count.video+1;
//                 }
//             }
//             return item;
//         });
//     }
//     return newState;
// });
//
// redux.reducerForType('DELETE_MEDIA', (state,payload) => {
//     let newState = {...state};
//     if(payload.data && payload.data._id){
//         newState.data = newState.data.map(item => {
//             if(item._id.toString() === payload.data.album.toString()){
//                 if(payload.data.kind === "gallery"){
//                     item.count.photo = item.count.photo-1;
//                 }
//                 else if(payload.data.kind === "video"){
//                     item.count.video = item.count.video-1;
//                 }
//             }
//             return item;
//         });
//     }
//     return newState;
// });

//
// redux.reducerForType('ADD', (state,payload) => {
//     let newState = {...state};
//     newState.data.unshift(payload.data);
//     newState.total++;
//     return newState;
// });
//
// redux.reducerForType('DELETE', (state,payload) => {
//     let newState = {...state};
//     if(payload.data && payload.data._id){
//         newState.data = newState.data.filter(item => item._id.toString() !== payload.data._id.toString());
//         newState.total--;
//         if(newState.total < 0){
//             newState.total = 0;
//         }
//     }
//     return newState;
// });
//
// redux.reducerForType('UPDATE', (state,payload) => {
//     let newState = {...state};
//     if(payload.data && payload.data._id){
//         newState.data = newState.data.map(item => {
//             if(item._id.toString() === payload.data._id.toString()){
//                 item = payload.data;
//             }
//             return item;
//         });
//     }
//     return newState;
// });

export const fetchUserAlbums = (userId, query = {}) => fetchUserAlbumsAction(`/users/${userId}/albums?${queryString.stringify(query)}`);
export const resetUserAlbums = () => redux.actions.reset();

export const userAlbums = redux.reducer();