import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('FACETTE').plugin(axiosPlugin);

let fetchAction = redux.fetchActionGetOne();

let updateAction = redux.fetchActionUpdate();


export const TYPES = redux.types;

export const fetchFacette = (facetteId, query = {}) => fetchAction(`/facettes/${facetteId}?${queryString.stringify(query)}`);
export const updateFacette = (facetteId, data = {}) => updateAction(`/facettes/${facetteId}`, data);
export const resetFacette = () => redux.actions.reset();

export const facette = redux.reducer();