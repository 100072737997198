import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('WARNINGS').plugin(axiosPlugin, {template: "array"});

let fetchWarningsAction = redux.fetchActionGet();


export const fetchWarnings = (query = {}) => fetchWarningsAction(`/warnings/admin?${queryString.stringify(query)}`);
export const resetWarnings = () => redux.actions.reset();

export const warnings = redux.reducer();