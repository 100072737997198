
const config = {
    urlSiteVoissa : process.env.REACT_APP_PUBLIC_URL || "https://www.voissa.com",
    preTitle: "",
    postTitle: " - Voissa",
    genderList: ['Non definis', 'Homme', 'Femme', 'Couple', 'Couple lesbien', 'Couple gay', 'Travesti'],
    genderListProfile: ['Homme', 'Femme', 'Couple', 'Couple lesbien', 'Couple gay', 'Travesti'],
    genderClass: ["undefined", 'man', 'woman', 'couple', 'couple-lesbian', 'couple-gay', 'trav'],
    genderClassProfile: ['man', 'woman', 'couple', 'couple-lesbian', 'couple-gay', 'trav'],
    genderOrientation: ["Non renseigné", 'Hétéro', 'Bi', 'Homo', 'Femme Bi', 'Homme Bi'],
    orientation: ["Non renseigné", "Hétéro", "Bi", "Homo"],
    lookingFor: ["Non renseigné", "Femme hétéro", "Femme lesbienne", "Femme bi", "Homme hétéro", "Homme Gay", "Homme bi", "Couple hétéro", "Couple homo", "Couple lesbien", "Couple femme bi", "Couple homme bi", "Trav / Trans"], // efface 8 Couple bi et ajoute de  8 "Couple homo", 9 "Couple lesbien"
    tendencies: ["Non renseigné", "Fidèle", "Voyeur", "Echangiste", "Melangiste", "Cote à Cotiste", "Candauliste", "Exhib", "SM", "Gangbang"],
    mobility: ["Non renseigné", "Reçoit", "Se déplace", "Les deux"],
    civilStatus: ["Non renseigné", "Célibataire", "Marié", "Couple", "Séparé", "Divorcé", "Veuf"],
    sexualPosition: ["Non renseigné", "Missionaire", "69", "Branlette espagnole", "Levrette", "Andromaque", "Autre"],
    fetishObject: ["Non renseigné", "Fouet", "Menottes", "Collants", "Masque", "Porte jartelles", "Chaussures", "Autre"],
    partnerBodyPart: ["Non renseigné", "Cheveux", "Yeux", "Bouche", "Poitrine", "Torse", "Dos", "Fesses", "Jambes", "Pieds", "Autre"],
    bodyPart: ["Non renseigné", "Cheveux", "Yeux", "Bouche", "Poitrine", "Torse", "Dos", "Fesses", "Jambes", "Pieds", "Autre"],
    placeToHaveFetishSex: ["Non renseigné", "Lit", "Salon", "Plage", "Nature", "Toilettes", "Lieux interdits", "Autre"],
    size: ["Non renseigné", "petite", "Taille moyenne", "Grande"],
    weight: ["Non renseigné", "Mince", "Poids moyen", "Kilos en trop", "Poids fort"],
    eyesColor: ["Non renseigné", "Noirs", "Marrons", "Noisettes", "Bleus", "Verts", "Gris"],
    hairColor: ["Non renseigné", "Blancs", "Poivre et sel", "Platine", "Platine", "Blonds", "Châtains", "Roux", "Bruns", "Noirs", "Couleurs vives"],
    lookStyle: ["Non renseigné", "Décontracté", "Classique", "BCBG", "Boheme", "Sportif", "Geek", "Grunge", "Gothique"],
    hairiness: ["Non renseigné", "Epilation totale", "Poilu", "Peu"],
    personality: ["Non renseigné", "Aventureux", "Conciliant", "Humoristique", "Sociable", "Insouciant", "Vif", "Expansif", "Inquiet", "Reservé", "Attentionné", "Calme", "Généreux", "Sensible", "Spontané", "Timide", "Exigeant", "Fier", "Possessif", "Solitaire", "Tenace"],
    smoker: ["Non renseigné", "Non", "Oui", "Occasionnellement"],
    genderUrl : {0 : "amateur-sexe", 1 : "amateur-sexe", 2 : "amatrice-sexe", 3 : "couple-amateur-sexe", 4 : "amatrice-sexe", 5 : "amateur-sexe", 6 : "travesti-amateur"},
    role: {emailUnconfirmed: "Compte non confirmé", standard: "Voissanaute",  creator: "Voissa Créateur", moderator: "Modérateur", administrator: "Administrateur", banned: "banni"},
    warningRestriction: ["Message verbal uniquement", "Suppression de la capacité à créer du contenu", "Suspenssion"]
};

const picture = () => {
    return {
        url: process.env.REACT_APP_BASE_STATIC,
        path: '/usr/src/app/files'
    }
};

const apiCropImage = (picture) => {
    let {url = "", width = 80, height = 80, quality = 80} = picture;
    if (url) {
        return `${url}?width=${width}&height=${height}&quality=${quality}`
    }
    return "";
};

export {picture, apiCropImage, config};
