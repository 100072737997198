import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('CATEGORIZED_MEDIA').plugin(axiosPlugin);

let fetchMediaAction = redux.fetchActionGetOne();

//let createMediaAction = redux.fetchActionCreate();

let updateMediaAction = redux.fetchActionUpdate();

//let deleteMediaAction = redux.fetchActionDelete();

export const TYPES = redux.types;

export const fetchCategorizedMediaRandom = (query = {}) => fetchMediaAction(`/medias/categorized-random?${queryString.stringify(query)}`);
export const fetchCategorizedMedia = (mediaId = {}) => fetchMediaAction(`/medias/categorized/${mediaId}`);

//export const createMedia = (mediaId, data = {}) => createMediaAction(`/medias`, data);
export const updateCategorizedMedia = (mediaId, data = {}) => updateMediaAction(`/medias/${mediaId}/update-categorized`, data);
export const refuserCategorizedMedia = (mediaId, data = {}) => updateMediaAction(`/medias/${mediaId}/refuse-categorized`, data);
//export const moveCategorizedMedia = (mediaId, data = {}) => updateMediaAction(`/medias/${mediaId}/move`, data);
//export const deleteCategorizedMedia = (mediaId = {}) => deleteMediaAction(`/medias/${mediaId}`);

export const acceptedCategorizedMedia = (mediaId, data = {}) => updateMediaAction(`/medias/${mediaId}/accepted-categorized`, data);
export const declinedCategorizedMedia = (mediaId, data = {}) => updateMediaAction(`/medias/${mediaId}/declined-categorized`, data);
export const clearCategorizedMedia = (mediaId, data = {}) => updateMediaAction(`/medias/${mediaId}/clear-categorized`, data);
export const resetCategorizedMedia = () => redux.actions.reset();

export const categorizedMedia = redux.reducer();