import {errorSkeleton} from "../../error";

export const INITIAL_STATE = {
    loading: false,
    submitting: false,
    hasError: false,
    data: [],
    more: false,
    total: 0,
    error: errorSkeleton,
    initialized: false
};

const reducer = (state, action) => ({
    ...state,
    loading: true,
    submitting: ['POST', 'PATCH', 'DELETE'].includes(action.payload.request.method)
});

const reducerSuccess = (state, action) => ({
    ...state,
    ...action.payload,
    data: action.meta.previousAction.more ? [...state.data, ...action.payload.data] : action.payload.data,
    error: errorSkeleton,
    initialized: true,
    loading: false,
    submitting: false,
    hasError: false
});

const reducerFail = (state, action) => ({
    ...state,
    error: action.error.response,
    loading: false,
    submitting: false,
    hasError: true
});

export default function () {
    return {
        INITIAL_STATE,
        reducer,
        reducerSuccess,
        reducerFail
    };
}


