import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {fetchCategoriesRoot, resetCategoriesRoot} from "../../../../creators/categories.rootChildrens";
import {LoadingBar} from "../../../components/Loader";
import {Col, Row} from "react-bootstrap";
import CategoriesList from "./CategoriesList";
import _ from "lodash";

class Categories extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            categories : this.props.valuesCheck || [],
            initial : false
        }
        this.handleCategories = this.handleCategories.bind(this);
        this.props.fetchCategoriesRoot();
    }

    handleCategories(id, parentId){
        let categories = this.state.categories;
        let isPresent = categories.includes(id.toString());

        let getParent = _.find(this.props.categoriesRoot, { '_id': parentId._id });
        if(!getParent.isMultipleSelection){

            let idsChildrens = getParent.childrens.map(item => item._id.toString());
            categories = categories.filter(item => !idsChildrens.includes(item.toString()));
        }
        if(isPresent){
            categories = categories.filter(item => item.toString() !== id.toString())
        }
        else if(!isPresent){
            categories.push(id.toString());
        }
        this.setState({categories, initial : getParent.slug ==="media"});
        if(typeof this.props.updateCategories === "function"){
            this.props.updateCategories(categories);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.valuesCheck.length !== this.props.valuesCheck.length) {
            this.setState({categories : this.props.valuesCheck || [] });
        }
        return null;
    }


    render() {
        return !this.props.categoriesRootInitialized ? (<LoadingBar />) : (
            this.props.categoriesRoot.map(categories => {
                return (
                    <Row key={categories._id} className={"mb-4 d-flex"}>
                        <Col sm={1} md={1} xl={1} lg={2} className={"d-flex align-items-center f-s-11 f-w-500"} >
                            {categories.title}
                        </Col>
                        <Col sm={11} md={11} xl={11} lg={10}  className={"border-dark border"}>
                            <CategoriesList
                                options={categories.childrens}
                                optionField={{
                                    value: "_id",
                                    label: "title"
                                }}
                                initial = {this.state.initial}
                                valuesCheck={this.state.categories}
                                handleCategories={this.handleCategories}
                            />
                        </Col>
                    </Row>
                )

    })
        )
    }
}


const mapStateToProps = (state) => {
    return {
        categoriesRoot : state.categoriesRoot.data,
        categoriesRootInitialized : state.categoriesRoot.initialized,
        categoriesRootLoading : state.categoriesRoot.loading,

    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCategoriesRoot,
    resetCategoriesRoot
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Categories);
