import React from 'react';
import {Button, Form, Modal, Table} from "react-bootstrap";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import FormFields from "../components/FormFields";

import {resetFacette} from "../../creators/facette";
import Col from "react-bootstrap/Col";
import ColoneTn from "../components/ColoneTh";

class FormFacette extends React.Component {
    constructor(props) {
        super(props);
        this.isUpdate = this.props._id || false;
        this.initialState =  {
            uri : this.props.uri || "",
            typeId : this.props.typeId || {},
            deleted : this.props.deleted || false,
            redirectUri : this.props.redirectUri || "",
            title : this.props.seo.title || "",
            baliseH1 : this.props.seo.baliseH1 || "",
            description : this.props.seo.description || "",
            valid : this.props.valid || true,
            indexable : this.props.indexable
        };
        this.state = {
            ...this.initialState
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkLastCaractere = this.checkLastCaractere.bind(this);

    }

    componentWillUnmount(){
        this.props.resetFacette();
    }

    checkLastCaractere(){
        let uri = this.state.uri;
        if(uri && !uri.endsWith("/")){
            uri = `${uri}/`;
            this.setState({uri});
        }
    }


    handleSubmit(e) {
        e.preventDefault();
        if (!this.props.typeLoading) {
            this.props.handleSubmit({
                uri : this.state.uri,
                deleted : this.state.deleted,
                redirectUri : this.state.deleted ? this.state.redirectUri : "",
                seo : {
                    title : this.state.title,
                    description : this.state.description,
                    baliseH1 : this.state.baliseH1
                },
                valid : this.state.valid,
                indexable : this.state.indexable
            })
        }
    }


    render() {

        return (
            <Form onSubmit={(e) => this.handleSubmit(e)} id={"facette-form"} >
                <Modal.Header closeButton>
                    <Modal.Title>{this.isUpdate ? "Modifier" : "Ajouter"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Row>
                        <Col md={6}>
                            <FormFields
                                group={{controlId : "uri"}}
                                type={"input"}
                                label={{
                                    label : "Uri"
                                }}
                                controle={{
                                    type : "text",
                                    name : "uri",
                                    value : this.state.uri,
                                    //required : true,
                                    onBlur : () => {this.checkLastCaractere()},
                                    onChange : (event) =>{this.setState({[event.target.name] : event.target.value})},
                                    isInvalid : this.props.typeError.uri
                                }}
                                text={ this.props.uriInitial !== this.state.uri ? {
                                    text : `Valeur initial : ${this.props.uriInitial}`,
                                    className : "text-secondary"
                                } : {}}
                            />

                            <FormFields
                                group={{controlId : "title"}}
                                type={"input"}
                                label={{
                                    label : "Titre"
                                }}
                                controle={{
                                    type : "text",
                                    name : "title",
                                    value : this.state.title,
                                    onChange : (event) =>{this.setState({[event.target.name] : event.target.value})},
                                    isInvalid : this.props.typeError.seo && this.props.typeError.seo.title
                                }}
                            />

                            <FormFields
                                group={{controlId : "baliseH1"}}
                                type={"input"}
                                label={{
                                    label : "Balise H1"
                                }}
                                controle={{
                                    type : "text",
                                    name : "baliseH1",
                                    value : this.state.baliseH1,
                                    onChange : (event) =>{this.setState({[event.target.name] : event.target.value})},
                                    isInvalid : this.props.typeError.seo && this.props.typeError.seo.baliseH1
                                }}
                            />

                            <FormFields
                                group={{controlId : "description"}}
                                type={"text"}
                                label={{
                                    label : "description"
                                }}
                                controle={{
                                    type : "text",
                                    as : "textarea",
                                    rows : "4",
                                    name : "description",
                                    value : this.state.description,
                                    onChange : (event) =>{this.setState({[event.target.name] : event.target.value})},
                                    isInvalid : this.props.typeError.seo && this.props.typeError.seo.description
                                }}
                            />
                            <div className={"infos"}>
                               <p className={"title w-100"}>Class title</p>
                               <p className={"text-danger w-50"}>Class text-danger</p>
                               <p className={"text-info w-50"}>Class text-info</p>
                               <p className={"text-warning w-50"}>Class text-warning</p>
                               <p className={"text-underlined w-50"}>Class text-underlined</p>
                            </div>

                            <Form.Row>
                                <FormFields
                                    group={{as : Col, controlId : "checkbox-valid"}}
                                    type={"checkbox"}
                                    checkBox={{
                                        id : `checkbox-valid`,
                                        name : "valid",
                                        checked : this.state.valid,
                                        label : "Valide",
                                        onChange : (e) =>{this.setState({valid : !this.state.valid})}
                                    }}
                                />
                                <FormFields
                                    group={{as : Col, controlId : "deleted"}}
                                    type={"checkbox"}
                                    checkBox={{
                                        id : `deleted`,
                                        name : "deleted",
                                        checked : this.state.deleted,
                                        label : "Supprimé",
                                        onChange : (e) =>{this.setState({deleted : !this.state.deleted})}
                                    }}
                                />
                            </Form.Row>

                            {this.state.deleted ? (<FormFields
                                group={{controlId : "redirectUri"}}
                                type={"input"}
                                label={{
                                    label : "URI de redirection"
                                }}
                                controle={{
                                    type : "text",
                                    name : "redirectUri",
                                    value : this.state.redirectUri,
                                    onChange : (event) =>{this.setState({[event.target.name] : event.target.value})},
                                    isInvalid : this.props.typeError.redirectUri
                                }}
                            />) : null}
                        </Col>
                        <Col md={5} className={"ml-4"}>
                            {this.props.categoriesValues ? (
                                <Table size="sm" striped bordered hover>
                                    <thead>
                                    <tr>
                                        <ColoneTn label={"Categorie"}   />
                                        <ColoneTn label={"Valeur"}   />
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.props.categoriesValues.map((item, index) => (
                                        <tr key={index}>
                                            <td>{item.category}</td>
                                            <td>{item.value}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>) : null}

                            <FormFields
                                group={{controlId : "count"}}
                                type={"input"}
                                label={{
                                    label : "Count"
                                }}
                                controle={{
                                    type : "text",
                                    name : "count",
                                    value : this.props.count,
                                    disabled : true,
                                    required : true,
                                    isInvalid : this.props.typeError.count
                                }}
                            />
                            <Form.Row className={"w-100"}>
                                <div className={"col-form-label w-25"}>Indexable</div>
                                <FormFields
                                    group={{controlId : "indexable"}}
                                    type={"select"}
                                    controle={{
                                        name : "indexable",
                                        value : this.state.indexable,
                                        options : [{_id : false, name : "Non"}, {_id : true, name : "Oui"}],
                                        optionField : {value : "_id", label : "name"},
                                        onChange : (event) =>{ this.setState({indexable : event.target.value})}
                                    }}
                                />
                            </Form.Row>
                        </Col>
                    </Form.Row>


                    {this.props.facetteStatusCode === 409 ? <p className={"text-danger"}>le Uri existe déja</p> : null}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type={"submit"}>
                        Valider
                    </Button>
                    <Button variant="danger" onClick={()=>this.props.onHide()}>
                        Annuler
                    </Button>
                </Modal.Footer>

            </Form>
        )
    }
}


const mapStateToProps = (state) => {

    return {
        facette : state.facette.data,
        typeError : state.facette.error && state.facette.error.errors,
        facetteStatusCode : state.facette.error && state.facette.error.statusCode,
        facetteLoading : state.facette.loading,
        facetteInitialized : state.facette.initialized,

    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    resetFacette
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormFacette);
