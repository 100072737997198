import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('IA_CLASSIFIER_MODERATION_MEDIAS_UPDATE_GENDER').plugin(axiosPlugin);


export const TYPES = redux.types;

let updateAction = redux.fetchActionUpdate();

export const updateIaClassifierGender = (mediaId = null,data = {}) => updateAction(`/ia/classifier/${mediaId}/update-gender`, data);
export const resetIaClassifierGender = () => redux.actions.reset();

export const iaClassifierGender = redux.reducer();