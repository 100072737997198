import {all, fork} from 'redux-saga/effects'

import {watchModerationContent} from "./moderationContent";
import {watchStory} from "./story"
import {watchReportingComment} from "./reportingComment";
import {watchCurrentUser} from "./currentUser";
import watchLogin from "./login";
import watchMedia from "./media";
import watchCategory from "./category";
import watchFacettesType from "./facettesType";
import watchFacettesTypesGenerateur from "./facettesTypesGenerateur";
import watchFacette from "./facette";
import watchWarning from "./warning";
import watchKeywordWarning from "./keywordWarning";
import {watchIaModerationUpdateCategories} from "./ia.classifierModeration.updateCategories";
import watchConfiguration from "./configuration";
import watchUser from "./user";

const sagas = [
    watchModerationContent,
    watchStory,
    watchReportingComment,
    watchCurrentUser,
    watchLogin,
    watchMedia,
    watchCategory,
    watchFacettesType,
    watchFacettesTypesGenerateur,
    watchFacette,
    watchWarning,
    watchKeywordWarning,
    watchIaModerationUpdateCategories,
    watchConfiguration,
    watchUser
];

function* rootSaga() {
    yield all(sagas.map(saga => fork(saga)));
}

export default rootSaga;