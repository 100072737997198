import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Aqp from '../../../libs/mk-react-aqp';
import {fetchModerationContents, resetModerationContents} from "../../../creators/moderationContents";
import {refusedModerationContent, acceptedModerationContent} from "../../../creators/moderationContent";
import {PageLoader, SpinLoader, LoadingBar} from "../../components/Loader";
import FilterGender from "../../components/FilterGender";
import {Button, Col, Row} from "react-bootstrap";
import createLink from "../../../libs/createLink";
import {config} from "../../../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";


window.toggleEllipsis = function(ellipsis) {
    ellipsis.classList.toggle('visible');
    ellipsis.innerHTML = ellipsis.classList.contains('visible') ? ellipsis.getAttribute('data-value') : '';
}
const decodeHtmlEntities = (text =  "") => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, 'text/html');
    return doc.documentElement.textContent;
};

const ReplaceContentMatch = ({
                                 keywords = [],
                                 user = {}
                             }) => {
    let {profile = {} } = user;
    let description = decodeHtmlEntities(profile.description || '');
    let keywordsMatch = keywords.map(keyword => keyword.isRegex ? keyword.keywords : keyword.keywords).join("|");

    if(keywordsMatch !== ""){
        let regexpString = new RegExp(`(${keywordsMatch})`,'gmi');
        description = description.replace(/[&<>'"]/g,
            tag => ({
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                "'": '&#39;',
                '"': '&quot;',
                '*': '&#42;',

            }[tag]));
        description = description.replace(regexpString, `<span class="keyword-warning">$1</span>`);
    }

    return (
        <div dangerouslySetInnerHTML={{__html: description}} className={"warning-text"} />
    );
}

let mapping = {
    "sort" : "trie",
    "owner.profile.gender" : "genre"
};

class ModerationsMemberDescription extends React.Component {
    constructor(props) {
        super(props);
        this.type = "user-description";
        this.filter = {
            limit: 18,
            page: 1,
            sort: "createdAt",
        };
        this.aqp = new Aqp(mapping, {
            blacklist: ['limit', 'page'],
            currentLink: "/moderations/membre-description",
            filterDefault: this.filter
        });

        this.state = {
            filter: {...this.filter, ...this.aqp.query, page: this.props.match.params.page || 1},
            tachesRemoveIds : [],
        };
        this.props.resetModerationContents();
        this.fetchModerationContents(this.state.filter);

        this.handleGender = this.handleGender.bind(this);
        this.submitAccepted = this.submitAccepted.bind(this);
        this.submitRefused = this.submitRefused.bind(this);
    }

    UNSAFE_componentWillMount(){
        this.props.resetModerationContents();
    }

    fetchModerationContents(filter){
        this.props.fetchModerationContents(this.type, this.aqp.set(filter));
        this.setState({filter})
    }


    handleGender(gender){
        let filter = {...this.state.filter, "owner.profile.gender" : gender, page : 1};
        if(filter["owner.profile.gender"] === ""){
            delete filter.gender;
        }
        else{
            filter["owner.profile.gender"] = parseInt(filter["owner.profile.gender"])
        }
        this.fetchModerationContents(filter);
    }

    submitRefused(id){
        this.props.refusedModerationContent(this.type, id);
        let tachesRemoveIds = [...this.state.tachesRemoveIds,id];
        this.setState({tachesRemoveIds});
    }
    submitAccepted(){
        let ids = this.props.moderationContents.map(item => item._id);
        this.props.acceptedModerationContent(this.type, {ids});
    }


    render() {
// console.log(this.props.moderationContentsInitialized);
        return !this.props.moderationContentsInitialized ? (<PageLoader />) :(
            <div id={"member-description"}>
                <FilterGender value={this.state.filter["owner.profile.gender"]} onChange={(gender)=>{this.handleGender(gender)}}/>
                <Row>
                    {this.props.moderationContentsLoading ? (<LoadingBar />) : (
                        this.props.moderationContents.map((item) =>{
                            let content = item.parentId;

                            let gender = config.genderList[content.profile && content.profile.gender];
                            let loading = this.state.tachesRemoveIds.includes(item._id) ? true : false;

                            return (
                                <Col sm={6} className={"p-0 mb-4"}>
                                    <Col sm={12} className={"font-size-14"}>
                                        <a href={createLink("userProfile", content)}
                                           target={"new"}>{content.username}</a>
                                    </Col>
                                    <Col sm={12} className={"font-size-14"}>
                                    Genre : {gender}
                                    </Col>
                                    <Col sm={12}>
                                        <ReplaceContentMatch key={`content_${item._id}`} user={content} keywords={item.keywords || []} />

                                    </Col>
                                    <Col sm={12}>
                                        <Link to={createLink("userProfileAdmin", content)} target={"new"} className={"pr-2"}>
                                            <FontAwesomeIcon
                                                icon="edit"
                                                className={"cursor-pointer"}
                                            />
                                        </Link>
                                        {loading ? (<div className={"pt-2 flex justify-content-center"}><SpinLoader className={"xsmall"} /></div>) : (
                                            <FontAwesomeIcon
                                                icon="trash"
                                                className={"cursor-pointer"}
                                                onClick={()=>this.submitRefused(item._id)}
                                            />
                                        )}
                                    </Col>
                                </Col>
                            )
                        })
                    )}
                </Row>
                <Button variant="primary" className={"btn-loading mt-4"} onClick={()=>this.submitAccepted()}>
                    Valider {this.props.moderationContentLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                </Button>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        moderationContents : state.moderationContents.data,
        moderationContentsTotal : state.moderationContents.total,
        moderationContentsLoading : state.moderationContents.loading,
        moderationContentsInitialized : state.moderationContents.initialized
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchModerationContents,
    refusedModerationContent,
    acceptedModerationContent,
    resetModerationContents
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModerationsMemberDescription);
