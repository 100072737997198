import {put,takeEvery} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {TYPES as MODERATION_CONTENT_TYPE} from '../creators/moderationContent';


export function deleteSuccess(action) {
    try {
        window.location.reload();

    } catch (error) {

    }
}

export function* updateSuccess(action) {
    try {
        yield put({type: "MODERATION_CONTENTS_DELETE", data : action.payload});
    } catch (error) {
    }
}

export function* watchModerationContent() {
    yield takeEvery(MODERATION_CONTENT_TYPE.DELETE_SUCCESS, deleteSuccess);
    yield takeEvery(MODERATION_CONTENT_TYPE.UPDATE_SUCCESS, updateSuccess);
}

export default watchModerationContent;

