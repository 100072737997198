import React from 'react';
import {Button, Col, Form, Modal, Row} from "react-bootstrap";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import FormFields from "../components/FormFields";

import {fetchTagsWarning} from "../../creators/tagsWarning";
import MkEditor from "../components/editor/Editor";
import moment from "moment-timezone";
import {createUserWarning, resetUserWarning} from "../../creators/user.warning";


class FormWarning extends React.Component {
    constructor(props) {
        super(props);

        let startDate = new Date();
        let endDate = moment(startDate).add("1", 'days').endOf('day').toDate();
        this.initialWarning = {
            contentRaw: {},
            moderatorNoteRaw: {},
            warningReason: '',
            tag : "",
            // tag: "5c86efe9180bdd0a546125a9",
            restriction: {
                type: 0,
                isPermanent: false,
                startedAt: startDate,
                endedAt: endDate
            }
        };
        this.state = {
            warning: this.initialWarning,
            restrictionDuration: 1,
            hoursToAdd: 1,
            warningPoint: 0,
            submitWarning: []
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleContent = this.handleContent.bind(this);
        this.handleRestriction = this.handleRestriction.bind(this);
        this.handleRestrictionDuration = this.handleRestrictionDuration.bind(this);
        this.props.resetUserWarning();
        this.props.fetchTagsWarning();
    }

    componentWillUnmount(){
        this.props.resetUserWarning()
    }

    componentDidUpdate(prevProps) {
        if(this.props.userWarningInitialized !== prevProps.userWarningInitialized){
            this.props.onHide();
        }
    }

    handleContent(name, value) {
        this.setState({warning: {...this.state.warning, [name]: value}});
    }

    handleRestriction(name, value){
        this.setState({warning: {...this.state.warning, restriction: {...this.state.warning.restriction, [name]: value}}});
    }

    handleRestrictionDuration(name, value){
        if(name === "isPermanant"){
            this.setState({restrictionDuration: 0, warning: {...this.state.warning, restriction: {...this.state.warning.restriction, isPermanent : value}}});
        } else {
            this.setState({restrictionDuration: value, warning: {...this.state.warning, restriction: {...this.state.warning.restriction, isPermanent: false}}});
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        let warning = this.state.warning;
        this.props.createUserWarning(this.props.user._id, warning);
    }


    render() {

        return (
            <Form onSubmit={(e) => this.handleSubmit(e)}>
                <Modal.Header closeButton>
                    <Modal.Title>Ajouter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group >
                        <Form.Label>Note au membre</Form.Label>
                        <MkEditor onChange={(contentRaw) => this.setState({warning : {...this.state.warning, contentRaw}})}/>
                    </Form.Group>

                    <Form.Group >
                        <Form.Label>Note pour les modérateurs</Form.Label>
                        <MkEditor onChange={(moderatorNoteRaw) => this.setState({warning : {...this.state.warning, moderatorNoteRaw}})}/>
                    </Form.Group>

                    <FormFields
                        group={{controlId : "tag"}}
                        type={"select"}
                        label={{
                            label : "Raison"
                        }}
                        controle={{
                            name : "tag",
                            value : this.state.warning.tag,
                            options : [{_id : "", title : "--Sélectionner une raison--"}, ...this.props.tagsWarning],
                            optionField : {value : "_id", label : "title"},
                            onChange : (event) =>{this.handleContent(event.target.name, event.target.value)},
                            isInvalid : this.props.userWarningError.tag
                        }}

                    />

                    <FormFields
                        group={{controlId : "type"}}
                        type={"select"}
                        label={{
                            label : "Punition"
                        }}
                        controle={{
                            name : "type",
                            value : this.state.warning.restriction.type,
                            options : [
                                {_id : 0, title : "Message verbal uniquement"},
                                {_id : 1, title : "Supprimer la capacité à créer du contenu"}
                            ],
                            optionField : {value : "_id", label : "title"},
                            onChange : (event) =>{this.handleRestriction(event.target.name, event.target.value)},
                        }}
                    />

                    {this.state.warning.restriction.type !== 0 && (
                        <div className={"column punishment-section"}>
                            <div className="text label bold pb-4">Durée de la sanction</div>
                            <Form.Group>
                                <Button
                                    className={"mr-2"}
                                    onClick={()=>this.handleRestrictionDuration("isPermanant", true)}
                                    active={this.state.restrictionDuration === 0}
                                >
                                    Permanente
                                </Button>
                                <Button
                                    onClick={()=>this.handleRestrictionDuration("restrictionDuration", 1)}
                                    active={this.state.restrictionDuration === 1}
                                >
                                    Période
                                </Button>
                            </Form.Group>
                            {!this.state.warning.restriction.isPermanent && (
                                <Row className={"p-3"}>
                                    {this.state.restrictionDuration === 1 && (
                                    <Form.Row>
                                        <FormFields
                                            group={{as : Col, controlId : "startedAt"}}
                                            type={"date"}
                                            datePicker={{showLeadingZeros : true, minDate : moment().toDate()}}
                                            label={{
                                                label : "Début"
                                            }}
                                            controle={{
                                                name : "startedAt",
                                                id : "startedAt",
                                                value : this.state.warning.restriction.startedAt,
                                                onChange : (date) =>{this.handleRestriction("startedAt",date)},
                                                //disabled : true,
                                                className : `w-100 ${this.state.warning.restriction.startedAt === null ? "warning" : ""}`
                                            }}
                                        />

                                        <FormFields
                                            group={{as : Col, controlId : "endedAt"}}
                                            type={"date"}
                                            datePicker={{showLeadingZeros : true, minDate : this.state.warning.restriction.startedAt}}
                                            label={{
                                                label : "Fin"
                                            }}
                                            controle={{
                                                name : "endedAt",
                                                id : "endedAt",
                                                value : this.state.warning.restriction.endedAt,
                                                onChange : (date) =>{this.handleRestriction("endedAt",date ? moment(date).endOf('day').toDate() : date)},
                                                //disabled : true,
                                                className : `w-100 ${this.state.warning.restriction.endedAt === null ? "warning" : ""}`
                                            }}
                                        />
                                    </Form.Row>
                                    )}
                                </Row>
                            )}
                        </div>
                    )}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type={"submit"}>
                        Valider
                    </Button>
                    <Button variant="danger" onClick={()=>this.props.onHide()}>
                        Annuler
                    </Button>
                </Modal.Footer>
            </Form>
        )
    }
}


const mapStateToProps = (state) => {

    return {
        tagsWarning: state.tagsWarning.data,
        user: state.user.data,
        userWarningInitialized : state.userWarning.initialized,
        userWarningLoading : state.userWarning.loading,
        userWarningError : (state.userWarning.error && state.userWarning.error.errors) || {}
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchTagsWarning,
    createUserWarning,
    resetUserWarning
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormWarning);
