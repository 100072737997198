import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('REPORTINGS').plugin(axiosPlugin, {template: "array"});

let fetchReportingsAction = redux.fetchActionGet();

redux.reducerForType('UPDATE', (state,payload) => {
    let newState = {...state};
    if(payload.data && payload.data._id){
        newState.data = newState.data.map(item => {
            if(item._id.toString() === payload.data._id.toString()){
                item = payload.data;
            }
            return item;
        });
    }
    return newState;
});

export const fetchReportings = (query = {}) => fetchReportingsAction(`/reportings?${queryString.stringify(query)}`);
export const resetReportings = () => redux.actions.reset();

export const reportings = redux.reducer();