import {put,takeEvery} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {TYPES as FACETTES_TYPE} from '../creators/facettes.type';
import {TYPES as FACETTES_TYPES} from '../creators/facettes.types';


export function* updateSuccess(action) {
    try {
        yield put({type: FACETTES_TYPES.UPDATE, data : action.payload});
    } catch (error) {
    }
}

export function* deleteSuccess(action) {
    try {
        yield put({type: FACETTES_TYPES.DELETE, data : action.payload});
    } catch (error) {

    }
}

export function* createSuccess(action) {
    try {
        yield put({type: FACETTES_TYPES.ADD, data : action.payload});
    } catch (error) {
    }
}

export function* watchFacettesType() {
    yield takeEvery(FACETTES_TYPE.UPDATE_SUCCESS, updateSuccess);
    yield takeEvery(FACETTES_TYPE.DELETE_SUCCESS, deleteSuccess);
    yield takeEvery(FACETTES_TYPE.CREATE_SUCCESS, createSuccess);
}

export default watchFacettesType;

