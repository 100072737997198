import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('FACETTES_TYPE').plugin(axiosPlugin);

let fetchAction = redux.fetchActionGetOne();

let createAction = redux.fetchActionCreate();

let updateAction = redux.fetchActionUpdate();

let deleteAction = redux.fetchActionDelete();

export const TYPES = redux.types;

export const fetchType = (typeId, query = {}) => fetchAction(`/facettes-types/${typeId}?${queryString.stringify(query)}`);
export const createType = (data = {}) => createAction(`/facettes-types`, data);
export const updateType = (typeId, data = {}) => updateAction(`/facettes-types/${typeId}`, data);
export const deleteType = (typeId = {}) => deleteAction(`/facettes-types/${typeId}`);
export const resetType = () => redux.actions.reset();

export const facettesType = redux.reducer();