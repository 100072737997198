import React from 'react';

import {apiUrl} from "../../../../libs/api";
import {SpinLoader} from "../../Loader";
import DraftContentReader from "../DraftContentReader";

export default class DraftBlockquote extends React.Component {
    constructor(props){
        super(props);
        this.messages = [
            "Vous n'avez pas accès à ce contenu",
            "Cette citation n'est peut être plus disponible"
        ];
        this.state = {
          comment: {},
          error: false,
          messageToDisplay: 0,
        };
    }
    componentDidMount() {
        if(this.props.kind === "topicComments"){
            if(this.props.forumId && this.props.topicId && this.props._id && isNaN(this.props._id)){
                apiUrl.get(`/forums/${this.props.forumId}/topics/${this.props.topicId}/comments/${this.props._id}`).then(comment => {
                    if(comment === null){
                        this.setState({error: true})
                    } else {
                        this.setState({comment: comment});
                    }
                }).catch(e => {
                    this.setState({error: true})
                });
            }
        }
        if(!isNaN(this.props._id)){
            this.setState({error: true, messageToDisplay: 1});
        }
    }

    render(){
        return this.state.error ? (
            <div className={"draft-shared-media error"}>
                {this.messages[this.state.messageToDisplay]}
            </div>
        ) : this.state.comment._id ? (
            <div className={"quoting-user"}>
                <blockquote className={"blockquote--classic"}>
                    {this.state.comment.contentRaw && <DraftContentReader extendedProps={this.props.extendedProps} contentRaw={this.state.comment.contentRaw} />}
                </blockquote>
                <span className={"quoting-user-username"}>par : <b>{this.state.comment.authorName}</b></span>
            </div>
        ) : (
            <SpinLoader className={"small"}/>
        )
    }
}