import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button, Modal} from "react-bootstrap";

import {createCategory, resetCategory} from "../../../../creators/category";
import FormCategory from "../../../formulaires/category";

class CreateCategories extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleModalAddCategory : false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps){
        if(this.props.categoryInitialized && (this.props.categoryStatusCode === 200 || this.props.categoryStatusCode === null) && this.state.toggleModalAddCategory){
            this.setState({toggleModalAddCategory : false})
            this.props.resetCategory();
        }
    }

    componentWillUnmount(){
        this.setState({toggleModalAddCategory : false})
        this.props.resetCategory();
    }


    handleSubmit(data) {
        let category = {
            title : data.title,
            slug : data.slug,
            isMultipleSelection : data.isMultipleSelection,
            parentId : data.parentId === "" ? null : data.parentId,
            metaDescription : data.metaDescription
        }
        if (!this.props.categoryLoading) {
            this.props.createCategory(category);
        }
    }

    render() {

        return (
            <Fragment>
                <Button variant="outline-primary" size="sm" className={"mt-2"} onClick={() =>this.setState({toggleModalAddCategory : true})}>
                    Ajouter une categorie
                </Button>
                <Modal centered backdrop={false} show={this.state.toggleModalAddCategory} onHide={()=>this.setState({toggleModalAddCategory : false})}>
                    <FormCategory handleSubmit={this.handleSubmit} parentId={this.props.parentId}  onHide={()=>this.setState({toggleModalAddCategory : false})} />
                </Modal>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        categoryStatusCode : state.category.error && state.category.error.statusCode,
        categoryInitialized : state.category.initialized,
        categoryLoading : state.category.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    createCategory,
    resetCategory
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateCategories);
