import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('USER').plugin(axiosPlugin);


let fetchUserAction = redux.fetchActionGetOne();
let updateUserAction = redux.fetchActionUpdate();
let updateUserPasswordAction = redux.fetchActionUpdate();
let deleteUserAction = redux.fetchActionDelete();

export const TYPES = redux.types;

export const fetchUser = (userId) => fetchUserAction(`/users/${userId}/admin`);
export const updateUser = (userId,data) => updateUserAction(`/users/${userId}`, data);
export const updateUserModePanic = (userId) => updateUserAction(`/users/${userId}/confirmed-account-panicmode`);
export const updateUserPassword = (userId, data = {}) => updateUserPasswordAction(`/users/${userId}/set-password`, data);
export const deleteUser = (userId) => deleteUserAction(`/users/${userId}`);
export const resetUser = () => redux.actions.reset();

export const user = redux.reducer();