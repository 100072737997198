import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('FACETTES_TYPE_TEMPLATES').plugin(axiosPlugin, {template: "array"});

let fetchAction = redux.fetchActionGet();

export const fetchTemplates = (query = {}) => fetchAction(`/facettes-types/templates?${queryString.stringify(query)}`);
export const resetTemplates = () => redux.actions.reset();

export const facettesTypesTemplates = redux.reducer();