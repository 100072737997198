import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import ReactJWPlayer from "react-jw-player";
import { fetchUserCertifications, resetUserCertifications } from '../../../creators/user.certifications';
import { Button, Card, Col, Container, Modal, Row } from 'react-bootstrap';
import momentTimezone from 'moment-timezone';
import { acceptedUserCertification, deleteUserCertification, refusedUserCertification } from '../../../creators/user.certification';

const STATUS_LABEL = {
    "approved" : "Accepté",
    "refused" : "Refusé",
    "padding" : "En attente"
};

const KIND_LABEL = {
    "certification-photo" : "Photo",
    "certification-video" : "Vidéo",
    "certification-passport" : "passport"
}

class Certifications extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCertification : null
        };

        this.props.fetchUserCertifications(this.props.user._id);
        this.handleClick = this.handleClick.bind(this);
    }

    componentWillUnmount() {
        this.props.resetUserCertifications();
    }

    handleClick(certification, newStatus){
        if(newStatus === 1){
            this.props.acceptedUserCertification(certification.owner._id,certification._id, {sendMail : false}).then(() =>{
                window.location.reload();
            })
        }
        else if(newStatus === 2){
            this.props.refusedUserCertification(certification.owner._id,certification._id, {sendMail : false}).then(() =>{
                window.location.reload();
            })
        }
        else if(newStatus === 3){
            this.props.deleteUserCertification(certification.owner._id,certification._id).then(() =>{
                window.location.reload();
            })
        }
    }


    render() {
        return (
            <Container className="mt-4 mb-4">
                <Row className="g-4 gy-4">
                    {this.props.userCertifications.map((certification) => (
                        <Col key={certification._id} md={3} sm={6} xs={12} className="mb-4">
                            <Card className="h-100 d-flex flex-column">
                                <Card.Img
                                    variant="top"
                                    src={Array.isArray(certification.previewUrl) ? `${certification.previewUrl[0]}?width=250` : `${certification.previewUrl}?width=250`}
                                    alt={""}
                                    onClick={() => this.setState({currentCertification : certification})}
                                    style={{ cursor: 'pointer' }}
                                />
                                <Card.Body className="d-flex flex-column justify-content-end">
                                    <Card.Text>
                                    <small className="text-muted d-block">
                                            Type : {KIND_LABEL[certification.kind] || "Non spécifiée"}
                                        </small>
                                        <div className="d-flex justify-content-between">
                                            <small className="text-muted">
                                                Date: {momentTimezone(certification.createdAt).format("DD/MM/YYYY") || "Non spécifiée"}
                                            </small>
                                            <small className={
                                                certification.status === 1 ? 'text-success' :
                                                certification.status === 2 ? 'text-danger' : 'text-warning'
                                            }>
                                                Statut: {STATUS_LABEL[certification.statusText] || "En attente"}
                                            </small>
                                        </div>                    
                                    </Card.Text>
                                    <div className="d-flex justify-content-between">
                                        <Button
                                            variant="success"
                                            size="sm"
                                            className="me-1"
                                            disabled={certification.status === 1}
                                            onClick={() => this.handleClick(certification, 1)}
                                        >
                                            Accepter
                                        </Button>
                                        <Button
                                            variant="danger"
                                            size="sm"
                                            className="me-1"
                                            disabled={certification.status === 2}
                                            onClick={() => this.handleClick(certification, 2)}
                                        >
                                            Refuser
                                        </Button>
                                        <Button
                                            variant="secondary"
                                            size="sm"
                                            onClick={() => this.handleClick(certification, 3)}
                                        >
                                            Effacer
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
                {this.state.currentCertification ? (
                    <Modal className={"modal-big-thumb"} centered backdrop={false} show={this.state.currentCertification ? true : false} onHide={()=>this.setState({currentCertification : null})}>
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {this.state.currentCertification.kind === "certification-video" && ("Vidéo ")} 
                                {this.state.currentCertification.kind === "certification-photo" && ("Photo ")} 
                                {this.state.currentCertification.kind === "certification-passport" && ("Passport ")} 
                                Certifié
                                </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {this.state.currentCertification.kind === "certification-video" ? 
                                (
                                <div>
                                    <p>{this.state.currentCertification.question}</p>
                                    <ReactJWPlayer
                                        playerScript='https://content.jwplatform.com/libraries/o4p642J0.js'
                                        file={this.state.currentCertification.videoUrl}
                                        isAutoPlay={true}
                                        customProps={{
                                            width: "100%",
                                        }}
                                        aspectRatio={"16:9"}
                                        className={"player"}
                                    />
                                </div>
                                )
                                 : (
                                    <img alt={"img"} src={this.state.currentCertification.previewUrl} />
                                )
                            }
                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                    </Modal>
                ) : null}  
            </Container>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        userCertifications : state.userCertifications.data,
        userCertificationsInitialized: state.userCertifications.initialized,
        userCertificationsLoading: state.userCertifications.loading,

        userCertification : state.userCertification.data,
        userCertificationInitialized: state.userCertification.initialized,
        userCertificationLoading: state.userCertification.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUserCertifications,
    resetUserCertifications,
    acceptedUserCertification,
    refusedUserCertification,
    deleteUserCertification
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Certifications);
