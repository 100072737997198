import React from "react";
import createLink from "../../../../libs/createLink";
import {Link} from "react-router-dom";

//created to avoid mentionPlugin error with @ in DraftContentReader

export const alternativeMentionStrategy = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity();
        return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === "mention"
        );
    }, callback);
};

export const alternativeMention = props => {
    const { contentState, entityKey } = props;
    const { mention } = contentState.getEntity(entityKey).getData();
    return (
        <Link key={mention._id} to={createLink('userProfile', mention)} target={"_blank"} rel="noopener noreferrer" className={"mentionned-name"}>
            @{mention.username}
        </Link>
    );
};

const alternativeToMentionPlugin = {
    decorators: [
        {
            strategy: alternativeMentionStrategy,
            component: alternativeMention
        }
    ]
};

export default alternativeToMentionPlugin;