import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Table} from "react-bootstrap";
import {LoadingBar, PageLoader} from "../../../components/Loader";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Aqp from '../../../../libs/mk-react-aqp';

import Pagination from "../../../components/Pagination";
import ColoneTn from "../../../components/ColoneTh";

import {fetchFacettes} from "../../../../creators/facettes";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import EditFacette from "./EditFacette";


let mapping = {
    "sort" : "trie",
    //"searchUri" : "searchUri"
};

class FacettesList extends React.Component {
    constructor(props) {
        super(props);
        this.currentPath = "/moteur-a-facettes/facettes";
        this.filter = {
            limit: 50,
            page: 1,
            sort: "-count",
            searchUri : "",
            isActive : false,
            isNotValid : false,
            noIndexable : false,
            isDeleted : false,
            typeId : ""
        };

        this.aqp = new Aqp(mapping, {
            blacklist: ['limit', 'page'],
            currentLink: this.currentPath,
            filterDefault: this.filter
        });

        this.state = {
            filter: {...this.filter, ...this.aqp.query, page: this.props.match.params.page || 1},
            currentId : null,
            isEdit : false
        };

        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSort = this.handleSort.bind(this);
        if(this.props.onRef){
            this.props.onRef(this);
        }

        this.fetchFacettes(this.state.filter);
    }

    componentWillUnmount() {
        if(this.props.onRef){
            this.props.onRef(undefined)
        }
    }

    fetchFacettes(filter){
        this.props.fetchFacettes(this.aqp.set(filter));
    }

    handleSearch(value, filterStatus = {}){
        let filter = {...this.state.filter, page : 1, searchUri : value, ...filterStatus};
        this.props.history.push(this.currentPath);
        this.setState({filter});
        this.fetchFacettes(filter);
        window.scrollTo(0, 0);
    }


    handleSort(value){
        let filter = {...this.state.filter, page : 1, sort : value};
        this.props.history.push(this.currentPath);
        this.setState({filter});
        this.fetchFacettes(filter);
        window.scrollTo(0, 0);
    }

    handleChangePage(page, link) {
        let filter = {...this.state.filter, page};
        if (page === 1) {
            this.props.history.push(link);
        } else {
            this.props.history.push(link + '/' + page);
        }
        this.setState({filter});
        this.fetchFacettes(filter);
        window.scrollTo(0, 0);
    }


    render() {

        let totalPage = this.props.facettesTotal ? Math.ceil(this.props.facettesTotal / this.state.filter.limit) : 0;
        return !this.props.facettesInitialized ? (<PageLoader />) : (
            <Fragment>
                {this.props.facettesLoading && (<LoadingBar />)}
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <ColoneTn label={"URI"}   />
                        <ColoneTn label={"Title"}   />
                        <ColoneTn label={"Description"}  />
                        <ColoneTn label={"Indexable"}  />
                        <ColoneTn label={"Count"} currentSort={this.state.filter.sort} sort={"count"} handleSort={this.handleSort}  />
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.facettes.map(facette => {
                        let classStatus = "";
                        if(facette.deleted){
                            classStatus = "text-barred";
                        }
                        else if(!facette.valid){
                            classStatus = "text-warning";
                        }
                        else if(!facette.indexable){
                            classStatus = "text-italic";
                        }

                        return (<tr className={"cursor-pointer"} key={facette._id} onClick={()=>this.setState({ isEdit : true, currentId : facette._id})}>
                            <td className={classStatus}>{facette.uri}</td>
                            <td>{facette.seo.title ? <FontAwesomeIcon icon={faCheck} className={"icon-hover"}/> :
                                <FontAwesomeIcon icon={faTimes} className={"icon-hover"}/>}</td>
                            <td>{facette.seo.description ? <FontAwesomeIcon icon={faCheck} className={"icon-hover"}/> :
                                <FontAwesomeIcon icon={faTimes} className={"icon-hover"}/>}</td>
                            <td>{facette.indexable ? "Oui" : "Non"}</td>
                            <td>{facette.count}</td>
                        </tr>)
                        }
                    )}
                    </tbody>
                </Table>
                {this.state.isEdit ? <EditFacette toggle={this.state.isEdit} handleToggle={()=>this.setState({isEdit : false, currentId : null})} currentId={this.state.currentId} /> : null}
                <Pagination currentPage={this.state.filter.page}
                            pagesCount={totalPage}
                            {...this.aqp.queryUrl(this.state.filter, mapping)}
                            handleChangePage={this.handleChangePage}/>

            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        facettesInitialized: state.facettes.initialized,
        facettesLoading: state.facettes.loading,
        facettes: state.facettes.data,
        facettesTotal: state.facettes.total
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchFacettes
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FacettesList);
