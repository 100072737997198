import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('USER_TRANSACTIONS').plugin(axiosPlugin, {template: "array"});

let fetchTransactionsAction = redux.fetchActionGet();

export const fetchUserTransactions = (owner = null,query = {}) => fetchTransactionsAction(`/transactions/${owner}?${queryString.stringify(query)}`);
export const resetUserTransactions = () => redux.actions.reset();

export const userTransactions = redux.reducer();