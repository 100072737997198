import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {PageLoader} from "../components/Loader";
import {fetchTag, resetTag} from "../../creators/tag";
import FormTags from "../formulaires/tag";



class TagEdit extends React.Component {
    constructor(props) {
        super(props);
        this.props.fetchTag(this.props.match.params.tagId);
    }

    componentWillUnmount() {
        this.props.resetTag();
    }

    render() {
        return !this.props.tagInitialized ? (<PageLoader />) :(
            <FormTags tag={this.props.tag} />
        )
    }
}


const mapStateToProps = (state) => {
    return {
        tag : state.tag.data,
        tagLoading : state.tag.loading,
        tagInitialized : state.tag.initialized
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchTag,
    resetTag
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(TagEdit);
