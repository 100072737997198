import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('TAGS_PROTAGONIST').plugin(axiosPlugin, {template: "array"});

let fetchTagsProtagonistAction = redux.fetchActionGet();

export const fetchTagsProtagonist = (query = {}) => fetchTagsProtagonistAction(`/tags-list/protagonist/childrens?${queryString.stringify(query)}`);

export const tagsProtagonist = redux.reducer();