import {put,takeEvery} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {TYPES as UPDATE_TEMPERATURE} from '../creators/ia.classifierModerationMedias.updateTemperature';
import {TYPES as UPDATE_GENDER} from '../creators/ia.classifierModerationMedias.updateGender';
import {TYPES as CLASSIFIER_MODERATION_MEDIAS} from '../creators/ia.classifierModerationMedias';

export function* updateSuccess(action) {
    try {
        yield put({type: CLASSIFIER_MODERATION_MEDIAS.UPDATE, data : action.payload});
    } catch (error) {
    }
}


export function* watchIaModerationUpdateCategories() {
    yield takeEvery(UPDATE_TEMPERATURE.UPDATE_SUCCESS, updateSuccess);
    yield takeEvery(UPDATE_GENDER.UPDATE_SUCCESS, updateSuccess);
}

export default watchIaModerationUpdateCategories;

