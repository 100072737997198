import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('DOMAIN_NOT_WHITE_LIST').plugin(axiosPlugin, {template: "array"});

let fetchTagsAction = redux.fetchActionGet();

export const fetchDomainNotWhiteList = (query = {}) => fetchTagsAction(`/banned-members/domain-not-white-list?${queryString.stringify(query)}`);
export const resetDomainNotWhiteList = () => redux.actions.reset();

export const domainNotWhiteList = redux.reducer();