import React from "react";
import {Button, Col, Image, Modal, Row, Card} from "react-bootstrap";
import createLink from "../../../../libs/createLink";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ImageRotation from "../../../components/ImageRotation";
import {config} from "../../../../config";
import history from "../../../../libs/history";


class ContentMedia extends React.Component {
    constructor(props) {
        super(props);

        this.currentContent = this.props.currentContent || {};
        this.reviewOptions =  this.props.reviewOptions.filter(item => (item._id !== "all" && item._id !== 0 && item._id !== parseInt(this.currentContent.contentReview)));
        this.gender = (this.currentContent && this.currentContent.owner && this.currentContent.owner.profile && this.currentContent.owner.profile.gender) || 0;

        this.state = {
            toggleAlert : false
        }

        this.handleDelete = this.handleDelete.bind(this);
    }

    createLinkOwner(){
        let owner = this.currentContent && this.currentContent.owner;
        return owner ? <a href={createLink("userProfile", owner)} target={"new"}>{owner.username}</a> : null;
    }

    createLinkTitle (){
        let content = this.currentContent;
        return content.kind === "video" ? <a href={createLink("mediaviewerVideo", {...content.owner, media : content})} target={"new"}>{content.title}</a> : <a href={createLink("mediaviewerPhoto", {...content.owner, media : content})} target={"new"}>{content.title}</a>
    }

    handleDelete(){
        this.props.deleteMedia(this.currentContent._id);
        this.setState({toggleAlert : false});
    }

    render() {
    return (
        <Col sm={6} className={"mb-4"}>
            <Card className={"f-d-r p-2"}>
                 <Col sm={4} className={"p-0"}>
                     {this.currentContent.kind === "video" ? (
                             <ImageRotation
                                 srcs={Array.isArray(this.currentContent.previewUrl) && this.currentContent.previewUrl}
                                 interval={600}
                                 alt={this.currentContent.title}
                                 width={"170"}
                                 height={"170"}
                             />
                     ) : (
                         <Image src={this.currentContent.previewUrl ? `${this.currentContent.previewUrl}?width=170&height=170` : ""} thumbnail />
                     )}
                 </Col>
                <Col sm={8} >
                    <Row className={"h-100"}>
                        <Col sm={12} className={"font-size-14"}>
                             Membre : {this.createLinkOwner()}
                         </Col>
                        <Col sm={12} className={"font-size-14"}>
                            Genre : <span className={config["genderClass"][this.gender]}>{config["genderList"][this.gender]}</span>
                        </Col>

                        <Col sm={12} className={"font-size-14"}>
                            Titre : {this.createLinkTitle()}
                         </Col>
                        <Col sm={12} className={"mt-2"}>
                            {this.reviewOptions.map(item => (
                                <Button key={item._id} size="sm" className={"mr-2"} variant={item.variant} onClick={()=>this.props.updateReview(this.currentContent._id,item._id)}>
                                    {item.title}
                                </Button>
                            ))}
                        </Col>
                        <Col sm={12} className={"flex justify-content-between mt-2"}>
                             <Button size="sm" variant={"outline-info"} onClick={()=>history.push(`${this.props.currentUrl}/${this.currentContent._id}`)}>
                                     <FontAwesomeIcon
                                     icon="edit"
                                     className={"mr-2"}
                                 />
                                 Editer
                             </Button>
                             <Button size="sm" variant={"outline-danger"} onClick={()=>this.setState({toggleAlert : true})}>
                                <FontAwesomeIcon
                                     icon="times"
                                     className={"mr-2"}
                                 />
                                 Effacer
                             </Button>
                         </Col>
                    </Row>
                </Col>
            </Card>
            <Modal centered backdrop={false} show={this.state.toggleAlert} onHide={()=>this.setState({toggleAlert : false})}>
                <Modal.Header closeButton>
                    <Modal.Title>SUPPRESSION</Modal.Title>
                </Modal.Header>
                <Modal.Body>Voulez-vous vraiment supprime ce media ?</Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={()=>this.setState({toggleAlert : false})}>
                        Non
                    </Button>
                    <Button variant="danger" onClick={()=>this.handleDelete()}>
                        Oui
                    </Button>
                </Modal.Footer>
            </Modal>
        </Col>
    )}
}

export default ContentMedia;