import React, {Fragment} from 'react';
import {Router, Switch, Route} from 'react-router-dom';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import history from '../../../libs/history';
import SubNavBar from "../../components/SubNavBar";
import iaModerations from "./ModerationsTemperature";
import iaModerationsGender from "./ModerationsGender";

class iaIndex extends React.Component {
    constructor(props) {
        super(props);
        this.pathPage = '/ia-classifier';
        this.subNavBar = [
            {link : `${this.pathPage}`, label : "Modération Température"},
            {link : `${this.pathPage}/gender`, label : "Modération Genre"},
        ]
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(nextProps.location !== this.props.location){
            // this.props.resetModerationContents();
            return true;
        }
        return false;
    }


    render() {
        return (
            <Fragment>
                <SubNavBar {...this.props} subNav={this.subNavBar}/>
                <Router history={history}>
                    <Switch>
                        <Route path={`${this.pathPage}`} exact component={iaModerations}/>
                        <Route path={`${this.pathPage}/gender`} exact component={iaModerationsGender}/>
                    </Switch>
                </Router>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        currentUserInitialized: state.currentUser.initialized
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(iaIndex);
