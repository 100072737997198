import React from 'react';
import {Route, Router, Switch} from 'react-router-dom';
import {AbilityContext} from './ability-context'
import ability from './ability'

import history from './libs/history';
import RequireAuth from './libs/Authentication';

import Main from './ui/layouts/Main';
import Signin from './ui/pages/Signin';


export default (
    <AbilityContext.Provider value={ability}>
        <Router history={history}>
            <Switch>
                <Route path="/connexion" exact component={Signin}/>
                <Route path="/"  component={RequireAuth(Main)}/>
            </Switch>
        </Router>
    </AbilityContext.Provider>
);
