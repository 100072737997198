import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('KEYWORDS_WARNINGS').plugin(axiosPlugin, {template: "array"});

let fetchAction = redux.fetchActionGet();


redux.reducerForType('DELETE', (state,payload) => {
    let newState = {...state};
    if(payload.data && payload.data._id){
        newState.data = newState.data.filter(item => item._id.toString() !== payload.data._id.toString());
    }
    return newState;
});

export const TYPES = redux.types;

export const fetchKeywordsWarnings = (query = {}) => fetchAction(`/keywords-warnings?${queryString.stringify(query)}`);
export const reseKeywordsWarnings = () => redux.actions.reset();

export const keywordsWarnings = redux.reducer();