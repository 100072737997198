import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Col, Table} from "react-bootstrap";
import {LoadingBar, PageLoader} from "./Loader";
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {fetchStories} from "../../creators/stories";
import Aqp from '../../libs/mk-react-aqp';

import Pagination from "./Pagination";
import ColoneTn from "./ColoneTh";
import FormFields from "./FormFields";
import createLink from "../../libs/createLink";


let mapping = {
    "sort" : "trie"
};


class StoriesList extends React.Component {
    constructor(props) {
        super(props);
        this.filter = {
            limit: 20,
            page: 1,
            sort: "-createdAt",
            status : "0"
        };

        this.aqp = new Aqp(mapping, {
            blacklist: ['limit', 'page'],
            currentLink: "/moderations/histoires",
            filterDefault: this.filter
        });

        this.state = {
            filter: {...this.filter, ...this.aqp.query, page: this.props.match.params.page || 1}
        };

        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleStatus = this.handleStatus.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.fetchStories(this.state.filter);

    }


    fetchStories(filter){
        this.props.fetchStories(this.aqp.set(filter));
    }

    handleStatus(value){
        let filter = {...this.state.filter, page : 1, status : value};
        this.props.history.push("/moderations/histoires");
        this.setState({filter});
        this.fetchStories(filter);
        window.scrollTo(0, 0);
    }

    handleSort(value){
        let filter = {...this.state.filter, page : 1, sort : value};
        this.props.history.push("/moderations/histoires");
        this.setState({filter});
        this.fetchStories(filter);
        window.scrollTo(0, 0);
    }

    handleChangePage(page, link) {
        let filter = {...this.state.filter, page};
        if (page === 1) {
            this.props.history.push(link);
        } else {
            this.props.history.push(link + '/' + page);
        }
        this.setState({filter});
        this.fetchStories(filter);
        window.scrollTo(0, 0);
    }


    render() {
        let totalPage = this.props.storiesTotal ? Math.ceil(this.props.storiesTotal / this.state.filter.limit) : 0;
        return !this.props.storiesInitialized ? (<PageLoader />) : (
            <Fragment>
                <Col sm={2}>
                    <FormFields
                        group={{controlId : "status"}}
                        type={"select"}
                        controle={{
                            name : "status",
                            value : this.state.filter.status,
                            options : [{value : 0, label : "En Attente"}, {value : 1, label : "Publiées"}, {value : 2, label : "Refusées"}],
                            onChange : (event) =>{this.handleStatus(event.target.value)}
                        }}
                    />
                </Col>
                {this.props.storiesLoading && (<LoadingBar />)}
                <Table striped bordered hover>
                    <thead>
                    <tr>
                        <ColoneTn label={"Pseudo"} currentSort={this.state.filter.sort} sort={"username"} handleSort={this.handleSort}  />
                        <ColoneTn label={"SUJECT / RESUME"}  />
                        <ColoneTn label={"TAGS"}  />
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.stories.map(item =>(
                        <tr key={item._id}>
                            <td><a href={createLink("userProfile", item.owner)} target={"new"}>{item.owner.username}</a></td>
                            <td>
                                <h6>{item.title}</h6>
                                {item.excerpt.substring(0, 250)}
                            </td>
                            <td>{item.tags.map((tag, index) =>{
                                return <span key={tag._id}>{tag.title}, </span>
                            })}</td>

                            <td>
                                <Link to={`/moderations/histoires/${item._id}`} className="btn btn-outline-primary btn-sm">
                                    <FontAwesomeIcon
                                        icon="edit"
                                    />
                                </Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

                <Pagination currentPage={this.state.filter.page}
                            pagesCount={totalPage}
                            {...this.aqp.queryUrl(this.state.filter, mapping)}
                            handleChangePage={this.handleChangePage}/>

            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        storiesInitialized: state.stories.initialized,
        storiesLoading: state.stories.loading,
        stories: state.stories.data,
        storiesTotal: state.stories.total
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchStories
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StoriesList);
