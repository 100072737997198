import React, {useLayoutEffect, useRef, useState} from 'react';
import queryString from "querystring";

const defaultAvatar = `${process.env.PUBLIC_URL}/images/default_avatar.png`;

const classGenders = {
    1: 'gender-man',
    2: 'gender-woman',
    3: 'gender-couple',
    4: 'gender-couple-lesbian',
    5: 'gender-couple-gay',
    6: 'gender-trav',
    guest: 'guest',
    group: 'group'
};

const generateImageWorkerUri = (uri, query = {}) => {
    return !!uri ? `${uri}?${queryString.stringify(query)}` : defaultAvatar;
}

const Avatar = function ({
                             alt,
                             className = "",
                             height = 50,
                             width = 50,
                             avatar,
                             gender = "guest"
                         }) {
    const imgDivRef = useRef(null);
    const [_height, setHeight] = useState(height);
    const [_width, setWidth] = useState(width);

    useLayoutEffect(() => {
        setHeight(height || imgDivRef.current.clientHeight);
        setWidth(width || imgDivRef.current.clientWidth);
    }, [height, width, _height, _width]);

    let classGender = classGenders[gender] || classGenders["guest"];
    let avatarIsGroup = false;
    let avatarUri = avatar;
    let query = {height: _height, width: _width};
    //TODO add array slice
    if (Array.isArray(avatar)) {
        if (avatar.length === 1) avatarUri = avatar[0];
        else {
            avatarIsGroup = true;
            classGender = classGenders["group"];
        }
    }
    //TODO => images en erreur
    return (
        <div className={`avatar ${classGender}`}>
            <div className="avatar-container" ref={imgDivRef}>
                {avatarIsGroup
                    ? avatarUri.map((avatar, index) => <div key={index} className="group-img"
                                                            style={{backgroundImage: `url(${generateImageWorkerUri(avatar, query)})`}}/>)
                    : (
                        <img
                            src={generateImageWorkerUri(avatarUri, query)}
                            alt={alt}
                            className={className}
                        />
                    )
                }
            </div>
        </div>
    )
}

export default Avatar;
