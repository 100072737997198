import {put,takeEvery} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {TYPES as WARNING_TYPE} from '../creators/keywordsWarning';
import {TYPES as WARNINGS_TYPE} from '../creators/keywordsWarnings';


export function* updateSuccess(action) {
    try {
        yield put({type: WARNINGS_TYPE.DELETE, data : action.payload});
    } catch (error) {
    }
}


export function* watchKeywordWarning() {
    yield takeEvery(WARNING_TYPE.UPDATE_SUCCESS, updateSuccess);
}

export default watchKeywordWarning;

