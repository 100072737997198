import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button, Modal} from "react-bootstrap";
import {createType, resetType} from "../../../../creators/facettes.type";
import FormFacettesType from "../../../formulaires/facettes.type";

class CreateType extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleModalAdd : false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps){
        if(this.props.typeInitialized && (this.props.typeStatusCode === 200 || this.props.typeStatusCode === null) && this.state.toggleModalAdd){
            this.setState({toggleModalAdd : false})
            this.props.resetType();
        }
    }


    handleSubmit(data) {
        let type = {
            uri : data.uri,
            tpl : data.tpl
        }
        if (!this.props.typeLoading) {
            this.props.createType(type);
        }
    }

    render() {
        return (
        <Fragment>
            <Button variant="outline-primary" size="sm"  onClick={() =>this.setState({toggleModalAdd : true})}>
                Ajouter
            </Button>
            <Modal centered backdrop={false} show={this.state.toggleModalAdd} onHide={()=>this.setState({toggleModalAdd : false})}>
                <FormFacettesType
                    handleSubmit={this.handleSubmit}
                    onHide={() => this.setState({toggleModalAdd: false})}/>
            </Modal>
        </Fragment>
        )
    }

}


const mapStateToProps = (state) => {
    return {
        typeStatusCode : state.facettesType.error && state.category.error.statusCode,
        typeInitialized : state.facettesType.initialized,
        typeLoading : state.facettesType.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    createType,
    resetType
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CreateType);
