import React from 'react';

import {Button, Col, Row} from "react-bootstrap";
import _ from "lodash";
import {apiUrl} from "../../../../libs/api";
import queryString from "querystring";
import {SpinLoader} from "../../../components/Loader";

class CreateZipMedia extends React.Component {
    constructor(props) {
        super(props);
        this.limit = 5000;
        this.state = {
            initial : false,
            loading : false,
            data : []
        };

        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.lastUpdate !== this.props.lastUpdate) {
            this.setState({
                initial : false,
                loading : false,
                data : []
            });
        }
        return null;
    }

    handleSubmit(){
        if(!this.state.initial){
            let filter = this.props.filter || {};
            filter = {
                ...filter,
                limit : this.limit,
                page : 1
            }
            this.setState({initial : true, loading : true});
            apiUrl.get(
                `/medias/categorized?${queryString.stringify(filter)}`
            ).then(response =>{
                let data = _.get(response, "data") || [];
                this.setState({data, loading : false})
            })

        }
    }


    render() {
        return (
            <Row className={"justify-content-end"}>
                <Col sm={4}>
                    {!this.state.initial ? (
                        <Button onClick={()=> this.handleSubmit()}>Generer le zip</Button>
                    ) : (
                        this.state.loading ? (
                            <Button className={"pl-1 f-a-c"}><SpinLoader className={"xsmall mr-2"} /> Generer le zip</Button>
                        ) : (
                            this.state.data.length !== 0 ? (
                                <form action="https://static-php.voissa.net/media-archive.php" method="post" target="_blank">
                                    {this.state.data.map(media =>{
                                        let path = _.get(media,"file.path");
                                        return path ? (
                                            <input key={media._id} type="hidden" name="files[]" value={_.get(media,"file.path").replace("/albums/","")} />
                                        ) : null})}
                                    <input type="submit" value="Télécharger le zip" />
                                </form>
                            ) : (
                                <Button>Aucun media a zip</Button>
                            )

                        )
                    )}
                </Col>
            </Row>
        )
    }
}


export default CreateZipMedia;
