import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('LOCATION_REGIONS').plugin(axiosPlugin, {template: "array"});

let fetchLocationRegionsAction = redux.fetchActionGet();

export const fetchLocationRegions = (query = {}) => fetchLocationRegionsAction(`/locations?${queryString.stringify(query)}`);
export const resetLocationRegions = () => redux.actions.reset();

export const locationRegions = redux.reducer();