import React from "react";

import DraftBlockquote from "../custom-block/DraftBlockquote";
import DraftUploadedMedia from "../custom-block/DraftUploadedMedia";
import DraftSharedMedia from "../custom-block/DraftSharedMedia";
import DraftUrlMedia from "../custom-block/DraftUrlMedia";

export const mediaBlockRenderer = function(block) {
    if (block.getType() === "atomic") {
        return {
            component: AtomicBlock,
            editable: false,
            props : this.extendedProps
        };
    }
    return null;
};

const AtomicBlock = function(props) {
    if(props.block.getEntityAt(0) !== null && !props.blockProps.isComment){
        const entity = props.contentState.getEntity(props.block.getEntityAt(0));
        const data = entity.getData();
        const type = entity.getType();

        switch(type) {
            case"IMAGE" :
                return <img alt={""} src={`${process.env.REACT_APP_BASE_STATIC_WORKER}${data.src}&width=700`} style={{width: '100%'}}/>;

            case"QUOTE":
                return (
                    <DraftBlockquote _id={data._id} extendedProps={props.blockProps} {...props.blockProps} {...data} />
                );

            case"UPLOADED_MEDIA":
                return(
                    <DraftUploadedMedia src={data.uri} kind={data.kind} width={740} />
                );

            case"URL_MEDIA":
                return(
                    <DraftUrlMedia src={data.src} kind={data.kind} />
                );

            case"SHARED_MEDIA":
                return(
                    <DraftSharedMedia _id={data._id} kind={data.kind} />
                );

            default :
                return null;
        }
    } else return null;
};

