import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Aqp from '../../../libs/mk-react-aqp';
import {LoadingBar, PageLoader} from "../../components/Loader";

import Pagination from "../../components/Pagination";
import {fetchStatus, resetStatus} from "../../../creators/status";
import _ from "lodash";
import DateSince from "../../components/Date";
import createLink from "../../../libs/createLink";
import {Link} from "react-router-dom";
import moment from "moment-timezone";

let mapping = {
    "sort" : "trie",
};


class ContentsStatus extends React.Component {
    constructor(props) {
        super(props);
        let createdAtMax = moment().subtract(1, 'month').format('YYYY-MM-DD');

        this.filter = {
            limit: 100,
            page: 1,
            sort: "-createdAt",
            "createdAt>" : createdAtMax
        };
        this.aqp = new Aqp(mapping, {
            blacklist: ['limit', 'page'],
            currentLink: "/contents/status",
            filterDefault: this.filter
        });

        this.state = {
            filter: {...this.filter, ...this.aqp.query, page: this.props.match.params.page || 1},
        };

        this.handleFilter = this.handleFilter.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.fetchStatus(this.state.filter);
    }

    componentWillUnmount() {
        this.props.resetStatus();
    }

    fetchStatus(filter){
        this.props.fetchStatus(this.aqp.set(filter));
        this.setState({filter});
    }


    handleFilter(name,value){
        let filter = {...this.state.filter, [name] : value};
        this.fetchMedias(filter);
    }

    handleChangePage(page, link) {
        let filter = {...this.state.filter, page};

        if (page === 1) {
            this.props.history.push(link);
        } else {
            this.props.history.push(link + '/' + page);
        }
        this.setState({filter});
        this.fetchStatus(filter);
        window.scrollTo(0, 0);
    }


    render() {
        let totalPage = this.props.statusTotal ? Math.ceil(this.props.statusTotal / this.state.filter.limit) : 0;
        return !this.props.statusInitialized ? (<PageLoader />) :(
            <div id={"status"}>
                <h3>Les derniers statuts sur 1 mois</h3>
                {this.props.statusLoading ? (<LoadingBar />) : null}
                <div className={"status-list"}>
                    {this.props.status.map(item => (
                            <div key={item._id} className={"status-list-item"}>
                                <div className={"status-list-item-informations"}>
                                    <div className={"status-list-item-informations-username"}>Publié par <Link to={createLink('userProfileAdmin', item.author)} alt={"Profile admin"} >{_.get(item,"author.username")}</Link> pour <Link to={createLink('userProfileAdmin', item.owner)} alt={"Profile admin"} >{_.get(item,"owner.username")}</Link></div>
                                    <div className={"status-list-item-informations-date"}><DateSince date={item.createdAt} currentFormat={false}/></div>
                                </div>
                                <div className={"status-list-item-content"}>{item.text}</div>
                            </div>
                        )
                    )}
                </div>
                <Pagination currentPage={this.state.filter.page}
                            pagesCount={totalPage}
                            {...this.aqp.queryUrl(this.state.filter, mapping)}
                            handleChangePage={this.handleChangePage}/>
            </div>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        status : state.status.data,
        statusTotal : state.status.total,
        statusLoading : state.status.loading,
        statusInitialized : state.status.initialized,

    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchStatus,
    resetStatus,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContentsStatus);
