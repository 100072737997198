import React, {Component, Fragment} from 'react';
import Icon from "../../Icon";
import {Button, Modal} from "react-bootstrap";

import Uploader from "../../upload/SingleUpload";
import {AtomicBlockUtils, EditorState} from "draft-js";
import FormFields from "../../FormFields";

export default class ImageAdd extends Component {
    constructor(props){
        super(props);
        this.whereToUpload = "";
        this.state = {
            url: '',
            open: false,
            toggleMediaUrl: false,
            toggleMediaUpload: false
        };

        switch(this.props.kind){
            case "articles":
                this.whereToUpload = "/articles/uploads";
                break;
            case "topic":
                this.whereToUpload = "/topics/uploads";
                break;
            case "chat":
                this.whereToUpload = "/rooms/comments/uploads";
                break;
            case "topicComments":
                this.whereToUpload = "/topics/comments/uploads";
                break;
            case "userContent":
                this.whereToUpload = `/users/uploads`;
                break;
            default :
                this.whereToUpload = "";
        }

        this.handleShareMedia = this.handleShareMedia.bind(this);
        this.handleUploadedMedia = this.handleUploadedMedia.bind(this);
        this.addImage = this.addImage.bind(this);
    }

    openItem(){
        this.setState({open: !this.state.open});
    }

    // addImage = () => {
    //     const { editorState, onChange } = this.props;
    //     onChange(this.props.modifier(editorState, this.state.url), this.setState({open: false, url: "", toggleMediaUrl: false}));
    // };

    addImage(){
        const editorState = this.props.editorState;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity("URL_MEDIA", 'IMMUTABLE',{
            kind: "image",
            external: true,
            src: this.state.url,
        });

        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        const newEditorState = EditorState.set(
            editorState,
            {currentContent: contentStateWithEntity},
        );

        this.props.onChange(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '), this.setState({open: false, url: "", toggleMediaUrl: false}));
    }

    changeUrl = (evt) => {
        this.setState({ url: evt.target.value });
    };

    handleKeyPress(event) {
        if (event.key === "Enter") {
            this.addImage()
        }
    }

    handleShareMedia(data){
        data.forEach((item, index) => {
            setTimeout(()=> {
                const editorState = this.props.editorState;
                const contentState = editorState.getCurrentContent();
                const contentStateWithEntity = contentState.createEntity("SHARED_MEDIA", 'IMMUTABLE',{
                    _id: item._id,
                    external: false,
                    kind: item.type
                });

                const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

                const newEditorState = EditorState.set(
                    editorState,
                    {currentContent: contentStateWithEntity},
                );

                this.props.onChange(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '));
            }, 100 * index);
        });
    }

    handleUploadedMedia(data){
        const editorState = this.props.editorState;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity("UPLOADED_MEDIA", 'IMMUTABLE',{
            kind: data.type,
            uri: data.preview,
            _id : data._id
        });

        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

        const newEditorState = EditorState.set(
            editorState,
            {currentContent: contentStateWithEntity},
        );

        this.props.onChange(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '));
    }

    render() {
        const popoverClassName = this.state.open ?
            "addItemPopover" :
            "addItemClosedPopover";
        const buttonClassName = this.state.open ?
            "addItemPressedButton" :
            "addItemButton";

        return (
            <div className={`addItem mk-editor-btn ${this.props.kind === "chat" ? 'chat-add-img' : ''}`}>
                {this.props.kind === "chat" ? (
                    <Fragment>
                        <div className={"menu-item"} onClick={()=>this.setState({toggleMediaUpload: true})}><Icon type={"upload"} /></div>
                        <div className={"menu-item"} onClick={()=>this.setState({toggleMediaUrl: true})}><Icon type={"link"} /></div>
                    </Fragment>
                ) : (
                    <Fragment>
                        <span
                            className={buttonClassName}
                            onMouseUp={()=>this.openItem()}
                            type="button"
                        >
                    <Icon type={"media"} />
                </span>
                        <div className={popoverClassName}>
                            <div className={"menu-item"} onClick={()=>this.setState({toggleMediaUpload: true})}><Icon type={"upload"} /> Importer depuis l'ordinateur</div>
                            <div className={"menu-item"} onClick={()=>this.setState({toggleMediaUrl: true})}><Icon type={"link"} /> À partir d'une URL</div>
                        </div>
                    </Fragment>
                )}

                <Modal centered backdrop={false}  show={this.state.toggleMediaUpload} onHide={()=>this.setState({toggleMediaUpload: false, open: false})}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <Uploader uploadPath={this.whereToUpload}
                                  handleClose={()=>this.setState({toggleMediaUpload: false, open: false})}
                                  getUploadData={this.handleUploadedMedia} />
                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>

                <Modal centered backdrop={false}  show={this.state.toggleMediaUrl} onHide={()=>this.setState({toggleMediaUrl: false})}>
                    <Modal.Header closeButton>
                        Ajouter une image
                    </Modal.Header>
                    <Modal.Body>
                        <FormFields
                            group={{controlId : "url"}}
                            type={"input"}
                            label={{
                                label : "Url"
                            }}
                            controle={{
                                type : "text",
                                name : "url",
                                placeholder : "Url de l'image",
                                value : this.state.url,
                                onChange : (event) =>{this.setState({[event.target.name] : event.target.value})}
                            }}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="danger"  className={"btn-loading"} onClick={()=>this.setState({toggleMediaUrl: false, open: false})}>
                            Annuler
                        </Button>

                        <Button variant="primary" type="submit" className={"btn-loading"} onClick={this.addImage} >
                            Ajouter
                        </Button>
                    </Modal.Footer>
                </Modal>

            </div>
        );
    }
}