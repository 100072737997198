import React from 'react';
import {Router, withRouter, Switch, Route} from 'react-router-dom';
import history from '../../libs/history';
import PropTypes from 'prop-types';
import {Container, Row, Col} from "react-bootstrap";
import logo from '../../images/logo.png';
import NavBar from "./NavBar";
import Home from "../pages/home/Home";
import {fetchCurrentUser} from "../../creators/currentUser";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Members from "../pages/Members";
import Moderations from "../pages/moderations/Moderations";
import Member from "../pages/member/Member";
import Reportings from "../pages/Reportings";
import Reporting from "../pages/Reporting";
import Tags from "../pages/Tags";
import Stats from "../pages/stats/Stats";
import {Can} from "../../ability-context";
import Contents from "../pages/contents/Contents";
import {logoutUser} from "../../creators/login";
import MoteurAFacettes from "../pages/facettes/Index";
import Categorizations from "../pages/categorizations/Index";
import KeywordsWarnings from "../pages/keywordsWarnings/KeywordsWarnings";
import iaIndex from "../pages/ia/Index";
import AlertMonitoring from "../components/alertMonitoring/AlertMonitoring";
import Configurations from "../pages/configurations/Configurations";


// const token = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME);

class Main extends React.Component {
    // constructor(props) {
    //     super(props);
    //     // if (token) {
    //     //     this.props.fetchCurrentUser();
    //     // }
    // }

    static propTypes() {
        return {
            match: PropTypes.object.isRequired,
            location: PropTypes.object.isRequired,
            history: PropTypes.object.isRequired
        }
    }

    // shouldComponentUpdate(nextProps, nextState, nextContext) {
    //     if(nextProps.location !== this.props.location){
    //         return true;
    //     }
    //     if(nextProps.currentUserInitialized !== this.props.currentUserInitialized){
    //         return true;
    //     }
    //     return false;
    // }


    render() {

        return (
            <Can action={"is-admin"} subject="all">
            <Container>
                <Row>
                    <Col xs={10}>
                        <img src={logo} alt={"logo"} />
                    </Col>
                    <Col xs={2} className={"pt-2"}>
                        <span className={"cursor-pointer text-primary"} onClick={()=>this.props.logoutUser()}>Deconnexion</span>
                    </Col>
                </Row>
                <AlertMonitoring />
                <NavBar {...this.props} />
                <Router history={history}>
                    <Switch>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/members/:page([0-9]+)?"  component={Members}/>
                        <Route path="/members/:id([A-Za-z0-9_-]+)"   component={Member}/>
                        <Route exact path="/reportings/:page([0-9]+)?"  component={Reportings}/>
                        <Route path="/reportings/:id([A-Za-z0-9_-]+)/:page([0-9]+)?"   component={Reporting}/>
                        <Route path="/tags" component={Tags}/>
                        <Route path="/configurations" component={Configurations}/>
                        <Route path="/stats" component={Stats}/>
                        <Route path="/moderations" component={Moderations}/>
                        <Route path="/contents" component={Contents}/>
                        <Route path="/moteur-a-facettes" component={MoteurAFacettes}/>
                        <Route path="/keywords-warnings" component={KeywordsWarnings}/>
                        <Route path="/ia-classifier" component={iaIndex} />
                    </Switch>
                </Router>
            </Container>
                <Router history={history}>
                    <Switch>
                        <Route path="/categorizations" component={Categorizations}/>
                    </Switch>
                </Router>
            </Can>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        currentUserInitialized: state.currentUser.initialized
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCurrentUser,
    logoutUser
}, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
