import React from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import FormFields from "../components/FormFields";
import {resetCategory} from "../../creators/category";
import createSlug from "../../libs/slug";



class FormCategory extends React.Component {
    constructor(props) {
        super(props);
        this.isUpdate = this.props._id || false;
        this.order = Array.from(new Array(100), (x,i) => i+1).map(item => {
            return {
                "title" : item,
                "_id" : item
            }});
        this.initialState =  {
            toggleAlert : false,
            title : this.props.title || "",
            slug : this.props.slug || "",
            parentId : this.props.parentId || "",
            genre : this.props.genre || "neutre",
            isMultipleSelection : this.props.isMultipleSelection || false,
            metaDescription : this.props.metaDescription || "",
            order : this.props.order || 50
        };
        this.state = {
            ...this.initialState
        }
        this.createSlug = this.createSlug.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentWillUnmount(){
        this.props.resetCategory();
    }

    createSlug(){
        if(this.state.slug === ""){
            this.setState({slug : createSlug(this.state.title)});
        }
    }

    fetchCategories(categories,level = 0, data=[]){
        let tire= '-';
        categories.forEach(category=>{
            data.push({_id : category._id, title : `${level >0 ? tire.repeat(level) : ""}${category.title}`});
            if(category.childrens){
                this.fetchCategories(category.childrens, level+1, data);
            }
        });
        return data;
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.props.categoryLoading) {
            this.props.handleSubmit({
                title : this.state.title,
                slug : this.state.slug,
                parentId : this.state.parentId,
                genre : this.state.genre,
                isMultipleSelection : this.state.isMultipleSelection,
                metaDescription : this.state.metaDescription,
                order : this.state.order
            })
        }
    }


    render() {
        //console.log(this.props.isEnableMulti);

        return (
            <Form onSubmit={(e) => this.handleSubmit(e)}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.isUpdate ? "Modifier une categories" : "Ajouter une categorie"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormFields
                        group={{controlId : "order"}}
                        type={"select"}
                        label={{
                            label : "Order"
                        }}
                        controle={{
                            name : "order",
                            value : this.state.order,
                            disabled : !this.isUpdate,
                            options : this.order,
                            optionField : {value : "_id", label : "title"},
                            //this.fetchCategories(this.props.categories)
                            onChange : (event) =>{this.setState({[event.target.name] : event.target.value})},
                            isInvalid : this.props.categoryError.order
                        }}
                    />
                    <FormFields
                        group={{controlId : "title"}}
                        type={"input"}
                        label={{
                            label : "Nom"
                        }}
                        controle={{
                            type : "text",
                            name : "title",
                            value : this.state.title,
                            required : true,
                            onBlur : () => {this.createSlug()},
                            onChange : (event) =>{this.setState({[event.target.name] : event.target.value})},
                            isInvalid : this.props.categoryError.title
                        }}
                    />

                    <FormFields
                        group={{controlId : "slug"}}
                        type={"input"}
                        label={{
                            label : "Slug"
                        }}
                        controle={{
                            type : "text",
                            name : "slug",
                            value : this.state.slug,
                            disabled : this.isUpdate,
                            required : true,
                            onChange : (event) =>{this.setState({[event.target.name] : event.target.value})},
                            isInvalid : this.props.categoryError.slug
                        }}
                    />

                    <FormFields
                        group={{controlId : "parentId"}}
                        type={"select"}
                        label={{
                            label : "Parent"
                        }}
                        controle={{
                            name : "parentId",
                            value : this.state.parentId,
                            disabled : this.isUpdate,
                            options : [{_id : "", title : "Aucun"}, ...this.fetchCategories(this.props.categories)],
                            optionField : {value : "_id", label : "title"},
                            //this.fetchCategories(this.props.categories)
                            onChange : (event) =>{this.setState({[event.target.name] : event.target.value})},
                            isInvalid : this.props.categoryError.parentId
                        }}
                    />

                    <FormFields
                        group={{controlId : "metaDescription"}}
                        type={"input"}
                        label={{
                            label : "Meta Description"
                        }}
                        controle={{
                            as : "textarea",
                            type : "text",
                            name : "metaDescription",
                            value : this.state.metaDescription,
                            required : false,
                            onChange : (event) =>{this.setState({[event.target.name] : event.target.value})},
                            isInvalid : this.props.categoryError.metaDescription
                        }}
                    />
                    <FormFields
                        group={{controlId : "genre"}}
                        type={"select"}
                        label={{
                            label : "Genre"
                        }}
                        controle={{
                            name : "genre",
                            value : this.state.genre,
                            options : [{id : "neutre", title : "Neutre"}, {id : "masculin", title : "Masculin"}, {id : "feminin", title : "Feminin"} ,{id : "masculin-pluriel", title : "Masculin pluriel"} ,{id : "feminin-pluriel", title : "Feminin pluriel"}],
                            optionField : {value : "id", label : "title"},
                            //this.fetchCategories(this.props.categories)
                            onChange : (event) =>{this.setState({[event.target.name] : event.target.value})},
                            isInvalid : this.props.categoryError.parentId
                        }}
                    />
                    {this.props.isEnableMulti ? (
                        <FormFields
                            group={{controlId : "isMultipleSelection"}}
                            type={"select"}
                            label={{
                                label : "Selection Multiple ?"
                            }}
                            controle={{
                                name : "isMultipleSelection",
                                value : this.state.isMultipleSelection,
                                options : [{id : false, title : "Non"}, {id : true, title : "Oui"}],
                                optionField : {value : "id", label : "title"},
                                //this.fetchCategories(this.props.categories)
                                onChange : (event) =>{this.setState({[event.target.name] : event.target.value})},
                                isInvalid : this.props.categoryError.isMultipleSelection
                            }}
                        />
                    ) : null}

                    {this.props.categoryStatusCode === 409 ? <p className={"text-danger"}>La categorie existe déja</p> : null}
                </Modal.Body>
                <Modal.Footer>
                    {this.isUpdate ? (<div className={"w-100"}><Button variant="danger" onClick={()=>this.setState({toggleAlert : true})}>
                        Supprimer
                    </Button></div>) : null}
                    <Button variant="primary" type={"submit"}>
                        Valider
                    </Button>
                    <Button variant="danger" onClick={()=>this.props.onHide()}>
                        Annuler
                    </Button>
                </Modal.Footer>
                <Modal centered backdrop={false} show={this.state.toggleAlert} onHide={()=>this.setState({toggleAlert : false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>SUPPRESSION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className={"text-danger font-weight-bold"}>AVERTISSEMENT</p>
                        <p className={"text-danger"}>NE PAS LE FAIRE A MOINS D'ETRE CERTAIN DE CE QUE VOUS FAITES !</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.setState({toggleAlert : false})}>
                            Non
                        </Button>
                        <Button variant="danger" onClick={()=>this.props.handleDelete()}>
                            Oui
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Form>
        )
    }
}


const mapStateToProps = (state) => {

    return {
        category : state.category.data,
        categoryError : state.category.error && state.category.error.errors,
        categoryStatusCode : state.category.error && state.category.error.statusCode,
        categoryLoading : state.category.loading,
        categoryInitialized : state.category.initialized,

        categories : state.categories.data,
        categoriesLoading : state.categories.loading,
        categoriesInitialized : state.categories.initialized
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    resetCategory
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormCategory);
