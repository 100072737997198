import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('USER_ROOM').plugin(axiosPlugin);

let fetchRoomAction = redux.fetchActionGetOne();


export const TYPES = redux.types;

export const fetchUserRoom = (roomId) => fetchRoomAction(`/rooms/admin/room/${roomId}`);

export const resetUserRoom = () => redux.actions.reset();

export const userRoom = redux.reducer();