import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('PRODUCTS').plugin(axiosPlugin, {template: "array"});

let fetchProductsAction = redux.fetchActionGet();

export const fetchProducts = (query = {}) => fetchProductsAction(`/products/admin-all?${queryString.stringify(query)}`);
export const resetProducts = () => redux.actions.reset();

export const products = redux.reducer();