import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('TAGS_WARNING').plugin(axiosPlugin, {template: "array"});

let fetchTagsProtagonistAction = redux.fetchActionGet();

// export const fetchTagsWarning = (query = {}) => fetchTagsProtagonistAction(`/tags?${queryString.stringify(query)}`);
export const fetchTagsWarning = (query = {}) => fetchTagsProtagonistAction(`/tags-list/warning/childrens?${queryString.stringify(query)}`);

export const tagsWarning = redux.reducer();


