import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('LOCATION_COUNTRIES').plugin(axiosPlugin, {template: "array"});

let fetchLocationCountriesAction = redux.fetchActionGet();

export const fetchLocationCountries = (query = {}) => fetchLocationCountriesAction(`/locations?${queryString.stringify(query)}`);
export const resetLocationCountries = () => redux.actions.reset();

export const locationCountries = redux.reducer();