import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Badge, ListGroup} from "react-bootstrap";
import CreateCategories from "./CreateCategory";
import EditCategory from "./EditCategory";


class CategoriesList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isEdit : false,
            currentId : null
        }

    }

    render() {
        let limit = this.props.limit || 0;
        return (
            <Fragment>
                <ListGroup variant="flush">
                    {this.props.categories.map(category =>
                        <ListGroup.Item key={category._id}>
                            <div className={"cursor-pointer"} onClick={()=>this.setState({isEdit : true, currentId : category._id})}>
                                {category.title}
                                <Badge className={"ml-2"} variant="info">{category.count}</Badge>
                            </div>
                            {category.childrens ? <CategoriesList categories={category.childrens} limit={limit} /> : null}
                            {category.parents.length < limit ? <CreateCategories parentId={category._id} /> : null}
                        </ListGroup.Item>)}
                </ListGroup>
                {this.state.isEdit ? <EditCategory toggle={this.state.isEdit} handleToggle={()=>this.setState({isEdit : false, currentId : null})} currentId={this.state.currentId} /> : null}
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        categories : state.categories.data,
        categoiesTotal : state.categories.total,
        lastUpdate : state.categories.lastUpdate
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CategoriesList);
