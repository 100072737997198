import React, {Fragment} from 'react';
import RechartsCustom from "../../components/RechartsCustom";
import Nav from "react-bootstrap/Nav";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {fetchStatsSiteDayly, resetStatsSiteDayly} from "../../../creators/stats.site.dayly";
import {fetchStatsSiteMonth, resetStatsSiteMonth} from "../../../creators/stats.site.month";


const initStats = {
    signup : [],
    confirmedEmail : [],
    medias : [],
    articles : [],
    forums : [],
    status : [],
    visitsMembers : [],
    rooms : [],
    transactions : []
}

const initStatsMonth = {
    signup : [],
    confirmedEmail : [],
    medias : [],
    articles : [],
    forums : [],
    status : [],
    visitsMembers : [],
    rooms : [],
    transactions : []
}

const colorStroke = {
    like : "#ff0000",
    photos : "#30ae00",
    videos : "#941fff",
    comments : "#ff7300",
    articles : "#30ae00",
    topics : "#30ae00",
    medias : "#30ae00",
    signup : "#30ae00",
    confirmedEmail : "#30ae00",
    visitsMembers : "#30ae00",
    clicksToPay : "#ff0000",
    within_24h : "#30ae00",
    from_24h_to_7d : "#941fff",
    from_7d_to_30d : "#ff7300",
    over_30d : "#ff0000"
}

const datakey={
    medias : [
        {
            key : "likes",
            stroke : colorStroke["like"]
        },
        {
            key : "photos",
            stroke : colorStroke["photos"]
        },
        {
            key : "videos",
            stroke : colorStroke["videos"]
        },
        {
            key : "comments",
            stroke : colorStroke["comments"]
        },
    ],
    articles : [
        {
            key : "likes",
            stroke : colorStroke["like"]
        },
        {
            key : "articles",
            stroke : colorStroke["articles"]
        },
        {
            key : "comments",
            stroke : colorStroke["comments"]
        },
    ],
    forums : [
        {
            key : "topics",
            stroke : colorStroke["topics"]
        },
        {
            key : "comments",
            stroke : colorStroke["comments"]
        },
    ],
    status : [
        {
            key : "status",
            stroke : colorStroke["status"]
        },
        {
            key : "comments",
            stroke : colorStroke["comments"]
        },
    ],
    rooms : [
        {
            key : "comments",
            stroke : colorStroke["comments"]
        },
        {
            key : "photos",
            stroke : colorStroke["photos"]
        },
        {
            key : "videos",
            stroke : colorStroke["videos"]
        }
    ],
    signup : [
        {
            key : "signup",
            stroke : colorStroke["signup"]
        }
    ],
    confirmedEmail : [
        {
            key : "confirmedEmail",
            stroke : colorStroke["confirmedEmail"]
        }
    ],
    visitsMembers : [
        {
            key : "visitsMembers",
            stroke : colorStroke["visitsMembers"]
        },
        {
            key: "clicksToPay",
            stroke: colorStroke["clicksToPay"]
        }
    ],
    transactions : [
        {
            key : "within_24h",
            stroke : colorStroke["within_24h"]
        },
        {
            key : "from_24h_to_7d",
            stroke : colorStroke["from_24h_to_7d"]
        },
        {
            key : "from_7d_to_30d",
            stroke : colorStroke["from_7d_to_30d"]
        },
        {
            key : "over_30d",
            stroke : colorStroke["over_30d"]
        }
    ],
};


class Home extends React.Component {
    constructor(props) {
        super(props);
        this.pathPage = '/';
        this.subNavBar = [
            {stat : "signup", label : "Inscriptions"},
            {stat : "confirmedEmail", label : "Confirmations Email"},
            {stat : "medias", label : "Medias"},
            {stat : "articles", label : "Blog"},
            {stat : "forums", label : "Forum"},
            {stat : "status", label : "Statuts"},
            {stat : "visitsMembers", label : "Visite de membres"},
            {stat : "transactions", label : "Transactions"},
        ];
        this.state = {
            currentStat : "",
            initStatsMonth : false,
            initStatsDayly : false
        }
        this.statsDayly = {...initStats};
        this.statsMonth = {...initStatsMonth};
        this.props.fetchStatsSiteDayly();
        this.props.fetchStatsSiteMonth();
    }




    componentDidUpdate(prevProps) {
        if(prevProps.statsDaylyInitialized !== this.props.statsDaylyInitialized){
            let statsDayly = {...this.statsDayly};
            this.props.statsDayly.forEach(stats =>{
                statsDayly.medias.push({date : stats.date, ...stats.medias});
                statsDayly.signup.push({date : stats.date, signup : stats.users.signup});
                statsDayly.confirmedEmail.push({date : stats.date, confirmedEmail : stats.users.confirmedEmail});
                statsDayly.visitsMembers.push({date : stats.date, visitsMembers : stats.users.visitsMembers, clicksToPay : stats.users.clicksToPay});
                statsDayly.articles.push({date : stats.date, ...stats.articles});
                statsDayly.forums.push({date : stats.date, ...stats.forums});
                statsDayly.status.push({date : stats.date, ...stats.status});
                statsDayly.rooms.push({date : stats.date, ...stats.rooms});
                statsDayly.transactions.push({date : stats.date, ...stats.transactions});
            });
            this.statsDayly = statsDayly;
            this.setState({currentStat : "signup", initStatsDayly : true});
        }
        else if(prevProps.statsMonthInitialized !== this.props.statsMonthInitialized){
            let statsMonth = {...this.statsMonth};
            this.props.statsMonth.forEach(stats =>{
                statsMonth.medias.push({date : stats.date, ...stats.medias});
                statsMonth.signup.push({date : stats.date, signup : stats.users.signup});
                // this.statsMonth.signup.push({date : statsMonth.date, signup : 10});
                statsMonth.confirmedEmail.push({date : stats.date, confirmedEmail : stats.users.confirmedEmail});
                statsMonth.visitsMembers.push({date : stats.date, visitsMembers : stats.users.visitsMembers, clicksToPay : stats.users.clicksToPay});
                statsMonth.articles.push({date : stats.date, ...stats.articles});
                statsMonth.forums.push({date : stats.date, ...stats.forums});
                statsMonth.status.push({date : stats.date, ...stats.status});
                statsMonth.rooms.push({date : stats.date, ...stats.rooms});
                statsMonth.transactions.push({date : stats.date, ...stats.transactions});
            });
            this.statsMonth = statsMonth;
            this.setState({currentStat : "signup", initStatsMonth : true});
        }
    }



    render(){
        let { currentStat = "", initStatsDayly = false, initStatsMonth = false} = this.state;

        return (
            <Fragment>
                <Nav variant="pills" className='mb-4'>
                    {this.subNavBar.map(item =>(
                        <Nav.Item key={item.label}>
                            <Nav.Link onClick={() => this.setState({currentStat : item.stat})} className ={`${currentStat === item.stat ? "active" : null}`}>{item.label}</Nav.Link>
                        </Nav.Item>
                    ))}
                </Nav>

                {initStatsDayly ? <RechartsCustom key={currentStat} data={this.statsDayly[currentStat]} datakey={datakey[currentStat]} /> : null}
                {initStatsMonth ? <RechartsCustom key={`${currentStat}_month`} data={this.statsMonth[currentStat]} datakey={datakey[currentStat]} /> : null}
            </Fragment>
        )
    }
}

const mapStateToProps = (state) => {
    
    return {
        statsDayly: state.statsSiteDayly.data,
        statsDaylyInitialized: state.statsSiteDayly.initialized,
        statsDaylyLoading: state.statsSiteDayly.loading,

        statsMonth: state.statsSiteMonth.data,
        statsMonthInitialized: state.statsSiteMonth.initialized,
        statsMonthLoading: state.statsSiteMonth.loading
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchStatsSiteDayly,
    resetStatsSiteDayly,
    fetchStatsSiteMonth,
    resetStatsSiteMonth,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Home);