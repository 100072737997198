import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('TAGS_STORIES').plugin(axiosPlugin, {template: "array"});

let fetchTagsStoriesAction = redux.fetchActionGet();

export const fetchTagsStories = (query = {}) => fetchTagsStoriesAction(`/tags-list/story/childrens?${queryString.stringify(query)}`);

export const tagsStories = redux.reducer();