import React from "react";

class BlockStyleButton extends React.Component {
    onToggle = e => {
        e.preventDefault();
        this.props.onToggle(this.props.style);
    };

    render() {
        return (
            <span className={`mk-editor-btn ${this.props.active && 'active'}`} onClick={this.onToggle}>
				{this.props.label}
			</span>
        );
    }
}

export default BlockStyleButton;
