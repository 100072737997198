import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('LOCATION_COUNTIES').plugin(axiosPlugin, {template: "array"});

let fetchLocationCountiesAction = redux.fetchActionGet();

export const fetchLocationCounties = (query = {}) => fetchLocationCountiesAction(`/locations?${queryString.stringify(query)}`);
export const resetLocationCounties = () => redux.actions.reset();

export const locationCounties = redux.reducer();