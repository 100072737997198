import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('TAGS_LISTES').plugin(axiosPlugin, {template: "array"});

let fetchTagsListesAction = redux.fetchActionGet();

redux.reducerForType('DELETE', (state,payload) => {
    let newState = {...state};
    if(payload.data && payload.data._id){
        newState.data = newState.data.filter(item => item._id.toString() !== payload.data._id.toString());
        newState.total--;
        if(newState.total < 0){
            newState.total = 0;
        }
    }
    return newState;
});

export const TYPES = redux.types;

export const fetchTagsListes = (query) => fetchTagsListesAction(`/tags-list/?${queryString.stringify(query)}`);

export const resetTagsListes = () => redux.actions.reset();


export const tagsListes = redux.reducer();