import React from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {PageLoader} from "../components/Loader";
// import createLink from "../../libs/createLink";
import {fetchTagsList, resetTagsList} from "../../creators/tags.list";
import FormTagsList from "../formulaires/tagsList";
import Autocomplete from "../components/Autocomplet";
import {Form} from "react-bootstrap";
import {fetchTags} from "../../creators/tags";
import {createTagListChildrens, fetchTagListChildrens, resetTagListChildrens, removeTagListChildrens} from "../../creators/tags.list.childrens";
import TagList from "../components/TagList";


class tagList extends React.Component {
    constructor(props) {
        super(props);
        this.tagListId = this.props.match && this.props.match.params && this.props.match.params.tagListId;

        this.state = {
            limit : 60,
        };
        this.props.fetchTagsList(this.tagListId);
        this.props.fetchTags({limit : 20});
        this.props.fetchTagListChildrens(this.tagListId,{...this.state});
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.removeTag = this.removeTag.bind(this);
    }

    displayTagLabel(item){
        return item.title;
    }

    handleChange(search){
        this.props.fetchTags({limit : 20, search});
    }

    handleSelect(item){
        this.props.createTagListChildrens(this.tagListId,{tagId : item._id});
    }

    removeTag(tagId){
        this.props.removeTagListChildrens(this.tagListId,{tagId : tagId});
    }

    componentWillUnmount() {
        this.props.resetTagsList();
        this.props.resetTagListChildrens();
    }

    render() {
        return !this.props.tagsListInitialized ? (<PageLoader />) :(
            <div className={"mb-5"}>
                <FormTagsList tagsList={this.props.tagsList} />
                <Form.Label >Ajouter un tag a la liste</Form.Label>
                <Autocomplete
                    results={this.props.tags}
                    display={this.displayTagLabel}
                    handleChange={this.handleChange}
                    handleSelect={this.handleSelect}
                    loading={this.props.tagsLoading}
                />

                <TagList
                    options={this.props.tagsListChildrens}
                    optionField = {{
                        value : "_id",
                        label : "title"
                    }}
                    remove = {{
                        isRemove : true,
                        remove : this.removeTag
                    }}
                    btn = {{
                        className : "btn btn-primary m-2 nocursor-pointer",
                        type : "button",
                        as : "input"
                    }}
                />

            </div>

        )
    }
}


const mapStateToProps = (state) => {
    return {
        tagsList : state.tagsList.data,
        tagsListLoading : state.tagsList.loading,
        tagsListInitialized : state.tagsList.initialized,
        tags : state.tags.data,
        tagsLoading : state.tags.loading,
        tagsInitialized : state.tags.initialized,

        tagsListChildrens : state.tagsListChildrens.data,
        tagsListChildrensLoading : state.tagsListChildrens.loading,
        tagsListChildrensInitialized : state.tagsListChildrens.initialized,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchTagsList,
    resetTagsList,
    fetchTags,
    fetchTagListChildrens,
    resetTagListChildrens,
    createTagListChildrens,
    removeTagListChildrens
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(tagList);
