import React, {Component} from 'react';
import {LoadingBar} from "./Loader";
import classNames from 'classnames';
import _ from 'lodash';

export default class Autocomplete extends Component {
    static defaultProps = {
        results: [],
        placeholder: '',
        error: false,
        loading: false,
        display: item => item,
        handleChange: () => {
        },
        handleSelect: () => {
        }
    };

    constructor(props) {
        super(props);
        this.state = {
            focus: false,
            mouseOver: false,
            search: ''
        };
        this.throttleHandleChange = _.debounce(this.props.handleChange, 400);
    }

    _onBlur() {
        if (!this.state.mouseOver) {
            this.setState({focus: false, search: ''});
        }
    }

    _onFocus() {
        this.setState({focus: true, search: ''});
        this.props.handleChange('');
    }

    _handleChange(search) {
        this.setState({search, selected: ''});
        this.throttleHandleChange(search);
    }

    _handleSelect(item) {
        this.setState({search: '', focus: false});
        this.props.handleSelect(item);
    }

    _getDisplay(item) {
        try {
            return this.props.display(item);
        }
        catch (e) {
            return item
        }
    }

    render() {

        let showDelete = typeof this.props.handleDelete === 'function' && this.props.selected && !this.props.disabled;
        let inputValue = !this.state.focus ? this._getDisplay(this.props.selected) || '' : this.state.search;
        return (
            <div className="position-relative w-100">
                <div className="input-group">
                    <input type="text" className={classNames('form-control', {
                        'is-invalid': this.props.error,
                        'rounded': this.props.error && !showDelete
                    })}
                           onFocus={this._onFocus.bind(this)}
                           onBlur={this._onBlur.bind(this)}
                           placeholder={this.props.placeholder}
                           value={inputValue}
                           disabled={this.props.disabled}
                           onChange={(event) => this._handleChange(event.target.value)}/>
                    {(showDelete) && (
                        <div className="input-group-append" onClick={this.props.handleDelete}>
                            <div className="btn btn-outline-danger rounded-right">Supprimer</div>
                        </div>
                    )}
                    {this.props.error && (
                        <div className="invalid-feedback">
                            {this.props.error}
                        </div>
                    )}
                </div>
                {this.state.focus && (
                    <ul
                        className="list-group position-absolute w-100"
                        onMouseEnter={() => this.setState({mouseOver: true})}
                        onMouseLeave={() => this.setState({mouseOver: false})}
                        style={{top: '46px', zIndex: 9999, maxHeight: 200, overflowY: 'scroll'}}>
                        {this.props.loading && (<LoadingBar className={"position-init"} />)}
                        {this.props.results.map((item, key) => (
                            <li
                                key={key}
                                onClick={() => this._handleSelect(item)}
                                className="list-group-item list-group-item-action cursor-pointer">
                                {this._getDisplay(item)}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        )
    }
}