import React from 'react';
import ReactJWPlayer from "react-jw-player";

const DraftUploadedMedia = (props) => {
    return (
        <div className={"draft-uploaded-media"}>
            {props.kind === "image" ? (
                <img  alt={""} src={props.src} />
            ) : props.kind === "video" && (
                <ReactJWPlayer
                    playerScript='https://content.jwplatform.com/libraries/o4p642J0.js'
                    file={props.src}
                    isAutoPlay={true}
                    customProps={{
                        width: "100%",
                    }}
                    aspectRatio={"16:9"}
                    className={"player"}
                />
            )}

        </div>
    )
};

export default DraftUploadedMedia;