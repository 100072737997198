import _ from 'lodash';
import single from './templates/single';
import array from './templates/array';
import multiSingle from './templates/multiSingle';
import multiArray from './templates/multiArray';

const templates = {
    single,
    array,
    multiSingle,
    multiArray
};

export function createPayload(method, url, data) {
    let base = {
        request: {
            url,
            method
        }
    };
    if (!!data) base.request.data = data;
    return base;
}

export default function (reduxCreator, {template = "single", templateOption = {}}) {
    const mapping = {
        Get: 'GET',
        GetOne: 'GET',
        Post: 'POST',
        Create: 'POST',
        Update: 'PATCH',
        Delete: 'DELETE'
    };

    if (typeof template === 'string') template = templates[template](templateOption);

    if (template.enhance) template.enhance(reduxCreator);
    if (template.INITIAL_STATE) reduxCreator.initialState = template.INITIAL_STATE;
    const fetchAction = (method = 'GET', type = 'GET', opts = {
        reducer: null,
        failReducer: null,
        successReducer: null
    }) => {
        let action = reduxCreator.action(type, (url, data, payloadOpts = {}) => ({
            payload: createPayload(method, url, data),
            ...payloadOpts
        }));

        reduxCreator.reducerForType(type, opts.reducer || template.reducer);
        reduxCreator.reducerForType(`${type}_FAIL`, opts.failReducer || template.reducerFail);
        reduxCreator.reducerForType(`${type}_SUCCESS`, opts.successReducer || template.reducerSuccess);
        return action;
    };

    _.forIn(mapping, (value, key) => {
        reduxCreator[`fetchAction${key}`] = (type = key, opts) => {
            return fetchAction(value, type, opts);
        };
    });
};