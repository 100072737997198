import React,{Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import _ from "lodash";
import {Button, Col, Image, Modal, Row} from "react-bootstrap";
import ViewCount from "./components/ViewCount";
import CardUser from "./components/CardUser";
import Categories from "./components/Categories";
import {SpinLoader} from "../../components/Loader";
import {
    fetchCategorizedMedia,
    refuserCategorizedMedia, resetCategorizedMedia,
    updateCategorizedMedia
} from "../../../creators/categorized.media";


class CategorizedManualPhoto  extends React.Component {
    constructor(props) {
        super(props);
        this.pathPage = `/categorizations/manual-photos`;

        this.state = {
            categories : [],
            toggleAlert : false
        }

        this.updateCategories = this.updateCategories.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.props.resetCategorizedMedia();
        this.props.fetchCategorizedMedia(_.get(this.props, "match.params.mediaId"));
    }

    componentWillUnmount() {
        this.props.resetCategorizedMedia();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.categorizedMedia._id !== this.props.categorizedMedia._id) {
            let categories = this.props.categorizedMedia.categories || [];

            this.setState({categories});
        }
        return null;
    }

    updateCategories(categories){
        this.setState({categories});
    }

    onSubmit(){
        let categories = this.state.categories;
        if(categories.length >0 && this.props.categorizedMedia._id){
            this.props.updateCategorizedMedia(this.props.categorizedMedia._id, {categories}).then(response =>{
                let  statusCode = _.get(response, "error.response.statusCode");
                let  message = _.get(response, "error.response.message");
                if(statusCode){
                    alert(message)
                }
                else{
                    this.props.fetchCategorizedMedia(_.get(this.props, "match.params.mediaId"));
                }
            }).catch(e=>{alert(e);})
        }
        else{
            alert("Aucune catégories selectionnée");
        }
    }

    handleDelete(){
        //update Media
        this.props.refuserCategorizedMedia(this.props.categorizedMedia._id).then(response =>{
            let  statusCode = _.get(response, "error.response.statusCode");
            let  message = _.get(response, "error.response.message");
            if(statusCode){
                alert(message)
            }
            else{
                let filter = {...this.state.filter};
                this.fetchMedia(filter);
            }
        }).catch(e=>{alert(e);})

        this.setState({toggleAlert : false});

    }

    render() {

        return (
            <Fragment>
                <h1>Categorisation manuelle d'une photo</h1>
                <Row>
                    <Col sm={6} md={12} xl={6} lg={6}>
                        <Image src={this.props.categorizedMedia.previewUrl ? `${this.props.categorizedMedia.previewUrl}?width=500` : ""} fluid className={"big-thumb"}  />
                        <ViewCount
                            count={this.props.categorizedMedia.count}
                        />
                        <CardUser owner={this.props.categorizedMedia.owner}/>
                    </Col>
                    <Col sm={12} md={12} xl={6} lg={6}>
                        <Categories updateCategories={this.updateCategories} valuesCheck={this.state.categories} />
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} className={"d-flex justify-content-center mt-4"}>
                        <Button variant="primary" onClick={() => this.onSubmit()}>
                            {this.props.categorizedMediaLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                            Enregistrer
                        </Button>
                        <Button variant="danger" className={"ml-4"} onClick={()=>this.setState({toggleAlert : true})}>
                            Cette photo ne peut pas être catégorisée
                        </Button>
                    </Col>
                </Row>
                <Modal centered backdrop={false} show={this.state.toggleAlert} onHide={()=>this.setState({toggleAlert : false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>Etes-vous sûr ?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            A n'utiliser que si l'image est de trés mauvaise qualité, ou n'est pas une photo d'une personne, par exemple.
                        </p>
                        <p className={"text-danger"}>
                            Si vous confirmza, cette image ne pourra plus être catégorisée (opération non réversible)
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.setState({toggleAlert : false})}>
                            Annuler
                        </Button>
                        <Button variant="danger" onClick={()=>this.handleDelete()}>
                            Confirmer
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>)
    }
}



const mapStateToProps = (state) => {
    return {
        categorizedMedia : state.categorizedMedia.data,
        categorizedMediaInitialized : state.categorizedMedia.initialized,
        categorizedMediaLoading : state.categorizedMedia.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchCategorizedMedia,
    resetCategorizedMedia,
    updateCategorizedMedia,
    refuserCategorizedMedia
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CategorizedManualPhoto);
