import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('USER_WARNING').plugin(axiosPlugin);

let createUserWarningsAction = redux.fetchActionCreate();

let updateUserWarningsAction = redux.fetchActionUpdate();

let deleteUserWarningsAction = redux.fetchActionDelete();

export const TYPES = redux.types;

export const createUserWarning = (userId, data = {}) => createUserWarningsAction(`/users/${userId}/warnings`, data);
export const updateUserWarningAccepted = (userId, warningId = {}) => updateUserWarningsAction(`/users/${userId}/warnings/${warningId}/accepted`);
export const deleteUserWarningAccepted = (userId, warningId = {}) => deleteUserWarningsAction(`/users/${userId}/warnings/${warningId}`);
export const resetUserWarning = () => redux.actions.reset();

export const userWarning = redux.reducer();