import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Aqp from '../../../libs/mk-react-aqp';
import {fetchModerationContents, resetModerationContents} from "../../../creators/moderationContents";
import {refusedModerationContent, acceptedModerationContent} from "../../../creators/moderationContent";
import {PageLoader, SpinLoader, LoadingBar} from "../../components/Loader";
import FilterGender from "../../components/FilterGender";
import {Button, Row} from "react-bootstrap";
import createLink from "../../../libs/createLink";
import ContentUserLink from "./components/ContentUserLink";


let mapping = {
    "sort" : "trie",
    "owner.profile.gender" : "genre"
};

class ModerationsLinkExternal extends React.Component {
    constructor(props) {
        super(props);
        this.type = "link-external";
        this.filter = {
            limit: 18,
            page: 1,
            sort: "createdAt",
        };
        this.aqp = new Aqp(mapping, {
            blacklist: ['limit', 'page'],
            currentLink: "/moderations/link-external",
            filterDefault: this.filter
        });

        this.state = {
            filter: {...this.filter, ...this.aqp.query, page: this.props.match.params.page || 1},
            tachesRemoveIds : [],
        };
        this.props.resetModerationContents();
        this.fetchModerationContents(this.state.filter);

        this.handleGender = this.handleGender.bind(this);
        this.submitAccepted = this.submitAccepted.bind(this);
        this.submitRefused = this.submitRefused.bind(this);
    }

    UNSAFE_componentWillMount(){
        this.props.resetModerationContents();
    }

    fetchModerationContents(filter){
        this.props.fetchModerationContents(this.type, this.aqp.set(filter));
        this.setState({filter})
    }

    handleGender(gender){
        let filter = {...this.state.filter, "owner.profile.gender" : gender, page : 1};
        if(filter["owner.profile.gender"] === ""){
            delete filter.gender;
        }
        else{
            filter["owner.profile.gender"] = parseInt(filter["owner.profile.gender"])
        }
        this.fetchModerationContents(filter);
    }

    submitRefused(id){
        this.props.refusedModerationContent(this.type, id);
        let tachesRemoveIds = [...this.state.tachesRemoveIds,id];
        this.setState({tachesRemoveIds});
    }
    submitAccepted(){
        let ids = this.props.moderationContents.map(item => item._id);
        this.props.acceptedModerationContent(this.type, {ids});
    }


    render() {
// console.log(this.props.moderationContentsInitialized);
        return !this.props.moderationContentsInitialized ? (<PageLoader />) :(
            <Fragment>
                <FilterGender value={this.state.filter["owner.profile.gender"]} onChange={(gender)=>{this.handleGender(gender)}}/>
                <Row>
                    {this.props.moderationContentsLoading ? (<LoadingBar />) : (
                        this.props.moderationContents.map((item) =>{
                            let content = item.parentId;
                            return <ContentUserLink tachesRemoveIds={this.state.tachesRemoveIds} key={item._id} gender={content.profile && content.profile.gender} linkExternal={content.profile.linkExternal} username={<a href={createLink("userProfile", content)} target={"new"}>{content.username}</a>} deleteList={this.submitRefused} _id={item._id} />
                        })
                    )}
                </Row>
                <Button variant="primary" className={"btn-loading"} onClick={()=>this.submitAccepted()}>
                    Valider {this.props.moderationContentLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                </Button>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        moderationContents : state.moderationContents.data,
        moderationContentsTotal : state.moderationContents.total,
        moderationContentsLoading : state.moderationContents.loading,
        moderationContentsInitialized : state.moderationContents.initialized,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchModerationContents,
    refusedModerationContent,
    acceptedModerationContent,
    resetModerationContents
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModerationsLinkExternal);
