import React from 'react';
import {Route, Router, Switch} from "react-router-dom";
import SubNavBar from "../../components/SubNavBar";
import Setting from "./Setting";
import Warnings from "./Warnings";


const KeywordsWarnings = props => {

    let pathPage = '/keywords-warnings';
    let subNavBar = [
        {link : `${pathPage}`, label : "Warning"},
        {link : `${pathPage}/setting`, label : "Paramètres"},
    ];

    return (
        <div id={"keywords-warnings"}>
            <SubNavBar {...props} subNav={subNavBar}/>
            <Router history={props.history}>
                <Switch>
                    <Route path={`${pathPage}/`} exact component={Warnings}/>
                    <Route path={`${pathPage}/setting`} exact component={Setting}/>
                </Switch>
            </Router>
        </div>
    )
}


export default KeywordsWarnings;