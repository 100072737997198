import React from 'react';
import ReportingsList from "../components/ReportingsList";


const Reportings = props => {
    return (
        <ReportingsList {...props}  />
    )
};

export default (Reportings);
