import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('REPORTING_COMMENT').plugin(axiosPlugin);

let fetchReportingCommentAction = redux.fetchActionGet();
let createReportingCommentAction = redux.fetchActionCreate();

export const fetchReportingComment = (reportingId, commentId) => fetchReportingCommentAction(`/reportings/${reportingId}/comments/${commentId}`);
export const createReportingComment = (reportingId, data) => createReportingCommentAction(`/reportings/${reportingId}/comments`, data);
export const resetReportingComment = () => redux.actions.reset();

export const TYPES = redux.types;

export const reportingComment = redux.reducer();