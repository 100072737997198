import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Col, Row} from "react-bootstrap";


import MemberSetingCommentAndVisitor from '../../formulaires/member.setting.commentsAndVisitor'
import MemberSettingFriendAndRequest from '../../formulaires/member.setting.friendAndRequest';
import MemberSettingSignature from '../../formulaires/member.setting.signature';


class Setting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }


    render() {

        return (
            <Fragment>
                <Row>
                    <Col xs={6}>
                        <MemberSetingCommentAndVisitor />
                    </Col>
                    <Col xs={6}>
                       <MemberSettingSignature />
                    </Col>
                </Row>
                <Row className={"mt-4"}>
                    <Col xs={12}>
                       <MemberSettingFriendAndRequest />
                    </Col>
                </Row>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user.data
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({

}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Setting);
