import React, { Fragment } from 'react';
import { Button, Col, Form, Row, Container, Modal } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import {
    fetchUserItemSubscription,
    resetUserItemSubscription,
    updateUserItemSubscription
} from "../../creators/user.itemSubscription";
import FormFields from "../components/FormFields";
import { SpinLoader } from "../components/Loader";
import FormProfileGirft from "./member.gift";

const reasonMap = {
    isPremium: "Abonnement Premium",
    isHistory: "Accès historique",
    isPrivileged: "Accès privilégié"
};

class FormProfilePremium extends React.Component {
    constructor(props) {
        super(props);
        const { user, fetchUserItemSubscription } = this.props;
        if (user && user._id) {
            fetchUserItemSubscription(user._id);
        }
        this.state = { endDate: null, toggleGift : false };
    }

    handleChangeDate = (date) => {
        this.setState({ endDate: date });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { user, userItemSubscription, userItemSubscriptionLoading, updateUserItemSubscription, resetUserItemSubscription, fetchUserItemSubscription } = this.props;
        const { endDate } = this.state;

        if (!userItemSubscriptionLoading && endDate) {
            updateUserItemSubscription(user._id, { endDate, subscriptionId : userItemSubscription._id }).then(() =>{
                resetUserItemSubscription();
                fetchUserItemSubscription(user._id);
            });
            this.setState({ endDate: null });
        }
    }

    renderPremiumStatus() {
        const { user } = this.props;
        const hasAccess = user && user.subscription && user.subscription.hasAccess;
        const reasonKey = user && user.subscription && Object.keys(reasonMap).find(key => user.subscription[key]);
        const reason = reasonKey ? reasonMap[reasonKey] : "Pas d'accès";

        return (
            <Row className="mb-4">
                <Col xs={12} md={6} className="text-center text-md-left">
                    Accès premium : <strong>{hasAccess ? "Activé" : "Désactivé"}</strong>
                </Col>
                <Col xs={12} md={6} className="text-center text-md-left">
                    Raison : <strong>{reason}</strong>
                </Col>
            </Row>
        );
    }

    renderSubscriptionForm() {
        const { userItemSubscription, userItemSubscriptionLoading } = this.props;
        const { endDate } = this.state;
        const subscription = userItemSubscription && userItemSubscription.subscription ? userItemSubscription.subscription : {};

        // Récupérer le statut de l'abonnement et la date de renouvellement
        const subscriptionStatus = subscription.status ? subscription.status : "Statut inconnu";
        const renewalDate = subscription.renewalDate ? new Date(subscription.renewalDate).toLocaleDateString() : "Date de renouvellement inconnue";
       
        return (
            <Fragment>
                <Row className="mb-2">
                    <Col xs={12}>
                        <strong>Cadeau : </strong>
                        <span className={userItemSubscription.isGift ? "text-success" : "text-danger"}>
                            {userItemSubscription.isGift ? "Oui" : "Non"}
                        </span>                        
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col xs={12}>
                        <strong>Statut de l'abonnement : </strong>
                        <span className={subscriptionStatus === "active" ? "text-success" : "text-danger"}>
                            {subscriptionStatus === "active" ? "Actif" : "Inactif"}
                        </span>
                        {/* Afficher la date de renouvellement si l'abonnement est actif */}
                        {subscriptionStatus === "active" && (
                            <p className="mt-2">
                                <strong>Date de renouvellement : </strong> {renewalDate}
                            </p>
                        )}
                    </Col>
                </Row>
                <Form onSubmit={this.handleSubmit}>
                    <Row className="mb-3">
                        <Col xs={12} md={4}>
                            <FormFields
                                group={{ as: Col, controlId: "startDate" }}
                                type="date"
                                label={{ label: "Date début" }}
                                controle={{
                                    name: "startDate",
                                    value: subscription.startDate,
                                    disabled: true,
                                    className: "w-100"
                                }}
                            />
                        </Col>
                        <Col xs={12} md={4}>
                            <FormFields
                                group={{ as: Col, controlId: "endDate" }}
                                type="date"
                                label={{ label: "Date Fin" }}
                                controle={{
                                    name: "endDate",
                                    value: endDate || subscription.endDate,
                                    onChange: (date) => this.handleChangeDate(date),
                                    className: "w-100"
                                }}
                            />
                        </Col>
                        <Col xs={12} md={4} className="mt-3 mt-md-4 text-center text-md-left">
                            <Button variant="primary" type="submit" className="btn-loading">
                                Valider {userItemSubscriptionLoading && (
                                <div className="pl-1 d-inline">
                                    <SpinLoader className="xsmall" />
                                </div>
                            )}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Fragment>
        );
    }

    renderSubscriptionGiftForm() {
        const { userItemSubscription } = this.props;
        const isToggle = (userItemSubscription._id && !userItemSubscription.isGift) || !userItemSubscription._id
        
        return !isToggle ? null : (
            <Fragment>
                <Row className="mb-2">
                    <Col xs={12}>
                    <Button size="sm" className={"mb-2"} variant="outline-info" onClick={()=>this.setState({toggleGift : !this.state.toggleGift})}>
                        Offrir un abonnement
                     </Button>
                    </Col>
                </Row>
                <Modal centered backdrop={false} show={this.state.toggleGift} onHide={()=>this.setState({toggleGift : false})}>
                        <Modal.Header closeButton>
                            <Modal.Title>Offrir un produit</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <FormProfileGirft userId={this.props.user._id} />
                        </Modal.Body>
                    </Modal>
            </Fragment>
        );
    }

    render() {
        const { userItemSubscription } = this.props;

        return (
            <Container>
                {this.renderPremiumStatus()}
                {userItemSubscription && userItemSubscription._id && this.renderSubscriptionForm()}
                {this.renderSubscriptionGiftForm()}
            </Container>
        );
    }
}

const mapStateToProps = (state) => {
    const lastSubscription = state.userItemSubscription.data && state.userItemSubscription.data.length ? state.userItemSubscription.data[0] : {}
    return {
    user: state.user.data,
    userItemSubscription: lastSubscription,
    userItemSubscriptionError: state.userItemSubscription.error,
    userItemSubscriptionLoading: state.userItemSubscription.loading,
}};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchUserItemSubscription,
    updateUserItemSubscription,
    resetUserItemSubscription
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormProfilePremium);