import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('CATEGORIES_GENDER').plugin(axiosPlugin, {template: "array"});
let fetchAction = redux.fetchActionGet();


export const TYPES = redux.types;

export const fetchCategoriesGender = (query = {}) => fetchAction(`/categories/gender`);
export const resetCategoriesGender = () => redux.actions.reset();

export const categoriesGender = redux.reducer();