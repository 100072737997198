import React from "react";
import createLink from "../../../../libs/createLink";
import {Link} from "react-router-dom";

export const customMentionStrategy = (contentBlock, callback, contentState) => {
    contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity();
        return (
            entityKey !== null &&
            contentState.getEntity(entityKey).getType() === "CUSTOM_MENTION"
        );
    }, callback);
};

export const customMention = props => {
    const { contentState, entityKey } = props;
    const { mention } = contentState.getEntity(entityKey).getData();
    return (
        <Link key={mention._id} to={createLink('userProfile', mention)} target={"_blank"} rel="noopener noreferrer" className={"mentionned-name"}>
            @{mention.username}
        </Link>
    );
};

const addCustomMentionPlugin = {
    decorators: [
        {
            strategy: customMentionStrategy,
            component: customMention
        }
    ]
};

export default addCustomMentionPlugin;