import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('MEDIAS').plugin(axiosPlugin, {template: "array"});

let fetchMediasAction = redux.fetchActionGet();



redux.reducerForType('DELETE', (state,payload) => {
    let newState = {...state, lastUpdate : Date.now()};
    if(payload.data && payload.data._id){
        newState.data = newState.data.filter(item => item._id.toString() !== payload.data._id.toString());
        newState.total--;
        if(newState.total < 0){
            newState.total = 0;
        }
    }
    return newState;
});

redux.reducerForType('UPDATE', (state,payload) => {
    let newState = {...state, lastUpdate : Date.now()};
    if(payload.data && payload.data._id){
        newState.data = newState.data.map(item => {
            if(item._id.toString() === payload.data._id.toString()){
                item = payload.data;
            }
            return item;
        });
    }
    return newState;
});

export const fetchMedias = (query = {}) => {
    let sort = ['-createdAt', '-count.like', '-count.like', '-count.like'];
    query = {...query, sort: sort[query.period] || sort[query.sort] || sort[0]};
    return fetchMediasAction(`/medias/admin-all?${queryString.stringify(query)}`)
};
export const TYPES = redux.types;

export const resetMedias = () => redux.actions.reset();

export const medias = redux.reducer();