import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('AB_TESTINGS').plugin(axiosPlugin, {template: "array"});

let fetchAction = redux.fetchActionGetOne();


export const TYPES = redux.types;

export const fetchAbTestings = () => fetchAction(`/ab-testings`);
export const resetAbTestings = () => redux.actions.reset();

export const abTestings = redux.reducer();