import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import Aqp from '../../../libs/mk-react-aqp';
import {fetchModerationContents, resetModerationContents} from "../../../creators/moderationContents";
import {refusedModerationContent, acceptedModerationContent} from "../../../creators/moderationContent";
import {PageLoader, SpinLoader, LoadingBar} from "../../components/Loader";
import {Button, Col, InputGroup} from "react-bootstrap";
import {Link} from "react-router-dom";

let mapping = {
    "sort" : "trie",
    "owner.profile.gender" : "genre"
};

class ModerationsNouveauxTags extends React.Component {
    constructor(props) {
        super(props);
        this.type = "tags";
        this.filter = {
            limit: 18,
            page: 1,
            sort: "createdAt",
        };
        this.aqp = new Aqp(mapping, {
            blacklist: ['limit', 'page'],
            currentLink: "/moderations/nouveaux-tags",
            filterDefault: this.filter
        });

        this.state = {
            filter: {...this.filter, ...this.aqp.query, page: this.props.match.params.page || 1},
            tachesRemoveIds : []
        };
        this.props.resetModerationContents();
        this.fetchModerationContents(this.state.filter);

        this.submitAccepted = this.submitAccepted.bind(this);
        this.submitRefused = this.submitRefused.bind(this);
    }

    componentWillUnmount(){
        this.props.resetModerationContents();
    }

    fetchModerationContents(filter){
        this.props.fetchModerationContents(this.type, this.aqp.set(filter));
        this.setState({filter})
    }


    submitAccepted(id){
        this.props.refusedModerationContent(this.type, id);
        let tachesRemoveIds = [...this.state.tachesRemoveIds,id];
        this.setState({tachesRemoveIds});
    }
    submitRefused(){
        let ids = this.props.moderationContents.map(item => item._id);
        this.props.acceptedModerationContent(this.type, {ids});
    }

    render() {
// console.log(this.props.moderationContentsInitialized);
        return !this.props.moderationContentsInitialized ? (<PageLoader />) :(
            <Fragment>
                <div className={"list-tags"}>
                    {this.props.moderationContentsLoading ? (<LoadingBar />) : (
                        this.props.moderationContents.map((item) =>{
                            let content = item.parentId;
                            return content && content.title ? (<Col sm={3} key={item._id} className={"list-group-item list-group-item-action list-group-item-light"}>
                                <div className={"mb-2"}>{content.title}</div>
                                <InputGroup className={"flex justify-content-between"}>
                                    <Button variant="outline-success" onClick={()=>this.submitAccepted(item._id)}>
                                        Accepter
                                    </Button>
                                    <Link to={`/tags/${content._id}`}>
                                        <Button variant="outline-dark">
                                            Editer
                                        </Button>
                                    </Link>
                                </InputGroup>
                            </Col>) : null
                        })
                    )}
                </div>
                <Button variant="primary" className={"btn-loading mt-4"} onClick={()=>this.submitRefused()}>
                    Valider {this.props.moderationContentLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                </Button>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        moderationContents : state.moderationContents.data,
        moderationContentsTotal : state.moderationContents.total,
        moderationContentsLoading : state.moderationContents.loading,
        moderationContentsInitialized : state.moderationContents.initialized,

        // moderationContent : state.moderationContents.data,
        // moderationContentLoading : state.moderationContent.loading,
        // moderationContentInitialized : state.moderationContent.initialized
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchModerationContents,
    refusedModerationContent,
    acceptedModerationContent,
    resetModerationContents
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ModerationsNouveauxTags);
