import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('BANNED_MEMBERS').plugin(axiosPlugin, {template: "array"});

let fetchTagsAction = redux.fetchActionGet();

export const fetchBannedMembers = (query = {}) => fetchTagsAction(`/banned-members?${queryString.stringify(query)}`);
export const resetBannedMembers = () => redux.actions.reset();

export const bannedMembers = redux.reducer();