import React from "react";

class InlineStyleButton extends React.Component {
    onToggle = e => {
        e.preventDefault();
        this.props.onToggle(this.props.style);
    };

    render() {
        return (
            <span className={`mk-editor-btn ${this.props.active && 'active'}`} onClick={this.onToggle}
                  dangerouslySetInnerHTML={{__html: this.props.label}}/>
        );
    }
}

export default InlineStyleButton;
