import React from 'react';
import {Button, Col, Form, Modal} from "react-bootstrap";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {SpinLoader} from "../components/Loader";
import FormFields from "../components/FormFields";
import history from "../../libs/history"
import {createTag, deleteTag, resetTag, updateTag} from "../../creators/tag";
import TagInfo from "./tag.info";



class FormTags extends React.Component {
    constructor(props) {
        super(props);
        this.schemaInfos = {
            description : "",
            title : "",
            count : 0,
            indexSeo : false,
            weight : 50
        };
        this.kindList = [{kind : 'gallery', label : "Photo"},{kind : 'video', label : "Vidéos"},{kind : 'story', label : "Histoire"},{kind : 'article', label : "Article"}];
        this.state = {
            toggleAlert : false,
            title : "",
            isActive : false,
            infos : {
                video : {...this.schemaInfos},
                gallery : {...this.schemaInfos},
                story : {...this.schemaInfos},
                article : {...this.schemaInfos}
            },
            ...this.props.tag
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValue = this.handleValue.bind(this);
        this.handleValueInfo = this.handleValueInfo.bind(this);
    }

    handleValue(name, value){
        this.setState({[name]: value});
    }

    handleValueInfo(kind, name, value){
        let infos = {...this.state.infos, [kind] : {...this.state.infos[kind], [name] : value}};

        this.setState({infos});
    }

    handleSubmit(e) {
        e.preventDefault();
        if (!this.props.tagLoading) {
            if(this.props.tag && this.props.tag._id){
                this.props.updateTag(this.props.tag._id, this.state);
            }
           else{
                this.props.createTag(this.state);
            }

            history.push("/tags");
        }
    }

    componentWillUnmount(){
        this.props.resetTag();
    }

    handleDelete(){
        this.props.deleteTag(this.props.tag._id);
        history.push("/tags");
    }

    render() {
        return (
            <Form onSubmit={(e) => this.handleSubmit(e)}>
                <FormFields
                    group={{controlId : "title"}}
                    type={"input"}
                    label={{
                        label : "Nom du tag"
                    }}
                    controle={{
                        type : "text",
                        name : "title",
                        value : this.state.title,
                        onChange : (event) =>{this.handleValue([event.target.name], event.target.value)}
                    }}
                />
                <FormFields
                    group={{controlId : "isActive"}}
                    type={"select"}
                    label={{
                        label : "Activé ?"
                    }}
                    controle={{
                        name : "isActive",
                        value : this.state.isActive,
                        options : [{value : true, label : "Oui"}, {value : false, label : "Non"}],
                        onChange : (event) =>{this.setState({isActive : event.target.value})}
                    }}
                />

                <Form.Row>
                    {this.kindList.map(item=>(
                        <Col sm={6} key={item.kind}>
                            <TagInfo kind={item} {...this.state.infos[item.kind]} handleValue={this.handleValueInfo} />
                        </Col>
                    ))}
                </Form.Row>
                <Form.Row>
                    <Button variant="primary" type="submit" className={"btn-loading mb-5"}>
                        Valider {this.props.tagLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                    </Button>
                    {this.props.tag && this.props.tag._id && (<Button variant="danger" onClick={()=>this.setState({toggleAlert : true})} className={"btn-loading mb-5 ml-3"}>
                        Supprimer
                    </Button>)}
                    <Modal centered backdrop={false} show={this.state.toggleAlert} onHide={()=>this.setState({toggleAlert : false})}>
                        <Modal.Header closeButton>
                            <Modal.Title>SUPPRESSION</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Voulez-vous vraiment supprime ce tag ?</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={()=>this.setState({toggleAlert : false})}>
                                Non
                            </Button>
                            <Button variant="danger" onClick={()=>this.handleDelete()}>
                                Oui
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Form.Row>
            </Form>
        )
    }
}


const mapStateToProps = (state) => {

    return {
        tag : state.tag.data,
        tagLoading : state.tag.loading,
        tagInitialized : state.tag.initialized
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateTag,
    resetTag,
    createTag,
    deleteTag
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormTags);
