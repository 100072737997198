import React, {Component} from 'react';
import {connect} from 'react-redux';
import {fetchCurrentUser} from '../creators/currentUser';
import {bindActionCreators} from "redux";


export default ComposedComponent => {
    class Authentication extends Component {
        componentWillMount() {
            const token = localStorage.getItem(process.env.REACT_APP_TOKEN_NAME);
            // if(!this.props.currentUserInitialized) this.props.history.push('/signin');
            // if (!this.props.currentUserInitialized) {
            //   this.props.fetchCurrentUser();
            // }
            if (token) {
                this.props.fetchCurrentUser();
            }
            else if(process.env.REACT_APP_TOKEN_NAME !== null) {
                this.props.history.push("/connexion");
            }
        }

        // routeUser(user){
        //   if (user.roles.includes('account-email-validation')) {
        //     this.props.history.push('/activation');
        //   } else if (user.roles.includes('account-pending')) {
        //     this.props.history.push('/kyc');
        //   }
        // }

        UNSAFE_componentWillUpdate(nextProps) {
            // if(!nextProps.currentUserInitialized) this.props.history.push('/signin');
            // if (this.props.currentUser.user.roles != nextProps.currentUser.user.roles) this.routeUser(nextProps.currentUser.user);
        }

        render() {
            return this.props.currentUserInitialized ?
                <ComposedComponent match={this.props.match} history={this.props.history}
                                   location={this.props.location}/> : <div/>
        }
    }

    const mapStateToProps = state => ({
        currentUserLoading: state.currentUser.loading,
        currentUserInitialized: state.currentUser.initialized,
    });

    const mapDispatchToProps = (dispatch) => bindActionCreators({
        fetchCurrentUser,
    }, dispatch);

    return connect(mapStateToProps, mapDispatchToProps)(Authentication);
}
