import React from 'react';
import {Row} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import Icon from "../../../components/Icon";

const ViewCount = (props) => {
    const {count = {view : 0, like : 0, comment : 0} } = props;
    return (
           <Row className={"justify-content-md-center mt-2"}>
               <Col xs={12} lg="4"  className={"flex flex-column"} >
                   <Icon type={"eye"} iconSize={20} className={"flex justify-content-md-center"} />
                   <span className={"text-center"}>{count.view}</span>
               </Col>
               <Col xs={12}  md="auto" className={"flex flex-column"}>
                   <Icon type={"heart-full"} iconSize={20} className={"flex justify-content-md-center"} />
                   <span className={"text-center"}>{count.like}</span>
               </Col>
               <Col xs={12} lg="4" className={"flex flex-column"}>
                   <Icon type={"comment"} iconSize={20} className={"flex justify-content-md-center"} />
                   <span className={"text-center"}>{count.comment}</span>
               </Col>
           </Row>
    )

}

export default ViewCount;