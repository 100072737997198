import React from 'react';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import _ from 'lodash';

import withUpload from "../../../hoc/withUpload";
import {Button} from "react-bootstrap";
import {fetchUser, updateUser} from "../../../creators/user";


class UploaderAC extends React.Component {
    constructor(props){
        super(props);
        this.id = _.random(0, 99999);
        this.state = {
            errorMessage: '',
            preview: this.props.preview,
            uploadedMedia: {},
            isUploaded: false,
        };
        this.type = {
            avatar : "un avatar",
            photo : "une photo"
        };
        this.typeText = {
            avatar : <span className={"mt-16"}>Les gros plans et images trop hard sont interdits sur les avatars.</span>,
            photo : "",
            cover : <span className={"mt-16"}>Gros plans ou images de scènes sexuelles HARD interdites.<br /> RECOMMANDATION: 828x 315 pixels pour éviter la pixellisation de votre couverture</span>
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.preview !== this.props.preview){
            this.setState({preview: this.props.preview});
            this.props.uploadFile();
        }
    }

    onUploadError({response}){
        this.props.reset();
        let message = '';
        switch (response.statusCode) {
            case 413:
                message = `Fichier trop lourd, maximum accepté : ${response.errors.maxFileSizeStr}`;
                break;
            case 415:
                message = `Fichier non accepté, sont acceptés : ${response.errors.allowedTypes.join(', ')}`;
                break;
            default :
                message = 'Envoi du fichier impossible, erreur système inconnue';
                break;
        }
        this.setState({errorMessage: message, preview: ""})
    }

    onUploadSuccess(data){
        this.setState({uploadedMedia: data, isUploaded: true, preview : data.preview});
    }

    handleSubmit(){
        this.props.reset();
        this.props.handleClose();
        // console.log(this.state.uploadedMedia);
        if(this.props.type === "avatar"){
            if(typeof this.props.handleData === "function"){
                this.props.handleData(this.state.uploadedMedia);
            }
            this.props.updateUser(this.props.userId,{
                profile : {
                    avatarId: this.state.uploadedMedia._id
                }
            });
        }
        else if(this.props.type === "photo"){
            this.props.updateUser(this.props.userId,{
                profile : {
                    photoCertifiedId: this.state.uploadedMedia._id
                }
            });
        }
        else {
            this.props.updateUser(this.props.userId,{
                profile : {
                    coverId: this.state.uploadedMedia._id
                }
            });
        }
    }

    render(){
        return <div id={"uploads"} className={"modal-container"}>
            <div className={"modal-topline"}>
                {`Télécharger ${this.type[this.props.type] || "une photo de couverture"}`}
            </div>
            <div className={"modal-body"}>
                {this.state.preview &&
                    <div className={"img-preview-container mb-2"}>
                        <img alt={""} className={`img-preview`} src={this.state.preview} />
                    </div>
                }
                <input type="file"
                       id={this.id}
                       key={this.props.loadedFile.name}
                       style={{display:'none'}}
                       accept="image/jpeg, image/png"
                       disabled={this.props.uploading}
                       onChange={this.props.loadFile.bind(this)}/>

                <label htmlFor={this.id} className={"upload-input-btn"}>
                    <div className="btn text-white cursor-pointer"
                        style={{background: `linear-gradient(to right,#51a9fd ${this.props.uploadProgress.percentCompleted}%,#1F7DEC ${this.props.uploadProgress.percentCompleted}%)`}}
                    >
                        {this.props.uploading ? (
                            `Envoi... ${Math.round(this.props.uploadProgress.eta / 1000)}s (${this.props.uploadProgress.kos}ko/s)`
                        ) : (
                            _.truncate(this.props.loadedFile.name, {length:this.props.nameLength || 25}) || 'Choisissez un fichier'
                        )}
                    </div>
                </label>
                <div>{this.typeText[this.props.type] || this.typeText['cover']}</div>
            </div>
            <div className={"modal-baseline"}>
                {this.state.errorMessage && <span className={"warning upload-error-message"}>{this.state.errorMessage}</span>}
                {!this.props.uploading &&
                    <Button size="sm" variant="danger" onClick={()=>this.props.handleClose()}>
                    Fermer
                    </Button>
                }
                {this.props.loadedFile.name && (
                    this.props.uploading &&
                    <Button size="sm" className={"ml-4"} variant="primary" onClick={()=>this.props.reset()}>
                    Annuler
                    </Button>
                )}
                {this.state.isUploaded && (
                    <Button size="sm" className={"ml-4"} variant="primary" onClick={()=>this.handleSubmit()}>
                    Enregistrer
                    </Button>
                )}
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user.data
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateUser,

    fetchUser
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withUpload(UploaderAC));