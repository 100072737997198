import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('USER_ARTICLES').plugin(axiosPlugin, {template: "array"});

let fetchUserArticlesAction = redux.fetchActionGet();

redux.reducerForType('ADD', (state,payload) => {
    let newState = {...state};
    newState.data.unshift(payload.data);
    newState.total++;
    return newState;
});

redux.reducerForType('DELETE', (state,payload) => {
    let newState = {...state};
    if(payload.data && payload.data._id){
        newState.data = newState.data.filter(item => item._id.toString() !== payload.data._id.toString());
        newState.total--;
        if(newState.total < 0){
            newState.total = 0;
        }
    }
    return newState;
});

redux.reducerForType('UPDATE', (state,payload) => {
    let newState = {...state};
    // if(payload.data && payload.data._id){
    //     newState.data = newState.data.map(item => {
    //         if(item._id.toString() === payload.data._id.toString()){
    //             item = payload.data;
    //         }
    //         return item;
    //     });
    // }
    if(payload.data && payload.data._id){
        let indexElement = newState.data.findIndex(item => item._id.toString() === payload.data._id.toString());
        if(indexElement >= 0){
            newState.data = newState.data.map(item => {
                if(item._id.toString() === payload.data._id.toString()){
                    item = payload.data;
                }
                return item;
            });
        } else {
            newState.data.unshift(payload.data);
            newState.total++;
        }
    }
    return newState;
});

export const fetchUserArticles = (userId, query = {}) => fetchUserArticlesAction(`/users/${userId}/articles?${queryString.stringify(query)}`);
export const resetUserArticles = () => redux.actions.reset();

export const userArticles = redux.reducer();