import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('USER_REMOVE_CONTENTS').plugin(axiosPlugin);


let deleteUserContentAction = redux.fetchActionDelete();

export const TYPES = redux.types;

export const deleteUserContents = (userId, data = {}) => deleteUserContentAction(`/users/${userId}/delete-contents`, data);
export const resetUserContents = () => redux.actions.reset();

export const userDeleteContents = redux.reducer();