import React from 'react'
import InlineStyleButton from "./InlineStyleButton";

let INLINE_STYLES = [
    {label: '<b>B</b>', style: 'BOLD'},
    {label: '<i>I</i>', style: 'ITALIC'},
    {label: '<u>U</u>', style: 'UNDERLINE'},
];

class InlineStyleControls extends React.Component {
    render() {
        const currentStyle = this.props.editorState.getCurrentInlineStyle();
        return (
            <div className="mk-editor-btn-group">
                {INLINE_STYLES.map((type) =>
                    <InlineStyleButton
                        key={type.label}
                        active={currentStyle.has(type.style)}
                        label={type.label}
                        onToggle={this.props.onToggle}
                        style={type.style}
                    />
                )}
            </div>
        );
    }
}

export default InlineStyleControls;