import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('IA_CLASSIFIER_MODERATION_MEDIAS_UPDATE_VERIFIED').plugin(axiosPlugin);


export const TYPES = redux.types;

let updateAction = redux.fetchActionUpdate();

export const updateIaClassifierVerified = (data = {}) => updateAction(`/ia/classifier/update-verified`, data);
export const resetIaClassifierVerified = () => redux.actions.reset();

export const iaClassifierVerified = redux.reducer();