import {put,takeEvery} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {TYPES as USER_TYPE} from '../creators/user';
import {TYPES as USERS_TYPE} from '../creators/users';


export function* updateSuccess(action) {
    try {
        yield put({type: USERS_TYPE.UPDATE, data : action.payload});
    } catch (error) {
    }
}


export function* watchUser() {
    yield takeEvery(USER_TYPE.UPDATE_SUCCESS, updateSuccess);
}

export default watchUser;

