import {put,takeEvery} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {TYPES as MEDIA_TYPE} from '../creators/media';



export function* updateSuccess(action) {
    try {
        yield put({type: "MODERATION_CONTENTS_UPDATE_MEDIA", data : action.payload});
        yield put({type: "MEDIAS_UPDATE", data : action.payload});
    } catch (error) {
    }
}

export function* deleteSuccess(action) {
    try {
        yield put({type: "MEDIAS_DELETE", data : action.payload});
    } catch (error) {

    }
}

export function* watchMedia() {
    yield takeEvery(MEDIA_TYPE.UPDATE_SUCCESS, updateSuccess);
    yield takeEvery(MEDIA_TYPE.DELETE_SUCCESS, deleteSuccess);
}

export default watchMedia;

