import {put,takeEvery} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';

import {TYPES as STORY_TYPE} from '../creators/story';
import history from "../libs/history";

export function* createSuccess(action) {
    try {
        yield put({type: "STORIES_ADD", data : action.payload});
    } catch (error) {

    }
}

export function* deleteSuccess(action) {
    try {
        yield put({type: "STORIES_DELETE", data : action.payload});
        history.push("/moderations/histoires");
    } catch (error) {

    }
}

export function* updateSuccess(action) {
    try {
        yield put({type: "STORIES_UPDATE", data : action.payload});
        history.push("/moderations/histoires");
    } catch (error) {

    }
}

export function* watchStory() {
    yield takeEvery(STORY_TYPE.CREATE_SUCCESS, createSuccess);
    yield takeEvery(STORY_TYPE.DELETE_SUCCESS, deleteSuccess);
    yield takeEvery(STORY_TYPE.UPDATE_SUCCESS, updateSuccess);
}

export default watchStory;

