
import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('IA_CLASSIFIER_MODERATION_MEDIAS_UPDATE_TEMPERATURE').plugin(axiosPlugin);


export const TYPES = redux.types;

let updateAction = redux.fetchActionUpdate();

export const updateIaClassifierTemperature = (mediaId = null,data = {}) => updateAction(`/ia/classifier/${mediaId}/update-temperature`, data);
export const resetIaClassifierTemperature = () => redux.actions.reset();

export const iaClassifierTemperature = redux.reducer();