const slicer = (text, metas = [])=>{
    let sliced = [];
    metas.sort((a,b)=>a.offset - b.offset);
    let hasMetas = metas.length > 0;
    if (hasMetas){
        let firstMeta = metas[0];
        let lastMeta = metas[metas.length - 1];
        if (firstMeta.offset !== 0) sliced.push({
            text : text.slice(0, firstMeta.offset)
        });
        metas.forEach((meta, i)=>{
            let endOffset = meta.offset + meta.length;
            sliced.push({
                text : text.slice(meta.offset, endOffset),
                data : meta.data || {},
                type : meta.type,
                isMeta : true
            });
            let nextMeta = metas[++i];
            if (!!nextMeta && endOffset !== nextMeta.offset) {
                sliced.push({
                    text : text.slice(endOffset, nextMeta.offset)
                })
            }
        })
        let lastMetaEndOffset = lastMeta.offset + lastMeta.length;
        if (lastMetaEndOffset !== text.length) sliced.push({
            text : text.slice(lastMetaEndOffset)
        });
    }
    else sliced = [{text}];
    return {sliced, hasMetas, text};
}

export default slicer;