import React from 'react';
import {Button, Col, Form, Row} from "react-bootstrap";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {updateStory} from "../../creators/story";
import {resetUserArticles,fetchUserArticles} from "../../creators/user.articles";
import {fetchTagsProtagonist} from "../../creators/tagsProtagonist";
import {fetchTagsStories} from "../../creators/stories.tagsStories";
import {SpinLoader} from "../components/Loader";
import FormFields from "../components/FormFields";
import TagList from "../components/TagList";
import createLink from "../../libs/createLink";



class FormStory extends React.Component {
    constructor(props) {
        super(props);
        // this.state = {...this.props.story, content : this.props.story.content._id};
        // let tags = this.props.story.tags || [];
        // let storyCat1 = tags.filter(item =>item.option.filter === "story" && item.option.group === 1);
        // let storyCat2 = tags.filter(item =>item.option.filter === "story" && item.option.group === 2);
        // let storyCat1  = [];
        // let storyCat2 = [];
        // let tagsProtagonist = tags.filter(item =>item.option.filter === "protagonist");
        // let tagsProtagonist = [];
        this.optionsMessages = [
            {value : 0, label : "Aucun"},
            {value : 1, label : "Histoire Acceptée"},
            {value : 2, label : "Refus / Trop de fautes"},
            {value : 3, label : "Refus / mauvaise mise en forme"},
        ];

        this.optionsStatus = [
            {value : 0, label : "En attente"},
            {value : 1, label : "Histoire Publiée"},
            {value : 2, label : "Histoire refusée"},
        ];
        this.state = {
            story: {
                isReal : false,
                isHard : false,
                ...this.props.story,
                //content : this.props.story.content && this.props.story.content._id,
                // tags: this.props.story.tags.filter(item =>item.option.filter !== "story").filter(item =>item.option.filter !== "protagonist"),
                excerpt: this.props.story.excerpt,
            },
            messageSend : 0,
            // type: (storyCat1[0] && storyCat1[0]._id) || "",
            // softness: (storyCat2[0] && storyCat2[0]._id) || "",
            // tagsProtagonist : (tagsProtagonist[0] && tagsProtagonist[0]._id) || ""
            tagsProtagonist : {}
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValue = this.handleValue.bind(this);
        this.handleStoryValue = this.handleStoryValue.bind(this);
        this.handleTagProtagonist = this.handleTagProtagonist.bind(this);
        this.removeTags = this.removeTags.bind(this);
        //this.props.resetUserArticles();
        //this.props.fetchUserArticles(this.props.story.owner._id, { limit : false});

        this.props.fetchTagsProtagonist();
        this.props.fetchTagsStories();
    }

    componentDidUpdate(prevProps) {
        // if(prevProps.story !== this.props.story){
        //     let storyCat1 = this.props.story.tags.filter(item =>item.option.filter === "story" && item.option.group === 1);
        //     let storyCat2 = this.props.story.tags.filter(item =>item.option.filter === "story" && item.option.group === 2);
        //     this.setState({story: {
        //             ...this.state.story,
        //             title: this.props.story.title,
        //             content : this.props.story.content._id,
        //             tags: this.props.story.tags.filter(item =>item.option.filter !== "story").filter(item =>item.option.filter !== "protagonist"),
        //             excerpt: this.props.story.excerpt
        //         },
        //         type: storyCat1[0]._id,
        //         softness: storyCat2[0]._id,
        //     });
        // }
        if(prevProps.tagsProtagonist !== this.props.tagsProtagonist && this.props.tagsProtagonistInitialized){
            let tags = this.state.story.tags.map(item=>item._id.toString());
            this.props.tagsProtagonist.forEach(item=>{
                if(tags.includes(item._id.toString())){
                    let newTags = this.state.story.tags.filter(tag =>tag._id !== item._id);
                    this.setState({tagsProtagonist : item, story : {...this.state.story, tags : newTags}});
                }
            });
        }
    }

    componentWillUnmount() {
        this.props.resetUserArticles();
    }

    handleValue(name, value){
        this.setState({[name]: value});
    }

    handleStoryValue(name, value){
        this.setState({story: {...this.state.story, [name]: value}});
    }

    handleTagProtagonist(id) {
        let tag = this.props.tagsProtagonist.filter(item => item._id === id);
        let tagsState = this.state.story.tags.filter(item => item._id.toString() !== id.toString());
        if (tag && tag[0]) {
            // console.log({story : {...this.state.story, tags: [...this.state.story.tags, tag[0]]}});
            this.setState({story : {...this.state.story, tags: [...tagsState, tag[0]]}})
        }
    }

    removeTags(tagId) {
        let tags = [...this.state.story.tags];
        tags = tags.filter(item => item._id !== tagId);
        this.setState({story: {...this.state.story, tags}});
    }


    handleSubmit(e) {
        e.preventDefault();
        if (!this.props.storyLoading) {
            let tags = this.state.story.tags;
            let newTags = [...tags];

            // newTags.push(this.props.tagsStories.filter(item => item._id === this.state.type)[0]);
            // newTags.push(this.props.tagsStories.filter(item => item._id === this.state.softness)[0]);
            // if(this.state.tagsProtagonist !== ""){
            //     newTags.push(this.props.tagsProtagonist.filter(item => item._id === this.state.tagsProtagonist)[0]);
            // }

            if(this.state.tagsProtagonist && this.state.tagsProtagonist._id){
                newTags.push(this.state.tagsProtagonist);
            }

            this.props.updateStory(this.props.story._id, {...this.state.story, tags: newTags, message : this.state.messageSend});
            // this.props.updateStory(this.props.story._id, this.state);
            // this.props.history.push("/contents/histoires");
        }
    }

    render() {
        // console.log(this.props.story);
        return this.props.story.content ? (
            <Form onSubmit={(e) => this.handleSubmit(e)}>
                <Row className={"mb-3"}>
                    <Col sm={1}>Pseudo</Col>
                    <Col><a href={createLink("userProfile", this.state.story.owner)} target={"new"}>{this.state.story.owner.username}</a></Col>
                </Row>
                <FormFields
                    group={{controlId : "title"}}
                    type={"input"}
                    label={{
                        label : "Titre"
                    }}
                    controle={{
                        type : "text",
                        name : "title",
                        value : this.state.story.title,
                        onChange : (event) =>{this.handleStoryValue([event.target.name], event.target.value)}
                    }}
                />
                <Row className={"mb-3"}>
                    <Col sm={1}>Article</Col>
                    <Col><a href={createLink("article", {...this.props.story.content.owner, article : this.props.story.content})} >Voir l'article : {this.props.story.content.title}</a></Col>
                </Row>


                <Form.Row>
                    <Col sm={2}>
                        Fantasme ou Reele ?
                    </Col>
                    <Col sm={6}>
                        <Row>
                            {[{_id : true, title : "Création / fantasme"}, {_id : false, title : "Histoire réelle"}].map(item =>(
                                <FormFields
                                    key={item._id}
                                    type={"radio"}
                                    group={{as : Col}}
                                    checkBox={{
                                        id : `checkbox-${item._id}`,
                                        name : "isReal",
                                        checked : this.state.story.isReal === item._id,
                                        label : item.title,
                                        onChange : (event) =>{this.handleStoryValue([event.target.name], item._id)}
                                    }}
                                />
                            ))}
                        </Row>
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col sm={2}>
                        Erotique ou hard ?
                    </Col>
                    <Col sm={6}>
                        <Row>
                            {[{_id : false, title : "Erotique"}, {_id : true, title : "Hard"}].map(item =>(
                                <FormFields
                                    key={item._id}
                                    type={"radio"}
                                    group={{as : Col}}
                                    checkBox={{
                                        id : `isHard-${item._id}`,
                                        name : "isHard",
                                        checked : this.state.story.isHard === item._id,
                                        label : item.title,
                                        onChange : (event) =>{this.handleStoryValue([event.target.name], item._id)}
                                    }}
                                />
                            ))}
                        </Row>
                    </Col>
                </Form.Row>

                <FormFields
                    initialized={this.props.tagsProtagonistInitialized}
                    group={{controlId : "tagsProtagonist"}}
                    type={"select"}
                    label={{
                        label : "Protagonistes"
                    }}
                    controle={{
                        name : "tagsProtagonist",
                        value : this.state.tagsProtagonist && this.state.tagsProtagonist._id,
                        options : [{_id : "", title : "Sélectionner"},...this.props.tagsProtagonist],
                        disabled : this.props.tagsProtagonist.length ? false : true,
                        optionField : {value : "_id", label : "title"},
                        onChange : (event) =>{this.setState({[event.target.name] : event.target.value})}
                    }}
                />

                <FormFields
                    group={{controlId : "excerpt"}}
                    type={"input"}
                    label={{
                        label : "Résumer"
                    }}
                    controle={{
                        type : "input",
                        as : "textarea",
                        name : "excerpt",
                        value : this.state.story.excerpt,
                        rows :"3",
                        onChange : (event) =>{ this.handleStoryValue([event.target.name] , event.target.value)}
                    }}
                />

                <Row>
                    <Col sm={12}>
                    Tags
                    </Col>
                </Row>

                <TagList
                    row = {{
                        className : "mb-3"
                    }}
                    btn = {{
                        className : "btn btn-primary m-2",
                        type : "button",
                        as : "input"
                    }}
                    remove = {{
                        isRemove : true,
                        remove : this.removeTags
                    }}
                    options = {this.state.story.tags.filter(e => !this.props.tagsProtagonist.includes(e))}
                    optionField = {{
                        value : "_id",
                        label : "title"
                    }}
                />

                <FormFields
                    group={{controlId : "messageSend"}}
                    type={"select"}
                    label={{
                        label : "Message à envoyer"
                    }}
                    controle={{
                        name : "messageSend",
                        value : this.state.messageSend,
                        options : this.optionsMessages,
                        onChange : (event) =>{this.setState({[event.target.name] : event.target.value})}
                    }}
                />

                <FormFields
                    group={{controlId : "status"}}
                    type={"select"}
                    label={{
                        label : "Statut"
                    }}
                    controle={{
                        name : "status",
                        value : this.state.story.status,
                        options : this.optionsStatus,
                        onChange : (event) =>{ this.handleStoryValue([event.target.name] , event.target.value)}
                    }}
                />


                <Button variant="primary" type="submit" className={"btn-loading"}>
                    Valider {this.props.userLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                </Button>
            </Form>
        ) : (
            <div>
                Contenu efface
            </div>
        )
    }
}


const mapStateToProps = (state) => {

    return {
        story: state.story.data,
        storyLoading: state.story.loading,

        tagsStories: state.tagsStories.data,
        tagsStoriesLoading: state.tagsStories.loading,

        tagsProtagonist: state.tagsProtagonist.data,
        tagsProtagonistLoading: state.tagsProtagonist.loading,
        tagsProtagonistInitialized: state.tagsProtagonist.initialized,

        userArticles : state.userArticles.data,
        userArticlesInitialized: state.userArticles.initialized,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateStory,
    fetchUserArticles,
    fetchTagsProtagonist,
    fetchTagsStories,
    resetUserArticles,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormStory);
