import React from 'react';
import {Button,  Form, Modal} from "react-bootstrap";

import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import FormFields from "../components/FormFields";

import {createType, resetType} from "../../creators/facettes.type";
import {fetchTemplates, resetTemplates} from "../../creators/facettes.types.templates";



class FormFacettesType extends React.Component {
    constructor(props) {
        super(props);
        this.isUpdate = this.props._id || false;
        this.initialState =  {
            toggleAlert : false,
            uri : this.props.uri || "",
            tpl : this.props.tpl ? parseInt(this.props.tpl) : 0,

        };
        this.state = {
            ...this.initialState
        }

        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkLastCaractere = this.checkLastCaractere.bind(this);
        this.props.fetchTemplates();
    }

    componentWillUnmount(){
        this.props.resetType();
        this.props.resetTemplates();
    }

    checkLastCaractere(){
        let uri = this.state.uri;
        if(uri && !uri.endsWith("/")){
            uri = `${uri}/`;
            this.setState({uri});
        }
    }


    handleSubmit(e) {
        e.preventDefault();
        if (!this.props.typeLoading) {
            this.props.handleSubmit({
                uri : this.state.uri,
                tpl : this.state.tpl
            })
        }
    }


    render() {

        return (
            <Form onSubmit={(e) => this.handleSubmit(e)}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.isUpdate ? "Modifier" : "Ajouter"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormFields
                        group={{controlId : "uri"}}
                        type={"input"}
                        label={{
                            label : "Format Uri"
                        }}
                        controle={{
                            type : "text",
                            name : "uri",
                            value : this.state.uri,
                            disabled : this.isUpdate,
                            required : true,
                            onBlur : () => {this.checkLastCaractere()},
                            onChange : (event) =>{this.setState({[event.target.name] : event.target.value})},
                            isInvalid : this.props.typeError.uri
                        }}
                        text={{
                            text : "Exemple : {environnement}-xxx/{pratique}/{cheveux}-amateur/",
                            className : "text-secondary"
                        }}
                    />


                    <FormFields
                        group={{controlId : "tpl"}}
                        type={"select"}
                        label={{
                            label : "Template d'affichage"
                        }}
                        controle={{
                            name : "tpl",
                            value : this.state.tpl,
                            //disabled : this.isUpdate,
                            options : [{id : 0, title : "Aucun"},...this.props.templates],
                            optionField : {value : "id", label : "title"},
                            //this.fetchCategories(this.props.categories)
                            onChange : (event) =>{this.setState({[event.target.name] : event.target.value})},
                            isInvalid : this.props.typeError.tpl
                        }}
                    />
                    {this.props.typeStatusCode === 409 ? <p className={"text-danger"}>le Uri existe déja</p> : null}
                </Modal.Body>
                <Modal.Footer>
                    {this.isUpdate ? (<div className={"w-100"}><Button variant="danger" onClick={()=>this.setState({toggleAlert : true})}>
                        Supprimer
                    </Button></div>) : null}
                    <Button variant="primary" type={"submit"}>
                        Valider
                    </Button>
                    <Button variant="danger" onClick={()=>this.props.onHide()}>
                        Annuler
                    </Button>
                </Modal.Footer>
                <Modal centered backdrop={false} show={this.state.toggleAlert} onHide={()=>this.setState({toggleAlert : false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>SUPPRESSION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className={"text-danger font-weight-bold"}>AVERTISSEMENT</p>
                        <p className={"text-danger"}>NE PAS LE FAIRE A MOINS D'ETRE CERTAIN DE CE QUE VOUS FAITES !</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.setState({toggleAlert : false})}>
                            Non
                        </Button>
                        <Button variant="danger" onClick={()=>this.props.handleDelete()}>
                            Oui
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Form>
        )
    }
}


const mapStateToProps = (state) => {

    return {
        type : state.facettesType.data,
        typeError : state.facettesType.error && state.facettesType.error.errors,
        typeStatusCode : state.facettesType.error && state.facettesType.error.statusCode,
        typeLoading : state.facettesType.loading,
        typeInitialized : state.facettesType.initialized,


        templates : state.facettesTypesTemplates.data,
        templatesLoading : state.facettesTypesTemplates.loading,
        templatesInitialized : state.facettesTypesTemplates.initialized,

    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    createType,
    resetType,
    fetchTemplates,
    resetTemplates
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormFacettesType);
