import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {deleteCategory, fetchCategory, resetCategory, updateCategory} from "../../../../creators/category";
import FormCategory from "../../../formulaires/category";
import {Modal} from "react-bootstrap";

class EditCategory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            toggleModalEditCategory : this.props.toggle || false
        }
        this.handleSubmit = this.handleSubmit.bind(this);
        this.props.fetchCategory(this.props.currentId);
    }

    componentWillUnmount(){
        this.handleToggle();
    }

    handleToggle(){
        this.setState({toggleModalEditCategory : false});
        this.props.handleToggle();
    }

    handleDelete(){
        this.props.deleteCategory(this.props.currentId);
        this.handleToggle();
    }

    handleSubmit(data) {
        let category = {
            title : data.title,
            genre : data.genre,
            isMultipleSelection : data.isMultipleSelection,
            metaDescription: data.metaDescription,
            order : data.order
        }

        if (!this.props.categoryLoading) {
            this.props.updateCategory(this.props.category._id,category);
            this.handleToggle();
        }
    }

    render() {
        return (
            <Fragment>
                {this.props.categoryInitialized ? (
                    <Modal centered backdrop={false} show={this.state.toggleModalEditCategory} onHide={()=>this.handleToggle()}>
                        <FormCategory
                            handleSubmit={this.handleSubmit}
                            onHide={()=>this.handleToggle()}
                            _id={this.props.category._id}
                            title={this.props.category.title}
                            slug={this.props.category.slug}
                            parentId={this.props.category.parentId}
                            genre={this.props.category.genre}
                            order={this.props.category.order}
                            isMultipleSelection={this.props.category.isMultipleSelection}
                            metaDescription={this.props.category.metaDescription}
                            isEnableMulti={this.props.category.childrens && this.props.category.childrens.length ? true : false}
                            handleDelete={()=>this.handleDelete()}
                        />
                    </Modal>
                ) : null}
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        category : state.category.data,
        categoryStatusCode : state.category.error && state.category.error.statusCode,
        categoryInitialized : state.category.initialized,
        categoryLoading : state.category.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateCategory,
    resetCategory,
    fetchCategory,
    deleteCategory
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(EditCategory);
