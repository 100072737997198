import React, {Fragment} from 'react';
import {Button, Modal} from "react-bootstrap";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import history from "../../libs/history";

import {deleteUser, resetUser} from "../../creators/user";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from "lodash";


class FormProfileDeleteAccount extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            toggleModal: false,
            error: ""
        };

    }


    handleDelete() {
        let userId = _.get(this.props,"user._id");
        if(userId){
            this.props.deleteUser(userId).then(response=>{
                let error = _.get(response, "error.response.message");
                if(error){
                    this.setState({error});
                }
                else{
                    this.props.resetUser();
                    history.push("/members");
                }
            })
        }
        else{
            this.setState({error : "Aucun ID User"});
        }
    }

    render() {
        return (
            <Fragment>
                {_.get(this.props,"user.deleteAccount.isCurrentDemand") ? (
                    <p>Compte en attente de suppression </p>
                ) : (
                    <Button variant="danger" onClick={()=>this.setState({toggleModal : true, error : ""})}>
                        <FontAwesomeIcon
                            icon="trash"
                        /> Supprimer
                    </Button>
                )}

                <Modal centered backdrop={false} show={this.state.toggleModal} onHide={()=>this.setState({toggleModal : false})}>
                    <Modal.Header closeButton>
                        <Modal.Title>SUPPRESSION</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Voulez-vous vraiment supprime ce compte ?</p>
                        {this.state.error !=="" ? (
                            <p className={"text-danger"}>{this.state.error}</p>
                        ) : null}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>this.setState({toggleModal : false})}>
                            Non
                        </Button>
                        <Button variant="danger" onClick={()=>this.handleDelete()}>
                            Oui
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        userLoading: state.user.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    deleteUser,
    resetUser
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormProfileDeleteAccount);
