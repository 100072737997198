import React, {Fragment} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button, Row, Table} from "react-bootstrap";
import CreateType from "./components/CreateType";
import {PageLoader, SpinLoader} from "../../components/Loader";
import {fetchTypes, resetTypes} from "../../../creators/facettes.types";
import {Link} from "react-router-dom";
import { faBolt, faEdit } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from "lodash";

import EditType from "./components/EditType";
import {createTypesGenerateur} from "../../../creators/facettes.types.generateur";


class Types extends React.Component {
    constructor(props) {
        super(props);
        this.pathPage = `/moteur-a-facettes`;
        this.state = {

        }

        this.props.fetchTypes();

    }

    generator(id = null){
        let data = {};
        if(id){
            data = {
                typeId : id,
            }
        }

        if(!this.props.generatorLoading){
            this.props.createTypesGenerateur(data);
        }
    }

    render() {
        return !this.props.typesInitialized ? (<PageLoader />) :(
            <Fragment>
                <Table responsive>
                    <thead>
                    <tr>
                        <th className={"w-50"}>URI</th>
                        <th>Possibles</th>
                        <th>Existantes</th>
                        <th>Actives</th>
                        <th>Indexables</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {this.props.types.map(facetteType => {
                        let possible = _.get(facetteType, "count.possible") || 0;
                        let existing = _.get(facetteType, "count.existing") || 0;
                        let active = _.get(facetteType, "count.active") || 0;
                        let indexable = _.get(facetteType, "count.indexable") || 0;
                        return (
                            <tr key={facetteType._id}>
                                <td><Link to={`${this.pathPage}/facettes?typeId=${facetteType._id}`}>{facetteType.uri}</Link></td>
                                <td>{possible}</td>
                                <td>
                                    {existing}
                                    {existing < possible ? <FontAwesomeIcon icon={faBolt} className={"ml-2 cursor-pointer"} onClick={()=>this.generator(facetteType._id)} /> : null}
                                </td>
                                <td>{active}</td>
                                <td>{indexable}</td>
                                <td>
                                    <Button variant="outline-primary" size={"sm"} onClick={()=>this.setState({isEdit : true, currentId : facetteType._id})}>
                                        <FontAwesomeIcon icon={faEdit} className={"icon-hover"}  />
                                    </Button>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
                <Row className="justify-content-md-center">
                    <CreateType />
                    <Button disabled={this.props.generatorLoading} variant="outline-dark" className={"ml-4 btn-loading"}  onClick={() =>this.generator()}>
                        <FontAwesomeIcon icon={faBolt} className={"mr-2"} /> Tout générer
                        {this.props.generatorLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                    </Button>
                </Row>
                {this.state.isEdit ? <EditType toggle={this.state.isEdit} handleToggle={()=>this.setState({isEdit : false, currentId : null})} currentId={this.state.currentId} /> : null}
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        types : state.facettesTypes.data,
        typesInitialized : state.facettesTypes.initialized,
        typesLoading : state.facettesTypes.loading,

        generator : state.facettesTypesGenerateur.data,
        generatorInitialized : state.facettesTypesGenerateur.initialized,
        generatorLoading : state.facettesTypesGenerateur.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchTypes,
    resetTypes,
    createTypesGenerateur
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Types);
