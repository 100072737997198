import React from 'react';
import Pagination from "react-bootstrap/Pagination";


const PaginationPage = ({
                        currentPage = 1, pagesCount = 1, paginationLength = 10, previousShow = true, nextShow = true, link = false, search = "", handleChangePage = () => {
    }
                    }) => {
    currentPage = parseInt(currentPage);
    pagesCount = parseInt(pagesCount);

    let canPrevious = (currentPage > 1);
    let canNext = (currentPage < pagesCount);

    const transformCountInArray = (count) => {
        let countArray = [];
        for (let i = 0; i < count; i++) {
            countArray.push(i + 1);
        }
        return countArray;
    };
    const slicedPages = () => {
        let sliceReferPage = (currentPage <= (Math.ceil(paginationLength / 2) - 1)) ? 1 : (currentPage > pagesCount - (Math.ceil(paginationLength / 2) - 1)) ? (pagesCount - paginationLength) + 1 : currentPage - (Math.ceil(paginationLength / 2) - 1);
        return (pagesCount > paginationLength) ? transformCountInArray(pagesCount).slice(sliceReferPage - 1, sliceReferPage - 1 + paginationLength) : transformCountInArray(pagesCount);
    };
    const handleClickPrevious = () => {
        canPrevious && handleChangePage((currentPage - 1), link);
    };
    const handleClickNext = () => {
        canNext && handleChangePage((currentPage + 1), link);
    };
    const handleClickFirst = () => {
        handleChangePage(1, link);
    };
    const handleClickLast = () => {
        handleChangePage(pagesCount, link);
    };

    // window.scrollTo(0, 0);

    return pagesCount > 1 ? (
        <Pagination className={"mt-3"}>
            <Pagination.First onClick={() => handleClickFirst()}/>
            <Pagination.Prev onClick={() => handleClickPrevious()} />

            {slicedPages().map((item) => (<Pagination.Item onClick={() => handleChangePage(item, link)} key={item} active={item === currentPage}>{item}</Pagination.Item>))}
            <Pagination.Next onClick={() => handleClickNext()} />
            <Pagination.Last onClick={() => handleClickLast()} />
        </Pagination>
    ) : null
};

export default PaginationPage;

