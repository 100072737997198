import React from 'react';
import {Button, Col, Form} from "react-bootstrap";
import {config} from '../../config';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import {fetchUser, updateUser} from "../../creators/user";

import {SpinLoader} from "../components/Loader";
import FormFields from "../components/FormFields";
import FormDatingInformation from "./member.dating.information";


class FormDating extends React.Component {
    constructor(props) {
        super(props);
        this.listGender = [];
        this.listOrientation = [];

        this.state = {
            appearInDating : false,
            lookingFor : [],
            tendencies : [],
            informations: [],
            mobility : 0,
            civilStatus : 0
        };

        // this.handleInformations = this.handleInformations.bind(this);
        // this.handleGender = this.handleGender.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCheckbox = this.handleCheckbox.bind(this);
        this.handleProfileInformations = this.handleProfileInformations.bind(this);
    }

    componentDidMount(oldProps) {
        //this.props.fetchUser();
        const newProps = this.props;
//        if ((oldProps && oldProps.currentUserInitialized !== newProps.currentUserInitialized) || newProps.currentUserInitialized === true) {
            let profile = newProps.user.profile;
            let setting = newProps.user.setting;
            this.setState({
                lookingFor: profile.lookingFor || [],
                mobility: profile.mobility || 0,
                tendencies: profile.tendencies || [],
                civilStatus: profile.civilStatus || 0,
                informations: profile.informations || [],
                appearInDating: setting.appearInDating || false
            });
       // }
    }


    handleCheckbox(name, id) {
        let stateName = this.state[name];
        if (stateName.includes(id)) {
            stateName = stateName.filter(item => item !== id);
        } else {
            stateName.push(id)
        }
        this.setState({[name]: stateName})
    }

    handleProfileInformations(id, name, value) {
        let informations = this.state.informations;
        let informationIndex = informations.findIndex(item => item._id === id);
        if (name === "personality") {
            if (!informations[informationIndex][name].includes(parseInt(value))) {
                informations[informationIndex][name].push(parseInt(value));
                this.setState({informations});
            } else {
                informations[informationIndex][name] = informations[informationIndex][name].filter(item => item !== value);
                this.setState({informations});
            }
        } else {
            informations[informationIndex][name] = parseInt(value);
            this.setState({informations});
        }
    }


    handleSubmit(e) {
        e.preventDefault();
        if (!this.props.userLoading) {
            let user = {
                profile: {
                    lookingFor: this.state.lookingFor,
                    tendencies: this.state.tendencies,
                    mobility: this.state.mobility,
                    civilStatus: this.state.civilStatus,
                    informations: this.state.informations,
                },
                setting : {
                    appearInDating : this.state.appearInDating
                }
            };
            this.props.updateUser(this.props.user._id, user);
        }
    }

    render() {
        return (
            <Form onSubmit={(e) => this.handleSubmit(e)}>
                <Form.Row>
                    <h5 className={"w-100"}>Options Générale</h5>
                    <FormFields
                        type={"checkbox"}
                        group={{as : Col}}
                        checkBox={{
                            id : `checkbox-appearInDating`,
                            name : "appearInDating",
                            checked : this.state.appearInDating,
                            label : "Afficher vos informations dans la section rencontre ?",
                            onChange : (event) =>{this.setState({appearInDating : !this.state.appearInDating})}
                        }}
                    />
                </Form.Row>

                <Form.Row>
                    <h5 className={"w-100"}>Recherche</h5>
                    {config.lookingFor.map((name, lookingForIndex) => {
                        if (lookingForIndex > 0) {
                            return (
                                <FormFields
                                    key={`lookingFor_${lookingForIndex}`}
                                    type={"checkbox"}
                                    group={{className : "w-25"}}
                                    checkBox={{
                                        id : `lookingFor_${lookingForIndex}`,
                                        name : `lookingFor_${lookingForIndex}`,
                                        checked : this.state.lookingFor.includes(lookingForIndex),
                                        label : name,
                                        onChange : (event) =>{this.handleCheckbox("lookingFor", lookingForIndex)}
                                    }}
                                />
                            )
                        } else return null;
                    })}
                </Form.Row>

                <Form.Row>
                    <h5 className={"w-100"}>Tendance</h5>
                    {config.tendencies.map((name, tendenciesIndex) => {
                        if (tendenciesIndex > 0) {
                            return (
                                <FormFields
                                    key={ `tendencies_${tendenciesIndex}`}
                                    type={"checkbox"}
                                    group={{className : "w-25"}}
                                    checkBox={{
                                        id : `tendencies_${tendenciesIndex}`,
                                        name : `tendencies_${tendenciesIndex}`,
                                        checked : this.state.tendencies.includes(tendenciesIndex),
                                        label : name,
                                        onChange : (event) =>{this.handleCheckbox("tendencies", tendenciesIndex)}
                                    }}
                                />
                            )
                        } else return null;
                    })}
                </Form.Row>

                <Form.Row>
                    <h5 className={"w-100"}>Mobilité</h5>
                    {config.mobility.map((name, mobilityIndex) => (
                        <FormFields
                            key={ `mobility_${mobilityIndex}`}
                            type={"radio"}
                            group={{className : "w-25"}}
                            checkBox={{
                                id : `mobility_${mobilityIndex}`,
                                name : `mobility_${mobilityIndex}`,
                                checked : this.state.mobility === mobilityIndex,
                                label : name,
                                onChange : (event) =>{this.setState({mobility: mobilityIndex})}
                            }}
                        />
                    ))}
                </Form.Row>

                <Form.Row>
                    <h5 className={"w-100"}>Statut marital</h5>
                    {config.civilStatus.map((name, civilStatusIndex) => (
                        <FormFields
                            key={`civilStatus_${civilStatusIndex}`}
                            type={"radio"}
                            group={{className : "w-25"}}
                            checkBox={{
                                id : `civilStatus_${civilStatusIndex}`,
                                name : `civilStatus_${civilStatusIndex}`,
                                checked : this.state.civilStatus === civilStatusIndex,
                                label : name,
                                onChange : (event) =>{this.setState({civilStatus: civilStatusIndex})}
                            }}
                        />
                    ))}

                </Form.Row>
                <Form.Row>
                    <h5 className={"w-100"}>Tableau d'information</h5>
                    {this.state.informations.map((information, index) => (
                        <FormDatingInformation
                            key={index}
                            information={information}
                            informationsLength={this.state.informations.length}
                            handleProfileInformations={this.handleProfileInformations}
                            index={index}/>
                    ))}
                </Form.Row>

                <Button variant="primary" type="submit" className={"btn-loading"}>
                    Valider {this.props.userLoading && (<div className={"pl-1"}><SpinLoader className={"xsmall"} /></div>)}
                </Button>
            </Form>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        user: state.user.data,
        userLoading: state.user.loading,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    updateUser,
    fetchUser
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormDating);
