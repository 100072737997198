import {Row} from "react-bootstrap";
import React from "react";

const CategoriesList = (props) => {
    let {
        row = {
            className : ""
        },
        btn = {
            className : "btn m-2",
            type : "button",
            as : "input"
        },
        /*remove = {
            isRemove : false,
            remove : ()=>{console.log("function non envoyer")}
        },*/
        options = [],
        optionField = {
            value : "value",
            label : "label"
        },
        valuesCheck = []
    } = props;


    return (
        <Row {...row}>
            {options.map((item, index) => {
                btn.className = `btn m-1 f-s-11 ${valuesCheck.includes(item[optionField.value].toString()) ? "btn-primary" : "btn-light"}`;
                if(!props.initial && item[optionField.label] === "Photo" && !valuesCheck.includes(item[optionField.value].toString())){
                    props.handleCategories(item[optionField.value].toString(), item.parentId);
                }
                return (
                    <button {...btn} key={index} onClick={() =>props.handleCategories(item[optionField.value].toString(), item.parentId)}>
                        {item[optionField.label]}
                    </button>
                )
            })}
        </Row>
    )
};
export default CategoriesList;