import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";

let redux = reduxCreator.type('CATEGORY').plugin(axiosPlugin);

let fetchAction = redux.fetchActionGetOne();

let createAction = redux.fetchActionCreate();

let updateAction = redux.fetchActionUpdate();

let deleteAction = redux.fetchActionDelete();

export const TYPES = redux.types;

export const fetchCategory = (categoryId, query = {}) => fetchAction(`/categories/${categoryId}?${queryString.stringify(query)}`);
export const createCategory = (data = {}) => createAction(`/categories`, data);
export const updateCategory = (categoryId, data = {}) => updateAction(`/categories/${categoryId}`, data);
export const deleteCategory = (categoryId = {}) => deleteAction(`/categories/${categoryId}`);
export const resetCategory = () => redux.actions.reset();

export const category = redux.reducer();