import {applyMiddleware, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import axiosMiddleware from 'redux-axios-middleware';
import createSagaMiddleware from 'redux-saga';
import reducers from './reducers';
import rootSaga from './sagas';

import tokenInterceptor from './interceptors/token';

import {apiUrl} from './libs/api';

import dataInterceptor from './libs/mk-womanizer/interceptor';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware(rootSaga);

const axiosMiddlewareConfig = {
    interceptors: {
        request: [
            tokenInterceptor.request,
        ],
        response: [
            tokenInterceptor.response,
            dataInterceptor.response,
        ]
    }
};

let store = createStore(
    reducers,
    {},
    composeEnhancers(
        applyMiddleware(thunk, axiosMiddleware(apiUrl, axiosMiddlewareConfig), sagaMiddleware),
    )
);

sagaMiddleware.run(rootSaga);

export default store;
