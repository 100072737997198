import React, {Fragment} from "react";
import BlockStyleButton from "./BlockStyleButton";
import HeaderStyleDropdown from "./HeaderStyleDropdown";

export const BLOCK_TYPES = [
    {label: " “ ” ", style: "blockquote"},
    {label: "UL", style: "unordered-list-item"},
    {label: "OL", style: "ordered-list-item"},
    // {label: "{ }", style: "code-block"}
];

export const HEADER_TYPES = [
    {label: "Paragraphe", labelTxt: "Paragraphe", style: "unstyled"},
    {label: "<h1>Titre 1</h1>", labelTxt: "Titre 1", style: "header-one"},
    {label: "<h2>Titre 2</h2>", labelTxt: "Titre 2", style: "header-two"},
    {label: "<h3>Titre 3</h3>", labelTxt: "Titre 3", style: "header-three"},
    {label: "<h4>Titre 4</h4>", labelTxt: "Titre 4", style: "header-four"},
    {label: "<h5>Titre 5</h5>", labelTxt: "Titre 5", style: "header-five"},
    {label: "<h6>Titre 6</h6>", labelTxt: "Titre 6", style: "header-six"}
];

export function getBlockStyle(contentBlock) {
    const type = contentBlock.getType();
    if (type === "blockquote") return "blockquote--classic";
}

class BlockStyleToolbar extends React.Component {
    render() {
        const {editorState} = this.props;
        const selection = editorState.getSelection();
        const blockType = editorState
            .getCurrentContent()
            .getBlockForKey(selection.getStartKey())
            .getType();

        return (
            <Fragment>
                <HeaderStyleDropdown
                    headerOptions={HEADER_TYPES}
                    active={blockType}
                    onToggle={this.props.onToggle}
                />
                <div className="mk-editor-btn-group">
                    {BLOCK_TYPES.map(type => {
                        return (
                            <BlockStyleButton
                                active={type.style === blockType}
                                label={type.label}
                                onToggle={this.props.onToggle}
                                style={type.style}
                                key={type.label}
                                type={type}
                            />
                        );
                    })}
                </div>
            </Fragment>
        );
    }
}

export default BlockStyleToolbar;
