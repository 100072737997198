import {put,takeEvery} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import {TYPES as CONFIGURATION_TYPE} from '../creators/configuration';
import {TYPES as CONFIGURATIONS_TYPE} from '../creators/configurations';


export function* updateSuccess(action) {
    try {
        yield put({type: CONFIGURATIONS_TYPE.UPDATE, data : action.payload});
    } catch (error) {
    }
}

export function* watchConfiguration() {
    yield takeEvery(CONFIGURATION_TYPE.UPDATE_SUCCESS, updateSuccess);
}

export default watchConfiguration;

