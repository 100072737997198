import React, {Fragment} from 'react';

import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {LoadingBar} from "../../components/Loader";
import {Table} from "react-bootstrap";
import ColoneTn from "../../components/ColoneTh";
import {fetchConfigurations, resetConfigurations} from "../../../creators/configurations";
import Icon from "../../components/Icon";
import {updateConfiguration} from "../../../creators/configuration";


class Configurations extends React.Component {
    constructor(props) {
        super(props);
        this.props.fetchConfigurations();
    }

    updateEtatConfig(id, value){
        this.props.updateConfiguration(id, {status : value});
    }

    render() {
        return (
            <Fragment>
                <div id={"configurations"}>
                    <div className={"configurations-list"}>
                        {!this.props.configurationsInitialized ? (
                            <LoadingBar />
                        ) : (
                            <Table striped bordered hover>
                                <thead>
                                <tr>
                                    <ColoneTn label={"Config"}  />
                                    <ColoneTn label={"Etat"}  />
                                    <ColoneTn label={""}  />
                                </tr>
                                </thead>
                                <tbody>
                                {this.props.configurations.map(config =>(
                                    <tr key={config._id}>
                                        <td>{config.title}</td>
                                        <td width={"100px"}>{config.status ? "Activé" : "Désactivé"}</td>
                                        <td width={"20px"} ><span className={"cursor-pointer"} onClick={()=>this.updateEtatConfig(config._id, !config.status)} ><Icon type={config.status ? "arrow-down" : "arrow-up"} iconSize={16} /></span></td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        )}
                    </div>
                </div>
            </Fragment>
        )
    }
}


const mapStateToProps = (state) => {
    return {
        currentUserInitialized: state.currentUser.initialized,

        configurations : state.configurations.data,
        configurationsLoading : state.configurations.loading,
        configurationsInitialized : state.configurations.initialized,
    }
};

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchConfigurations,
    resetConfigurations,
    updateConfiguration
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Configurations);
