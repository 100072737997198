import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('USER_CERTIFICATIONS').plugin(axiosPlugin, {template: "array"});

let fetchAction = redux.fetchActionGet();


export const fetchUserCertifications = (ownerId = null,query = {}) => fetchAction(`/users/${ownerId}/certifications?${queryString.stringify(query)}`);
export const resetUserCertifications = () => redux.actions.reset();

export const userCertifications = redux.reducer();