import queryString from 'querystring';

import reduxCreator from '../libs/mk-womanizer/redux-creator';
import axiosPlugin from "../libs/mk-womanizer/ac-axios-plugin";


let redux = reduxCreator.type('USER_WARNINGS').plugin(axiosPlugin, {template: "array"});

let fetchUserWarningsAction = redux.fetchActionGet();

redux.reducerForType('ADD', (state,payload) => {
    let newState = {...state};
    newState.data.push(payload.data);
    newState.total++;
    return newState;
});

redux.reducerForType('DELETE', (state,payload) => {
    let newState = {...state};
    if(payload.data && payload.data._id){
        newState.data = newState.data.filter(item => item._id.toString() !== payload.data._id.toString());
        newState.total--;
        if(newState.total < 0){
            newState.total = 0;
        }
    }
    return newState;
});

redux.reducerForType('UPDATE', (state,payload) => {
    let newState = {...state};
    if(payload.data && payload.data._id){
        newState.data = newState.data.map(item => {
            if(item._id.toString() === payload.data._id.toString()){
                item = payload.data;
            }
            return item;
        });
    }
    return newState;
});


export const fetchUserWarnings = (userId, query = {}) => fetchUserWarningsAction(`/users/${userId}/warnings?${queryString.stringify(query)}`);
export const resetUserWarnings = () => redux.actions.reset();

export const userWarnings = redux.reducer();